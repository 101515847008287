import { useDispatch, useSelector } from "react-redux";
import { TextField, Typography, Stack, Dialog } from "@mui/material";
import { useState } from "react";
import dayjs from "dayjs";
import CloseIcon from "@mui/icons-material/Close";
import { addMoreQuestionsModel } from "../../../../services/modules/models/Actions";
import { useThemePalette } from "../../../../common/hooks/theme_palette";
import { icons } from "../../../../assets/AssetHelper";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import { getQuestionBankDetailsRequest } from "../../../../services/modules/question-bank";
import QuestionsList from "../questionBank/questionsList";
import { setSelectedQuestionsRequest } from "../../../../services/modules/quiz";

const AddMoreQuestions = ({ setImportedQuestions }) => {
  const { inputBg, secondryColor } = useThemePalette();
  const isOpenAddMoreQuestions = useSelector((state) => state.model.isOpenAddMoreQuestions);
  const questionBanks = useSelector((state) => state.questionBanks.questionBanks);
  const questionBank = useSelector((state) => state.questionBanks.questionBank);
  const { quizData, questions } = useSelector((state) => state.quiz);
  const dispatch = useDispatch();
  const [bankId, setBankId] = useState("");
  const [checked, setChecked] = useState([false]);
  const [selectedquestions, setSelectedQuestions] = useState([]);
  const question = questions?.map((data) => data.data);

  const selectedBankQuestions = selectedquestions?.map((select, index) => {
    return { ...select?.data, position: question?.length + (index + 1) };
  });
  //console.log(questionBank.questions)
  return (
    <Dialog
      open={isOpenAddMoreQuestions}
      onClose={() => dispatch(addMoreQuestionsModel())}
      PaperProps={{
        sx: {
          borderRadius: "12px",
          height: "auto",
          minWidth: "1160px",
          padding: "20px",
        },
      }}
    >
      <Stack direction="row" display="flex" justifyContent="space-between" mb="114px">
        {/* questions banks*/}
        <Stack width={{ sx: "100%", md: "35%" }} spacing={2}>
          <Typography fontWeight="600" fontSize="20px" lineHeight="24px">
            Select Form
          </Typography>
          <Typography my="20px" fontWeight="500" fontSize="16px" lineHeight="19px" color="#8B8D97">
            Find the form that you want to import the questions
          </Typography>
          <TextField
            className="degree-input"
            InputProps={{
              startAdornment: <img src={icons.searchIcon} alt="search" />,
            }}
            id="outlined-textarea"
            placeholder="Search form name"
          />
          <Stack spacing={2}>
            {questionBanks?.map((questionBank, index) => {
              return (
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={2}
                  sx={{ cursor: "pointer" }}
                  onClick={() => setBankId(questionBank?.quiz_id)}
                  key={index}
                >
                  <img src={icons.QuestionBankIcon} alt="question bank" />
                  <Stack>
                    <Typography
                      fontWeight="600"
                      fontSize="16px"
                      lineHeight="19px"
                      color="#45464E"
                      onClick={(_) =>
                        dispatch(getQuestionBankDetailsRequest({ bank_id: questionBank?.quiz_id }))
                      }
                    >
                      {questionBank?.title} {index + 1}
                    </Typography>
                    <Typography fontWeight="400" fontSize="12px" lineHeight="15px" color="#8B8D97">
                      Last Modified : {dayjs(questionBank?.due_date)?.format("hh:mm a")}
                    </Typography>
                  </Stack>
                </Stack>
              );
            })}
          </Stack>
        </Stack>
        {/* questions */}
        <Stack width={{ sx: "100%", md: "58%" }} spacing={2}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mx={1}>
            <Typography fontWeight="600" fontSize="20px" lineHeight="24px">
              Select questions
            </Typography>
          </Stack>
          {!questionBank.questions || questionBank.questions?.length === 0 ? (
            <>
              <img
                src={icons.EmptyQuestionsIcons}
                width="140px"
                height="140px"
                style={{ alignSelf: "center", marginTop: "100px" }}
                alt=""
              />
              {/* <Typography textAlign="center">No Orders Yet?</Typography> */}
              <Typography textAlign="center" sx={{ marginBottom: "100px !important" }}>
                Select the form first to show all questions that you want to import
              </Typography>
            </>
          ) : (
            <>
              <Typography
                my="20px"
                fontWeight="500"
                fontSize="16px"
                lineHeight="19px"
                color="#8B8D97"
              >
                Select the questions that you want to import to your quiz
              </Typography>
              <TextField
                className="degree-input"
                InputProps={{
                  startAdornment: <img src={icons.searchIcon} alt="search" />,
                }}
                id="outlined-textarea"
                placeholder="Search form name"
              />
              <QuestionsList
                questions={questionBank?.questions}
                setChecked={setChecked}
                checked={checked}
                selectedquestions={selectedquestions}
                setSelectedQuestions={setSelectedQuestions}
              />
            </>
          )}
        </Stack>
        <Stack width={{ sx: "100%", md: "2%" }} spacing={2}>
          <Stack
            onClick={() => dispatch(addMoreQuestionsModel())}
            sx={{
              cursor: "pointer",
              background: " #FFF2E2",
              borderRadius: "8px",
              alignItems: "center",
              justifyContent: "center",
              padding: "4px",
            }}
          >
            <CloseIcon fontSize="14px" />
          </Stack>
        </Stack>
      </Stack>
      {/* controlled */}
      <Stack
        direction="row"
        position="absolute"
        width="100%"
        boxShadow="0px -2px 14px rgba(0, 0, 0, 0.1)"
        borderRadius="0px 0px 12px 12px"
        bottom="0"
        left="0"
        background="white"
        height="114px"
        style={{ zIndex: "10" }}
        alignItems="center"
        justifyContent="flex-end"
      >
        <PrimaryButton
          text="Cancel"
          variant={"outlined"}
          style={{
            borderColor: secondryColor,
            color: secondryColor,
            height: "45px",
            width: "150px",
            marginRight: "15px",
          }}
          click={() => dispatch(addMoreQuestionsModel())}
        />
        <PrimaryButton
          bgColor={secondryColor}
          text="Select"
          disabled={selectedquestions?.length > 0 ? false : true}
          variant={"contained"}
          style={{ color: "white", height: "45px", width: "150px", marginRight: "15px" }}
          click={() => {
            dispatch(addMoreQuestionsModel());
            dispatch(
              setSelectedQuestionsRequest({
                quiz_id: quizData?.quiz_id,
                body: {
                  quiz_questions: [...selectedBankQuestions, ...question],
                },
              })
            );
          }}
        />
      </Stack>
    </Dialog>
  );
};

export default AddMoreQuestions;
