import { CoursesURL } from "../../utils/constant";
import { adminCoursesLoading } from "../Actions/courses"
import { addCourseInstructorsSuccess, deleteCourseInstructorsSuccess, getCourseInstructorsSuccess } from "../Actions/instrucrors"
import { reduxRequest } from "common/utils/reduxRequest";

export const getCourseInstructorsRequest = ({ ...variables }) => {
    const requestData = {
        isLoading: adminCoursesLoading,
        successFunction: getCourseInstructorsSuccess,
        url: `${CoursesURL}/instructors?course_id=${variables?.course_id}`,
        method: "get",
        loadingType: "instructor list",
        ...variables,
    };
    return async (dispatch) => {
        reduxRequest({
            dispatch,
            ...requestData,
        });
    };
}
export const AddCourseInstructorsRequest = ({ ...variables }) => {
    const requestData = {
        isLoading: adminCoursesLoading,
        successFunction: addCourseInstructorsSuccess,
        url: `${CoursesURL}/instructors?course_id=${variables?.course_id}`,
        method: "post",
        loadingType: "list",
        ...variables,
    };
    return async (dispatch) => {
        reduxRequest({
            dispatch,
            ...requestData,
        });
    };
}
export const DeleteCourseInstructorsRequest = ({ ...variables }) => {
    const requestData = {
        isLoading: adminCoursesLoading,
        successFunction: deleteCourseInstructorsSuccess,
        url: `${CoursesURL}/instructors?course_id=${variables?.course_id}&teacher_id=${variables?.teacher_id}`,
        method: "delete",
        loadingType: "list",
        ...variables,
    };
    return async (dispatch) => {
        reduxRequest({
            dispatch,
            ...requestData,
        });
    };
}