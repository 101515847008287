import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import DescriptionCard from "../cards/description.jsx";
import QuestionCard from "../cards/question.jsx";
import TitleCard from "../cards/title.jsx";
import { ItemTypes } from "./item_types.js";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

export const Card = ({ id, type, data, index, moveCard, setCards, cards, display, setDisplay }) => {

  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      setDisplay((s) => {
        return {
          state: "block",
          id: data?.quiz_question_id,
          index: index,
          data: data,
        };
      });
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  const Cards = {
    title: TitleCard,
    question: QuestionCard,
    description: DescriptionCard,
  };

  const style = {
    padding: "0.5rem 1rem",
    marginBottom: ".5rem",
    backgroundColor: "white",
    position: "relative",
  };

  return (
    <div
      ref={ref}
      style={{ ...style, opacity }}
      className="card_shadow"
      data-handler-id={handlerId}
    >
      {display?.index === index && (
        <DragIndicatorIcon sx={{ cursor: "move", transform: "rotate(90deg)", color: "#BDBDBD" }} />
      )}
      {React.createElement(Cards[type], {
        data: { ...data, id: id },
        setCards: setCards,
        cards: cards,
        index: index,
        display: display,
      })}
    </div>
  );
};
