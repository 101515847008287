import { reduxRequest } from "common/utils/reduxRequest";
import { LookupsURL } from "../utils/constant";
import { getAdminLookupsSuccess, adminLookupsLoading, getCurriculumsLookupsSuccess, getClassesLookupsSuccess, getClassSubjectsLookupsSuccess, getScrollClassesLookupsSuccess } from "./Actions";

export const getAdminLookupsRequest = () => {
  const requestData = {
    isLoading: adminLookupsLoading,
    successFunction: getAdminLookupsSuccess,
    url: `${LookupsURL}`,
    method: "get",
    loadingType: "list",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
export const getCurriculumsLookupsRequest = () => {
  const requestData = {
    isLoading: adminLookupsLoading,
    successFunction: getCurriculumsLookupsSuccess,
    url: `v1/curriculums`,
    method: "get",
    loadingType: "list",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
export const getClassesLookupsRequest = ({...variables}) => {
  const requestData = {
    isLoading: adminLookupsLoading,
    successFunction: getClassesLookupsSuccess,
    url: `v1/curriculums/${variables.curriculum_id}/classes`,
    method: "get",
    loadingType: "list",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
export const getScrolledClassesLookupsRequest = ({...variables}) => {
  const requestData = {
    isLoading: adminLookupsLoading,
    successFunction: getScrollClassesLookupsSuccess,
    url: `v1/curriculums/${variables.curriculum_id}/classes`,
    method: "get",
    loadingType: "list",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
export const getClassSubjectsLookupsRequest = ({...variables}) => {
  const requestData = {
    isLoading: adminLookupsLoading,
    successFunction: getClassSubjectsLookupsSuccess,
    url: `v1/classes/${variables.class_id}/subjects`,
    method: "get",
    loadingType: "list",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
