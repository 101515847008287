import { Stack } from "@mui/material";
import {
  checkOtherOptionChossen,
  handelAddOption,
} from "components/Organisms/quiz/utils/question_func";
import React from "react";
import { useDispatch } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import { useThemePalette } from "common/hooks/theme_palette";

const QuestionAddOptions = ({
  setOptionsCount,
  setOptionFocus,
  course_id,
  quiz_id,
  question_quiz_id,
  optionsCount,
  optionsData,
  setCards,
  cards,
  index,
}) => {
  const dispatch = useDispatch();
  const { inputBg, secondryColor } = useThemePalette();

  return (
    <Stack direction="row" alignItems="center" color={secondryColor} mb={1}>
      <AddIcon />
      <p
        style={{ cursor: "pointer" }}
        onClick={(event) =>
          handelAddOption(
            "",
            setOptionsCount,
            setOptionFocus,
            optionsCount,
            dispatch,
            course_id,
            quiz_id,
            question_quiz_id,
            index,
            setCards,
            cards
          )
        }
      >
        Add option
      </p>
      {/* {!checkOtherOptionChossen(optionsData) && (
        <>
          <span style={{ color: "black", marginInline: "10px" }}>Or</span>
          <p
            style={{ cursor: "pointer" }}
            onClick={() => {
              handelAddOption(
                "Other",
                setOptionsCount,
                setOptionFocus,
                optionsCount,
                dispatch,
                course_id,
                quiz_id,
                question_quiz_id,
                index,
                setCards,
                cards
              );
            }}
          >
            Add other
          </p>
        </>
      )} */}
    </Stack>
  );
};

export default QuestionAddOptions;
