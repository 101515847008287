import React from "react";
import { IconButton, Menu, MenuItem, MenuList } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreHorizRounded";

const TableActionList = ({ id, index, data, actionsList, open, handleClick, handleClose,anchorEl }) => {
  return (
    <div >
      <IconButton
        aria-label="more"
        id={`${id}-${index}`} // Generate a unique ID for each IconButton
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={(event) => handleClick(event, id)} // Pass the id to handleClick
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": `${id}-${index}`, // Use the same ID for MenuList
        }}
        anchorEl={open ? document.getElementById(`${id}-${index}`) : null} // Use the same ID to reference the anchor element
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          style: {
            boxShadow: "rgba(0, 0, 0, 0.08) 0px 4px 32px !important",
            width: "130px",
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuList autoFocusItem={open} id="composition-menu" aria-labelledby="composition-button">
          {actionsList({ id, index, data })?.map((action, index) => {
            return (
              action.condition(index) === true && (
                <MenuItem
                  key={index}
                  onClick={() => {
                    action.click();
                    handleClose();
                  }}
                >
                  {action.text}
                </MenuItem>
              )
            );
          })}
        </MenuList>
      </Menu>
    </div>
  );
};

export default TableActionList;
