import { TextField } from "@mui/material";
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

export default function BasicTimePicker({ id, value, setValue, sx, placeholder, onChange,minTime,maxTime,disableIgnoringDatePartForTimeValidation }) {
  return (
    <TimePicker
      id={id}
      value={value}
      onChange={setValue ? (newValue) => setValue(newValue) : onChange}
      placeholder={placeholder}
      views={['hours', 'minutes']}
      
      renderInput={(params) => <TextField {...params} sx={sx} 
      
      inputProps={{
        ...params.inputProps,
        readOnly: true
      }}
      />
    }

    minTime={minTime}
    maxTime={maxTime}
    disableIgnoringDatePartForTimeValidation={disableIgnoringDatePartForTimeValidation}

    />
  );
}
