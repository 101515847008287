import { Divider, Stack, Typography,Box,Accordion,AccordionActions,AccordionSummary,AccordionDetails } from "@mui/material"
import React from "react"
import { icons } from "../../../../assets/AssetHelper";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const QuestionCard = ({quizData,question,attempt_info})=>{

    //console.log(quizData)
    return(

        <Stack flexDirection={'column'} gap={1}>
        <Stack flexDirection={'row'} justifyContent={'space-between'}>
        <Typography fontSize={'25px'}>Quiz: {quizData?.title} </Typography>
        <Typography fontSize={'25px'} color={"#FFBA00"} fontWeight={600}>{attempt_info?.evaluation?.correct_answers_number}/{attempt_info?.evaluation?.total_number_of_questions} Points</Typography>
        </Stack>

        <Stack flexDirection={'row'} gap={1}>
            <Typography>Grade Received</Typography>
            <Typography color={"#5CC97A"}>{attempt_info?.evaluation?.grade}%</Typography>

        </Stack>

        <Typography>To Pass {quizData?.quiz_configuration?.passing_grade}% or higher</Typography>

        <Divider/>
        <Box sx={{width:'80%'}}>
            <Typography fontSize={'22px'}>
            {question?.text_question}
            </Typography>
        </Box>


        {
        question?.quiz_question_choices?.map((choice,index)=>{
            return(
                <Stack flexDirection={'row'} key={index} justifyContent={'space-between'}>
                    <Typography fontSize={'22px'} >
                    {index+1}- {choice?.text_choice}
                    </Typography>
                    {choice.quiz_question_choice_id==question?.attempt_answer?.quiz_question_choice_id?<img src={choice?.is_correct_answer?icons?.correctIcon:icons?.wrongIcon}/>:null}
                </Stack>
            )
        })
        
        }
        <Divider/>
    <Accordion
    sx={{backgroundColor:'transparent',
    boxShadow:'none',
    '&:before': {
        display: 'none',
    }
        }}
    >
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
        >
        <Typography fontSize={'28px'} color={"#27AE60"} fontWeight={600}>Hints</Typography>
        </AccordionSummary>
        <AccordionDetails>
        {question?.hint?question?.hint:"No hint was provided for this question."}
        </AccordionDetails>
      </Accordion>
      <Divider/>


        <Stack flexDirection={'column'} sx={{backgroundColor:'#0D8C401F',borderRadius:'8px' ,padding:2}} gap={1}>
            <Stack flexDirection={'row'} gap={1}>
                <img src={icons?.correctGreenIcon}/>
                <Typography fontSize={'24px'} fontWeight={600} color={'#27AE60'}>Correct answer</Typography>

            </Stack>
            <Typography fontSize={'22px'}>
                {question?.attempt_answer?.answer_explanation}
                 </Typography>
        </Stack>
    </Stack>
    )

}

export default QuestionCard