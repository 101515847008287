// var En = false
// function getEnglishLanguage() {
//     if(localStorage.getItem("lang") === "en"){
//         En = true;
//     }
// };
// getEnglishLanguage();

export function makeFilterString(filter_obj) {
    var filterString = "?"
    Object.keys(filter_obj).map(function (key) {
        if (filter_obj[key]) {
            filterString += key + "=" + filter_obj[key] + "&"
        }
    })
    if (filterString.substr(filterString.length - 1) === '&') {
        filterString = filterString.slice(0, -1);
    }
    return filterString
}

export const findInObject = (object, attr) => {
    const res = Object.keys(object)
        .filter((key) => key === attr)
        .reduce((obj, key) => {
            return Object.assign(obj,
                object[key]
            );
        }, {});
    return res
}

export const resetEditableInputValue = (name, value) => {
    console.log(name, value);
    document.getElementById(name).value = value;
};

export const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;


export const isScrolledToBottom = (e, extra = 0) => {
    const bottom = e.target.scrollHeight - e.target.clientHeight <= e.target.scrollTop + extra;
    return bottom;
};

// export const DaysList = [
//     {
//         name: En ? "Saturday" : "السبت",
//         id: "Saturday"
//     },
//     {
//         name: En ? "Sunday" : "الأحد",
//         id: "Sunday"
//     },
//     {
//         name: En ? "Monday" : "الاثنين",
//         id: "Monday"
//     },
//     {
//         name: En ? "Tuesday" : "الثلاثاء",
//         id: "Tuesday"
//     },
//     {
//         name: En ? "Wednesday" : "الاربعاء",
//         id: "Wednesday"
//     },
//     {
//         name: En ? "Thursday" : "الخميس",
//         id: "Thursday"
//     },
//     {
//         name: En ? "Friday" : "الجمعة",
//         id: "Friday"
//     },
// ]

// export const Duration = [
//     {
//         name: En ? "Morning" : "صباحا",
//         id: "AM"
//     },
//     {
//         name: En ? "Evening" : "مساءا",
//         id: "PM"
//     },
// ]

// export const Intervals = [
//     {
//         name: En ? "1 Hour" : "ساعة",
//         id: "60"
//     },
//     {
//         name: En ? "1.5 Hour" : "ساعة ونصف",
//         id: "90"
//     },
//     {
//         name: En ? "2 Hours" : "ساعتين",
//         id: "120"
//     },
// ]

// export const Gender = [
//     {
//         name: En ? "Male" : "ذكر",
//         id: "male"
//     },
//     {
//         name: En ? "Female" : "انثي",
//         id: "female"
//     },
// ]

// export const groupBy = (objectArray, property) => {
//     return objectArray.reduce(function (acc, obj) {
//       var key = obj[property];
//       if (!acc[key]) {
//         acc[key] = [];
//       }
//       acc[key].push(obj);
//       return acc;
//     }, {});
// }

// const ConvertToArabicNumbers = (num) => {
//     const arabicNumbers = '\u0660\u0661\u0662\u0663\u0664\u0665\u0666\u0667\u0668\u0669';
//    return new String(num).replace(/[0123456789]/g, (d)=>{return arabicNumbers[d]});
// }

// export const convertTimeFormate = (incomingTime) => {
//     const time = incomingTime;
//     const timeArr = time?.split(" ");
//     if(timeArr !== undefined){
//         var formatedTime;
//         if(!En){
//             if(timeArr[1] === 'AM'){
//                 formatedTime = `${ConvertToArabicNumbers(timeArr[0])} ص`
//             }else{
//                 formatedTime = `${ConvertToArabicNumbers(timeArr[0])} م`
//             }
//         }else{
//             formatedTime = time.slice(0, 8);
//         }
//         return formatedTime;
//     }
// }

// export const convertDayLocale = (day) => {
//     var Day;
//     if(!En){
//         if(day === 'Saturday') Day = 'السبت'
//         if(day === 'Sunday') Day = 'الأحد'
//         if(day === 'Monday') Day = 'الاثنين'
//         if(day === 'Tuesday') Day = 'الثلاثاء'
//         if(day === 'Wednesday') Day = 'الاربعاء'
//         if(day === 'Thursday') Day = 'الخميس'
//         if(day === 'Friday') Day = 'الجمعة'
//     }else{
//         Day = day;
//     }
//     return Day
// }