import React from "react";
import HomeTemplate from "Templates/Home/Home.Template";
import { useEffect } from "react";

const HomePage = ({ setLayoutData }) => {
  useEffect(() => {
    setLayoutData({
      title: "Dashboard",
    });
  }, []);
  return <HomeTemplate />;
};

export default HomePage;
