import { Stack } from "@mui/system";
import CTable from "components/Molecules/Table/table";
import { Button, Fade, Menu, MenuItem } from "@mui/material";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { useState } from "react";
import PrimaryButton from "components/Atoms/Buttons/Primary/PrimaryButton";
import { useThemePalette } from "common/hooks/theme_palette";
import AddIcon from "@mui/icons-material/Add";
import CreateInstructorModel from "components/Organisms/instructors/models/create";
import { useDispatch } from "react-redux";
import { creatAdminUserModel, creatInstructorModel } from "services/modules/models/Actions";
import CreateAdminUserModel from "../../components/Organisms/AdminUsers/models/create";

function AdminUsersListTemplate(props) {
  const dispatch = useDispatch();
  const { secondryColor } = useThemePalette();

  const [anchorEl, setAnchorEl] = useState(null);
  const [checked, setChecked] = useState([false]);
    const [createOrDelete, setCreateOrDelete] = useState('')
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const columns = [
    {
      Header: "ID",
      accessor: "id",
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "",
      accessor: "Actions",
    },
  ];

  const filterList = (
    <div style={{ height: "40px" }}>
      <Button
        variant="outlined"
        id="fade-button"
        style={{ height: "100%" }}
        aria-controls={open ? "fade-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        startIcon={<FilterAltOutlinedIcon />}
        color="secondary"
      >
        Filter
      </Button>
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={handleClose}>Profile</MenuItem>
        <MenuItem onClick={handleClose}>My account</MenuItem>
        <MenuItem onClick={handleClose}>Logout</MenuItem>
      </Menu>
    </div>
  );

  return (
    <>
      <Stack mb={6} p={2} spacing={2}>
        <PrimaryButton
          width={{
            sm: "50%",
            md: "15%",
          }}
          text="Add  New Admin User"
          variant={"contained"}
          style={{
            background: secondryColor,
            color: "white",
            alignSelf: "flex-end",
            textTransform:'none'
          }}
          startIcon={<AddIcon />}
          click={() => {
            props.setCreateOrEdit('create')
            dispatch(creatAdminUserModel())}}
        />
        <CTable
          selectedData={props.viewData}
          footerBtnTitle
          filterList={filterList}
          noFilter={false}
          Data={props.data}
          Columns={columns}
          Title="Admin Users List"
          subTitle=""
          btnTitle=""
          placeHolder=""
          handlePageChange={props.handlePageChange}
          total_count={props.total_count}
          paginationPage={props.paginationPage}
          setPage={props.setPage}
          setPerPage={props.setPerPage}
          currentpage={props.pageNumber}
          setPageNumber={props.setPageNumber}
          perPage={props.perPage}
          totalPage={Math.ceil(1 / 10)}
          checked={checked}
          setChecked={setChecked}
        />
      </Stack>
      <CreateAdminUserModel
              type={props.createOrEdit}
              submitBtnTitle={props.createOrEdit == 'create' ? 'Add' : 'Update'}
              initialValue={props?.selectedUser }
              page={props.paginationPage}
      />
    </>
  );
}

export default AdminUsersListTemplate;
