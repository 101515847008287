import Types from "./Types";

export const createCourseModel = () => ({
  type: Types.CREATE_COURSE_MODEL,
});

export const addMoreQuestionsModel = () => ({
  type: Types.ADD_MORE_QUETIONS_MODEL,
});

export const creatInstructorModel = () => ({
  type: Types.CREATE_INSTRUCTOR_MODEL,
});
export const creatAdminUserModel = () => ({
  type: Types.CREATE_ADMIN_USER_MODEL,
});
export const editAdminUserModel = () => ({
  type: Types.EDIT_ADMIN_USER_MODEL,
});
export const editStudentUserModel = () => ({
  type: Types.EDIT_STUDENT_USER_MODEL,
});
export const enrollStudentModel = () => ({
  type: Types.ENROLL_STUDENT_MODEL,
});

export const ToggleAlertModal = (payload) => ({
  type: Types.TOGGLE_ALERT_MODAL,
  payload,
});


export const uploadCSVModel = () =>({
  type : Types.UPLOAD_CSV_MODEL,
})
