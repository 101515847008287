const LookupsType = {
    GET_ADMIN_LOOKUPS_SUCCESS: 'GET_ADMIN_LOOKUPS_SUCCESS',
    ADMIN_LOOKUPS_LOADING: 'ADMIN_LOOKUPS_LOADING',
    GET_SCHOOL_LOOKUPS_SUCCESS:"GET_SCHOOL_LOOKUPS_SUCCESS",
    GET_CURRICULUMS_LOOKUPS_SUCCESS:"GET_CURRICULUMS_LOOKUPS_SUCCESS",
    GET_CLASS_LOOKUPS_SUCCESS:"GET_CLASS_LOOKUPS_SUCCESS",
    GET_SCROLL_CLASS_LOOKUPS_SUCCESS:"GET_SCROLL_CLASS_LOOKUPS_SUCCESS",
    GET_CLASS_SUBJECT_LOOKUPS_SUCCESS:"GET_CLASS_SUBJECT_LOOKUPS_SUCCESS"

};
export default LookupsType;