import { Avatar, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useThemePalette } from "common/hooks/theme_palette";
import CTable from "components/Molecules/Table/table";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getStudentQuizzesRequest } from "services/modules/courses/Requests/quizes";

const StudentQuizes = ({ data }) => {
  const { studentQuizesCount, studentQuizes } = useSelector((state) => state.courses);

  const [page, setPage] = useState();
  const { course_id, student_id } = useParams();
  const [checked, setChecked] = useState([false]);
  const { primaryColor, gray } = useThemePalette();

  const [selected, setSelected] = useState({
    id: "",
  });
  const [allSelectedData, setAllSelectedData] = useState([]);
  const pendingUsersCount = useSelector((state) => state.courses.pendingUsersCount);
  const dispatch = useDispatch();

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handleSearchChange = (key) => {};

  const viewData = (data) => {
    setSelected(data);
  };

  useEffect(() => {
    dispatch(
      getStudentQuizzesRequest({
        student_id,
        user_type: "student",
        filter: { page_number: 1, page_size: 4 },
      })
    );
  }, []);
  useEffect(() => {
    dispatch(
      getStudentQuizzesRequest({
        student_id,
        user_type: "student",
        filter: { page_number: page, page_size: 4 },
      })
    );
  }, [page]);
  const mappedData =
    studentQuizes &&
    studentQuizes?.map((quiz, index) => {
      return {
        ...quiz,
        curriculumData: quiz?.cirriculum?.map((curriculum) => curriculum),
        attempts: <Typography>{quiz?.number_of_attempts} /4</Typography>,
        created_at: <>{dayjs(quiz?.created_at).format("DD-MM-YYYY hh:mm a")}</>,
      };
    });
  const columns = [
    {
      Header: "Course Name",
      accessor: "course_title",
    },
    {
      Header: "Title",
      accessor: "title",
    },
    {
      Header: "Curriculum",
      accessor: "curriculumData",
    },
    {
      Header: "Quiz time",
      accessor: "quiz_time",
    },
    {
      Header: "Class Year",
      accessor: "class_year",
    },
    {
      Header: "Graded",
      accessor: "Graded",
    },
    {
      Header: "Attempts",
      accessor: "attempts",
    },
  ];

  return (
    <Stack p={2}>
      <CTable
        selectedData={viewData}
        footerBtnTitle
        noFilter={false}
        noSearchBar={true}
        Data={mappedData || []}
        Columns={columns}
        Title="Quizes"
        subTitle=""
        btnTitle=""
        placeHolder=""
        hasBottomAddBtn={true}
        bottomBtnTitle={"Add New User"}
        hasPagination={false}
        displayFooter={false}
        total_count={studentQuizesCount}
        handlePageChange={handlePageChange}
        perPage={4}
        paginationPage={page}
        handleSearchChange={handleSearchChange}
        checked={false}
        setChecked={setChecked}
        setAllSelectedData={setAllSelectedData}
      />
    </Stack>
  );
};
export default StudentQuizes;
