import axios from "axios";
import { BaseURL } from "services/modules/utils/constant";
import handleErrors from "services/modules/utils/handel_error";

export const reduxRequest = async ({
  dispatch,
  isLoading,
  url,
  method = "post",
  loadingType = "list",
  body,
  action,
  successFunction,
  headers,
  successFunctionVariables = {},
  failAction
}) => {
  dispatch(
    isLoading({
      type: loadingType,
      loading: true,
    })
  );
  try {
    const { data } = await axios({
      url: url,
      baseURL: BaseURL,
      method: method,
      data: body,
      headers:headers,
    });
    successFunction && dispatch(successFunction(data, successFunctionVariables));
    action && action(data);
  } catch (error) {
    handleErrors(error);
    failAction ? failAction(error) : handleErrors(error);
  } finally {
    dispatch(
      isLoading({
        type: "",
        loading: false,
      })
    );
  }
};
