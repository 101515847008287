import axios from "axios"
import setAuthToken, { axiosFn } from "./handel_api";
import handleError from '../utils/handel_error';
import { PresignedURL } from '../utils/constant';

export async function getPresignedLinkAPI(files) {
    return await axios.post(`${PresignedURL}`, { "files": files })
        .then((res) => {
            return {
                res: res.data,
                err: null,
            }
        })
        .catch(handleError)
}

export async function uploadFileAPI(presigned_url, file,onUploadProgress=()=>{}) {
    const config = {
        headers: {
          "Content-Type": file.type,
        },
        onUploadProgress
      }
      delete axios.defaults.headers.common["Authorization"];

      try {
        const response = await axios.put(presigned_url, file, config);
        return response;
      } catch (error) {
        // Handle error
        console.error('Upload failed:', error);
        throw error;
      }finally {
        delete axios.defaults.headers.common["Content-Type"];
        setAuthToken(localStorage.getItem("token"));

    }
}
