import { Stack ,Paper, Avatar,Typography,Divider} from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import PrimaryButton from "components/Atoms/Buttons/Primary/PrimaryButton";
import EditableTable from "components/Molecules/Table/editableTable";
import CTable from "components/Molecules/Table/table";
import PaginatedQuestions from "components/Organisms/quiz/attempts/paginatedQuestions";
import StudentDetails from "components/Organisms/students/studentDetails";
import React from "react";
import { useState } from "react";

const StudentResponseTemplate = ({data,count, formik,quizData }) => {
  const { secondryColor, errorColor } = useThemePalette();

  const [pageNumber, setPageNumber] = useState(1);

  return (
    <Stack pl={2} pt={2} my={2} p={2} gap={2}>
      <Stack spacing={2} direction="row" alignItems="center" justifyContent={{ md: "flex-end" }}>
        <PrimaryButton
          text="Edit Student"
          variant={"contained"}
          style={{
            background: secondryColor,
            color: "white",
          }}
          click={() => {}}
        />
        <PrimaryButton
          text="Delete Student"
          variant={"contained"}
          style={{
            background: errorColor,
            color: "white",
          }}
          click={() => {}}
        />
        <PrimaryButton
          text="Deactivate"
          variant={"outlined"}
          style={{
            color: secondryColor,
            borderColor: secondryColor,
          }}
          click={() => {}}
        />
      </Stack>

      <Stack
        width={{
          sm: "100%",
          md: "90%",
          lg: "85%",
        }}
        padding={{ md: "20px" }}
        spacing={3}
      >
        {/* <StudentDetails studentData={studentData} /> */}

        <Stack component={Paper} p="30px 20px" gap={2}>
          <Typography fontWeight={600}>Student Details</Typography>
          <Divider/>
          <Avatar alt="Remy Sharp" src="" sx={{ width: 95, height: 95 }}/>

              <form onSubmit={formik.handleSubmit}>
                <EditableTable formik={formik}/>
              </form>
        </Stack>

        <PaginatedQuestions data={data} page={pageNumber} count={count}  quizData={quizData}/>

      </Stack>
    </Stack>
  );
};

export default StudentResponseTemplate;
