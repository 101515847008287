import Types from "./Types";

export const CreateStudentSuccess = (payload) => ({
  type: Types.CREATE_STUDENT_SUCCESS,
  payload,
});

export const ShowStudentDetailsSuccess = (payload) => ({
  type: Types.SHOW_STUDENT_DETAILS_SUCCESS,
  payload,
});

export const UpdateStudentSuccess = (payload) => ({
  type: Types.UPDATE_STUDENT_SUCCESS,
  payload,
});

export const DeleteStudentSussess = () => ({
  type: Types.DELETE_STUDENT_SUCCESS,
});

export const GetAllStudentsSuccess = (payload) => ({
  type: Types.GET_ALL_STUDENTS_SUCCESS,
  payload,
});

export const StudentLoading = (payload) => ({
  type: Types.STUDENT_LOADING,
  payload,
});

export const SuspendStudentAccountSuccess = (payload) => ({
  type: Types.SUSPEND_STUDENT_ACCOUNT_SUCCESS,
  payload,

});

export const ActiveStudentAccountSuccess = (payload) => ({
  type: Types.ACTIVE_STUDENT_ACCOUNT_SUCCESS,
  payload,
});

export const enrollStudentToMultipleCoursesSuccess = (payload) => ({
  type: Types.ENROLL_STUDENT_TO_MULTIPLE_COURSES,
  payload,
})
export const getStudentUnrolledCoursesSuccess = (payload) => ({
  type: Types.GET_STUDENTS_UNENROLLED_COURSES,
  payload,
})