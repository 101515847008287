import { BaseURL, CoursesURL } from "services/modules/utils/constant";
import {
  CreateSessionSuccess,
  ShowSessionDetailsSuccess,
  deleteCourseSessionSuccess,
  getAllCourseSessionSuccess,
  getCourseSectionSessionSuccess,
} from "../Actions/sessions";
import { reduxRequest } from "common/utils/reduxRequest";
import { adminCoursesLoading } from "../Actions/courses";
export const CreateSessionRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: adminCoursesLoading,
    successFunction: CreateSessionSuccess,
    url: `v2/course_sessions/?course_id=${variables?.course_id}&section_id=${variables?.section_id}`,
    method: "post",
    loadingType: "create session",
    body:variables?.data,
    action:variables?.action,
    ...variables,
};
return async (dispatch) => {
    reduxRequest({
        dispatch,
        ...requestData,
    });
};
  /*return async (dispatch) => {
    dispatch(
      SessionLoading({
        type: "create",
        loading: true,
      })
    );
    try {
      const { data } = await axios.post(
        `${BaseURL}course_sessions/${course_id}/${section_id}`,
        body
      );
      dispatch(CreateSessionSuccess(data));
      action && action();
    } catch (error) {
      handleErrors(error);
    } finally {
      dispatch(
        SessionLoading({
          type: "",
          loading: false,
        })
      );
    }
  };*/
};
export const UpdateSessionRequest = (course_id, section_id, course_session_id, body, action) => {
  return async (dispatch) => {
    dispatch(
      SessionLoading({
        type: "update",
        loading: true,
      })
    );
    try {
      const { data } = await axios.put(
        `${BaseURL}${CoursesURL}/${course_id}/materials/sections/${section_id}/sessions/${course_session_id}`,
        body
      );
      dispatch(UpdateSessionSuccess(data));
      action && action();
    } catch (error) {
      handleErrors(error);
    } finally {
      dispatch(
        SessionLoading({
          type: "",
          loading: false,
        })
      );
    }
  };
};
export const ShowSessionDetailsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: adminCoursesLoading,
    successFunction: ShowSessionDetailsSuccess,
    url: `v2/course_sessions/${variables?.session_id}?course_id=${variables?.course_id}&section_id=${variables?.section_id}`,
    method: "get",
    loadingType: "courseSessions",
    ...variables,
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
export const deleteCourseSessionRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: adminCoursesLoading,
    successFunction: deleteCourseSessionSuccess,
    url: `v2/course_sessions/${variables?.course_session_id}?course_id=${variables?.course_id}&section_id=${variables?.section_id}`,
    method: "delete",
    loadingType: "courseSession",
    ...variables,
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
/*export const getAllCourseSessionsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: adminCoursesLoading,
    successFunction: getAllCourseSessionSuccess,
    url: `${CoursesURL}/${variables?.course_id}/materials/sections/${variables?.section_id}/sessions`,
    method: "get",
    loadingType: "courseSessions",
    ...variables,
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};*/
export const getCourseSectionSessionsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: adminCoursesLoading,
    successFunction: getCourseSectionSessionSuccess,
    url: `${CoursesURL}/sections/${variables?.section_id}/sessions?course_id=${variables?.course_id}`,
    method: "get",
    loadingType: "courseSessions",
    ...variables,
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
