import { Button, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { useThemePalette } from "common/hooks/theme_palette";
import ModelBox from "components/Atoms/Model/Model";
import MultiSelect from "components/Atoms/Select/MultiSelect";
import ScrollableAutocomplete from "components/Atoms/Select/scrollSelect";
import CircularProgress from "@mui/material/CircularProgress";

const AddInstructor = ({
  openModel,
  handleClose,
  handleAction,
  options,
  title,
  handleChange,
  btnTitle,
  pageNumber,
  count,
  subTitle,
  hasMore,
  fetchMoreOptions,
  onOpen,
  name,
  isLoading,
  actionLoading,
}) => {
  const { orangeBtn } = useThemePalette();

  return (
    <ModelBox
      openVar={openModel}
      title={title}
      closeFunc={handleClose}
      titleStyle={{ fontWeight: 700 }}
    >
      <Box p={1}>
        <Typography>{subTitle}</Typography>

        <MultiSelect
          items={options}
          hasMore={hasMore}
          pageNumber={pageNumber}
          fetchNextPage={fetchMoreOptions}
          count={count}
          onchange={handleChange}
          onOpen={onOpen && onOpen}
          name={name && name}
          isLoading={isLoading && isLoading}
        ></MultiSelect>
        <Stack flexDirection={"row"} justifyContent={"center"} alignItems={"center"} gap={4} pt={4}>
          <Button
            onClick={handleClose}
            variant="outlined"
            sx={{ textTransform: "none", color: orangeBtn, borderColor: orangeBtn }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleAction}
            sx={{
              textTransform: "none",
              backgroundColor: orangeBtn,
              ":hover": { backgroundColor: orangeBtn },
            }}
          >
            {actionLoading ? (
              <CircularProgress
                sx={{
                  color: "white",
                  width: "25px !important",
                  height: "25px !important",
                  mx: "7px",
                }}
              />
            ) : (
              btnTitle
            )}
          </Button>
        </Stack>
      </Box>
    </ModelBox>
  );
};
export default AddInstructor;
