import { reduxRequest } from "common/utils/reduxRequest";
import { addSectionToCourseSuccess, courseSectionLoading, deleteSectionFromCourseSuccess, editSectionSuccess, showSectionSuccess } from "./Actions";
import { CoursesURL } from "../utils/constant";

export const addSectionToCourseRequest = ({ ...variables }) => {
    const requestData = {
      isLoading: courseSectionLoading,
      successFunction: addSectionToCourseSuccess,
        url: `${CoursesURL}/${variables?.course_id}/sections/`,
      method: "post",
      loadingType: "create",
      ...variables,
    };
    return async (dispatch) => {
      reduxRequest({
        dispatch,
        ...requestData,
      });
    };
  };
  export const deleteSectionFromCourseRequest = ({ ...variables }) => {
    const requestData = {
      isLoading: courseSectionLoading,
      successFunction: deleteSectionFromCourseSuccess,
        url: `${CoursesURL}/${variables?.course_id}/sections/${variables?.section_id}`,
      method: "delete",
      loadingType: "delete",
      ...variables,
    };
    return async (dispatch) => {
      reduxRequest({
        dispatch,
        ...requestData,
      });
    };
  };
  export const editSectionRequest = ({ ...variables }) => {
    const requestData = {
      isLoading: courseSectionLoading,
      successFunction: editSectionSuccess,
        url: `${CoursesURL}/${variables?.course_id}/sections/${variables?.section_id}`,
      method: "put",
      loadingType: "edit section",
      ...variables,
    };
    return async (dispatch) => {
      reduxRequest({
        dispatch,
        ...requestData,
      });
    };
  };
  export const showSectionRequest = ({ ...variables }) => {
    const requestData = {
      isLoading: courseSectionLoading,
      successFunction: showSectionSuccess,
        url: `${CoursesURL}/${variables?.course_id}/sections/${variables?.section_id}`,
      method: "get",
      loadingType: "show section",
      ...variables,
    };
    return async (dispatch) => {
      reduxRequest({
        dispatch,
        ...requestData,
      });
    };
  };