import { useState } from "react";
import { PropTypes } from "prop-types";
import { TextField, InputAdornment, IconButton, Typography,Stack } from "@mui/material";
import "./TextFieldInput.css";
// import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { useMemo } from "react";
import { useThemePalette } from "common/hooks/theme_palette";
import { Visibility, VisibilityOff } from "@mui/icons-material";
function TextFieldInput({
  type = "text",
  placeholder = "",
  StartAdornment = null,
  EndAdornment = null,
  multiline = false,
  rows,
  className,
  onChange,
  error,
  value,
  labelStyle,
  label,
  clickEnter,
  InputProps,
  disabled,
  justifyContent,
  gridClass,
  bgColor,
  sx,
  required,
  ...props
}) {
  const { fontColor ,textFieldColor} = useThemePalette();
  const defaultStyle = {
    color: fontColor,
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "15px",
    my: 1,
  };

  const [showPassword, setshowPassword] = useState(false);

  const mainLabelStyle = useMemo(() => {
    return labelStyle ? labelStyle : defaultStyle;
  }, [labelStyle]);

  const handleClickShowPassword = () => {
    setshowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Grid
      container
      sx={{ position: "relative" }}
      justifyContent={justifyContent}
      className={gridClass}
    >
      {label && (<Stack flexDirection={'row'} alignItems={'center'} gap={'3px'}>
        <Typography as="span" sx={mainLabelStyle}>
          {label} 
        </Typography>
        {required ? <Typography color={'red'}>*</Typography>:null}
      </Stack>
      )}
      <TextField
        sx={{
          backgroundColor: bgColor,
          "& .MuiInputBase-root:before": {
            border: "none",
          },
          "& .MuiInputBase-root:focus": {
            border: "none",
          },
          "& .MuiInputBase-root:hover:not(.Mui-disabled):before": {
            border: "none",
          },
          ...sx,
        }}
        disabled={disabled}
        fullWidth
        multiline={multiline}
        rows={rows}
        type={type === "password" ? (showPassword ? "text" : "password") : type}
        className={className}
        error={error ? true : false}
        placeholder={placeholder}
        // variant="outlined"
        value={value}
        onChange={onChange}
        onKeyDown={(e) => {
          if (e.key === "Enter") clickEnter();
        }}
        InputProps={
          InputProps
            ? InputProps
            : {
                startAdornment: <InputAdornment position="start">{StartAdornment}</InputAdornment>,
                endAdornment: (
                  <InputAdornment position="end">
                    {type !== "password" ? (
                      EndAdornment
                    ) : (
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? (
                          <Visibility className="password-icon" />
                        ) : (
                          <VisibilityOff className="password-icon" />
                        )}
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }
        }
        {...props}
      />
    </Grid>
  );
}

TextFieldInput.propTypes = {
  type: PropTypes.string,
  placeholder: PropTypes.string,
  StartAdornment: PropTypes.element,
  EndAdornment: PropTypes.element,
  className: PropTypes.string,
};
export default TextFieldInput;
