export const isImage = (url) => {
    //checks if it's an image , so we can show the actual img , if not a static img will be put to represent .pdf ,.doc
    const extension = url?.substring(url?.lastIndexOf(".") + 1, url?.length);
    return (
      extension === "jpg" ||
      extension === "png" ||
      extension === "jpeg" ||
      extension === "JPG"
    );
  };

  export const isPdf = (url) => {
    //checks if it's an image , so we can show the actual img , if not a static img will be put to represent .pdf ,.doc
    const extension = url?.substring(url?.lastIndexOf(".") + 1, url?.length);
    return ( extension === "pdf")
  };


export const StringToDateTime=(string)=>{

  const parts=string.split(" ");
  return parts
}