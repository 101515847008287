import { Routes, Route } from "react-router-dom";
import CourseDetailsPage from "pages/courses/CourseDetails.Page.jsx";
import CoursesListPage from "pages/courses/CoursesList.Page.jsx";
import QuizPage from "pages/courses/quiz/Quiz.Page.jsx";
import HomePage from "pages/home/Home.Page.jsx";
import InstructorsListPage from "pages/users/instructors/InstructorsList.Page.jsx";
import StudentsListPage from "pages/users/students/StudentsList.Page.jsx";
import AssignmentPage from "pages/courses/Assignment.Page.jsx";
import { useEffect, useState } from "react";
import StudentResponsePage from "pages/courses/quiz/StudentResponse.Page";
import InstructorDetailsPage from "pages/users/instructors/InstructorDetails.Page";
import Layout from "components/Organisms/Layout";
import AdminUsersListPage from "../pages/adminUsers/AdminUsersList.Page";
import AdminUserDetailsPage from "../pages/adminUsers/AdminUsersDetails.Page";
import SessionPage from "pages/courses/session/Session.Page";
import SessionDetailsPage from "pages/courses/session/SessionDetails.Page";
import LoginPage from "../pages/login/Login.Page";
import AddSectionPage from "pages/courses/section/AddSection.Page";
import ForgetPasswordPage from "pages/login/forgetPassword.Page";
import VerifyOTPPAge from "pages/login/VerifyOTP.Page";
import ResetPasswordPage from "pages/login/ResetPassword.Page";

import EvaluateAssignmentPage from "pages/courses/EvaluateAssignment.Page.jsx";
import EditSectionPage from "pages/courses/section/EditSection.Page";
import DeleteAccountPage from "pages/deleteAccount/DeleteAccount.Page";
import StudentDetailsPage from "pages/users/students/studentDetails.Page";
const AppRoute = () => {
  const [layoutData, setLayoutData] = useState(null);
  const [isAuth, setIsAuth] = useState(false);
  useEffect(() => {
    // on page load
    const token = sessionStorage.getItem("token") || localStorage.getItem("token");
    setIsAuth(Boolean(token));

    // when the storage changes
    const handleStorageListener = () => {
      const token = localStorage.getItem("token");
      setIsAuth(Boolean(token));
    };
    window.addEventListener("storage", handleStorageListener);

    //clean up
    return () => {
      window.removeEventListener("storage", handleStorageListener);
    };
  }, []);
  return (
    <Routes>
      {/* admin layout */}
      <Route path="/delete-account" element={<DeleteAccountPage />} />
      {isAuth ? (
        <Route path="/" element={<Layout layoutData={layoutData} />}>
          <Route index element={<HomePage setLayoutData={setLayoutData} />} />
          <Route
            path="admin-users"
            element={<AdminUsersListPage setLayoutData={setLayoutData} />}
          />
          <Route
            path="admin-users/:admin_id"
            element={<AdminUserDetailsPage setLayoutData={setLayoutData} />}
          />
          <Route
            path="courses/:course_id/quiz/:quiz_id/:tab"
            element={<QuizPage setLayoutData={setLayoutData} />}
          />
          <Route
            path="courses/:course_id/quiz/:quiz_id/:tab/:attempt_id"
            element={<StudentResponsePage setLayoutData={setLayoutData} />}
          />
          <Route
            path="courses/:course_id/assignment/:section_id/:assignment_id/:tab"
            element={<AssignmentPage setLayoutData={setLayoutData} />}
          />
          <Route
            path="courses/:course_id/assignment/:section_id/:assignment_id/:tab/:delivery_id"
            element={<EvaluateAssignmentPage setLayoutData={setLayoutData} />}
          />

          <Route
            path="session/:course_id/:section_id"
            element={<SessionPage setLayoutData={setLayoutData} />}
          />
          <Route
            path="section/:course_id"
            element={<AddSectionPage setLayoutData={setLayoutData} />}
          ></Route>
          <Route
            path="section/:course_id/:section_id"
            element={<EditSectionPage setLayoutData={setLayoutData} />}
          ></Route>

          <Route
            path="session/:course_id/:section_id/:session_id"
            element={<SessionDetailsPage setLayoutData={setLayoutData} />}
          />
          {/* <Route
          path="assignment/:course_id/:section_id"
          element={<AssignmentPage setLayoutData={setLayoutData} />}
        /> */}
          <Route path="courses" element={<CoursesListPage setLayoutData={setLayoutData} />} />
          <Route
            path="courses/:course_id"
            element={<CourseDetailsPage setLayoutData={setLayoutData} />}
          />
          <Route
            path="users/students"
            element={<StudentsListPage setLayoutData={setLayoutData} />}
          />
          <Route
            path="users/instructors"
            element={<InstructorsListPage setLayoutData={setLayoutData} />}
          />
          <Route
            path="users/instructors/:instructor_id"
            element={<InstructorDetailsPage setLayoutData={setLayoutData} />}
          />
          <Route
            path="users/student/:student_id"
            element={<StudentDetailsPage setLayoutData={setLayoutData} />}
          />

          <Route element={<p>Not found</p>} />
        </Route>
      ) : (
        <Route path="/">
          <Route index element={<LoginPage />} />

          <Route path="/forget_password" element={<ForgetPasswordPage />} />
          <Route path="/verify_otp" element={<VerifyOTPPAge />} />
          <Route path="/reset_password" element={<ResetPasswordPage />} />
        </Route>
      )}
    </Routes>
  );
};

export default AppRoute;
