import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Stack, LinearProgress } from "@mui/material";
import { useFormik } from "formik";
import dayjs from "dayjs";
import * as Yup from "yup";

import ModelBox from "../../../Atoms/Model/Model";
import { createCourseModel } from "../../../../services/modules/models/Actions";
import { getAdminLookupsRequest } from "../../../../services/modules/lookups";
import { useThemePalette } from "../../../../common/hooks/theme_palette";

import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import { findInObject } from "../../../../services/modules/utils/Helper";
import CreateCourseStep2 from "../../../Molecules/Courses/Forms/CreateCourseStep2";
import CreateCourseStep1 from "../../../Molecules/Courses/Forms/CreateCourseStep1";
import { createAdminCourseRequest } from "services/modules/courses/Requests/courses";

const CreateCourse = () => {
  const { inputBg, secondryColor } = useThemePalette();
  const lookups = useSelector((state) => state.lookups.lookups);

  const isOpenCreateCourse = useSelector((state) => state.model.isOpenCreateCourse);
  const dispatch = useDispatch();

  const [step, setStep] = useState({ step: 1, val: 50 });
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [paymentType, setPaymentType] = useState(0);
  const [currency, setCurrency] = useState(0);

  const formik = useFormik({
    initialValues: {},
    validationSchema: Yup.object().shape({
      // title: Yup.string()
      //     .min(2, 'Too Short!')
      //     .max(50, 'Too Long!')
      //     .required('Required'),
      // description: Yup.string()
      //     .min(2, 'Too Short!')
      //     .max(50, 'Too Long!')
      //     .required('Required'),
      // url: Yup.string().matches(/^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm, 'URL is not valid'),
      // price: Yup.string().required('Required'),
      // discount: Yup.string().required('Required'),
      // price_section: Yup.string().required('Required'),
      // price_session: Yup.string().required('Required'),
    }),
    onSubmit: (values) => {
      const obj = {
        ...values,
        start_date: dayjs(startDate).format("YYYY-MM-DD"),
        end_date: dayjs(endDate).format("YYYY-MM-DD"),
        payment_method: paymentType,
        original_price_currency: currency,
        classification: "course",
        image_url:
          "https://pyramakerz.s3.eu-central-1.amazonaws.com/staging/i35yzwhgyRkuiIucbLyPnw_1667833740_.png",
        url: "https://www.facebook.com/inovaeg/videos/2680627578734604/",
        level: "advanced",
        place: "incenter",
      };
      if (step.step === 1) setStep({ step: 2, val: 100 });
      else {
        const requestData = {
          body: { course: obj },
          action: () => dispatch(createCourseModel()),
        };
        dispatch(createAdminCourseRequest({ ...requestData }));
      }
    },
  });

  // useEffect(() => {
  //   dispatch(getAdminLookupsRequest());
  // }, []);

  useEffect(() => {
    const array = [];
    const payment = findInObject(lookups, "payment_methods");
    for (const [key, value] of Object.entries(payment)) {
      array.push({
        id: value,
        name: key,
      });
    }
    setPaymentMethods(array);
  }, [lookups]);

  return (
    <ModelBox
      openVar={isOpenCreateCourse}
      closeFunc={() => dispatch(createCourseModel())}
      title="Add New Course"
      titleStyle={{ fontSize: "20px", fontWeight: "600" }}
    >
      <form onSubmit={formik.handleSubmit}>
        <Stack alignItems="center" justifyContent="center">
          {step.step === 1 ? (
            <CreateCourseStep1
              inputBg={inputBg}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              formik={formik}
            />
          ) : (
            <CreateCourseStep2
              inputBg={inputBg}
              paymentMethods={paymentMethods}
              paymentType={paymentType}
              setPaymentType={setPaymentType}
              currency={currency}
              setCurrency={setCurrency}
              formik={formik}
            />
          )}
          <Stack width="60%" alignItems="center" justifyContent="center">
            {/* controlle */}
            <Stack
              spacing={2}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              width="70%"
              my={6}
            >
              <PrimaryButton
                text={step.step === 1 ? "Cancel" : "Back"}
                variant={"outlined"}
                style={{
                  color: secondryColor,
                  borderColor: secondryColor,
                  width: "50%",
                  padding: "15px",
                }}
                click={() => {
                  if (step.step === 2) setStep({ step: 1, val: 50 });
                  else dispatch(createCourseModel());
                }}
              />
              <PrimaryButton
                type="submit"
                text={step.step === 1 ? "Next" : "Create Course"}
                variant={"contained"}
                style={{
                  background: secondryColor,
                  color: "white",
                  width: "50%",
                  padding: "15px",
                }}
              />
            </Stack>
            <Typography alignSelf="flex-end" fontSize="12px" color="#ABAFB1">
              <span style={{ color: "#5570F1" }}>Step {step?.step}</span> of 2
            </Typography>
            <LinearProgress
              value={step?.val}
              variant="determinate"
              sx={{ borderRadius: "8px", height: "8px", width: "100%" }}
            />
          </Stack>
        </Stack>
      </form>
    </ModelBox>
  );
};

export default CreateCourse;
