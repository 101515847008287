import {
  Divider,
  FormControlLabel,
  Stack,
  Typography,
  Checkbox, Box, Button, CircularProgress
} from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../Atoms/Loader/Loader";
import Form from "../../../Molecules/Forms/form";
import FormField from "../../../Molecules/Forms/formField";
import { loginRequest } from "../../../../services/modules/authentication";
import { loginSchema } from "../../../../common/utils/schemas";
import SubmitButton from "../../../Molecules/Forms/submitButton";
import { useState } from "react";
import setAuthToken from "services/modules/utils/handel_api";
import { Organization_name } from "services/modules/utils/constant";


export default function LoginForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { textFieldColor,bgColor,fontColor } = useThemePalette();
  const [isChecked, setIsChecked] = useState(true);
  const {load}=useSelector((state)=>state.auth)
  //console.log(load)
  

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };
  const handleFormSubmit = (values,{setSubmitting}) => {
    //console.log(values)
    const action = (data) => {
      if(!isChecked){
        sessionStorage.setItem("token",data.data?.token)
        sessionStorage.setItem('userType',data?.data?.type?.value)
      }
      else{
        localStorage.setItem("token", data.data?.token)
        localStorage.setItem("userType",data?.data?.type?.value)
      }
      //console.log(data.data?.token)
      setAuthToken(data.data?.token)
      
     // window.dispatchEvent(new Event("storage"));
      window.location.reload();
    };
    const requestData = {
      body: {
        user: values,
        device: {
          fcm_token: "fcm_token",
          uid: 1,
        },
        },
        Organization_name,
      action,
    };
    dispatch(loginRequest(requestData));
    setSubmitting(false);

  };

  return (
    <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'center'} width={'100%'} height={'100vh'}  sx={{backgroundColor:'#F1F4F6'}}>
    <Stack p={5} postion={'relative'}   width={'50%'} height={'auto'} sx={{backgroundColor:'white'}}>
      <Stack sx={{ gap: 4 }}>
        <Typography  textAlign={'center'} fontFamily="Inter" fontSize="16px" fontWeight='600'>
          E-learning Login
        </Typography>
        <Form
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={loginSchema}
          onSubmit={(values, { setSubmitting }) => {
            handleFormSubmit(values,{setSubmitting});
          }}
        >
          <Stack gap={1}>
          <Stack sx={{ gap: 4 }}>
            <FormField
              label="Email*"
              name="email"
              type="text"
              labelStyle={{ fontSize: "12px" ,fontWeight:'400',mb:1,color:fontColor}}
              placeholder="Enter your email"
            />
            <FormField
              label="Password*"
              name="password"
              labelStyle={{ fontSize: "12px",fontWeight:'400',mb:1,color:fontColor }}
              type="password"
              placeholder="***********"
            />
          </Stack>
            <Stack direction="row"
                   alignItems="center">
              <FormControlLabel
                control={<Checkbox checked={isChecked} onChange={handleCheckboxChange} />}
                label="Remember me"
              />
            </Stack>
            <Stack direction="row"
                   alignItems="center">
              <SubmitButton sx={{height:'52px',fontSize: "12px",fontWeight:'400',mb:1}} >
              {load?.loading ? <CircularProgress size={20} color="inherit" /> : "Login"}
                </SubmitButton>
            </Stack>

            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                sx={{
                  color: fontColor,
                  cursor: "pointer",
                  fontSize: "12px",fontWeight:'400'
                }}
                onClick={(_) => navigate("/forget_password")}
              >
                Forget Password ?
              </Typography>
            </Stack>
          </Stack>
        </Form>
      </Stack>
    </Stack>
    </Stack>
  );
}
