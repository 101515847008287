import { Stack, Typography } from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers"
import { useThemePalette } from "common/hooks/theme_palette"
import BasicDatePicker from "components/Atoms/Input/DatePicker/DatePicker"
import TextFieldInput from "components/Atoms/Input/TextField/TextFieldInput"

const ZoomMeeting=({formik})=>{
    const{inputBg}=useThemePalette()
return(
    <Stack gap={1}>
        <TextFieldInput
        label="URL"
       
        sx={{
          background: inputBg,
          padding: "0",
          borderRadius: "8px",
          "& fieldset": {
            border: "none",
            width:'47%'
          },
        }}
        placeholder="https://us04web.zoom.us/meeting"
        name="join_url"
        onChange={formik.handleChange}
        value={formik.values.join_url}
        error={formik.errors.join_url}
        helperText={formik.errors.join_url}
      />
        <Stack flexDirection="row" gap={3}>
        <TextFieldInput
        label="Meeting ID"
       
        sx={{
          background: inputBg,
          padding: "0",
          borderRadius: "8px",
          "& fieldset": {
            border: "none",
            width:'47%'
          },
        }}
        placeholder="Enter Here"
        name="meeting_id"
        onChange={formik.handleChange}
        value={formik.values.meeting_id}
        error={formik.errors.meeting_id}
        helperText={formik.errors.meeting_id}
      />
        <TextFieldInput
        label="Password"
        type="password"
       
        sx={{
          background: inputBg,
          padding: "0",
          borderRadius: "8px",
          "& fieldset": {
            border: "none",
            width:'47%'
          },
        }}
        placeholder="********"
        name="meeting_password"
        onChange={formik.handleChange}
        value={formik.values.meeting_password}
        error={formik.errors.meeting_password}
        helperText={formik.errors.meeting_password}
      />
        </Stack>
        <Stack flexDirection="row" gap={3}>
            <Stack width='100%' mt={1}> 
        <Typography fontSize="12px" color="#5E6366" mb={1}>
              {" "}
              {/* Start date */}
              Date
            </Typography>
            <BasicDatePicker
              id="start_date"
              
              sx={{
                background: inputBg,
                borderRadius: "8px",
                "& fieldset": {
                  border: "none",
                  width:'47%'
                },
              }}
              name="start_time"
             onChange={formik.handleChange}
              value={formik.values.start_time}
              error={formik.errors.start_time}
            helperText={formik.errors.start_time}
            />
           
          </Stack>
          <TextFieldInput
        label="Estimated Time "
       
        sx={{
          background: inputBg,
          padding: "0",
          borderRadius: "8px",
          "& fieldset": {
            border: "none",
            width:'47%'
          },
        }}
        placeholder="Enter Here"
        name="end_time"
        onChange={formik.handleChange}
        value={formik.values.end_time}
        error={formik.errors.end_time}
        helperText={formik.errors.end_time}
      />
          </Stack>
        <Stack flexDirection="row" gap={3}></Stack>

        
    </Stack>
)
}
export default ZoomMeeting