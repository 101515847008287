import Types from "../Types";
export const getCourseJoinedUsersSuccess = (payload) => ({
    type: Types.GET_COURSE_JOINED_USERS_SUCCESS,
    payload,
});
export const addCourseJoinedUsersSuccess = (payload) => ({
    type: Types.ADD_COURSE_JOINED_USERS_SUCCESS,
    payload,
});
export const getCoursePendingUsersSuccess = (payload) => ({
    type: Types.GET_COURSE_PENDING_USERS_SUCCESS,
    payload,
});

export const moveToJoinedUsersSuccess = (payload) => ({
    type: Types.MOVE_TO_JOINED_USER_SUCCESS,
    payload
})