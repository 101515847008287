import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getAdminCourseDetailsRequest } from "services/modules/courses/Requests/courses";
import { useThemePalette } from "common/hooks/theme_palette";
import CourseDetailsTemplate from "Templates/Courses/CourseDetails.Template.jsx";
import { getCourseInstructorsRequest } from "../../services/modules/courses/Requests/instructors";
import { getCourseJoinedUsersRequest, getCoursePendingUsersRequest } from "../../services/modules/courses/Requests/joined-pendingUsers";
import { getCourseCouponsRequest } from "../../services/modules/courses/Requests/coupons";
import { getCourseSectionsRequest } from "../../services/modules/courses/Requests/sections";
import { getCourseSubjectsRequest } from "services/modules/courses/Requests/subjects";
import { getCurriculumsLookupsRequest } from "services/modules/lookups";
import { isEquals } from "immutability-helper";
import DeleteModal from "components/Atoms/Model/DeleteModel";
import CourseDetailsSkeleton from "components/Atoms/skeleton/courseDetailsSkeleton";
import { Skeleton, Stack } from "@mui/material";
import Loader from "components/Atoms/Loader/Loader";

const CourseDetailsPage = ({ setLayoutData }) => {
  const { errorColor } = useThemePalette();
    const course = useSelector((state) => state.courses.course);
    const instructors = useSelector((state) => state.courses.instructors)
    const joinedUsers = useSelector((state) => state.courses.joinedUsers)
    const pendingUsers = useSelector((state) => state.courses.pendingUsers)
    const coupons = useSelector((state) => state.courses.coupons)
    const sections = useSelector((state) => state.courses.sections);
    const subjects=useSelector((state)=>state.courses.subjects);
    const[updated,setUpdated]=useState(false)
//console.log(pendingUsers,'pendingUsers from courseDetails ');
//console.log(coupons)
  const { course_id } = useParams();
  const dispatch = useDispatch();
  const [cards, setCards] = useState(sections);
  const {isLoading}=useSelector((state)=>state.courses)
  const userLoading=useSelector((state)=>state.students.isLoading)
  const instructorLoading=useSelector((state)=>state.students.isLoading)
  //console.log(userLoading)
  //console.log(instructorLoading)



  useEffect(() => {
    
    if (! isEquals( cards,sections)){
       setCards(sections);
       setUpdated(true);
    }
    
  }, [sections]);
  //console.log(course, "data course page");
  useEffect(()=>{
    dispatch( getCurriculumsLookupsRequest({}));
  },[])
  const skeleton= <>
  <Stack gap={2}>
     <Stack flexDirection={'row'}>
 <Skeleton variant="rounded" width={210} height={40} style={{marginLeft:'auto',marginTop:10}}  />
 <Skeleton variant="rounded" width={210} height={40} style={{marginLeft:'auto',marginTop:10}}  />
 <Skeleton variant="rounded" width={210} height={40} style={{marginLeft:'auto',marginTop:10}}  />
 
 </Stack>
 <Skeleton variant="rounded" width={'400'} height={100} style={{margin:10,mr:'0'}}  />
 <Skeleton variant="rounded" width={'400'} height={100} style={{margin:10,mr:'0'}}  />
 
 </Stack>
 </>
  

  useEffect(() => {
      if (course_id) {
          dispatch(getAdminCourseDetailsRequest({ course_id }));
          dispatch(getCourseInstructorsRequest({ course_id }));
        //  dispatch(getCourseJoinedUsersRequest({filter:{ course_id:course_id,page_number:1,page_size:4,status:'joined'} }));
          dispatch(getCoursePendingUsersRequest({ filter:{course_id,page_number:1,page_size:4,status:'pending'} }));
          dispatch(getCourseCouponsRequest({ course_id }));
          dispatch(getCourseSubjectsRequest({course_id}))
      }
    setLayoutData({
      title: "Courses",
      breadcrumb: [
        { title: "Home", path: "/", active: true },
        { title: "Courses", path: "/courses", active: true },
        {
          title: "Course detail",
          path: `/courses/${course_id}`,
          active: false,
        },
      ],
    });
  }, [course_id]);
  return (
    <>
    <Loader open={isLoading?.loading||userLoading?.loading||instructorLoading?.loading}></Loader>
    <CourseDetailsTemplate
          errorColor={errorColor}
          course={course}
          cards={cards}
          updated={updated}
          setCards={setCards}
          instructors={instructors}
          joinedUsers={joinedUsers}
          pendingUsers={pendingUsers }
          coupons={coupons}
          subjects={subjects}
    />
    </>
   
    
  );
};

export default CourseDetailsPage;
