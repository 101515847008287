import Types from './Types';

export const getQuestionBanksSuccess = (payload) => ({
    type: Types.GET_QUESTION_BANKS_SUCCESS,
    payload
});

export const questionBankLoading = (payload) => ({
    type: Types.QUETION_BANK_LOADING,
    payload
});

export const getQuestionBankDetailsSuccess = (payload) => ({
    type: Types.GET_QUETION_BANK_DETAILS_SUCCESS,
    payload
});




