import { Box } from "@mui/material";
import { images } from "assets/AssetHelper";
import React from "react";

export default function Logo({ sx }) {
  return (
    <Box
      component="img"
      src={images.Logo}
      sx={{ width: { xs: "45px", md: "90px" }, height: { xs: "51px", md: "90px" }, ...sx }}
    />
  );
}
