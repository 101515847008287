import { Paper, Stack, Typography,Checkbox ,FormControlLabel,Divider} from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import React from "react";
import TextFieldInput from "components/Atoms/Input/TextField/TextFieldInput";
import SearchableSelect from "components/Atoms/Select/SearchableSelect";

const QuizConfigurations = ({formik}) => {
    const { inputBg } = useThemePalette();
    //grading options
    const gradingOptions=[
      {
        id: "0",
        name: "normal",
      },
      {
        id: "1",
        name: "american",
      },
    ];
  
    const handelGradingOptions = gradingOptions?.map((val) => {
      return {
        label: val?.name,
        value: val?.id,
        color: val?.color,
      };
    });
    //block following list
    const blockingOptions=[
      {
        id: "0",
        name: "true",
      },
      {
        id: "1",
        name: "false",
      },
    ];
  
    const handelBlockingOptions = blockingOptions?.map((val) => {
      return {
        label: val?.name,
        value: val?.id,
        color: val?.color,
      };
    });

    // grade based on
    const evaluationOptions=[
      {
        id: "0",
        name: "highest",
      },
      {
        id: "1",
        name: "latest",
      },
      {
        id: "2",
        name: "average",
      },

    ];
  
    const handelEvaluationOptions = evaluationOptions?.map((val) => {
      return {
        label: val?.name,
        value: val?.id,
        color: val?.color,
      };
    });
    const onPaste = event => {
      const symbol=event.clipboardData.getData("text/plain")[0]
      if (symbol === '-' || symbol === '+') {
        event.preventDefault();
      }

    };
  

    return (
      <Stack spacing={2} direction={'column'} component={Paper} p={4}>
      <Typography fontSize="16px" fontWeight={600}>
            Configurations
      </Typography>
      <Divider/>
      <Stack direction="row" spacing={2} width="100%" >

      <Stack width="50%">
          <TextFieldInput
            label="Duration of the Quiz (minutes)"
            name="quiz_configuration.duration"
            type="number"
            placeholder="duration"
            sx={{
              background: inputBg,
              padding: "0",
              borderRadius: "8px",
              "& fieldset": {
                border: "none",
              },
            }}
            InputProps={{
              inputProps: { min: 0 },
              onPaste

            }}
            onKeyPress={(event) => {
              if (event?.key === '-' || event?.key === '+') {
                event.preventDefault();
              }
            }}
            
          
            onChange={formik.handleChange}
            value={formik.values?.quiz_configuration?.duration}
            // error={formik.errors.duration}
            // helperText={formik.errors.duration}
        />

        </Stack>


        <Stack width="50%">
          <TextFieldInput
            label="Max Number of Attempts"
            name="quiz_configuration.max_no_of_attempts"
            type="number"
            placeholder="attempts"
            sx={{
              background: inputBg,
              padding: "0",
              borderRadius: "8px",
              "& fieldset": {
                border: "none",
              },
            }}
            InputProps={{
              inputProps: { min: 0 },
              onPaste

            }}
            onKeyPress={(event) => {
              if (event?.key === '-' || event?.key === '+') {
                event.preventDefault();
              }
            }}

            onChange={formik.handleChange}
            value={formik.values?.quiz_configuration?.max_no_of_attempts}
            // error={formik.errors.attempts}
            // helperText={formik.errors.attempts}
        />

        </Stack>

      </Stack>



      <Stack direction="row" spacing={2} width="100%" >

        <Stack width="50%">
            <TextFieldInput
              label="Passing Percentage"
              name="quiz_configuration.passing_grade"
              type="number"
              placeholder="passing grade"
              sx={{
                background: inputBg,
                padding: "0",
                borderRadius: "8px",
                "& fieldset": {
                  border: "none",
                },
              }}
              InputProps={{
                inputProps: { min: 0 },
                onPaste
  
              }}
              onKeyPress={(event) => {
                if (event?.key === '-' || event?.key === '+') {
                  event.preventDefault();
                }
              }}
                onChange={formik.handleChange}
              value={formik.values?.quiz_configuration?.passing_grade}
              // error={formik.errors.passing_grade}
              // helperText={formik.errors.passing_grade}
          />

          </Stack>


          <Stack width="50%">
            <TextFieldInput
              label="No. of questions Per page"
              name="quiz_configuration.num_of_questions_per_page"
              type="number"
              placeholder="questions Per page"
              sx={{
                background: inputBg,
                padding: "0",
                borderRadius: "8px",
                "& fieldset": {
                  border: "none",
                },
              }}
              InputProps={{
                inputProps: { min: 1 },
                onPaste
  
              }}
              onKeyPress={(event) => {
                if (event?.key === '-' || event?.key === '+') {
                  event.preventDefault();
                }
              }}
                onChange={formik?.handleChange}
              value={formik?.values?.quiz_configuration?.num_of_questions_per_page}
              // error={formik.errors.num_of_questions_per_page}
              // helperText={formik.errors.num_of_questions_per_page}
          />

          </Stack>

      </Stack>

    <Stack direction="row" spacing={2} width="100%" >

      <Stack width="50%">
      <SearchableSelect
        isClearable={false}
        options={gradingOptions}
        value={handelGradingOptions[handelGradingOptions.findIndex(option => option.label === formik?.values?.quiz_configuration?.correcting_system)]}
        onChange={(value)=>{
          formik?.setFieldValue("quiz_configuration.correcting_system",value?.label)
        }}
        
        placeholder="Grading System"
        title="Grading System"
        />


      </Stack>


        <Stack width="50%">
          <TextFieldInput
            label="American System"
            name="quiz_configuration.american_system_penalty"
            type="number"
            placeholder="Enter here"
            sx={{
              background: inputBg,
              padding: "0",
              borderRadius: "8px",
              "& fieldset": {
                border: "none",
              },
            }}
            InputProps={{
              inputProps: { min: 0 },
              onPaste

            }}
            onKeyPress={(event) => {
              if (event?.key === '-' || event?.key === '+') {
                event.preventDefault();
              }
            }}
            onChange={formik.handleChange}
            value={formik.values?.quiz_configuration?.american_system_penalty}
            // error={formik.errors.american_system}
            // helperText={formik.errors.american_system}
        />

        </Stack>

      </Stack>


      <Stack direction="row" spacing={2} width="100%" >

        <Stack width="50%">
          <SearchableSelect
            isClearable={false}
            options={blockingOptions}
            value={handelBlockingOptions[handelBlockingOptions.findIndex(option => option.label === formik?.values?.quiz_configuration?.block_following_resources.toString())]}
            onChange={(value)=>{
              formik?.setFieldValue("quiz_configuration.block_following_resources",value.label==="true"?true:false)
            }}
    
            placeholder="Block Following Resources"
            title="Block Following Resources"
            />
        </Stack>


        <Stack width="50%">
          <SearchableSelect
            isClearable={false}
            options={evaluationOptions}
            value={handelEvaluationOptions[handelEvaluationOptions.findIndex(option => option.label === formik?.values?.quiz_configuration?.final_grade_based_on?.value)]}
            onChange={(value)=>{
              formik?.setFieldValue("quiz_configuration.final_grade_based_on.value",value?.label)
            }}

            placeholder="Final Grade Based on"
            title="Final Grade Based on"
            />
        </Stack>

      </Stack>
      <FormControlLabel control={<Checkbox
      checked={formik?.values?.is_posted !==undefined ?formik?.values?.is_posted:false}
      onChange={formik?.handleChange}
      name="is_posted"
    />} label="Is Posted" />

    </Stack>

  );
};

export default QuizConfigurations;
