import { Divider, Stack, Typography, Menu, MenuItem, IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreHorizRounded";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import SectionSelecteSource from "../actionsMenu/sectionSelecteSource";
import { useParams } from "react-router";
import { deleteCourseSectionRequest, getCourseSectionsRequest } from "services/modules/courses/Requests/sections";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import DeleteModal from "components/Atoms/Model/DeleteModel";
import { useNavigate } from "react-router-dom";

const CourseSections = ({ data, children, type }) => {
  const course = useSelector((state) => state.courses.course);
  const [anchorEl, setAnchorEl] = useState(null);
  const[openDeleteModel,setOpenDeleteModel]=useState(false)
  const open = Boolean(anchorEl);
  const {course_id}=useParams()
  const dispatch=useDispatch();
  const navigate=useNavigate();
  //console.log(type)
  const handleEditClick=(event)=>{
    event.stopPropagation();

  navigate(`/section/${course_id}/${data?.section_id}`)
  }
//console.log(openDeleteModel)
  const handleClick = (event) => {
    event.stopPropagation();
    if (anchorEl === event.currentTarget) setAnchorEl(null);
    else setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
   
    event.stopPropagation();
    setAnchorEl(null);
  };
  const opendeleteSectionModel=(event)=>{
    event.stopPropagation();
    setOpenDeleteModel(true)
    setAnchorEl(null);

  }
  const handleCloseModel=(event)=>{
    event.stopPropagation();
    setOpenDeleteModel(false)



  }
  const handleDelete=(event)=>{
    event.stopPropagation();
    const requestData={
      course_id,
      section_id:data?.section_id,
      action:()=>{
        dispatch(getCourseSectionsRequest({course_id}))
      }
    }
    setOpenDeleteModel(false)
    dispatch(deleteCourseSectionRequest(requestData))

  }
  return (
    <Stack sx={{ width: "98%" ,cursor:'pointer'}}spacing={2} marginBottom="12px !important">
      
      <Stack
        alignItems="flex-start"
        direction="row"
        width={"100% !important"}
        justifyContent="space-between"
      >
        <Typography fontWeight="600" fontSize="20px">
          {data?.title}
        </Typography>
        <Stack flexDirection={'row'} alignItems={'center'}>

        <div>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? "long-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                padding: "10px",
                width: "20ch",
              },
            }}
          >
            <MenuItem onClick={opendeleteSectionModel}>
              <Typography  color={"error"} >Delete</Typography>
            </MenuItem>
            <MenuItem onClick={handleEditClick}>
              <Typography   >Edit</Typography>
            </MenuItem>
           {/* <Divider sx={{ my: 0.5 }} />
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: "14px",
                lineHeight: "17px",
                color: "#828282",
                paddingInline: "15px",
              }}
            >
              Move to
            </Typography>
            <Typography
              sx={{
                fontWeight: "500",
                fontSize: "16px",
                lineHeight: "19px",
                color: "#4F4F4F",
                paddingInline: "15px",
                paddingBlock: "5px",
              }}
            >
              Bottom
            </Typography>
            <MenuItem onClick={handleClose} sx={{ paddingBlock: "15px" }}>
              <Stack>
                {course?.sections?.map((section, idx) => {
                  if (data?.title !== section?.title)
                    return (
                      <Typography
                        key={idx}
                        sx={{
                          fontWeight: "500",
                          fontSize: "16px",
                          lineHeight: "19px",
                          color: "#4F4F4F",
                        }}
                      >
                        {section?.title}
                      </Typography>
                    );
                })}
              </Stack>
              </MenuItem>*/}
          </Menu>
        </div>
        <ExpandLessIcon></ExpandLessIcon>

        </Stack>
      </Stack>
      <DeleteModal
                  open={openDeleteModel}
                  text={`Are you sure you want to delete "${data?.title}" section`} handelDeleteRequest={handleDelete} handleClose={handleCloseModel}
              >
              </DeleteModal>
      

      <Stack alignItems="flex-start" direction="row">
        {type !== "modal" && 
        <SectionSelecteSource course={course} section={data} />
        
        }
      </Stack>

      {/* description */}
      {data?.text ? (
        <Stack>
          <Typography fontWeight="400" fontSize="14px" marginBottom="10px" color="#2C2D33">
            Description
          </Typography>
          <Typography fontWeight="300" fontSize="14px" color="#6E7079">
            {data?.text}
          </Typography>
        </Stack>
      ) : (
        data?.body && (
          <Stack>
            <Typography fontWeight="400" fontSize="14px" marginBottom="10px" color="#2C2D33">
              Description
            </Typography>
            <Typography fontWeight="300" fontSize="14px" color="#6E7079">
              {data?.body}
            </Typography>
          </Stack>
        )
      )}
    </Stack>
  );
};

export default CourseSections;
