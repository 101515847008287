import AddSectionTemplate from "Templates/Courses/section/AddSection.Template";
import EditSectionTemplate from "Templates/Courses/section/EditSection.Template";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getCourseSectionsRequest } from "services/modules/courses/Requests/sections";
import { showSectionRequest } from "services/modules/section";

const EditSectionPage=({setLayoutData})=>{
    const dispatch = useDispatch();
    const { course_id,section_id } = useParams();
    const course=useSelector((state)=>state.courses.course)
    const section=useSelector((state)=>state.sections?.section)
    useEffect(()=>{
        dispatch(showSectionRequest({course_id,section_id}))
        dispatch(getCourseSectionsRequest({course_id}))


    },[])

  useEffect(() => {
    setLayoutData({
      title: "Section",
      breadcrumb: [
        { title: "Home", path: "/", active: true },

        {
          title: "Courses",
          path: "/courses",
          active: true,
        },
        {
          title: "Course Detail",
          path: `/courses/${course_id}`,
          active: true,
        },
        {
          title:section?.title ,
          path: "",
          active: false,
        },
      ],
    });
  }, [course_id,section_id,section]);
return(
    <EditSectionTemplate  />
)
}
export default EditSectionPage;