import { Stack, Typography } from "@mui/material";
import BasicDatePicker from "components/Atoms/Input/DatePicker/DatePicker";
import TextFieldInput from "components/Atoms/Input/TextField/TextFieldInput";
import UploadInput from "components/Atoms/Input/FileInput/UploadInput";
import Loader from "components/Atoms/Loader/Loader";
import { useState } from "react";
import { getPresignedLinkAPI, uploadFileAPI } from "services/modules/utils/Upload";

function CreateCourseStep1(props) {
  const [openLoader, setOpenLoader] = useState(false);
  const [imageFileName, setImageFileName] = useState("");
  const [videoFileName, setVideoFileName] = useState("");

  const uploadFile = async (event, type) => {
    setOpenLoader(true);
    const res = await getPresignedLinkAPI([{ extension: type === "image" ? "png" : "mp4" }]);
    const presignedUrl = res?.res?.data[0].presigned_url;
    const file = event.target.files[0];
    const upload = await uploadFileAPI(presignedUrl, file);
    if (upload) {
      if (type === "image") setImageFileName(file?.name); // res?.res?.data[0].file_name
      else setVideoFileName(file?.name);
    }
    setOpenLoader(false);
  };

  return (
    <Stack spacing={2} direction="row" className="custom_input">
      <Loader open={openLoader} />
      {/* right side */}
      <Stack spacing={2} width="50%">
        <Typography fontSize="12px" color="#5E6366">
          {" "}
          {/* Title */}
          Title*
        </Typography>
        <TextFieldInput
          sx={{
            background: props.inputBg,
            padding: "10px",
            borderRadius: "8px",
          }}
          placeholder="Title"
          onChange={props.formik.handleChange}
          value={props.formik.values.title}
          name="title"
          variant="standard"
          error={props.formik.errors.title}
          helperText={props.formik.errors.title}
        />
        <Typography fontSize="12px" color="#5E6366">
          {" "}
          {/* Description */}
          Description*
        </Typography>
        <TextFieldInput
          rows={4}
          multiline={true}
          variant="standard"
          sx={{
            background: props.inputBg,
            padding: "10px",
            borderRadius: "8px",
          }}
          placeholder="Description"
          onChange={props.formik.handleChange}
          value={props.formik.values.description}
          name="description"
          error={props.formik.errors.description}
          helperText={props.formik.errors.description}
        />
        <Stack direction="row" spacing={2}>
          <Stack>
            <Typography fontSize="12px" color="#5E6366" mb={1}>
              {" "}
              {/* Start date */}
              Start Date
            </Typography>
            <BasicDatePicker
              id="start_date"
              value={props.startDate}
              setValue={props.setStartDate}
              sx={{
                background: props.inputBg,
                borderRadius: "8px",
                "& fieldset": {
                  border: "none",
                },
              }}
            />
          </Stack>
          <Stack>
            <Typography fontSize="12px" color="#5E6366" mb={1}>
              {" "}
              {/* End date */}
              End Date (Optional)
            </Typography>
            <BasicDatePicker
              id="end_date"
              value={props.endDate}
              setValue={props.setEndDate}
              sx={{
                background: props.inputBg,
                borderRadius: "8px",
                "& fieldset": {
                  border: "none",
                },
              }}
            />
          </Stack>
        </Stack>
      </Stack>
      {/* left side */}
      <Stack spacing={2} width="50%">
        <Typography fontSize="12px" color="#5E6366">
          {" "}
          {/* URL */}
          URL (Optional)
        </Typography>
        <TextFieldInput
          variant="standard"
          sx={{
            background: props.inputBg,
            padding: "10px",
            borderRadius: "8px",
          }}
          placeholder="https://www.example.com"
          onChange={props.formik.handleChange}
          value={props.formik.values.url}
          name="url"
          error={props.formik.errors.url}
          helperText={props.formik.errors.url}
        />
        <Typography fontSize="12px" color="#5E6366">
          {" "}
          {/* Video upload */}
          Or Upload Video (Optional)
        </Typography>
        <UploadInput
          id="video"
          btnText="Choose File"
          placeholder="Select Video"
          btnStyle={{
            background: "#2F80ED",
            color: "white",
            width: "100%",
            height: "100%",
            borderRadius: "0 8px 8px 0",
            textTransform: "capitalize",
          }}
          inputStyle={{
            background: props.inputBg,
            borderRadius: "8px 0 0 8px",
            "& fieldset": {
              border: "none",
            },
          }}
          change={(e) => uploadFile(e, "video")}
          textValue={videoFileName}
        />
        <Typography fontSize="12px" color="#5E6366">
          {" "}
          {/* Image Upload */}
          Upload Image
        </Typography>
        <UploadInput
          id="image"
          btnText="Choose File"
          placeholder="Select image"
          btnStyle={{
            background: "#2F80ED",
            color: "white",
            width: "100%",
            height: "100%",
            borderRadius: "0 8px 8px 0",
            textTransform: "capitalize",
          }}
          inputStyle={{
            background: props.inputBg,
            borderRadius: "8px 0 0 8px",
            "& fieldset": {
              border: "none",
            },
          }}
          change={(e) => uploadFile(e, "image")}
          textValue={imageFileName}
        />
      </Stack>
    </Stack>
  );
}

export default CreateCourseStep1;
