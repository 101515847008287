/* eslint-disable array-callback-return */
import Types from "./Types";

const INIT_STATE = {
  adminUsers: [],
  adminUser:{},
  count:0,
  isLoading: {
    type: "",
    loading: false,
  },
};

export function adminUsersReducer(state = INIT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.CREATE_ADMIN_USERS_SUCCESS: {
      return {
        ...state,
        adminUsers: state.adminUsers.concat(payload.data),
      };
    }
    case Types.ADMIN_USERS_LOADING: {
      return {
        ...state,
        isLoading: payload,
      };
    }
      case Types.GET_ALL_ADMIN_USERS_SUCCESS: {
      return {
        ...state,
        adminUsers: payload.data,
        count:payload.extra.total_count,
      };
    }
    case Types.SHOW_ADMIN_USERS_DETAILS_SUCCESS: {
      return {
        ...state,
        adminUser: payload.data,
      };
    }
      case Types.DELETE_ADMIN_USERS_SUCCESS: {
      return {
          ...state,
          adminUsers: payload.data,

      };
    }
    case Types.UPDATE_ADMIN_USERS_SUCCESS: {
     
      return {
        ...state,
        adminUser: payload.data,

      };
    }

    default: {
      return state;
    }
  }
}
