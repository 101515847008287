import * as Yup from "yup";
export const loginSchema = Yup.object().shape({
  email: Yup.string().required().email().label("Email"),
  password: Yup.string().required().label("Password"),
});
export const forgetPasswordSchema = Yup.object().shape({
  email: Yup.string().required().email().label("Email"),
});
export const resetPasswordSchema = Yup.object().shape({
  password: Yup.string().required().label("Password"),
  password_confirmation: Yup.string().required().label("Password"),

});