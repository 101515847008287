import { Stack, Typography } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import UploadInput from "components/Atoms/Input/FileInput/UploadInput"
import TextFieldInput from "components/Atoms/Input/TextField/TextFieldInput"
import UploadModel from "components/Atoms/Model/uploadModel"
import { useState } from "react";
import { getPresignedLinkAPI, uploadFileAPI } from "services/modules/utils/Upload";

const E_Book=({formik})=>{
    const [openUploadModel,setOpenUploadModel]=useState(false);
    const [videoFileName, setVideoFileName] = useState("");
    const{inputBg}=useThemePalette();
    const uploadFile = async (event, type) => {
        setOpenLoader(true);
        const res = await getPresignedLinkAPI([{ extension: type }]);
        const presignedUrl = res?.res?.data[0].presigned_url;
        //console.log(presignedUrl)
        const file = event.target.files[0];
        //console.log(file)
        const upload = await uploadFileAPI(presignedUrl, file);
        if (upload) {
          setVideoFileName(file?.name);
        }
        setOpenLoader(false);
      };
    const handleClose=()=>{
      setOpenUploadModel(false)
    }
return(<> 
   <Stack flexDirection={'row'} gap={3} sx={{width:'100%'}}>
   <TextFieldInput
      label="No.of Chapters"

  sx={{
    background: inputBg,
    padding: "0",
    borderRadius: "8px",
    "& fieldset": {
      border: "none",
      width:'47%'
    },
  }}
  placeholder="price"
  name="book_num_of_chapters"
  onChange={formik.handleChange}
  value={formik.values.book_num_of_chapters}
  error={formik.errors.book_num_of_chapters}
  helperText={formik.errors.book_num_of_chapters}
/>
      <TextFieldInput
      label="Price"

  sx={{
    background: inputBg,
    padding: "0",
    borderRadius: "8px",
    "& fieldset": {
      border: "none",
      width:'47%'
    },
  }}
  placeholder="price"
  name="price"
  onChange={formik.handleChange}
  value={formik.values.price}
  error={formik.errors.price}
  helperText={formik.errors.price}
/>
      </Stack>
 <Typography fontSize="12px" color="#5E6366">
          {" "}
          {/* Video upload */}
          Upload Book 
        </Typography>
              <UploadInput
        openModel={true}
          id="book"
          setOpenUploadModel={setOpenUploadModel}
          btnText="Add Attachment"
          placeholder="Choose file"
          btnStyle={{
            background: "#2F80ED",
            opacity:'64%',
            color: "white",
            width: "100%",
            height: "100%",
            borderRadius: "0 8px 8px 0",
            textTransform: "capitalize",
          }}
          inputStyle={{
            borderRadius: "8px 0 0 8px",
            "& fieldset": {
              border: "none",
              background:inputBg,
            },
            
          }}
          change={(e) => uploadFile(e, "pdf")}
          textValue={videoFileName}
        />
         <Typography fontSize="12px" color="#5E6366">
          {" "}
          {/* Video upload */}
           Upload Outline 
        </Typography>
         <UploadInput
        openModel={true}
          id="outline"
          setOpenUploadModel={setOpenUploadModel}
          btnText="Add Attachment"
          placeholder="Choose File"
          btnStyle={{
            background: "#2F80ED",
            opacity:'64%',
            color: "white",
            width: "100%",
            height: "100%",
            borderRadius: "0 8px 8px 0",
            textTransform: "capitalize",
          }}
          inputStyle={{
            borderRadius: "8px 0 0 8px",
            "& fieldset": {
              border: "none",
              background:inputBg,
            },
            
          }}
          change={(e) => uploadFile(e, "pdf")}
          textValue={videoFileName}
        />
         <UploadModel
         formik={formik}
      openVar={openUploadModel}
       title={'Upload Video'}
       titleStyle={{fontWeight:500,fontSize:20,color:'#45464E'}}
       setOpenUploadModel={setOpenUploadModel}
       closeFunc={handleClose}
       setVideoFileName={setVideoFileName}>
       </UploadModel>
</>)
}
export default E_Book