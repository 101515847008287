// import axios from "axios";
// import {
//   fileUploadLoading,
//   fileUploadOnServerLoading,
//   fileUploadSuccess,
//   getActiveIndex,
// } from "./Action";
// import { reduxRequest } from "common/utils/reduxRequest";
// import { store } from "services/store";
// const token = localStorage.getItem("token");
// export const getPresignedUrlRequest = ({ ...variables }) => {
//   //console.log(variables);
//   const requestData = {
//     ...variables,
//     isLoading: fileUploadLoading,
//     // successFunction: fileUploadSuccess,
//     loadingType: "upload",
//     url: "v1/presigned_url",
//     method: "post",
//     data: variables.body,
//     onUploadProgress:variables?.onUploadProgress,

//     action: (data) => {
//       const res = data?.data;
//       //console.log(res);
//       store.dispatch(
//         uploadRequest({
//           presigned_url: res[0]?.presigned_url,
//           file: variables.file,
//           fileUrl: res[0]?.file_url,
//           onUploadProgress:variables?.onUploadProgress
//         })
//       );
//     },
//   };
//   return async (dispatch) => {
//     reduxRequest({
//       dispatch,
//       ...requestData,
//     });
//   };
// };
// export const uploadRequest = ({ ...variables }) => {
//   //console.log(variables);
//   const requestData = {
//     ...variables,
//     isLoading: fileUploadOnServerLoading,
//     loadingType: "uploadOnServer",
//     url: variables.presigned_url,
//     onUploadProgress:variables?.onUploadProgress,
//     data: variables.file,
   
//     method: "put",
//     action: () => {
//       store.dispatch(fileUploadSuccess(variables?.fileUrl));
//       // set token in headers after uploading success
//       axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
//       delete axios.defaults.headers.common["Content-Type"];


//     },
//   };
//   delete axios.defaults.headers.common["Authorization"];
//   axios.defaults.headers.common["Content-Type"] = variables?.file?.type;

//   return async (dispatch) => {
//     reduxRequest({
//       dispatch,
//       ...requestData,
//     });
//   };
// };



import axios from "axios";
import {
  fileUploadLoading,
  fileUploadOnServerLoading,
  fileUploadSuccess,
  getActiveIndex,
} from "./Action";

import { reduxRequest } from "common/utils/reduxRequest";
import { store } from "services/store";
const token = localStorage.getItem("token");
export const getPresignedUrlRequest = ({ ...variables }) => {
  const requestData = {
    ...variables,
    isLoading: fileUploadLoading,
    successFunction: fileUploadSuccess,
    loadingType: "upload",
    url: "/v1/presigned_url",
    method: "post",
    body: variables.body.extension,
    action: (data) => {
      //console.log(data);
      //console.log(data?.data[0].presigned_url)
      //console.log(variables.body.file)
      //console.log(data?.data[0].file_url)
      //console.log(variables.updateData)

      store.dispatch(
        uploadRequest({
          presigned_url: data?.data[0].presigned_url,
          file: variables.body.file,
          fileUrl: data?.data[0].file_url,
          updateData: variables.updateData,
        })
      );
    },
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};




export const uploadRequest = ({ ...variables }) => {
  const requestData = {
    ...variables,
    isLoading: fileUploadOnServerLoading,
    successFunction: fileUploadSuccess,
    loadingType: "uploadOnServer",
    url: variables.presigned_url,
    body: variables.file,
    method: "put",
    action: () => {
      store.dispatch(fileUploadSuccess(variables?.fileUrl));
      // //console.log(variables?.updateData);
      variables?.updateData(variables.fileUrl);
      // set token in headers after uploading success
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    },
  };
  delete axios.defaults.headers.common["Authorization"];
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
