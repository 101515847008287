import { useState } from "react";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import { icons } from "assets/AssetHelper";
import SelectBox from "components/Atoms/Select/Select";
import { useThemePalette } from "common/hooks/theme_palette";
import CloseIcon from "@mui/icons-material/Close";

const Header = ({
  globalFilter,
  setGlobalFilter,
  Data,
  Title,
  subTitle,
  noFilter,
  noSearchBar,
  filterList,
  handleSearchChange,
  selectBoxData,
  setPage,
}) => {
  const [value, setValue] = useState(globalFilter);
  const onChange = () => {
    if (value) {
      setGlobalFilter(value);
      handleSearchChange(value);
      setPage(1);
    }
  };
  const { primaryColor } = useThemePalette();

  return (
    <Stack
      direction="row"
      alignItems={selectBoxData ? "end" : "center"}
      justifyContent="space-between"
      p={2}
    >
      <Stack width="100%">
        <Typography fontSize="18px" fontWeight="600" gutterBottom>
          {Title}
        </Typography>
        {Data.length === 0 ? (
          ""
        ) : (
          <Typography variant="subtitle1" gutterBottom>
            {subTitle}
          </Typography>
        )}
        <Box sx={{ width: "50%" }}>
          {selectBoxData && (
            <SelectBox
              items={selectBoxData?.items}
              className="custom-select"
              value={selectBoxData?.value}
              placeholder={selectBoxData?.placeholder}
              onclick={selectBoxData?.onclick}
              onchange={selectBoxData?.onChange && selectBoxData?.onChange}
              variant="outlined"
              sx={{
                padding: "0!important",
                borderRadius: "2px",
                bgcolor: "transparent",
                border: "1px solid primary.main",
                "& .MuiSelect-select": {
                  padding: "14px  10px 13px",
                },
                ".MuiOutlinedInput-notchedOutline": { border: `1px solid ${primaryColor}` },
              }}
            />
          )}
        </Box>
      </Stack>

      {noSearchBar ? (
        ""
      ) : (
        <Stack
          direction="row"
          justifyContent={"end"}
          alignItems="center"
          width={{ sm: "50%", md: "30%" }}
        >
          <>
            <>
              <OutlinedInput
                id="outlined-adornment-password"
                type={"text"}
                value={value}
                size="small"
                placeholder="Search"
                sx={{ marginX: "5px" }}
                onChange={(e) => setValue(e.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <IconButton aria-label="toggle password visibility" edge="start">
                      <img src={icons.searchIcon} alt="" />
                    </IconButton>
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment
                    onClick={() => {
                      if (value) {
                        setValue("");
                        setGlobalFilter("");
                        handleSearchChange("");
                        setPage && setPage(1);
                      }
                    }}
                    position="end"
                  >
                    <IconButton
                      aria-label="toggle password visibility"
                      edge="end"
                      sx={{ opacity: value ? 1 : 0, cursor: value ? "pointer" : "auto" }}
                    >
                      <CloseIcon fontSize="14px" sx={{ cursor: value ? "pointer" : "auto" }} />
                    </IconButton>
                  </InputAdornment>
                }
              />
              <Button
                sx={{ cursor: value ? "pointer" : "auto" }}
                onClick={onChange}
                disabled={!value}
              >
                Search
              </Button>
            </>
            {/* This filter will be temporarily commented out until the backend and design teams finish their work. Once they're done, we'll revisit and uncomment it as needed.*/}
            {/* {noFilter ? "" : <>{filterList}</>} */}
          </>
        </Stack>
      )}
    </Stack>
  );
};

export default Header;
