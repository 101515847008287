import AdminUserDetailsTemplate from "Templates/AdminUsers/AdminUserDetails.Template";
import SessionDetailsTemplate from "Templates/Courses/session/SessionDetails.Template";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { ShowAdminUserDetailsRequest } from "services/modules/AdminUsers";
import { getAdminCourseDetailsRequest } from "services/modules/courses/Requests/courses";
import { ShowSessionDetailsRequest } from "services/modules/courses/Requests/sessions";


const SessionDetailsPage = ({ setLayoutData }) => {
  const dispatch = useDispatch();
  const { section_id, session_id, course_id } = useParams();
  //console.log(section_id,session_id,course_id)
    const session = useSelector((state) => state.courses.session);
    const course = useSelector((state) => state.courses.course);
    const [courseDetails,setCourseDetails]=useState(course)

    //console.log(course)
    useEffect(() => {
        if (session_id) dispatch(ShowSessionDetailsRequest({course_id, session_id,section_id}));
      }, [session_id]);
      useEffect(() => {
        dispatch(getAdminCourseDetailsRequest({course_id}))
      }, []);
  
  useEffect(() => {
    setLayoutData({
      title: "Session",
      breadcrumb: [
        { title: "Home", path: "/", active: true },

        {
          title: "Courses",
          path: "/courses",
          active: true,
        },
        {
          title: course?.title,
          path: `/courses/${course_id}`,
          active: true,
        },
        {
          title: session?.title,
          path: "",
          active: false,
        },
      ],
    });
  }, [course,session]);
  return <SessionDetailsTemplate session={session} />;
};

export default SessionDetailsPage;
