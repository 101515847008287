import AddIcon from "@mui/icons-material/Add";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { Box, Stack } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ReactSortable } from "react-sortablejs";
import {
  getCourseSectionsRequest,
  reoderCourseSectionRequest,
} from "services/modules/courses/Requests/sections";
import { useThemePalette } from "../../../../common/hooks/theme_palette";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import CourseSections from "../components/sectionFullData";
import SectionMainData from "../components/sectionMainData";
import SubSection from "../models/SubSection";
import { SectionsData } from "./lv-2";
import Footer from "components/Molecules/Table/footer";
import { isScrolledToBottom } from "services/modules/utils/Helper";

export const Sections = ({ course, updated, setUpdated }) => {
  const [display, setDisplay] = useState({ index: 0, state: true });
  const navigate = useNavigate();
  const { course_id } = useParams();
  const { secondryColor } = useThemePalette();
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [data, setData] = useState([]);
  const [selecetedSessionData, setSelectedSessionData] = useState({});
  const { sections, sectionsCount } = useSelector((state) => state.courses);
  const [position, setPosition] = useState();
  const [page, setPage] = useState(1);
  const page_size = 10;
  const ref = useRef(null);
  const [isFetching, setIsFetching] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    setData(sections);
  }, [sections]);
  // check type of section data
  const handleSectionChange = (evt) => {
    const { newIndex, oldIndex, item } = evt;
    const draggedSection = data[oldIndex]; // id el section elly hy drag
    //console.log(draggedSection)

    const requestData = {
      course_id,
      section_id: draggedSection.section_id,
      new_position: newIndex + 1,
      action: () => {
        dispatch(getCourseSectionsRequest({ filter: { page_number: page, page_size, course_id } }));
      },
    };
    dispatch(reoderCourseSectionRequest(requestData));
  };

  useEffect(() => {
    if (course_id) {
      dispatch(getCourseSectionsRequest({ filter: { page_number: page, page_size, course_id } }));
    }
  }, [course_id, page]);

  useEffect(() => {
    const handleScroll = () => {
      if (ref.current) {
        const rect = ref.current.getBoundingClientRect();
        const sectionBottomInViewport = Math.floor(rect.bottom);
        const currentPage = sections?.length / page_size;
        // Check if the section is in view and not fetching data
        if (
          sectionBottomInViewport <= window.innerHeight &&
          sections?.length !== sectionsCount &&
          !isFetching
        ) {
          setIsFetching(true); // Set fetching flag to true
          if (Number.isInteger(currentPage)) {
            setTimeout(() => setPage(Math.ceil(currentPage + 1)), 200);
          } else {
            setPage(Math.ceil(currentPage));
          }
        }
      }
    };
    window.addEventListener("scroll", handleScroll);

    // Initial check in case the section is already in view
    handleScroll();

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [sections?.length, sectionsCount, isFetching]);

  useEffect(() => {
    if (page > 1) {
      setIsFetching(false); // Reset fetching flag after data is fetched
    }
  }, [sections]);



  return (
    <Stack ref={ref}>
      <ReactSortable list={data} setList={setData} onEnd={(elm) => handleSectionChange(elm)}>
        {updated &&
          data?.map((section, index) => {
            return (
              <React.Fragment key={index}>
                {display?.index === index ? (
                  <Stack
                    id={section.id}
                    alignItems="flex-start"
                    onClick={() => {
                      if (display?.index === index) setDisplay({ index: "", state: false });
                      else
                        setDisplay({
                          index: index,
                          state: !display?.state,
                        });
                    }}
                    bgcolor={"white"}
                    p="19px"
                    spacing={1}
                    borderRadius="12px"
                    mb={3}
                  >
                    <DragIndicatorIcon
                      sx={{
                        cursor: "move",
                        fontSize: "20px",
                        color: "#BDBDBD",
                        transform: "rotate(90deg)",
                        alignSelf: "center",
                      }}
                    />
                    {/* course main data */}
                    <CourseSections data={section} sectionID={section?.section_id} />
                    {console.log(section, "section")}
                    {/* course materials */}
                    <Stack sx={{ width: "100%" }} spacing={2}>
                      <SectionsData
                        section={section}
                        sessions={section.items}
                        sectionID={section?.section_id}
                        id={section?.id}
                        blockIndex={[index]}
                        setData={setData}
                        setOpen={setOpen}
                        setSelectedSessionData={setSelectedSessionData}
                      />
                    </Stack>
                  </Stack>
                ) : (
                  <Stack
                    id={section.id}
                    alignItems="flex-start"
                    onClick={() => {
                      if (display?.index === index) setDisplay({ index: "", state: false });
                      else
                        setDisplay({
                          index: index,
                          state: !display?.state,
                        });
                    }}
                    bgcolor={"white"}
                    p="16px"
                    spacing={1}
                    borderRadius="12px"
                    mb={3}
                  >
                    <SectionMainData data={section} setDisplay={setDisplay} index={index} />
                  </Stack>
                )}
                <SubSection
                  open={open}
                  handleClose={handleClose}
                  section={section}
                  blockIndex={[index]}
                  setData={setData}
                  selecetedSessionData={selecetedSessionData}
                  // sessions={[...courseAssignments, ...courseQuizzes, ...courseSessions]}
                />
              </React.Fragment>
            );
          })}
      </ReactSortable>
      <Box alignSelf={"flex-end"}>
        <PrimaryButton
          width={{ sm: "50%", md: "15%" }}
          text="Add Section"
          variant={"contained"}
          style={{
            background: secondryColor,
            color: "white",
            alignSelf: "flex-end",
            textTransform: "none",
          }}
          click={() => navigate(`/section/${course_id}`)}
          startIcon={<AddIcon />}
          // click={() => { setOpen(true) }}
        />
      </Box>
      {/* {sectionsCount > page_size && (
        <Footer
          total_count={sectionsCount}
          paginationPage={page}
          handlePageChange={setPage}
          perPage={page_size}
        />
      )} */}
    </Stack>
  );
};
