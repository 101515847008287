import { Stack } from "@mui/system";
import CTable from "components/Molecules/Table/table";
import { Button, Fade, Menu, MenuItem } from "@mui/material";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { useState } from "react";
import PrimaryButton from "components/Atoms/Buttons/Primary/PrimaryButton";
import { useThemePalette } from "common/hooks/theme_palette";
import AddIcon from "@mui/icons-material/Add";
import CreateInstructorModel from "components/Organisms/instructors/models/create";
import { useDispatch } from "react-redux";
import { creatInstructorModel } from "services/modules/models/Actions";

function InstructorsListTemplate(props) {
  const dispatch = useDispatch();
  const { secondryColor } = useThemePalette();

  const [anchorEl, setAnchorEl] = useState(null);
  const [checked, setChecked] = useState([false]);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const columns = [
    {
      Header: "ID",
      accessor: "instructor_id",
    },
    {
      Header: "Name",
      accessor: "instructor_name",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Country Code",
      accessor: "country_code",
    },
    {
      Header: "Phone number",
      accessor: "phone_number",
    },
   
    {
      Header: "Phone verified",
      accessor: "is_phone_verified",
    },
    {
      Header: "",
      accessor: "Actions",
    },
  ];

  const filterList = (
    <div style={{ height: "40px" }}>
      <Button
        variant="outlined"
        id="fade-button"
        style={{ height: "100%" }}
        aria-controls={open ? "fade-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        startIcon={<FilterAltOutlinedIcon />}
        color="secondary"
      >
        Filter
      </Button>
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={handleClose}>Profile</MenuItem>
        <MenuItem onClick={handleClose}>My account</MenuItem>
        <MenuItem onClick={handleClose}>Logout</MenuItem>
      </Menu>
    </div>
  );

  return (
    <>
      <Stack mb={6} p={2} spacing={2}>
        <PrimaryButton
          width={{
            sm: "50%",
            md: "15%",
          }}
          text="Add a New Instructor"
          variant={"contained"}
          style={{
            background: secondryColor,
            color: "white",
            alignSelf: "flex-end",
          }}
          startIcon={<AddIcon />}
          click={() => {
            props.setSelectedRow(null)
            dispatch(creatInstructorModel())}}
        />
        <CTable
          selectedData={props.viewData}
          footerBtnTitle
          filterList={filterList}
          noFilter={false}
          Data={props.data}
          Columns={columns}
          Title="Instructors List"
          subTitle=""
          btnTitle=""
          placeHolder=""
          setPage={props.setPage}
          setPerPage={props.setPerPage}
          perPage={props.perPage}
          currentpage={props.page}
          setPageNumber={props.setPageNumber}
          handlePageChange={props.setPageNumber}
          paginationPage={props.page}
          totalPage={props?.count}
          checked={checked}
          setChecked={setChecked}
          total_count={props.count}
          onRowClick={props.onRowClick}
        />
      </Stack>
      <CreateInstructorModel selectedRow={props.selectedRow} setSelectedRow={props.setSelectedRow} page={props.page} page_size={props.perPage}/>
    </>
  );
}

export default InstructorsListTemplate;
