import { Box, CircularProgress, Paper, Stack, Typography } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import PrimaryButton from "components/Atoms/Buttons/Primary/PrimaryButton";
import FormDetails from "components/Organisms/session/components/details";
import FormTitle from "components/Organisms/session/components/title";
import ZoomMeeting from "components/Organisms/session/components/zoomMeeting";

import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { DeleteAssignmentRequest } from "services/modules/courses/Requests/assignment";

const SessionTemplate = ({ formik }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [sessionType,setSessionType]=useState(formik.values.course_session_type);

  const { section_id, assignment_id, course_id } = useParams();
  const { primaryColor, secondryColor,errorColor } = useThemePalette();
  const {isLoading}=useSelector((state)=>state.courses)

  return (
    <form onSubmit={formik.handleSubmit}>
    <Stack my={4} gap={2} px="20px">
      <Stack spacing={2} direction="row" alignItems="center" justifyContent={{ md: "flex-end" }}>
        {/*<PrimaryButton
          text="Delete Session"
          variant={"contained"}
          style={{
            color: 'white',
            backgroundColor: errorColor,
            textTransform:'none'
          }}
          click={() => {
            const requestData = {
              course_id,
              assignment_id,
              action: () => navigate(`/courses/${course_id}`),
            };
            dispatch(DeleteAssignmentRequest({ ...requestData }));
          }}
        />*/}
        <PrimaryButton
          text={isLoading?.loading&&isLoading.type==='create session'?<CircularProgress size={20} color="inherit"/>:"Create Session"}
          variant={"contained"}
          disabled={isLoading?.loading&&isLoading?.type==='create session'}

          style={{
            background: secondryColor,
            color: "white",
            textTransform:'none',
            minWidth:'170px',

          }}
          type={'submit'}
        />
      </Stack>
      <Stack component={Paper} p="30px 20px" gap={2}>
        <Typography color={primaryColor} fontSize="16px">
          Session
        </Typography>
        <FormTitle formik={formik} sessionType={sessionType} setSessionType={setSessionType} />
      </Stack>
      {sessionType==='zoom_meeting'&&
      <Stack component={Paper} p="30px 20px" gap={2}>
        <ZoomMeeting formik={formik} sessionType={sessionType} setSessionType={setSessionType} />
      </Stack>
       }
      <Stack component={Paper} p="30px 20px">
        <form onSubmit={formik.handleSubmit}>
          <FormDetails formik={formik} />
        </form>
      </Stack>
    </Stack>
    </form>
  );
};

export default SessionTemplate;
