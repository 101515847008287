import * as React from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import fileImage from "assets/icons/file_placeholder.svg";
import { Stack } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import IconButton from '@mui/material/IconButton';

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 ,alignItems: 'center',justifyContent:'center'}}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 10 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

export default function LinearWithValueLabel({progress,setProgress,file,stopUpload}) {



  return (
    <Box sx={{ width: '96%',mt:2,p:1, background:'#F1F4F6',borderRadius:'8px'}}>
        <Stack flexDirection={'row'} justifyContent={'space-between'}>
        <Stack
                    sx={{
                        cursor: 'pointer',
                        borderRadius: '8px',
                        alignItems: 'center',
                        justifyContent: 'center',
                        ml:'auto',
                        padding: '4px'
                    }}
                    onClick={stopUpload}

                >

                </Stack>
        </Stack>

      <Stack flexDirection="row" position="relative" gap={1} alignItems="center">
      <img src={fileImage} alt="file-placeholder"></img>

  <Stack width={'100%'}>
    <Typography>{file.name}</Typography>
    <Typography>{file.size / 1000} KB</Typography>
    {progress < 100 && <LinearProgressWithLabel value={progress} />}
  </Stack>
  <Box position="absolute" top={0} right={0}>
    {progress < 100 ? <Box style={{ cursor: 'pointer' }} onClick={stopUpload}>
      <CloseIcon fontSize="14px" />
    </Box> : <Stack flexDirection={'column'} gap={1}> 
    <CheckIcon fontSize="14px" sx={{ color: 'green' }} />
    <Box style={{ cursor: 'pointer' }} onClick={stopUpload}>
      <CloseIcon fontSize="14px" />
    </Box>
      </Stack>}
  </Box>
</Stack>


    </Box>
  );
}