import { Box, Button, Divider, Modal, Stack, Typography } from "@mui/material";
import { icons } from "assets/AssetHelper";
import { useThemePalette } from "common/hooks/theme_palette";
import CircularProgress from '@mui/material/CircularProgress';

const DeleteModal = ({
                       t,
                       open,
                       setOpen,
                       handelDeleteRequest,
                       handleClose,
                       customClass,
                       text,
                       index,
                       isInstructor=false,
                       btnTitle,
                       bgcolor,
                       isLoading
                     }) => {
    const { red, orangeBtn } = useThemePalette();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "25px",
    py: 4,
  };
  // const handleClose = () => setOpen(false);

  return (
    <Modal
      open={isInstructor ? ( open?.state&&index ==open?.index) : open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack
        sx={style}
        alignItems="center"
        justifyContent="center"
        className={`delete-modal ${customClass}`}
      >
        <Stack
          gap={3}
          alignItems="center"
          justifyContent="center"
          sx={{ width: "100%" }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            gap={1}
          >
            <Box component="img" src={icons.errorCircle} width="40px" />
            <Typography
              textAlign="center"
              style={{
                fontFamily: "Inter",
                fontWeight: "600",
                fontSize: "24px",
              }}
            >
              {text}
            </Typography>
          </Stack>
        </Stack>
        {/* <Stack flexDirection='row' alignItems='center' mt={4}>
                    <Button style={{ width: '150px', color: 'black' }} onClick={() => setOpen(false)}>  {t('offer.cancel')}</Button>

                    <Button style={{ width: '150px' }} className='primary r-25px' onClick={handelDeleteRequest}>{t('offer.delete')}</Button>
                </Stack> */}
        <Stack
          flexDirection="row"
          alignItems="center"
          mt={4}
          className="buttons-wrap"
          gap={2}
              >
                  <Button
                      sx={{
                          color: orangeBtn,
                          border: "1px solid #F79E1B",
                          "&:hover": {
                              backgroundColor: 'transparent',
                          },
                          textTransform: 'none',
                          minWidth: '180px',
                      }}
                      className="r-25px"
                      onClick={handleClose}
                  >
                      Cancel
                  </Button>
          

          <Button
            sx={{
              backgroundColor: bgcolor || 'red',
              "&:hover": {
                backgroundColor:bgcolor ||  'red',
              },
                          color: "white",
                          textTransform:'none',
                          minWidth: '180px',
            }}
            className="primary r-25px"
            onClick={handelDeleteRequest}
          >
          {isLoading ?   <CircularProgress  sx={{
            color: "white",
            width: "25px !important",
            height: "25px !important",
            // bgcolor:bgcolor || 'red'
          }}/> :(   btnTitle ||  'Yes,Delete')}
        
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
};

export default DeleteModal;
