import { Modal, Stack, Typography, Box, Dialog } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const ModelBox = ({ title, children, closeFunc, openVar,titleStyle }) => {

    return (
        <Dialog
            open={openVar}
            onClose={closeFunc}
            PaperProps={{
                sx: {
                    borderRadius: "12px",
                    height: 'auto',
                    minWidth: {lg:'725px' , md:'800px'  , sm:'500px'},
                    padding: '20px',
                }
            }}
        >
            <Stack direction='row' alignItems='center' justifyContent='space-between' mb={2} mx={1}>
                <Typography
                    sx={titleStyle}
                    component="h2"
                >
                    {title}
                </Typography>
                <Stack
                    onClick={closeFunc}
                    sx={{
                        cursor: 'pointer',
                        borderRadius: '8px',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '4px'
                    }}
                >
                    <CloseIcon fontSize='14px' />
                </Stack>
            </Stack>
            {children}
        </Dialog>
    );
}

export default ModelBox