import { useThemePalette } from "common/hooks/theme_palette";
import TextFieldInput from "components/Atoms/Input/TextField/TextFieldInput";
import React from "react";

const FormTitle = ({ formik,value }) => {
  const { inputBg } = useThemePalette();
  return (
    <TextFieldInput
      label="Assignment Title"
      required
      sx={{
        background: inputBg,
        padding: "0",
        borderRadius: "8px",
        "& fieldset": {
          border: "none",
        },
      }}
      placeholder="Title"
      name="title"
      onChange={formik.handleChange}
      value={formik.values.title}
      error={formik.errors.title}
      helperText={formik.errors.title}
    />
  );
};

export default FormTitle;
