import React, { useState } from "react";
import { Divider, Stack, Typography, Menu, MenuItem } from "@mui/material";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  deleteCourseSessionRequest,
  getCourseSectionSessionsRequest,
} from "services/modules/courses/Requests/sessions";
import { DeleteAssignmentRequest } from "services/modules/courses/Requests/assignment";
import { deleteCourseQuizRequest } from "services/modules/courses/Requests/quizes";
import DeleteModal from "components/Atoms/Model/DeleteModel";
import { getCourseSectionsRequest } from "services/modules/courses/Requests/sections";
import { DeleteQuizRequest } from "services/modules/quiz";

const SectionActions = ({ course, anchorEl, open, handleClose, sectionID, session }) => {
  const dispatch = useDispatch();
  const { course_id } = useParams();
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const action = () => {
    dispatch(getCourseSectionsRequest({ course_id }));
  };
  const deletedData = () => {
    console.log(session, "sessionsession");
    if (session?.type === "CourseSession") {
      const requestData = {
        course_id,
        section_id: sectionID,
        course_session_id: session?.id,
        action,
      };
      dispatch(deleteCourseSessionRequest({ ...requestData }));
    } else if (session?.course_session_id) {
      const requestData = {
        course_id,
        section_id: sectionID,
        course_session_id: session?.course_session_id,
        action,
      };
      dispatch(
        deleteCourseSessionRequest({
          ...requestData,
        })
      );
    } else if (session?.type == "Quiz") {
      const requestData = {
        course_id,
        quiz_id: session?.id,
        action,
      };
      dispatch(DeleteQuizRequest({ ...requestData }));
    } else {
      const requestData = {
        course_id,
        assignment_id: session?.id,
        action,
      };
      dispatch(
        DeleteAssignmentRequest({
          ...requestData,
        })
      );
    }
  };
  return (
    <>
      <Menu /* Side Menu */
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            padding: "10px",
            width: "20ch",
          },
        }}
      >
        <MenuItem onClick={handleClose}>
          <Typography color={"error"} onClick={() => setOpenDeleteModel(true)} width="100%">
            Delete
          </Typography>
        </MenuItem>
        {/*}  <Divider sx={{ my: 0.5 }} />
      <Typography
        sx={{
          fontWeight: "400",
          fontSize: "14px",
          lineHeight: "17px",
          color: "#828282",
          paddingInline: "15px",
        }}
      >
        Move to
      </Typography>
      <MenuItem onClick={handleClose} sx={{ paddingBlock: "15px" }}>
        {course?.sections?.map((section, idx) => {
          if (sectionID !== section?.section_id)
            return (
              <Stack key={idx}>
                <Typography
                  sx={{
                    fontWeight: "500",
                    fontSize: "16px",
                    lineHeight: "19px",
                    color: "#4F4F4F",
                  }}
                >
                  {section?.title}
                </Typography>
              </Stack>
            );
        })}
      </MenuItem>*/}
      </Menu>
      <DeleteModal
        open={openDeleteModel}
        text={`Are you sure you want to delete "${session?.title}" account`}
        handelDeleteRequest={deletedData}
        handleClose={() => setOpenDeleteModel(false)}
      ></DeleteModal>
    </>
  );
};

export default SectionActions;
