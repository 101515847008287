import { Button, ClickAwayListener, MenuItem, MenuList, Paper, Popper } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { GetQuizSubmissionRequest, ShowCurrenQuizRequest } from "services/modules/quiz";
import ResponsesTemplate from "Templates/Courses/quiz/StudentResponse.Template";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useRef } from "react";
import { useState } from "react";
import { useFormik } from "formik";
const StudentResponsePage = ({ setLayoutData }) => {
  const { quiz_id, course_id,attempt_id } = useParams();
  const dispatch = useDispatch();
  const { quizData,submission ,questions_count} = useSelector((state) => state.quiz);

  useEffect(() => {
    if (quiz_id && course_id) 
    {
      dispatch(ShowCurrenQuizRequest({ course_id, quiz_id }));
      dispatch(GetQuizSubmissionRequest({ course_id, quiz_id,attempt_id }))
    }
  }, [quiz_id, course_id]);
  //console.log(submission)
  // breadcrumb data
  useEffect(() => {
    setLayoutData({
      title: "Courses",
      breadcrumb: [
        { title: "Home", path: "/", active: true },
        { title: "Courses", path: "/courses", active: true },
        {
          title: "Course Detail",
          path: `/courses/${course_id}`,
          active: true,
        },
        { title: quizData?.title, path: `/courses/${course_id}/quiz/${quiz_id}/responses`, active: true },
        { title: "Student Name", path: `/quiz/${course_id}`, active: false },
      ],
    });
  }, [course_id, quizData]);



  const formik = useFormik({
    initialValues: {
        "table": [
          { name: "School", value: "El Quds LS",editable: false },
          { name: "School Class", value: "" ,editable: false},
          { name: "Name", value: "" ,editable: false},
          { name: "Email", value: "" ,editable: false},
          { name: "Type", value: "" ,editable: false},
          { name: "Country Code", value: "" ,editable: false},
          { name: "Phone Number", value: "" ,editable: false},
          { name: "Social Account", value: "" ,editable: false},
          { name: "Is Email Subscribed", value: "" ,editable: false},
          { name: "Is Social Verified", value: "" ,editable: false},
          { name: "Is Phone Verified", value: "" ,editable: false},
          { name: "Status", value: "" ,editable: false},
          { name: "Created at", value: "" ,editable: false},
      ],
  },
    // validationSchema: Yup.object().shape({}),
    // enableReinitialize: true,
    onSubmit: (values) => {
      //console.log(values)
    },
  });

  return <ResponsesTemplate data={submission} count={questions_count} formik={formik} quizData={quizData} />;
};

export default StudentResponsePage;
