import { Box, Paper, Stack, Typography } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import PrimaryButton from "components/Atoms/Buttons/Primary/PrimaryButton";
import EvaluationForm from "components/Organisms/assignment/components/evaluateAssignment";
import React,{ useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { icons } from 'assets/AssetHelper'

const EvaluateAssignmentTemplate = ({ formik }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { section_id, assignment_id, course_id,tab } = useParams();
  const { primaryColor, secondryColor ,redColor} = useThemePalette();
  return (
    <Stack p="20px" my={4} flexDirection={'column'} gap={2} >
            <Stack spacing={2} direction="row" alignItems="center" justifyContent={{ md: "flex-end" }}>
              
              <PrimaryButton
                startIcon={<Box component="img" src={icons?.deleteIcon} /> }
                text="Delete"
                variant={"contained"}
                style={{
                  color: "white",
                  background:redColor
                }}
                click={() => {
                  const requestData = {
                    course_id,
                    assignment_id,
                    action: () => navigate(`/courses/${course_id}`),
                  };
                  // dispatch(DeleteAssignmentRequest({ ...requestData })); place holder for delete submission
                }}
              />
              <PrimaryButton
                text="Submit"
                variant={"contained"}
                startIcon={<Box component="img" src={icons?.correct} /> }
                style={{
                  background: secondryColor,
                  color: "white",
                }}
                click={formik.handleSubmit}
              />

            </Stack>

              <Stack component={Paper} p="30px 20px">
              <form onSubmit={formik.handleSubmit}>
                  <EvaluationForm formik={formik} />
                </form>
              </Stack>
                  </Stack>

  );
};

export default EvaluateAssignmentTemplate;
