import { Button, Grid,CircularProgress,Stack,Box,Typography } from "@mui/material";
import TextFieldInput from "../TextField/TextFieldInput";
import { useDispatch, useSelector } from "react-redux";
import { getPresignedUrlRequest } from "services/modules/uploadFiles";
import CancelIcon from "@mui/icons-material/Cancel";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { useThemePalette } from "common/hooks/theme_palette";
import { isPdf,isImage } from "common/utils/commonFunctions";
import setAuthToken from "services/modules/utils/handel_api";
function MultiUpload({ formik,placeholder, btnStyle, inputStyle, btnText, id, textValue , title,required }) {
  const {labelColor} = useThemePalette();

  const titleStyle = {
    color: labelColor,
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "15px",
    my: 1,
  };



  const files=formik?.values?.attachments;
  const dispatch = useDispatch();
  const { loadFile } = useSelector((state) => state.files);

  const handleChange = (e) => {
    const fileName = e.target.files[0].name; // Extracting the file name  
    dispatch(
      getPresignedUrlRequest({
        body: {
          file: e.target.files[0],
          extension: {
            files: [
              {
                extension: fileName.substring(
                  fileName.lastIndexOf(".") + 1,
                  fileName.length
                ),
              },
            ],
          },
        },
        updateData: (data) => {
            formik?.setFieldValue("attachments", [
              ...formik.values.attachments,
              { url: data, name: fileName }, // Including the file name in documents_attributes
            ]);      
        },
        action:()=>{
          setAuthToken(localStorage.getItem("token"));
        }
      })
    );
  };
  
    const handleDeleteFile = (file) => {
    //removing from the form directly.
    
      formik?.setFieldValue(
        "attachments",
        formik.values?.attachments?.filter((item) => file !== item.url)
      );

  };

  return (
    <Stack flexDirection={'column'}>
      <Stack flexDirection={'row'} alignItems={'center'} gap={'3px'}>
      {title?<Typography sx={titleStyle}>{title}</Typography>:null}
      {required ? <Typography color={'red'}>*</Typography>:null}
      </Stack>


    <Grid container>
      <Grid item xs={8}>
        <TextFieldInput sx={inputStyle} placeholder={placeholder} value={textValue} />
      </Grid>
      <Grid item xs={4}>
        <label htmlFor={`upload-${id}`}>
          <input
            id={`upload-${id}`}
            name={`upload-${id}`}
            type="file"
            style={{ display: "none" }}
            onChange={handleChange}
          />
          <Button component="span" style={btnStyle}>
            {btnText}
          </Button>
        </label>
      </Grid>


              {/* if file is uploaded then show the file */}
      <Stack flexDirection={"row"} gap={"16px"} marginLeft={'10px'} marginTop={'20px'}>
          {files?.map((e, i) => (
              <Stack flexDirection={"column"}  alignItems={'center'} key={i} >
                <Stack flexDirection={'row'}>
              <Button
                sx={{
                  overflow: "hidden",
                  backgroundColor: "white",
                  color: "#616161",
                  // padding: "20px",
                  // padding: "0",
                  //   minWidth: "min-content",
                  lineHeight: "1",
                  //   height: "min-content",
                  width: "73px",
                  height: "73px",
                  boxShadow: "none",
                  border: "1px solid #616161",
                  borderRadius: "12px",
                  ":hover": {
                    boxShadow: "none",
                    color: "#616161",
                  },
                  padding: 0,
                }}
                onClick={() => window.open(e.url, "_blank")}
              >
                {isImage(e.url) ? (
                  <img
                    src={e.url}
                    style={{
                      // maxWidth: "100%",
                      height: "100%",
                      // margin: "auto",
                      display: "block",
                      objectFit: "fill",
                    }}
                    alt=""
                  />
                ) : isPdf(e.url)?(
                  <PictureAsPdfIcon/>
                  // <Box
                  // component={'img'}
                  // src={PictureAsPdfIcon}
                  // />
                ):
                  <Box
                  component={'img'}
                  src={"https://upload.wikimedia.org/wikipedia/commons/8/87/PDF_file_icon.svg"}
                  sx={{ width: "100%" }}
                  />
                }
              </Button>
              <CancelIcon
                  onClick={() => handleDeleteFile(e.url)}
                  fontSize="small"
                  color="error"
                  sx={{
                    cursor: "pointer",
                    marginLeft:'-10px',
                    marginTop:'-10px',
                    position:'relative'
                  }}
                />
              </Stack>
              <Typography fontSize={'11px'} style={{ wordWrap: "break-word" ,width:'65px'}}>
                {e.name}
              </Typography>

              </Stack>
          ))}
          {loadFile?.loading && (
            <CircularProgress
              color="primary"
              size={30}
              sx={{ height: "max-content" }}
            />
          )}
        </Stack>

    </Grid>
    </Stack>

  );
}

export default MultiUpload;