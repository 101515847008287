import React from "react";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { Divider, InputAdornment, Paper, TableContainer, Typography } from "@mui/material";
import EditableInput from "../../../Atoms/Input/EditableInput/EditableInput";
import { useRef } from "react";
import { useState, useEffect } from "react";
import { updateAdminCourseDetailsRequest } from "services/modules/courses/Requests/courses";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { resetEditableInputValue } from "services/modules/utils/Helper";

const CoursePricing = ({ data }) => {
  const price = useRef("");
  const currency = useRef("");
  const discount = useRef("");
  const seats = useRef("");
  const hidden_price = useRef("");
  const dispatch = useDispatch();
  const { course_id } = useParams();

  const [courseData, setCourseData] = useState(data);

  const handleChange = (evt, ref) => {
    ref.current = evt.target.value;
  };

  const handleBlur = (ref) => {
    // //console.log(ref.current);
  };

  const handelAccept = (name, ref) => {
    if (typeof ref?.current === "string") {
      const requestData = {
        course_id,
        body: {
          course: {
            [name]: ref?.current,
            ...(name === "original_price_cents" && { original_price_cents: ref?.current * 100 }),
          },
        },
      };
      dispatch(updateAdminCourseDetailsRequest({ ...requestData }));
    }
  };
  const handleEditPriceHiddding = (ref) => {
    //console.log(document?.getElementById('is_price_hidden')?.value)
    const requestData = {
      course_id,
      body: {
        course: {
          is_price_hidden: document?.getElementById("is_price_hidden")?.value,
        },
      },
    };
    dispatch(updateAdminCourseDetailsRequest({ ...requestData }));
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
      backgroundColor: "#2f80ed17",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  useEffect(() => {
    if (courseData) {
      price.current = courseData?.original_price;
      currency.current = courseData?.price_after_discount?.currency_iso;
      discount.current = courseData?.discount_percentage;
      seats.current = courseData?.max_quantity;
      hidden_price.current = courseData?.is_price_hidden;
    }
  }, [courseData]);

  return (
    <TableContainer component={Paper} sx={{ background: "white", borderRadius: "12px" }}>
      <Typography m={2} fontWeight="600" fontSize="16px">
        Pricing
      </Typography>
      <Divider />
      <Table aria-label="customized table">
        <TableBody>
          <StyledTableRow>
            <StyledTableCell component="th" scope="row" width="25%">
              Full original price
            </StyledTableCell>
            <StyledTableCell align="right" width="25%">
              <EditableInput
                type="number"
                handelAccept={(e) => handelAccept("original_price_cents", price)}
                width={75}
                name="price"
                id={"original_price"}
                refProp={price}
                defaultValue={data["original_price"]}
                handelChange={(e) => handleChange(e, price)}
                // handleBlur={(_) =>
                //   resetEditableInputValue("original_price", data["original_price"])
                // }
                onClose={(_) => resetEditableInputValue("original_price", data["original_price"])}
              />
            </StyledTableCell>
            <StyledTableCell align="right" width="25%">
              Price currency
            </StyledTableCell>
            <StyledTableCell align="right" width="25%">
              <EditableInput
                type="text"
                handelAccept={(e) => handelAccept("original_price_currency", currency)}
                width={75}
                name="currency"
                id="currency"
                refProp={currency}
                defaultValue={data?.price_after_discount?.currency_iso}
                handelChange={(e) => handleChange(e, currency)}
                // handleBlur={(_) =>
                //   resetEditableInputValue("currency", data?.price_after_discount?.currency_iso)
                // }
                onClose={(_) =>
                  resetEditableInputValue("currency", data?.price_after_discount?.currency_iso)
                }
              />
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell component="th" scope="row" width="25%">
              Discount
            </StyledTableCell>
            <StyledTableCell align="right" width="25%">
              <EditableInput
                type="number"
                handelAccept={(e) => handelAccept("discount_percentage", discount)}
                width={75}
                name="discount"
                id="discount"
                refProp={discount}
                defaultValue={data?.discount_percentage}
                handelChange={(e) => handleChange(e, discount)}
                // handleBlur={(_) => resetEditableInputValue("discount", data["discount_percentage"])}
                onClose={(_) => resetEditableInputValue("discount", data["discount_percentage"])}
                endAdornment={<Typography sx={{ fontWeight: "500" }}>%</Typography>}
              />
            </StyledTableCell>
            {/*<StyledTableCell align="right" width="25%">
              Price per section
            </StyledTableCell>
            <StyledTableCell align="right" width="25%">
              <EditableInput
                type="number"
                handelAccept={(e)=>handelAccept('pric_per_section',discount)}
                width={75}
                name="pric_per_section"
                refProp={discount}
                defaultValue={courseData?.discount}
                handelChange={(e) => handleChange(e, discount)}
                handleBlur={() => handleBlur(discount)}
              />
            </StyledTableCell>
  </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell component="th" scope="row" width="25%">
              Price per session
            </StyledTableCell>
            <StyledTableCell align="right" width="25%">
              <EditableInput
                type="number"
                handelAccept={(e)=>handelAccept('pric_per_session',e,discount)}
                width={75}
                name="pric_per_session"
                refProp={discount}
                defaultValue={courseData?.pric_per_session}
                handelChange={(e) => handleChange(e, pric_per_session)}
                handleBlur={() => handleBlur(pric_per_session)}
              />
  </StyledTableCell>*/}
            <StyledTableCell align="right" width="25%">
              Is Price Hidden
            </StyledTableCell>
            <StyledTableCell align="right" width="25%">
              <EditableInput
                type="text"
                handelAccept={(e) => handleEditPriceHiddding(hidden_price)}
                width={75}
                name="is_price_hidden"
                id="is_price_hidden"
                refProp={hidden_price}
                defaultValue={data?.is_price_hidden}
                handelChange={(e) => handleChange(e, discount)}
                // handleBlur={(_) => resetEditableInputValue("is_price_hidden", data["is_price_hidden"])}
                onClose={(_) => resetEditableInputValue("is_price_hidden", data["is_price_hidden"])}
              />
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell component="th" scope="row" width="25%">
              Max Seats
            </StyledTableCell>
            <StyledTableCell align="right" width="25%">
              <EditableInput
                type="text"
                handelAccept={(e) => handelAccept("max_quantity", seats)}
                width={75}
                name="seats"
                id="seats"
                refProp={seats}
                defaultValue={`${data?.max_seats || 1000}`}
                handelChange={(e) => handleChange(e, seats)}
                // handleBlur={(_) => resetEditableInputValue("seats", data?.max_seats)}
                onClose={(_) => resetEditableInputValue("seats", data?.max_seats)}
              />
            </StyledTableCell>
            {/* <StyledTableCell align="right" width="25%">
              Payment Type
</StyledTableCell>*/}
            <StyledTableCell align="right" width="25%"></StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CoursePricing;
