import {
  Avatar,
  Box,
  Stack,
  Typography,
  Badge,
  IconButton,
  Grid,
  Checkbox,
  FormControlLabel,
  Button,
  Skeleton,
} from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import TextFieldInput from "components/Atoms/Input/TextField/TextFieldInput";
import ModelBox from "components/Atoms/Model/Model";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { creatInstructorModel } from "services/modules/models/Actions";
import * as Yup from "yup";
import { styled } from "@mui/material/styles";
import { icons } from "../../../../assets/AssetHelper";
import { getPresignedUrlRequest } from "services/modules/uploadFile";
import ClearIcon from "@mui/icons-material/Clear";
import SearchableSelect from "components/Atoms/Select/SearchableSelect";
import {
  CreateInstructorRequest,
  GetAllInstructorsRequest,
  UpdateInstructorRequest,
} from "services/modules/instructors";
import { toast } from "react-toastify";
import { emailRegex } from "services/modules/utils/Helper";
import PhoneInput from "components/Atoms/Input/PhoneInput/PhoneInput";
import en from "react-phone-number-input/locale/en.json";

const CreateInstructorModel = ({ selectedRow, setSelectedRow, page, page_size }) => {
  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 22,
    height: 22,
    border: `2px solid ${theme.palette.background.paper}`,
  }));

  const dispatch = useDispatch();
  const { inputBg, orangeBtn, fontColor } = useThemePalette();
  const isOpenCreateInstructor = useSelector((state) => state.model.isOpenCreateInstructor);
  const { load, loadFile } = useSelector((state) => state.files);
  console.log(selectedRow !== null);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: selectedRow ? selectedRow : {},
    // validationSchema: Yup.object().shape({}),
    onSubmit: (values) => {
      const requestdata = {
        instructor_id: values?.instructor_id,
        body: {
          user: {
            ...values,
            country_code: values?.country_code,
          },
          device: {
            uid: "262222",
            fcm_token: "i",
          },
        },
        action: () => {
          dispatch(creatInstructorModel());
          formik.resetForm();
          dispatch(
            GetAllInstructorsRequest({ filter: { page_number: page, page_size: page_size } })
          );
        ;
          toast.success(
            `Instructor was ${selectedRow !== null ? "Edited" : "added"} sucessfully`
          );
        },
      };
      if (selectedRow) {
        dispatch(UpdateInstructorRequest(requestdata));
      } else {
        dispatch(CreateInstructorRequest(requestdata));
      }
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().required("email is required").matches(emailRegex, "Email is not valid"),
      name: Yup.string().required("name is required"),
      job_title: Yup.string().required("job title is required"),
      country_code: Yup.string().required("Country code is required"),
    }),
  });
  const handleAvatarChange = (fileName) => {
    dispatch(
      getPresignedUrlRequest({
        body: {
          file: fileName,
          extension: {
            files: [
              {
                extension: fileName.name.substring(
                  fileName.name.lastIndexOf(".") + 1,
                  fileName.name.length
                ),
              },
            ],
          },
        },
        updateData: (data) => {
          //console.log(data)
          formik.setFieldValue("profile_picture_url", data);
        },
      })
    );
  };

  const handleDeleteAvatar = (e) => {
    e.stopPropagation();
    formik.setFieldValue("profile_picture_url", null);
  };
  useEffect(() => {
    if (Object.keys(formik?.errors).length !== 0 && formik?.isSubmitting) {
      const errorMessages = [];
      // Check for Email confirmation error
      if (formik.errors.email) {
        errorMessages.push(formik.errors.email);
      }
      if (formik.errors.name) {
        errorMessages.push(formik.errors.name);
      }
      if (formik.errors.country_code) {
        errorMessages.push(formik.errors.country_code);
      }
      if (formik.errors.job_title) {
        errorMessages.push(formik.errors.job_title);
      }
      // Display error messages in toast
      if (errorMessages.length > 0) {
        toast.error(errorMessages.join(", "));
      }
    }
  }, [formik?.errors, formik?.isSubmitting]);
  return (
    <ModelBox
      openVar={isOpenCreateInstructor}
      closeFunc={() => {
        formik.resetForm();
        dispatch(creatInstructorModel());
      }}
      title={selectedRow ? "Edit Instructor" : "Add New Instructor"}
      titleStyle={{ fontSize: "20px", fontWeight: "600" }}
    >
      <Box>
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          badgeContent={
            <>
              {formik.values?.profile_picture_url && (
                <IconButton
                  onClick={handleDeleteAvatar}
                  style={{ position: "absolute", top: "-70px", right: 0 }}
                >
                  <img src={icons.wrongIcon} />
                </IconButton>
              )}
              <label htmlFor="avatar-input" style={{ cursor: "pointer" }}>
                <SmallAvatar alt="Remy Sharp" src={icons?.imageIconBlue} />
              </label>
            </>
          }
        >
          {(load?.loading && load?.type == "upload") ||
          (loadFile?.type == "uploadOnServer" && loadFile?.loading) ? (
            <Skeleton variant="circular" width={90} height={90} />
          ) : (
            <Avatar
              alt="Travis Howard"
              sx={{ width: "90px", height: "90px" }}
              src={formik.values?.profile_picture_url}
            />
          )}
        </Badge>
        <input
          id="avatar-input"
          type="file"
          style={{ display: "none" }}
          onChange={(e) => handleAvatarChange(e.target.files[0])}
        />

        <form onSubmit={formik.handleSubmit}>
          <Stack flexDirection={{ xs: "column", md: "row" }} justifyContent="space-between">
            <Stack spacing={4} width="48%">
              <Box>
                <TextFieldInput
                  type="text"
                  label="Name"
                  sx={{
                    background: inputBg,
                    padding: "0",
                    borderRadius: "8px",
                    "& fieldset": {
                      border: "none",
                    },
                  }}
                  placeholder="Enter here"
                  name="name"
                  onChange={formik.handleChange}
                  value={formik.values?.name}
                  // error={formik.errors.grade}
                  // helperText={formik.errors.grade}
                  required
                />
              </Box>

              <Box>
                <TextFieldInput
                  type="password"
                  label="Password"
                  sx={{
                    background: inputBg,
                    padding: "0",
                    borderRadius: "8px",
                    "& fieldset": {
                      border: "none",
                    },
                  }}
                  placeholder="Enter here"
                  name="password"
                  onChange={formik.handleChange}
                  value={formik.values?.password}
                  // error={formik.errors.grade}
                  // helperText={formik.errors.grade}
                  required
                />
              </Box>
              <Box sx={{ width: "100%" }}>
                <Stack flexDirection={"row"} alignItems={"center"} gap={"3px"}>
                  <Typography
                    as="span"
                    sx={{
                      color: fontColor,
                      fontSize: "12px",
                      fontWeight: "400",
                      lineHeight: "15px",
                      my: 1,
                    }}
                  >
                    Country code
                  </Typography>
                  <Typography color={"red"}>*</Typography>
                </Stack>
                <PhoneInput
                  id={"country"}
                  FormControlWidth="100%"
                  name={"country"}
                  selectId={"search-select"}
                  // inputBg={lightGray2}
                  displayCountry={true}
                  labels={en}
                  value={formik.values?.country_code}
                  onChange={(value) => {
                    console.log(value);
                    formik?.setValues({
                      ...formik?.values,
                      country_code: `+${value}`,
                    });
                  }}
                  displayPhoneInput={false}
                  error={formik?.touched?.country_code && Boolean(formik?.errors?.country_code)}
                  helperText={formik?.touched?.country_code && formik?.errors?.country_code}
                  countryCodePlaceholder={"Select"}
                />
              </Box>

              {/* <Box>
                <SearchableSelect
                  isClearable={false}
                  options={[{ id: 0, name: "+20" }]}
                  placeholder="Country Code"
                  title="Country Code"
                  onChange={(value) => {
                    formik.setFieldValue("country_code", value);
                  }}
                  value={formik.values?.country_code}
                  // error={formik.errors.course_title}
                  // helperText={formik.errors.course_title}
                  required
                />
              </Box> */}
            </Stack>
            <Stack spacing={4} width="48%">
              <Box>
                <TextFieldInput
                  type="text"
                  label="Email"
                  sx={{
                    background: inputBg,
                    padding: "0",
                    borderRadius: "8px",
                    "& fieldset": {
                      border: "none",
                    },
                  }}
                  placeholder="Enter here"
                  name="email"
                  onChange={formik.handleChange}
                  value={formik.values?.email}
                  // error={formik.errors.grade}
                  // helperText={formik.errors.grade}
                  required
                />
              </Box>

              <Box>
                <TextFieldInput
                  type="text"
                  label="Job Title"
                  sx={{
                    background: inputBg,
                    padding: "0",
                    borderRadius: "8px",
                    "& fieldset": {
                      border: "none",
                    },
                  }}
                  placeholder="Enter here"
                  name="job_title"
                  onChange={formik.handleChange}
                  value={formik.values?.job_title}
                  // error={formik.errors.grade}
                  // helperText={formik.errors.grade}
                  required
                />
              </Box>

              <Box>
                <TextFieldInput
                  type="text"
                  label="Phone Number"
                  sx={{
                    background: inputBg,
                    padding: "0",
                    borderRadius: "8px",
                    "& fieldset": {
                      border: "none",
                    },
                  }}
                  placeholder="Enter here"
                  name="phone_number"
                  onChange={formik.handleChange}
                  value={formik.values?.phone_number}
                  // error={formik.errors.grade}
                  // helperText={formik.errors.grade}
                />
              </Box>
            </Stack>
          </Stack>

          <TextFieldInput
            label="Bio"
            name="bio"
            sx={{
              background: inputBg,
              padding: "0",
              borderRadius: "8px",
              "& fieldset": {
                border: "none",
              },
            }}
            placeholder="Description"
            onChange={formik.handleChange}
            value={formik?.values?.bio}
            // error={formik.errors.description}
            // helperText={formik.errors.description}
            multiline
            rows={4}
          />
          {/* is_phone_verified:false,is_security_enabled:false,has_many_devices */}
          <Grid container width={"80%"} justifyContent={"space-between"}>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values?.is_phone_verified || ""}
                    onChange={(event) => {
                      formik.setValues({
                        ...formik?.values,
                        is_phone_verified: event.target.checked,
                      });
                    }}
                    onBlur={formik.handleBlur}
                    value="is_phone_verified"
                    name="is_phone_verified"
                    size="small"
                    style={{ color: "#F79E1B" }}
                  />
                }
                label={<Typography fontSize={"12px"}>Is Phone Verified</Typography>}
              />
            </Grid>

            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values?.is_security_enabled || ""}
                    onChange={(event) => {
                      formik.setValues({
                        ...formik?.values,
                        is_security_enabled: event.target.checked,
                      });
                    }}
                    onBlur={formik.handleBlur}
                    value="is_security_enabled"
                    name="is_security_enabled"
                    style={{ color: "#F79E1B" }}
                    size="small"
                  />
                }
                label={<Typography fontSize={"12px"}>Is Security Enabled</Typography>}
              />
            </Grid>

            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values?.has_many_devices || ""}
                    onChange={(event) => {
                      formik.setValues({
                        ...formik?.values,
                        has_many_devices: event.target.checked,
                      });
                    }}
                    onBlur={formik.handleBlur}
                    value="has_many_devices"
                    name="has_many_devices"
                    size="small"
                    style={{ color: "#F79E1B" }}
                  />
                }
                label={<Typography fontSize={"12px"}>Has Many Devices</Typography>}
              />
            </Grid>
          </Grid>

          <Stack
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"center"}
            gap={2}
            mt={2}
            width={"100%"}
          >
            <Button
              sx={{
                color: orangeBtn,
                border: "1px solid #F79E1B",
                "&:hover": {
                  backgroundColor: "transparent",
                },
                textTransform: "none",
                width: "20%",
              }}
              className="r-25px"
              onClick={() => {
                formik.resetForm();
                dispatch(creatInstructorModel());
              }}
            >
              Cancel
            </Button>

            <Button
              type={"submit"}
              sx={{
                backgroundColor: orangeBtn,
                textTransform: "none",
                width: "20%",
                "&:hover": {
                  backgroundColor: orangeBtn,
                },
                color: "white",
              }}
              className="primary r-25px"
            >
              {selectedRow ? "Edit" : "Add"}
            </Button>
          </Stack>
        </form>
      </Box>
    </ModelBox>
  );
};

export default CreateInstructorModel;
