import React, { useEffect, useState } from "react";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { Avatar, Button, Divider, Paper, TableContainer, Typography } from "@mui/material";
import { icons } from "../../../../assets/AssetHelper";
import { Stack } from "@mui/system";
import dayjs from "dayjs";
import TableWithBottomBtn from "../../../Molecules/Table/TableWithBottomBtn/tableWithBottomBtn";
import {
  getCourseJoinedUsersRequest,
  getCoursePendingUsersRequest,
  moveToJoinedUsersRequest,
} from "services/modules/courses/Requests/joined-pendingUsers";
import CTable from "components/Molecules/Table/table";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useThemePalette } from "common/hooks/theme_palette";

const PendingUsers = ({ data }) => {
  // const [page, setPage] = useState(0);
  const { count, pendingUsersPageNumber } = useSelector((state) => state.courses);
  const [page, setPage] = useState(pendingUsersPageNumber);

  const { course_id } = useParams();
  const [checked, setChecked] = useState([false]);
  const { primaryColor, gray } = useThemePalette();
  const joinedUsers = useSelector((state) => state.courses.joinedUsers);
  const pendingStudents = useSelector((state) => state.courses.pendingUsers?.students);
  const [tableData, setTableData] = useState({});
  const [selectedRow, setSelectedRow] = useState({});
  const [selected, setSelected] = useState({
    id: "",
  });
  const [allSelectedData, setAllSelectedData] = useState([]);
  const pendingUsersCount = useSelector((state) => state.courses.pendingUsersCount);
  const isAllPendingUsersChecked = allSelectedData?.length == pendingStudents?.length;
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(true);
  let checkedIds = [];
  checked?.forEach((check, i) => {
    if (check) {
      checkedIds?.push(pendingStudents?.[i]?.student_id);
    }
  });
  const resetSelection = () => {
    setAllSelectedData([]);
    checkedIds = [];
    setSelected({
      id: "",
    });
    setChecked([false]);
  };
  const handlePageChange = (page) => {
    setPage(page);
    resetSelection();
  };
  const action = () => {
    dispatch(
      getCoursePendingUsersRequest({
        filter: { course_id, page_number: 1, page_size: 4, status: "pending" },
      })
    );
    dispatch(
      getCourseJoinedUsersRequest({
        filter: { course_id, page_number: 1, page_size: 4, status: "joined" },
      })
    );
    resetSelection();
  };
  const handleSearchChange = (key) => {
    if (key) {
      dispatch(
        getCoursePendingUsersRequest({
          filter: {
            course_id: course_id,
            page_number: 1,
            page_size: 4,
            status: "pending",
            keyword: key,
          },
        })
      );
    } else {
      dispatch(
        getCoursePendingUsersRequest({
          filter: { course_id: course_id, page_number: 1, page_size: 4, status: "pending" },
        })
      );
    }
  };
  const moveToJoinedUsers = (student_id) => {
    const requestData = {
      body: {
        user_ids: [student_id],
        paid_type: "course",
        paid_id: +course_id,
        // is_all_selected: isAllPendingUsersChecked
      },
      action,
    };
    dispatch(moveToJoinedUsersRequest({ ...requestData }));
  };
  const viewData = (data) => {
    setSelected(data);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getCoursePendingUsersRequest({
        filter: { course_id, page_number: page, page_size: 4, status: "pending" },
      })
    );
  }, [page]);
  useEffect(() => {
    dispatch(
      getCoursePendingUsersRequest({
        filter: { course_id: course_id, page_number: 1, page_size: 4, status: "pending" },
      })
    );
  }, []);
  const mappedData = pendingStudents?.map((student, index) => {
    return {
      ...student,
      name: (
        <>
          <Stack direction="row" alignItems="center" justifyconten="center">
            <Avatar
              alt={student?.name}
              src={student?.profile_picture_url}
              sx={{ width: 30, height: 30 }}
            />
            <Typography mx="20px" textTransform="capitalize">
              {student?.name}
            </Typography>
          </Stack>
        </>
      ),
      created_at: <>{dayjs(student?.created_at).format("DD-MM-YYYY hh:mm a")}</>,
      Actions: (
        <>
          <Typography
            color={primaryColor}
            fontWeight={"500"}
            fontSize="14px"
            onClick={(_) => moveToJoinedUsers(student?.student_id, "student_id")}
            sx={{ cursor: "pointer" }}
          >
            Move to Joined users
          </Typography>{" "}
        </>
      ),
    };
  });
  const columns = [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Country Code",
      accessor: "country_code",
    },
    {
      Header: "Phone Number",
      accessor: "phone_number",
    },
    {
      Header: "",
      accessor: "Actions",
    },
  ];
  const moveAllToJoinedUsers = (value) => {
    if (value == 1 && (allSelectedData?.length > 0 || checkedIds > 0)) {
      const requestData = {
        body: {
          paid_type: "course",
          paid_id: +course_id,
          user_ids: allSelectedData?.length < 0 ? allSelectedData : checkedIds,
          // is_all_selected: isAllPendingUsersChecked
        },
        action,
      };
      dispatch(moveToJoinedUsersRequest({ ...requestData }));
    }
  };
  const selectBoxData = {
    items: [
      { name: "Batch Action", id: 0, disable: true, style: { color: primaryColor } },
      { name: "Move to joined users", id: 1, style: {color:gray} },
    ],
    onclick: (value) => moveAllToJoinedUsers(value),
    placeholder: "Batch Actions",
    value: 0,
  };
  const handleCheckAll = (event, data) => {
    const isChecked = event.target.checked;
    const studentIds = data?.map((item) => item?.student_id);
    setIsSelectAllChecked(isChecked);
    if (isChecked) {
      // If select all is checked, set allSelectedData to contain all student ids
      setAllSelectedData(studentIds);
      setChecked(new Array(data?.length)?.fill(true)); // Check all checkboxes
    } else {
      // If select all is unchecked, clear allSelectedData and uncheck all checkboxes
      setAllSelectedData([]);
      setChecked(new Array(data?.length)?.fill(false)); // Uncheck all checkboxes
    }
  };

  const handleCheck = (event, index, data) => {
    const isChecked = event.target.checked;
    const studentId = data?.[index]?.student_id;
    setChecked((prevChecked) => {
      const updatedChecked = [...prevChecked];
      updatedChecked[index] = isChecked;
      return updatedChecked;
    });
    if (isChecked) {
      setAllSelectedData(
        (prevSelectedData) => prevSelectedData && [...prevSelectedData, studentId]
      );
    } else {
      setAllSelectedData((prevSelectedData) => prevSelectedData.filter((id) => id !== studentId));
    }
  };

  return (
    <CTable
      selectedData={viewData}
      footerBtnTitle
      noFilter={false}
      noSearchBar={false}
      Data={mappedData || []}
      Columns={columns}
      Title="Pending Users"
      subTitle=""
      btnTitle=""
      placeHolder=""
      hasBottomAddBtn={true}
      bottomBtnTitle={"Add New User"}
      hasPagination={false}
      displayFooter={false}
      total_count={pendingUsersCount}
      handlePageChange={handlePageChange}
      perPage={4}
      paginationPage={page}
      handleSearchChange={handleSearchChange}
      checked={checked}
      setChecked={setChecked}
      setAllSelectedData={setAllSelectedData}
      selectBoxData={selectBoxData}
      handleCheckAll={handleCheckAll}
      handleCheck={handleCheck}
    />
  );
};
export default PendingUsers;
