import Types from "./Types";

const COURSES_INIT_STATE = {
  courses: [],
  count: 0,
  currentPage: 1,
  course: {},
  courseSessions: [],
  joinedUsersCount: 0,
  joinedUsersPageNumber: 0,
  pendingUsersCount: 0,
  pendingUsersPageNumber: 0,
  session: {},
  createdCourseId: null,
  sectionAssignments: [],
  sectionQuizzes: [],
  sectionSessions: [],
  courseQuizzes: [],
  courseAssignments: [],

  joinedUsers: {},
  pendingUsers: {},
  assignment: {},
  sections: [],
  sectionsCount: 0,
  subjects: {},
  coupons: [],
  instructors: [],
  courseAssignments: [],
  assignment: {},
  submissions: [],
  actionMenuDetails: {
    state: false,
    index: 0
  },
  studentQuizes: [],
  studentQuizesCount: 0,
  studentAssignments: [],
  studentAssignmentsCount: 0,
  isLoading: {
    type: "",
    loading: false,
  },
};

export function coursesReducer(state = COURSES_INIT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    //loading
    case Types.ADMIN_COURSES_LOADING: {
      return {
        ...state,
        isLoading: payload,
      };
    }

    //courses
    case Types.OPEN_ACIONS_MENU: {
      //console.log(payload)
      return {
        ...state,
        actionMenuDetails: payload,
      };
    }
    case Types.CREATE_COURSE_SUCCESS: {
      //console.log(payload)
      return {
        ...state,
        course: payload?.data,
      };
    }
    case Types.GET_STUDENT_QUIZES_SUCCESS: {
      return {
        ...state,
        studentQuizes: payload?.data,
        studentQuizesCount: payload?.extra?.total_count
      };
    }
    case Types.GET_STUDENT_ASSIGNMENTS_SUCCESS: {
      return {
        ...state,
        studentAssignments: payload?.data?.assignments,
        studentAssignmentsCount: payload?.extra?.total_count
      };
    }
    case Types.GET_ADMIN_COURSES_SUCCESS: {
      return {
        ...state,
        courses: payload?.data.courses,
        count: payload.extra.total_count,
        currentPage: payload.extra.page_number,
      };
    }
    case Types.SEARCH_COURSE_LIST_SUCCESS: {
      return {
        ...state,
        courses: payload?.data.courses,
        count: payload.extra.total_count,
        currentPage: payload.extra.page_number,
      }
    }
    case Types.GET_ADMIN_COURSE_DETAILS_SUCCESS: {
      return {
        ...state,
        course: payload?.data,
      };
    }
    case Types.DELETE_ADMIN_COURSE_SUCCESS: {
      return {
        ...state,
      };
    }
    case Types.UPDATE_ADMIN_COURSE_DETAILS_SUCCESS: {
      return {
        ...state,
        course: payload.data,
      };
    }

    //quizz
    case Types.DELETE_COURSE_QUIZ_SUCCESS: {
      let newData = payload?.data;
      let coursesQuizesArr = state.courseQuizzes;
      const filteredData = coursesQuizesArr?.filter((val) => val?.quiz_id != newData?.quiz_id);
      return {
        ...state,
        courseQuizzes: filteredData,
      };
    }
    case Types.GET_ALL_COURSE_QUIZZES_SUCCESS: {
      return {
        ...state,
        courseQuizzes: payload.data,
      };
    }

    // sessions
    case Types.UPDATE_SESSION_SUCCESS: {
      let newData = payload.data;
      let sessionArr = state.sessions;
      let index;
      sessionArr?.map((val, i) => {
        if (val?.quiz_question_id === newData?.quiz_question_id) index = i;
      });
      sessionArr[index] = newData;
      return {
        ...state,
        sessions: sessionArr,
      };
    }
    case Types.CREATE_SESSION_SUCCESS: {
      return {
        ...state,
        session: payload.data,
      };
    }
    case Types.SHOW_SESSION_DETAILS_SUCCESS: {
      return {
        ...state,
        session: payload.data,
      };
    }
    case Types.GET_ALL_COURSE_SESSIONS_SUCCESS: {
      return {
        ...state,
        courseSessions: payload.data,
      };
    }
    case Types.DELETE_COURSE_SESSION_SUCCESS: {
      const filteredCourseSessions = state?.courseSessions?.filter(
        (ele) => ele?.course_session_id !== payload?.data?.course_session_id
      );
      return {
        ...state,
        courseSessions: filteredCourseSessions,
      };
    }

    // assignment
    case Types.DELETE_COURSE_ASSIGNMENT_SUCCESS: {
      //console.log(payload);
      let newData = payload.data;

      let coursesQuizesArr = state.courseAssignments;
      const filteredData = coursesQuizesArr?.filter(
        (val) => val?.data?.assignment_id != newData?.assignment_id
      );
      return {
        ...state,
        courseAssignments: filteredData,
      };
    }
    case Types.GET_ALL_COURSE_ASSIGNMENTS_SUCCESS: {
      return {
        ...state,
        courseAssignments: payload.data,
      };
    }
    case Types.CREATE_ASSIGNMENT_SUCCESS: {
      return {
        ...state,
        courseAssignments: [...state?.courseAssignments, payload?.data],
      };
    }
    case Types.SHOW_ASSIGNMENT_DETAILS_SUCCESS: {
      return {
        ...state,
        assignment: payload.data,
      };
    }
    case Types.UPDATE_ASSIGNMENT_SUCCESS: {
      let newData = payload.data;
      let assignmentArr = state.courseAssignments;
      let index;
      assignmentArr?.map((val, i) => {
        if (val?.quiz_question_id === newData?.quiz_question_id) index = i;
      });
      assignmentArr[index] = newData;
      return {
        ...state,
        courseAssignments: assignmentArr,
      };
    }
    //instructors
    case Types.GET_COURSE_INSTRUCTORS_SUCCESS: {
      return {
        ...state,
        instructors: payload.data.Instructors

      }
    }
    case Types.ADD_COURSE_INSTRUCTORS_SUCCESS: {
      return {
        ...state,
        //instructors: payload.data.Instructors

      }
    }
    case Types.DELETE_COURSE_INSTRUCTORS_SUCCESS: {
      return {
        ...state,
        //instructors: payload.data.Instructors
      }
    }
    case Types.MOVE_TO_JOINED_USER_SUCCESS: {
      // return {
      //   ...state,
      // }
    }
    //joined users
    case Types.GET_COURSE_JOINED_USERS_SUCCESS: {
      return {
        ...state,
        joinedUsers: payload.data,
        joinedUsersCount: payload.extra?.total_count,
        joinedUsersPageNumber: payload.extra?.page_number,
      }
    }
    case Types.ADD_COURSE_JOINED_USERS_SUCCESS: {
      return {
        ...state,
      }
    }
    //pending users
    case Types.GET_COURSE_PENDING_USERS_SUCCESS: {
      return {
        ...state,
        pendingUsers: payload?.data,
        pendingUsersCount: payload?.extra?.total_count,
        pendingUsersPageNumber: payload?.extra?.page_number
      }
    }
    //coupons
    case Types.GET_COURSE_COUPONS_SUCCESS: {
      return {
        ...state,
        coupons: payload.data.coupons
      }
    }

    //sections
    case Types.GET_COURSE_SECTIONS_SUCCESS: {
      return {
        ...state,
        sections: payload?.extra?.page_number == 1
          ? payload?.data?.sections
          : [...state.sections, ...payload?.data?.sections],
        sectionsCount: payload?.extra?.total_count
      }
    }
    case Types.DELETE_COURSE_SECTION_SUCCESS: {
      return {
        ...state,
      }
    }
    case Types.REORDER_COURSE_SECTION_SUCCESS: {
      return {
        ...state,
      }
    }
    case Types.REORDER_COURSE_SECTION_ITEMS_SUCCESS: {
      return {
        ...state,
      }
    }
    case Types.GET_COURSE_SECTION_ASSIGNMENTS_SUCCESS: {
      //console.log('walahy', payload)
      return {
        ...state,
        sectionAssignments: payload?.data?.assignments
      }
    }
    case Types.GET_COURSE_SECTION_QUIZ_SUCCESS: {
      return {
        ...state,
        sectionQuizzes: payload.data.quizzes
      }
    }
    case Types.GET_COURSE_SECTION_SESSIONS_SUCCESS: {
      return {
        ...state,
        sectionSessions: payload.data.sessions
      }
    }
    case Types.GET_COURSE_SUBJECTS_SUCCESS: {
      return {
        ...state,
        subjects: payload.data
      }
    }
    case Types.ADD_COURSE_SUBJECT_SUCCESS: {
      return {
        ...state,
      }
    }
    default: {
      return state;
    }
  }
}
