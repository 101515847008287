import {
  InstructorLoading,
  CreateInstructorSuccess,
  DeleteInstructorSussess,
  ShowInstructorDetailsSuccess,
  UpdateInstructorSuccess,
  GetAllInstructorsSuccess,
} from "./Actions";
import { BaseURL, instructor } from "../utils/constant";

import { makeFilterString } from "../utils/Helper";
import { reduxRequest } from "common/utils/reduxRequest";
export const CreateInstructorRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: InstructorLoading,
    successFunction: CreateInstructorSuccess,
    url: `/v2/users/instructor`,
    method: "post",
    loadingType: "addInstructor",
    ...variables,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
export const UpdateInstructorRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: InstructorLoading,
    successFunction: UpdateInstructorSuccess,
      url: `/v2/users/instructor/${variables?.instructor_id}`,
    method: "put",
    loadingType: "update",
    ...variables,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
export const ShowInstructorDetailsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: InstructorLoading,
    successFunction: ShowInstructorDetailsSuccess,
      url: `v2/users/instructor/${variables?.instructor_id}`,
    method: "get",
    loadingType: "details",
    ...variables,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
export const GetAllInstructorsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: InstructorLoading,
    successFunction: GetAllInstructorsSuccess,
      url: `${instructor}/${variables?.filter ? makeFilterString(variables?.filter) : null}`,
    method: "get",
    loadingType: "list",
    ...variables,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const DeleteInstructorRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: InstructorLoading,
    successFunction: DeleteInstructorSussess,
      url: `/v2/users/instructor/${variables?.instructor_id}`,
    method: "delete",
    loadingType: "delete",
    ...variables,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
