import { toast } from "react-toastify";
import {
  CreateChoicesRequest,
  CreateQuestionModelAnswerRequest,
  CreateQuestionRequest,
  DeleteChoicesRequest,
  DeleteQuestionRequest,
  UpdataQuestionModelAnswerRequest,
  UpdateQuestionRequest,
} from "../../../../services/modules/quiz";

export const handelKeyPress = async (
  event,
  idx,
  setOptionsCount,
  setOptionFocus,
  optionsCount,
  dispatch,
  course_id,
  quiz_id,
  question_quiz_id,
  index
) => {
  if (event?.key === "Enter") {
    if (index <= 3) {
      event.preventDefault();
      setOptionsCount((s) => s + 1);
      handelOptionFocus(setOptionFocus, optionsCount);
      dispatch(
        CreateChoicesRequest({
          course_id,
          quiz_id,
          quiz_question_id: question_quiz_id,
          body: {
            quiz_question_choice: {
              text_choice: "",
              position: index + 1,
            },
          },
        })
      );
    } else {
      toast.error("Each Question Must have 4 choices only");
    }
  }
};
export const handelAddOption = (
  value,
  setOptionsCount,
  setOptionFocus,
  optionsCount,
  dispatch,
  course_id,
  quiz_id,
  question_quiz_id,
  index, //question index
  setCards,
  cards
) => {
  if (index <= 3) {
    setOptionsCount((s) => s + 1);
    handelOptionFocus(setOptionFocus, optionsCount);
    const action = (data) => {
      let actionData = data?.data;
      let newCards = cards;
      let question = newCards[index];
      question.data = actionData;
      setCards(newCards);
      if (optionsCount==0) {
        dispatch(
          CreateQuestionModelAnswerRequest({
            course_id,
            quiz_id,
            quiz_question_id: question_quiz_id,
            body: {
              quiz_question_model_answer: {
                quiz_question_choice_id:
                  actionData?.quiz_question_choices[0]?.quiz_question_choice_id,
              },
            },
          })
        );
      }
    };
    dispatch(
      CreateChoicesRequest({
        course_id,
        quiz_id,
        quiz_question_id: question_quiz_id,
        body: {
          quiz_question_choice: {
            text_choice: value,
            position: cards[index].data.quiz_question_choices?.length + 1,
          },
        },
        action,
      })
    );
  } else {
    toast.error("Each Question Must have 4 choices only");
  }
};
export const handelAddOtherOption = (
  optionsData,
  optionsCount,
  setOptionsCount,
  setOptionsData,
  setLoading
) => {
  setOptionsCount((s) => s + 1);
  setLoading(true);
  let newData = optionsData;
  newData[optionsCount] = "Other";
  setOptionsData((s) => newData);
  setTimeout(() => {
    setLoading(false);
  }, 200);
};
export const handelChange = (e, index, setOptionsData, setLoading, optionsData) => {
  setLoading(true);
  let newData = optionsData;
  newData[index] = e.target.value;
  setOptionsData((s) => newData);
  setTimeout(() => {
    setLoading(false);
  }, 200);
};
export const handelRemoveOption = (
  optionsData,
  index,
  setOptionsData,
  setOptionsCount,
  dispatch,
  course_id,
  quiz_id
) => {
  dispatch(
    DeleteChoicesRequest({
      course_id,
      quiz_id,
      quiz_question_id: optionsData[index]?.quiz_question_id,
      quiz_question_choice_id: optionsData[index]?.quiz_question_choice_id,
    })
  );
  let newData = optionsData;
  newData?.splice(index, 1);
  setOptionsData(newData);
  setOptionsCount((s) => s - 1);
  setOptionsData(newData);
};
export const handelCopyQuestion = ({
  setCards,
  cards,
  index,
  dispatch,
  quiz_id,
  course_id,
  display,
}) => {
  setCards((s) => [
    ...s,
    {
      index: cards?.length + 1,
      type: "question",
      data: { ...cards[index]?.data,text_question:cards[index]?.data.text_question +"copy", position: cards?.length + 1 },
    },
  ]);
  createQuestion({
    dispatch,
    quiz_id,
    course_id,
    display,
    quiz_question: { ...cards[index]?.data,text_question:cards[index]?.data.text_question +"copy", position: cards?.length + 1 },
  });
};
export const handelDeleteQuestion = ({
  dispatch,
  cards,
  setCards,
  course_id,
  quiz_id,
  question_quiz_id,
  index,
}) => {
  let newData = cards;
  const requestData = {
    course_id,
    quiz_id,
    quiz_question_id: question_quiz_id,
  };
    newData?.splice(index, 1);
    setCards(newData);
    dispatch(DeleteQuestionRequest({ ...requestData }));
};
export const handelClick = (index, setOptionFocus, optionsCount) => {
  let newData = [];
  for (let key = 0; key <= optionsCount; key++) {
    if (key === index) newData.push(true);
    else newData.push(false);
  }
  setOptionFocus(newData);
};
export const handelOptionFocus = (setOptionFocus, optionsCount) => {
  let newData = [];
  for (let key = 0; key <= optionsCount; key++) {
    if (key === optionsCount) newData.push(true);
    else newData.push(false);
  }
  setOptionFocus(newData);
};

export const handelUpdateModelAnswer = (
  course_id,
  quiz_id,
  question_quiz_id,
  quiz_question_model_answer_id,
  choice,
  dispatch
) => {
  dispatch(
    UpdataQuestionModelAnswerRequest({
      course_id,
      quiz_id,
      quiz_question_id: question_quiz_id,
      quiz_question_model_answer_id,
      body: {
        quiz_question_model_answer: {
          quiz_question_choice_id: choice?.quiz_question_choice_id,
        },
      },
    })
  );
};

export const checkOtherOptionChossen = (choices) => {
  const check = choices?.find((data) => data.text_choice == "Other");
  if (check) return true;
  else return false;
};

export const createQuestion = ({ dispatch, quiz_question, course_id, quiz_id }) => {
  const createQuestionRequestData = {
    course_id,
    quiz_id,
    body: {
      quiz_question: quiz_question,
    },
  };
  dispatch(CreateQuestionRequest({ ...createQuestionRequestData }));
};

export const updateQuestion = ({
  dispatch,
  value,
  difficulty,
  cards,
  setCards,
  display,
  index,
  course_id,
  quiz_id,
  question_quiz_id,
}) => {
  {
    let cardsData = cards;
    cardsData[index].data.text_question = value;
    setCards(cardsData);

    const requestData = {
      course_id,
      quiz_id,
      quiz_question_id: question_quiz_id,
      body: {
        quiz_question: {
          text_question: value,
          difficulty:difficulty,
          position: display?.index,
        },
      },
    };
    dispatch(UpdateQuestionRequest({ ...requestData }));
  }
};
