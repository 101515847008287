import Types from "../Types";

// course
export const getAdminCoursesSuccess = (payload) => ({
  type: Types.GET_ADMIN_COURSES_SUCCESS,
  payload,
});
export const getAdminCoursesSearchSuccess = (payload) => ({
  type: Types.SEARCH_COURSE_LIST_SUCCESS,
  payload,
});

export const adminCoursesLoading = (payload) => ({
  type: Types.ADMIN_COURSES_LOADING,
  payload,
});

export const getAdminCourseDetailsSuccess = (payload) => ({
  type: Types.GET_ADMIN_COURSE_DETAILS_SUCCESS,
  payload,
});

export const updateAdminCourseDetailsSuccess = (payload) => ({
  type: Types.UPDATE_ADMIN_COURSE_DETAILS_SUCCESS,
  payload,
});

export const deleteAdminCourseSuccess = (payload) => ({
  type: Types.DELETE_ADMIN_COURSE_SUCCESS,
  payload,
});

export const createCourseSuccess = (payload) => ({
  type: Types.CREATE_COURSE_SUCCESS,
  payload,
});

export const openActionsMenu =(payload)=>({
  type:Types.OPEN_ACIONS_MENU,
  payload
})