import { ListItemIcon, ListItemText, MenuItem, MenuList, Paper } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { icons } from "../../../../assets/AssetHelper";
import { addMoreQuestionsModel, uploadCSVModel } from "../../../../services/modules/models/Actions";
import { useParams } from "react-router-dom";
import { getQuestionBanksRequest } from "../../../../services/modules/question-bank";
import { createQuestion } from "../utils/question_func";

// const QuizOptions = ({ display, id, cards, setDisplay }) => {
const QuizOptions = ({cards}) => {

  const dispatch = useDispatch();
//   const quiz_id = display?.data?.data?.quiz_id;
  const { course_id,quiz_id } = useParams();
//   //console.log(display);
  const options = [
    {
      title: "Add Question",
      icon: icons.addCircleIcon,
      onClick: () => {
        createQuestion({
          dispatch,
          quiz_id,
          course_id,
          quiz_question: {
            text_question: `Question ${cards?.length + 1} `,
            position: cards?.length + 1,
          },
        });
      },
    },
    {
      title: "Import Questions",
      icon: icons.importIcon,
      onClick: () => {
        dispatch(addMoreQuestionsModel());
        dispatch(getQuestionBanksRequest());
      },
    },
    { title: "Add Image", icon: icons.imageIcon },
    { title: "Upload CSV", icon: icons.uploadIcon,
    onClick: () => {
      dispatch(uploadCSVModel());
    },
  },
    { title: "Add Video", icon: icons.videoIcon },
  ];

  return (
    <Paper
      sx={{
        width:'100%',padding: "17px",borderRadius: "9px"}}
    >
      <MenuList sx={{ alignItems: "flex-start" }}>
        {options?.map((val, idx) => {
          return (
            <MenuItem key={idx} onClick={val?.onClick}>
              <ListItemIcon sx={{ marginLeft: idx === 1 ? "-5px" : "" }}>
                <img src={val?.icon} fontSize="small" alt="" />
              </ListItemIcon>
              <ListItemText sx={{ textAlign: "initial" }}>{val?.title}</ListItemText>
            </MenuItem>
          );
        })}
      </MenuList>
    </Paper>
  );
};

export default QuizOptions;
