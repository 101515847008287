import { Avatar, Box, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { icons } from "assets/AssetHelper";
import { useThemePalette } from "common/hooks/theme_palette";
import PrimaryButton from "components/Atoms/Buttons/Primary/PrimaryButton";
import CTable from "components/Molecules/Table/table";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getStudentQuizzesRequest } from "services/modules/courses/Requests/quizes";
import addStudent from "assets/icons/addStudent.svg";
import AddInstructor from "../Courses/components/AddInstructor";
import { GetAllInstructorsRequest } from "services/modules/instructors";
import {
  AddCourseInstructorsRequest,
  getCourseInstructorsRequest,
} from "services/modules/courses/Requests/instructors";
import {
  GetStudentUrolledCoursesRequest,
  enrollStudentToMultipleCoursesRequest,
} from "services/modules/students";
import { instructor } from "services/modules/utils/constant";
import { toast } from "react-toastify";

const PaidCourses = ({ data }) => {
  const { studentQuizesCount, studentQuizes } = useSelector((state) => state.courses);
  const { count, page_number } = useSelector((state) => state.instructors);
  const [openAddModel, setOpenAddModel] = useState(false);
  const [allInstructors, setAllInstructors] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const [page, setPage] = useState();
  const { course_id, student_id } = useParams();
  const [checked, setChecked] = useState([false]);
  const { primaryColor, gray } = useThemePalette();
  const [selected, setSelected] = useState({
    id: "",
  });
  const [allSelectedData, setAllSelectedData] = useState([]);
  const pendingUsersCount = useSelector((state) => state.courses.pendingUsersCount);
  const { unrolledCourses, unrolledCoursesCount, isLoading } = useSelector(
    (state) => state.students
  );
  const dispatch = useDispatch();
  const [pageNumber, setPageNumber] = useState(1);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handleSearchChange = (key) => {};
  const viewData = (data) => {
    setSelected(data);
  };
  useEffect(() => {
    dispatch(
      getStudentQuizzesRequest({
        student_id,
        user_type: "student",
        filter: { page_number: 1, page_size: 4 },
      })
    );
  }, []);
  useEffect(() => {
    dispatch(
      getStudentQuizzesRequest({
        student_id,
        user_type: "student",
        filter: { page_number: page, page_size: 4 },
      })
    );
  }, [page]);
  const handleClose = () => {
    setOpenAddModel(false);
  };
  const handleChange = (event, newValue) => {
    console.log(newValue, "newValue");
    setSelectedOptions(newValue?.map((course) => course.id));
  };
  console.log(selectedOptions, "selectedOptions");

  const fetchMoreOptions = (pageNumber, pageSize, key) => {
    if (key != "") {
      dispatch(
        GetStudentUrolledCoursesRequest({
          student_id,
          filter: { page_number: pageNumber, page_size: pageSize, keyword: key },
        })
      );
    } else {
      pageNumber !== 1 &&
        dispatch(
          GetStudentUrolledCoursesRequest({
            student_id,
            filter: { page_number: pageNumber, page_size: pageSize },
          })
        );
    }
  };
  const onMenuOpen = () => {
    unrolledCourses?.length == 0 &&
      dispatch(
        GetStudentUrolledCoursesRequest({
          student_id,
          filter: { page_number: 1, page_size: 10 },
        })
      );
  };

  const handleSelectCourses = () => {
    const requestedData = {
      student_id,
      body: {
        course_ids: selectedOptions,
      },
      action: () => {
        //NOTE : should get paid courses
        // dispatch(getCourseInstructorsRequest({ course_id }));
        toast.success("Student enrolled to courses successfully");
        handleClose();
      },
    };
    dispatch(enrollStudentToMultipleCoursesRequest({ ...requestedData }));
  };
  const mappedData =
    studentQuizes &&
    studentQuizes?.map((quiz, index) => {
      return {
        ...quiz,
        curriculumData: quiz?.cirriculum?.map((curriculum) => curriculum),
        attempts: <Typography>{quiz?.number_of_attempts} /4</Typography>,
        created_at: <>{dayjs(quiz?.created_at).format("DD-MM-YYYY hh:mm a")}</>,
      };
    });
  const columns = [
    {
      Header: "Course Name",
      accessor: "course_title",
    },
    {
      Header: "Title",
      accessor: "title",
    },
    {
      Header: "Curriculum",
      accessor: "curriculumData",
    },
    {
      Header: "Quiz time",
      accessor: "quiz_time",
    },
    {
      Header: "Class Year",
      accessor: "class_year",
    },
    {
      Header: "Graded",
      accessor: "Graded",
    },
    {
      Header: "Attempts",
      accessor: "attempts",
    },
  ];
  console.log(isLoading,'isLoading  ');
  return (
    <Stack p={2}>
      <CTable
        selectedData={viewData}
        footerBtnTitle
        noFilter={false}
        noSearchBar={true}
        Data={mappedData || []}
        Columns={columns}
        Title={
          <Stack
            direction={"row"}
            sx={{ width: "100%" }}
            alignItems={"center"}
            columnGap={"5px"}
            justifyContent={"space-between"}
          >
            <Stack direction={"row"} columnGap={"5px"}>
              <img src={icons.greenCircle} alt="green circle" />
              <Typography>Paid Courses </Typography>
            </Stack>
            <PrimaryButton
              text={"Add Courses"}
              width={{
                sm: "50%",
                md: "15%",
              }}
              variant="contained"
              startIcon={<img src={addStudent} alt="add student"></img>}
              style={{
                background: "#F79E1B",
                color: "white",
                alignSelf: "flex-end",
                textTransform: "none",
              }}
              click={() => setOpenAddModel(true)}
            />
          </Stack>
        }
        subTitle=""
        btnTitle=""
        placeHolder=""
        hasBottomAddBtn={true}
        bottomBtnTitle={"Add New User"}
        hasPagination={false}
        displayFooter={false}
        total_count={studentQuizesCount}
        handlePageChange={handlePageChange}
        perPage={4}
        paginationPage={page}
        handleSearchChange={handleSearchChange}
        checked={false}
        setChecked={setChecked}
        setAllSelectedData={setAllSelectedData}
        borderLeft={"9px solid #8CE294"}
      />
      <AddInstructor
        openModel={openAddModel}
        handleClose={handleClose}
        handleChange={handleChange}
        fetchMoreOptions={fetchMoreOptions}
        hasMore={pageNumber < Math.ceil(unrolledCoursesCount / 10)}
        count={unrolledCoursesCount}
        options={unrolledCourses}
        handleAction={handleSelectCourses}
        pageNumber={pageNumber}
        title="Add New Course"
        subTitle={"Course Name"}
        btnTitle={"Add"}
        onOpen={onMenuOpen}
        isLoading={isLoading?.loading && isLoading?.type == "getUnrolledCourses"}
        actionLoading={isLoading?.loading && isLoading?.type == "enrollStudentToMultipleCourses"}
      />
    </Stack>
  );
};
export default PaidCourses;
