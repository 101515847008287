import React from "react";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { Divider, Paper, TableContainer, Typography } from "@mui/material";
import EditableInput from "../../../Atoms/Input/EditableInput/EditableInput";
import { useRef } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateAdminCourseDetailsRequest } from "services/modules/courses/Requests/courses";
import { resetEditableInputValue } from "services/modules/utils/Helper";

const CourseConfig = ({ data }) => {
  const comments_per_day = useRef("");
  const comments_per_hour = useRef("");
  const{course_id}=useParams();
  const dispatch=useDispatch();

 

  const [courseData, setCourseData] = useState({
    comments_per_day: "1",
    comments_per_hour: `1`,
  });

  const handleChange = (evt, ref) => {
    ref.current = evt.target.value;
  };

  const handleBlur = (ref) => {
    // //console.log(ref.current);
  };

  const handelAccept = (name,ref) => {
    const value = document.getElementById(name)?.value;


    const requestData = {
      course_id,
      body: {
        course: {
          [name]:value,
                
        },
      },
    };
    dispatch(updateAdminCourseDetailsRequest({ ...requestData }));
    // //console.log(ref.current);
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
      backgroundColor: "#2f80ed17",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  return (
    <TableContainer component={Paper} sx={{ background: "white", borderRadius: "12px" }}>
      <Typography m={2} fontWeight="600" fontSize="16px">
        Configurations
      </Typography>
      <Divider />
      <Table aria-label="customized table">
        <TableBody>
          <StyledTableRow>
            <StyledTableCell component="th" scope="row" width="25%">
              Rate of comments per day
            </StyledTableCell>
            <StyledTableCell align="right" width="25%">
              <EditableInput
                type="number"
                handelAccept={()=>handelAccept('rate_of_comments_per_day',comments_per_day)}
                width={75}
                id={'rate_of_comments_per_day'}
                name="comments_per_day"
                refProp={comments_per_day}
                defaultValue={data?.rate_of_comments_per_day
}
                handelChange={(e) => handleChange(e, comments_per_day)}
                // handleBlur={(_) => resetEditableInputValue("rate_of_comments_per_day", data?.rate_of_comments_per_day)}
                onClose={(_) => resetEditableInputValue("rate_of_comments_per_day", data?.rate_of_comments_per_day)}
                row={undefined}
                customSection={undefined}
                placeholder={undefined}
                styles={undefined}
              />
            </StyledTableCell>
            <StyledTableCell align="right" width="25%">
              Rate of comments per hour
            </StyledTableCell>
            <StyledTableCell align="right" width="25%">
              <EditableInput
                type="number"
                handelAccept={()=>handelAccept('rate_of_comments_per_hour',comments_per_hour)}
                width={75}
                id={'rate_of_comments_per_hour'}
                name="comments_per_hour"
                refProp={comments_per_hour}
                defaultValue={data?.rate_of_comments_per_hour}
                handelChange={(e) => handleChange(e, comments_per_hour)}
                // handleBlur={(_) => resetEditableInputValue("rate_of_comments_per_hour", data?.rate_of_comments_per_hour)}
                onClose={(_) => resetEditableInputValue("rate_of_comments_per_hour", data?.rate_of_comments_per_hour)}
                row={undefined}
                customSection={undefined}
                placeholder={undefined}
                styles={undefined}
              />
            </StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CourseConfig;
