import * as Yup from "yup";
import { useFormik } from "formik";
import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import EvaluateAssignmentTemplate from "Templates/Courses/EvaluateAssignment.Template";
// import AssignmentTemplate from "Templates/Courses/Assignment.Template";
// import {
//   CreateAssignmentRequest,
//   ShowAssignmentDetailsRequest,
//   UpdateAssignmentRequest,
// } from "services/modules/courses/Requests/assignment";
import {
GetAssignmentSubmissionsRequest
}from "services/modules/courses/Requests/assignment"
const EvaluateAssignmentPage = ({ setLayoutData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // useEffect(()=>{
  //   GetAssignmentSubmissionsRequest({delivery_id})
  // },[])
  // const assignment = useSelector((state) => state.courses.assignment); // what ?? el mafrod fe3lan dah el assignment
  // //console.log(assignment)
  const { section_id, assignment_id, course_id,delivery_id ,tab} = useParams();
  useEffect(() => {
    setLayoutData({
      title: "Assignment",
      breadcrumb: [
        { title: "Home", path: "/", active: true },
        { title: "Courses", path: "/courses", active: true },
        {
          title: "Course Detail",
          path: `/courses/${course_id}`,
          active: true,
        },
        { title: "Assignment Details",
        path: `/assignment/${course_id}/${section_id}/${assignment_id}/${tab}`,
        active: true },
      ],
    });
  }, [course_id]);

  // useEffect(() => {
  //   if (assignment_id) dispatch(ShowAssignmentDetailsRequest({assignment_id}));
  // }, [assignment_id]);

  const formik = useFormik({
    initialValues: {
        "table": [
          { name: 'Course', value: 'Advanced Physics', editable: false },
          { name: 'Course session', value: 'Geometrical Optics & Waves', editable: false },
          { name: 'Due Date', value: '23/7/2023', editable: false },
          { name: 'Passing percentage', value: '50.0', editable: false },
        ],
      "question": "addora assignment body example updated",
      "student_answer": {
          "body": "This is the assignment 1 delivery",
          "attachment_url": "https://inclass-app.s3.eu-central-1.amazonaws.com/staging/dEPj_0aMFV5085fUuw68pg_1695133467_.pdf",
          "media": []
      },
      "evaluation": {
          "evaluation": {
              "assignmnet_evaluation_id": 1,
              "feedback": "very good",
              "grade": 100.0,
              "has_attachments": false,
              "attachments": [],
              "status": {
                  "value": "pass",
                  "translation": "Pass"
              }
          }
      }
  },
    // validationSchema: Yup.object().shape({}),
    // enableReinitialize: true,
    onSubmit: (values) => {
      //console.log(values)
      // const data = { assignment: { ...values }, attachments: [] };
      // const action = () => {
      //   // navigate(`/courses/${course_id}`);
      //   //console.log('done')
      // };
      //   const requestData = {
      //     course_id,
      //     assignment_id,
      //     body: data,
      //     action: action,
      //   };
      //   dispatch(UpdateAssignmentRequest({ ...requestData }));
    },
  });
  return(
    <EvaluateAssignmentTemplate formik={formik}/>
  )
};

export default EvaluateAssignmentPage;
