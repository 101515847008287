import Types from "../Types";

// sessions
export const getAllCourseSessionSuccess = (payload) => ({
  type: Types.GET_ALL_COURSE_SESSIONS_SUCCESS,
  payload,
});
export const getCourseSectionSessionSuccess = (payload) => ({
  type: Types.GET_COURSE_SECTION_SESSIONS_SUCCESS,
  payload,
});

export const CreateSessionSuccess = (payload) => ({
  type: Types.CREATE_SESSION_SUCCESS,
  payload,
});

export const ShowSessionDetailsSuccess = (payload) => ({
  type: Types.SHOW_SESSION_DETAILS_SUCCESS,
  payload,
});

export const UpdateSessionSuccess = (payload) => ({
  type: Types.UPDATE_SESSION_SUCCESS,
  payload,
});

export const deleteCourseSessionSuccess = (payload) => ({
  type: Types.DELETE_COURSE_SESSION_SUCCESS,
  payload,
});
