import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import Container from './container.jsx'

const DragabelCards = ({ cards, setCards }) => {
	return (
		<div className="App">
			<DndProvider backend={HTML5Backend}>
				<Container cards={cards} setCards={setCards} />
			</DndProvider>
		</div>
	)
}
export default DragabelCards