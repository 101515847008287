import { Box, Paper, Radio, Stack, Typography } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import UploadInput from "components/Atoms/Input/FileInput/UploadInput";
import RadioButtonsGroup from "components/Atoms/Input/Radio/RadioInput";
import TextFieldInput from "components/Atoms/Input/TextField/TextFieldInput";
import UploadModel from "components/Atoms/Model/uploadModel";
import SelectBox from "components/Atoms/Select/Select";
import E_Book from "components/Molecules/Session/e-book";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { uploadFileAPI } from "services/modules/utils/Upload";

const FormTitle = ({ formik,sessionType,setSessionType }) => {
  const { inputBg } = useThemePalette();
  const [selectedOption,setSelectedOption]=useState();

  const [videoFileName, setVideoFileName] = useState("");
  const [openLoader, setOpenLoader] = useState(false);
  const [openUploadModel,setOpenUploadModel]=useState(false);
  const quizzes=useSelector((state)=>state.quiz.quizzes);
  const handleClose=()=>{
    setOpenUploadModel(false)
  }
  //console.log('out',formik.values.course_session_type)


  const uploadFile = async (event, type) => {
    setOpenLoader(true);
    const res = await getPresignedLinkAPI([{ extension: type === "image" ? "png" : "mp4" }]);
    const presignedUrl = res?.res?.data[0].presigned_url;
    //console.log(presignedUrl)
    const file = event.target.files[0];
    //console.log(file)
    const upload = await uploadFileAPI(presignedUrl, file);
    if (upload) {
      setVideoFileName(file?.name);
    }
    setOpenLoader(false);
  };
  return (
    <Stack gap={3}>
      <TextFieldInput
        label="Session Title*"
        sx={{
          background: inputBg,
          padding: "0",
          borderRadius: "8px",
          "& fieldset": {
            border: "none",
          },
        }}
        placeholder="Title*"
        name="title"
        onChange={formik.handleChange}
        value={formik.values.title}
        error={formik.errors.title}
        helperText={formik.errors.title}
      />
      <SelectBox
      label={'Type of session*'}
        items={[
          {
            id: "url",
            name: "URL",
          },
          {
            id: "video",
            name: "Video",
          },
          {
            id: "zoom_meeting",
            name: "Zoom meeting",
          },
         /* {
            id: "e-book",
            name: "E-book",
          },*/
        ]}
        className="course_session_type"
        value={formik.values.course_session_type}
        onchange={(e) => {
          formik.setValues({ ...formik?.values, course_session_type: e.target.value })
          setSessionType(e.target.value)
          setSelectedOption(null)
        }}
        sx={{
          padding: "10px",
          borderRadius: "8px",
          "&:before": {
            border: "none",
          },
        }}
      />
      <TextFieldInput
        label="Session Description*"
        multiline
        rows={5}
        sx={{
          background: inputBg,
          padding: "0",
          borderRadius: "8px",
          "& fieldset": {
            border: "none",
          },
        }}
        placeholder="Description*"
        name="description"
        onChange={formik.handleChange}
        value={formik.values.description}
        error={formik.errors.description}
        helperText={formik.errors.description}
      />
      <Stack flexDirection={'row'} gap={3}>
      <TextFieldInput
        label="Expected Time*"
       
        sx={{
          background: inputBg,
          padding: "0",
          borderRadius: "8px",
          "& fieldset": {
            border: "none",
            width:'47%'
          },
        }}
        placeholder="Expected Time*"
        name="expected_time"
        onChange={formik.handleChange}
        value={formik.values.expected_time}
        error={formik.errors.expected_time}
        helperText={formik.errors.expected_time}
      />
      <SelectBox
      label={'Expected Time Unit*'}
        items={[
          {
            id: "seconds",
            name: "Seconds",
          },
          {
            id: "minutes",
            name: "Minutes",
          },
          {
            id: "hours",
            name: "Hours",
          },
        ]}
        className="expected_time_unit"
        value={formik.values.expected_time_unit}
        onchange={(e) => formik.setValues({ ...formik?.values, expected_time_unit: e.target.value })}
        sx={{
          padding: "10px",
          borderRadius: "8px",
          "&:before": {
            border: "none",
            width:'47%'

          },
        }}
      />
            </Stack>
            {(sessionType=== 'url'||sessionType === 'video')&&
                        <Stack flexDirection={'row'} gap={3} sx={{width:'100%'}}>
           <Stack width='100%'>
            <RadioButtonsGroup 
            row 
            value={selectedOption}
            style={{mr:22,justifyContent:'space-between'}}
            options={[{value:'url',label:'Url'},{value:'upload',label:'Upload video'}]}
            change={(value)=>setSelectedOption(value)}
            >
            </RadioButtonsGroup>
            </Stack>
            {/*<TextFieldInput
            label="Price"

        sx={{
          background: inputBg,
          padding: "0",
          borderRadius: "8px",
          "& fieldset": {
            border: "none",
            width:'47%'
          },
        }}
        placeholder="price"
        name="price"
        onChange={formik.handleChange}
        value={formik.values.price}
        error={formik.errors.price}
        helperText={formik.errors.price}
      />*/}
            </Stack>
      }
      {selectedOption==='url'&&
      <TextFieldInput
        label="URL"
       
        sx={{
          background: inputBg,
          padding: "0",
          borderRadius: "8px",
          "& fieldset": {
            border: "none",
            width:'47%'
          },
        }}
        placeholder="https://www.example.com"
        name="video_url"
        onChange={formik.handleChange}
        value={formik.values.video_url}
        error={formik.errors.video_url}
        helperText={formik.errors.video_url}
      />}
      {selectedOption==='upload'&&
      <>
      <Stack flexDirection={'row'} gap={3}>
      <TextFieldInput
        label="Max Number of Views"
        type='number'
        sx={{
          background: inputBg,
          padding: "0",
          borderRadius: "8px",
          "& fieldset": {
            border: "none",
            width:'47%'
          },
        }}
        placeholder="Max Number of Views"
        name="max_number_of_views"
        onChange={formik.handleChange}
        value={formik.values.max_number_of_views}
        error={formik.errors.max_number_of_views}
        helperText={formik.errors.max_number_of_views}
      />
      <SelectBox
      label={'Add Quick Quiz'}
        items={quizzes}
        className="quiz_id"
        value={formik.values.quick_quiz}
        onchange={(e) => formik.setValues({ ...formik?.values, quiz_id: e.target.value })}
        sx={{
          padding: "10px",
          borderRadius: "8px",
          "&:before": {
            border: "none",
            width:'47%'

          },
        }}
      />
      </Stack>
      <Typography fontSize="12px" color="#5E6366">
          {" "}
          {/* Video upload */}
          Or Upload Video (Optional)
        </Typography>
        <UploadInput
        openModel={true}
          id="video"
          setOpenUploadModel={setOpenUploadModel}
          btnText="Add Attachment"
          placeholder="Select Video"
          btnStyle={{
            background: "#2F80ED",
            opacity:'64%',
            color: "white",
            width: "100%",
            height: "100%",
            borderRadius: "0 8px 8px 0",
            textTransform: "capitalize",
          }}
          inputStyle={{
            borderRadius: "8px 0 0 8px",
            "& fieldset": {
              border: "none",
              background:inputBg,
            },
            
          }}
          value={videoFileName}
          change={(e) => uploadFile(e, "video")}
          textValue={videoFileName}
        />
         <UploadModel
         type={"video"}
         formik={formik}
      openVar={openUploadModel}
       title={'Upload Video'}
       titleStyle={{fontWeight:500,fontSize:20,color:'#45464E'}}
       setOpenUploadModel={setOpenUploadModel}
       closeFunc={handleClose}
       setVideoFileName={setVideoFileName}>
       </UploadModel>
      </>
      }
      {sessionType==='e-book'&&<E_Book formik={formik}></E_Book>}
      
    </Stack>
   
  );
};

export default FormTitle;
