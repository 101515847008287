import Types from "./Types";

const INIT_STATE = {
  isOpenCreateCourse: false,
  isOpenAddMoreQuestions: false,
  isOpenCreateInstructor: false,
  isOpenCreateAdminUser: false,
  isOpenEditAdminUser: false,
  isOpenEditStudentUser:false,
  isOpenEnrollStudent:false,
  alertModal: {
    isOpen: false,
    icon: null,
    message: null,
  },
  isOpenUploadCSV:false,



};

export default function modelReducer(state = INIT_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case Types.TOGGLE_ALERT_MODAL: {
      const { isOpen, icon, message } = payload;
      return {
        ...state,
        alertModal: {
          isOpen,
          icon,
          message,
        },
      };
    }
    case Types.CREATE_COURSE_MODEL: {
      return {
        ...state,
        isOpenCreateCourse: !state.isOpenCreateCourse,
      };
    }

    case Types.ADD_MORE_QUETIONS_MODEL: {
      return {
        ...state,
        isOpenAddMoreQuestions: !state.isOpenAddMoreQuestions,
      };
    }
    case Types.CREATE_INSTRUCTOR_MODEL: {
      return {
        ...state,
        isOpenCreateInstructor: !state.isOpenCreateInstructor,
      };
    }
    case Types.CREATE_ADMIN_USER_MODEL: {
      return {
        ...state,
        isOpenCreateAdminUser: !state.isOpenCreateAdminUser,
      };
    }

    case Types.EDIT_STUDENT_USER_MODEL: {
      return {
        ...state,
        isOpenEditStudentUser: !state.isOpenEditStudentUser,
      };
    }
    case Types.ENROLL_STUDENT_MODEL: {
      return {
        ...state,
        isOpenEnrollStudent: !state.isOpenEnrollStudent,
      };
    }

    case Types.UPLOAD_CSV_MODEL: {
      return {
        ...state,
        isOpenUploadCSV: !state.isOpenUploadCSV,
      };
    }

    default: {
      return state;
    }
  }
}
