import DeleteAccountTemplate from "Templates/deleteAccount/DeleteAccount.Template";
import { icons } from "assets/AssetHelper";
import Loader from "components/Atoms/Loader/Loader";
import AlertModal from "components/Atoms/Model/AlertModal";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { DeleteAccountRequest } from "services/modules/authentication";
import { ToggleAlertModal } from "services/modules/models/Actions";
import { Organization_id } from "services/modules/utils/constant";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  email: Yup.string().required().email("Invalid Email").label("Email"),
  password: Yup.string().required().label("Password"),
});

export default function DeleteAccountPage() {
  console.log(Organization_id);
  const dispatch = useDispatch();
  const load = useSelector((state) => state?.auth?.isLoading);
    //console.log(Organization_name,'Organization_name');
  const handleDeleteAccount = (values) => {
    const { email, password } = values;
    const action = () => {
      dispatch(
        ToggleAlertModal({
          isOpen: true,
          icon: icons?.Success,
          message: "Your account has been successfully deleted.",
        })
      );
      formik?.resetForm();
    };
    const failAction = (error) => {
      const errorType = error?.response?.data?.error;
      if (errorType == "invalid_credentials") {
        dispatch(
          ToggleAlertModal({
            isOpen: true,
            icon: icons?.Exclamationmark,
            message: (
              <>
                Oops! There seems to be a mismatch. <br />
                Check your email and password again.
              </>
            ),
          })
        );
      } else if (errorType == "account_not_found") {
        dispatch(
          ToggleAlertModal({
            isOpen: true,
            icon: icons?.NotFound,
            message: (
              <>
                That account seems to be missing! <br />
                Double-check the email and try again.
              </>
            ),
          })
        );
      }
    };
    const requestData = {
      body: {
        email,
        password,
        // organization_id: 1,
      },
      action,
      failAction,
    };
    dispatch(DeleteAccountRequest(requestData));
  };

  const formik = useFormik({
    initialValues: { email: "", password: "" },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      handleDeleteAccount(values, formik);
    },
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
  });

  return (
    <>
      {/* <Loader open={Boolean(load?.loading)} /> */}
      <AlertModal />
      <DeleteAccountTemplate formik={formik} />
    </>
  );
}
