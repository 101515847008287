import { reduxRequest } from "common/utils/reduxRequest";
import { QUESTION_BANK } from "../utils/constant";
import {
  questionBankLoading,
  getQuestionBankDetailsSuccess,
  getQuestionBanksSuccess,
} from "./Actions";

export const getQuestionBanksRequest = () => {
  const requestData = {
    isLoading: questionBankLoading,
    successFunction: getQuestionBanksSuccess,
    url: `v2/question_banks`,
    method: "get",
    loadingType: "list",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const getQuestionBankDetailsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: questionBankLoading,
    successFunction: getQuestionBankDetailsSuccess,
    url: `/v2/question_banks/${variables?.bank_id}`,
    method: "get",
    loadingType: "details",
    ...variables,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
