import { useEffect, useState, useRef, RefObject } from "react";



const BOUND_HEIGHT = 70;

function getScrollDirection({
    position,
    upperBounds = Infinity,
    lowerBounds = -Infinity
}) {
    if (position === undefined) {
        return "stable";
    }
    if (position > lowerBounds - BOUND_HEIGHT) {
        return "bottom";
    }
    if (position < upperBounds + BOUND_HEIGHT) {
        return "top";
    }
    return "stable";
}

export const useScroll = (ref) => {
    const [config, setConfig] = useState({
        position: 0,
        isScrollAllowed: false
    });

    const scrollTimer = useRef(null);

    const scrollSpeed = 1;
    const { position, isScrollAllowed } = config;

    const bounds = ref.current?.getBoundingClientRect();
    // //console.log(bounds);
    const direction = getScrollDirection({
        position,
        upperBounds: bounds?.top,
        lowerBounds: bounds?.bottom
    });

    useEffect(() => {
        if (direction !== "stable" && isScrollAllowed) {
            scrollTimer.current = setInterval(() => {
                ref.current?.scrollBy(0, scrollSpeed * (direction === "top" ? -1 : 1));
            }, 1);
        }
        return () => {
            if (scrollTimer.current) {
                clearInterval(scrollTimer.current);
            }
        };
    }, [isScrollAllowed, direction, ref, scrollSpeed]);

    return { updatePosition: setConfig };
};
