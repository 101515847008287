const CoursesType = {
  GET_ADMIN_COURSES_SUCCESS: "GET_ADMIN_COURSES_SUCCESS",
  ADMIN_COURSES_LOADING: "ADMIN_COURSES_LOADING",
  GET_ADMIN_COURSE_DETAILS_SUCCESS: "GET_ADMIN_COURSE_DETAILS_SUCCESS",
  ADMIN_COURSE_DETAILS_LOADING: "ADMIN_COURSE_DETAILS_LOADING",
  UPDATE_ADMIN_COURSE_DETAILS_SUCCESS: "UPDATE_ADMIN_COURSE_DETAILS_SUCCESS",
  DELETE_ADMIN_COURSE_SUCCESS: "DELETE_ADIMI_COURSE_SUCCESS",
  DELETE_ADMIN_COURSE_LOADING: "DELETE_ADMIN_COURSE_LOADING",
  DELETE_COURSE_SESSION_SUCCESS: "DELETE_COURSE_SESSION_SUCCESS",
  GET_ALL_SESSIONS_SUCCESS: "GET_ALL_SESSIONS_SUCCESS",
  GET_ALL_COURSE_QUIZZES_SUCCESS: "GET_ALL_COURSE_QUIZZES_SUCCESS",
  DELETE_COURSE_QUIZ_SUCCESS: "DELETE_COURSE_QUIZ_SUCCESS",
  DELETE_COURSE_ASSIGNMENT_SUCCESS: "DELETE_COURSE_ASSIGNMENT_SUCCESS",
  GET_ALL_COURSE_ASSIGNMENTS_SUCCESS: "GET_ALL_COURSE_ASSIGNMENTS_SUCCESS",
  CREATE_COURSE_SUCCESS: "CREATE_COURSE_SUCCESS",
  GET_ALL_COURSE_SESSIONS_SUCCESS: "GET_ALL_COURSE_SESSIONS_SUCCESS",
  SHOW_SESSION_DETAILS_SUCCESS: "SHOW_SESSION_DETAILS_SUCCESS",
  CREATE_ASSIGNMENT_SUCCESS: "CREATE_ASSIGNMENT_SUCCESS",
  SHOW_ASSIGNMENT_DETAILS_SUCCESS: "SHOW_ASSIGNMENT_DETAILS_SUCCESS",
  UPDATE_ASSIGNMENT_SUCCESS: "UPDATE_ASSIGNMENT_SUCCESS",
  GET_COURSE_INSTRUCTORS_SUCCESS: "GET_COURSE_INSTRUCTORS_SUCCESS",
  ADD_COURSE_INSTRUCTORS_SUCCESS: "ADD_COURSE_INSTRUCTORS_SUCCESS",
  DELETE_COURSE_INSTRUCTORS_SUCCESS: "DELETE_COURSE_INSTRUCTORS_SUCCESS",
  SEARCH_COURSE_LIST_SUCCESS: "SEARCH_COURSE_LIST_SUCCESS",

  GET_COURSE_JOINED_USERS_SUCCESS: "GET_COURSE_JOINED_USERS_SUCCESS",
  ADD_COURSE_JOINED_USERS_SUCCESS: "ADD_COURSE_JOINED_USERS_SUCCESS",

  GET_COURSE_PENDING_USERS_SUCCESS: "GET_COURSE_PENDING_USERS_SUCCESS",
  GET_COURSE_COUPONS_SUCCESS: "GET_COURSE_COUPONS_SUCCESS",

  GET_COURSE_SECTIONS_SUCCESS: "GET_COURSE_SECTIONS_SUCCESS",
  DELETE_COURSE_SECTION_SUCCESS: "DELETE_COURSE_SECTION_SUCCESS",

  REORDER_COURSE_SECTION_SUCCESS: "REORDER_COURSE_SECTION_SUCCESS",
  REORDER_COURSE_SECTION_ITEMS_SUCCESS: "REORDER_COURSE_SECTION_ITEMS_SUCCESS",


  GET_COURSE_SUBJECTS_SUCCESS: "GET_COURSE_SUBJECTS_SUCCESS",
  CREATE_SESSION_SUCCESS: "CREATE_SESSION_SUCCESS",
  ADD_COURSE_SUBJECT_SUCCESS: "ADD_COURSE_SUBJECT_SUCCESS",

  GET_COURSE_SECTION_ASSIGNMENTS_SUCCESS: "GET_COURSE_SECTION_ASSIGNMENTS_SUCCESS",
  GET_COURSE_SECTION_QUIZ_SUCCESS: "GET_COURSE_SECTION_QUIZ_SUCCESS",
  GET_COURSE_SECTION_SESSIONS_SUCCESS: "GET_COURSE_SECTION_SESSIONS_SUCCESS",
  GET_ASSIGNMENT_SUBMISSIONS_SUCCESS: "GET_ASSIGNMENT_SUBMISSIONS_SUCCESS",
  MOVE_TO_JOINED_USER_SUCCESS: "MOVE_TO_JOINED_USER_SUCCESS",
  OPEN_ACIONS_MENU: "OPEN_ACIONS_MENU",
  GET_STUDENT_QUIZES_SUCCESS: "GET_STUDENT_QUIZES_SUCCESS",
  GET_STUDENT_ASSIGNMENTS_SUCCESS: "GET_STUDENT_ASSIGNMENTS_SUCCESS"
};
export default CoursesType;
