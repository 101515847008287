import axios from "axios";
import {
  fileUploadLoading,
  fileUploadOnServerLoading,
  fileUploadSuccess,
  getActiveIndex,
} from "./Action";

import { reduxRequest } from "common/utils/reduxRequest";
import { store } from "services/store";
const token = localStorage.getItem("token");
export const getPresignedUrlRequest = ({ ...variables }) => {
  const requestData = {
    ...variables,
    isLoading: fileUploadLoading,
    successFunction: fileUploadSuccess,
    loadingType: "upload",
    url: "/v1/presigned_url",
    method: "post",
    body: variables.body.extension,
    action: (data) => {
      //console.log(data);
      store.dispatch(
        uploadRequest({
          presigned_url: data?.data[0].presigned_url,
          file: variables.body.file,
          fileUrl: data?.data[0].file_url,
          updateData: variables.updateData,
          ...variables
        })
      );
    },
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};



// export async function uploadFileAPI(presigned_url, file) {
//   const config = {
//       header: {
//           "Content-Type": file.type,
//       }
//   }
//   return await axios.put(presigned_url, file, config)
//       .then(res => {
//           if (res) {
//               return res
//           }
//       }).catch(handleError)
// }

export const uploadRequest = ({ ...variables }) => {
  const requestData = {
    ...variables,
    isLoading: fileUploadOnServerLoading,
    successFunction: fileUploadSuccess,
    loadingType: "uploadOnServer",
    url: variables.presigned_url,
    body: variables.file,
    method: "put",
    action: () => {
      store.dispatch(fileUploadSuccess(variables?.fileUrl));
      // //console.log(variables?.updateData);
      variables?.updateData(variables.fileUrl);
      // set token in headers after uploading success
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      variables?.action && variables?.action()
    },
  };
  delete axios.defaults.headers.common["Authorization"];
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
