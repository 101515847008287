  import { useState, useEffect } from "react";
  import { useDispatch, useSelector } from "react-redux";
  import { Link, useNavigate } from "react-router-dom";
  import {
    deleteAdminCourseRequest,
    getAdminCoursesRequest,
    getAdminSearchCourseRequest,
  } from "services/modules/courses/Requests/courses";
  import CoursesListTemplate from "Templates/Courses/CoursesList.Template";
  import MoreVertIcon from "@mui/icons-material/MoreHorizRounded";
  import { Button, ClickAwayListener, MenuItem, MenuList, Paper, Popper } from "@mui/material";
  import React from "react";
  import DeleteModal from "components/Atoms/Model/DeleteModel";
  import { toast } from "react-toastify";
  import ListSkeleton from "components/Atoms/skeleton/listSkeleton";
  import Loader from "components/Atoms/Loader/Loader";
  import MenuPopup from "components/Molecules/Menus/MenuPopup";
  import trash from "assets/icons/trash.svg";
  import pen from "assets/icons/pen.svg";
  import dayjs from "dayjs";
  const CoursesListPage = ({ setLayoutData }) => {
    const courses = useSelector((state) => state.courses.courses);
    const { isLoading } = useSelector((state) => state.courses);
    //console.log(isLoading?.type)

    const count = useSelector((state) => state.courses.count);
    const currentPage = useSelector((state) => state.courses.currentPage);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [filter, setFilter] = useState(null);
    const [openDeleteModel, setOpenDeleteModel] = useState(false);
    const [keyword, setKeyword] = useState(null);
    const [selected, setSelected] = useState({
      id: "",
    });
    const [anchorEl, setAnchorEl] = React.useState(null);
    const anchorRef = React.useRef(null);
    const open = Boolean(anchorEl);

    //console.log(selected,'selected Data');

    const handleDelete = () => {
      const requestedData = {
        course_id: selected?.id,
        action: () => {
          dispatch(getAdminCoursesRequest({ filter: { page_number: page, page_size: 10 } }));

          toast.success(`Course ${selected?.title?.props?.children} deleted successfully`);

          setOpenDeleteModel(false);
        },
      };
      dispatch(deleteAdminCourseRequest(requestedData));
    };
    const handleCloseModel = () => {
      setOpenDeleteModel(false);
    };
    const viewData = (data) => {
      setSelected(data);
    };
    const handleClick = (event, index, data) => {
      anchorEl == index ? setAnchorEl(null) : setAnchorEl(index);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const onRowClick = (data) => {
      navigate(`/courses/${data?.row?.values?.id}`);
    }
    const mappedData = courses?.map((data, index) => {
      return {
        ...data,

        school_class: (
          <Link
            to={`/courses/${data?.school_class}`}
            style={{ textDecoration: "none", color: "#6E7079" }}
          >
            {data?.school_class ? data?.school_class : "-"}
          </Link>
        ),
        title: (
          <Link to={`/courses/${data?.id}`} style={{ textDecoration: "none", color: "#6E7079" }}>
            {data?.title}
          </Link>
        ),
        start_date: dayjs(data?.start_date)?.format("DD-MM-YYYY "),
        original_price: `${data?.original_price} ${data?.price_after_discount?.currency_iso} `,
        Actions: (
          <div style={{ position: "relative" , display:'flex' , gap: '4px'}}>
            <Button
              sx={{ textTransform: "none", color: "black", border: "solid 1px #EDEFF1"}}
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/courses/${data?.id}`);
              }}
            >
              <img src={pen}></img>Edit
            </Button>
            <Button
              sx={{
                textTransform: "none",
                color: "#EB001B",
                border: "solid 1px #EDEFF1",
              }}
              onClick={(e) => {
                e.stopPropagation();
                setOpenDeleteModel(true);
              }}
            >
              <img src={trash}></img> Delete
            </Button>
          </div>
        ),
      };
    });
    useEffect(() => {
      const filterParams = { page_number: page, page_size: 10 };
      if (keyword != null) {

        filterParams.course_name = keyword;
        dispatch(
          getAdminSearchCourseRequest({
            filter: { ...filterParams, course_name: keyword },
          })
        );
      } else {
        dispatch(getAdminCoursesRequest({ filter: filterParams }));
      }
    }, [page, keyword]);

    const handleSearchChange = (key) => {
      if (key) {
        setKeyword(key);
      } else {
        setKeyword(null);
      }
    };

    useEffect(() => {
      setLayoutData({
        title: "Courses",
        breadcrumb: [
          { title: "Home", path: "/", active: true },
          { title: "Courses", path: "/courses", active: false },
        ],
      });
    }, []);

    return (
      <>
        <Loader open={isLoading?.loading}></Loader>

        <CoursesListTemplate
          dispatch={dispatch}
          setPage={setPage}
          perPage={perPage}
          setPerPage={setPerPage}
          viewData={viewData}
          mappedData={mappedData}
          page={page}
          setPageNumber={setPage}
          count={count}
          handleSearchChange={handleSearchChange}
          onRowClick={onRowClick}
        />
        <DeleteModal
          open={openDeleteModel}
          handelDeleteRequest={handleDelete}
          handleClose={handleCloseModel}
          text={`Are you sure you want to delete "${selected.title?.props?.children}" account`}
        ></DeleteModal>
      </>
    );
  };

  export default CoursesListPage;
