import { Box, Button, Divider, Modal, Stack, Typography } from "@mui/material";
import { icons } from "assets/AssetHelper";
import { useThemePalette } from "common/hooks/theme_palette";

const ConfirmationModal = ({
                             open,
                             setOpen,
                             handleConfirmation,
                             handleCancellation,
                             customClass,
                             text,
                             confirmBtnTitle,
                             textStyle,
                             btnStyle,
                             color='black', btnColor='#D92C32'
                           }) => {

  const { red } = useThemePalette();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 750,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "25px",
    py: 4,
  };

  // const handleClose = () => setOpen(false);
  const handleClose = () => {
    setOpen(false);
    handleCancellation && handleCancellation();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack
        sx={style}
        alignItems="center"
        justifyContent="center"
        className={`delete-modal ${customClass}`}
      >
        <Stack
          gap={3}
          alignItems="center"
          justifyContent="center"
          sx={{ width: "100%" }}
        >
          <Typography
            component="h1"
            style={{ color: color, fontWeight: "bold", fontSize: "30px" }}
          >
            {text[0]}
          </Typography>
          <Divider sx={{ border: "1px solid #F5F5F5", width: "100%" }} />
          {text[1] && (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              gap={1}
            >
              <Box component="img" src={icons.errorCircle} width="40px" />
              <Typography
                textAlign="center"
                style={{
                  fontFamily: "Jost",
                  fontWeight: "400",
                  fontSize: "24px",
                }}
              >
                {text[1]}
              </Typography>
            </Stack>
          )}
        </Stack>
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent={'center'}
          mt={4}
          className="buttons-wrap"
          gap={2}
          width={'100%'}
        >
          <Button
            sx={{
              color: "#F79E1B",
              border: "1px solid #F79E1B",
              textTransform:'none',
              width:'45%',
            }}
            className="r-25px"
            onClick={handleClose}
          >
            Cancel
          </Button>

          <Button
            sx={{
              backgroundColor:btnColor,
              textTransform:'none',
              width:'45%',

              "&:hover": {
                backgroundColor:btnColor,
              },
              color: "white",
              ...btnStyle
            }}

            className="primary r-25px"
            onClick={handleConfirmation}
          >
            {confirmBtnTitle}
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
};

export default ConfirmationModal;
