import React, { useState } from "react";
import InstructorsListTemplate from "Templates/users/instructors/InstructorsList.Template";
import { useEffect } from "react";
import { GetAllInstructorsRequest } from "services/modules/instructors";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Button, ClickAwayListener, MenuItem, MenuList, Paper, Popper,Stack } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreHorizRounded";
import { Link } from "react-router-dom";
import AdminUsersListTemplate from "../../Templates/AdminUsers/AdminUsers.Template";
import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined';
import trash from "assets/icons/trash.svg";
import pen from "assets/icons/pen.svg";

import {
  DeleteAdminUserRequest,
  GetAllAdminUserssRequest,
  ShowAdminUserDetailsRequest
} from "../../services/modules/AdminUsers";
import { creatAdminUserModel } from "../../services/modules/models/Actions";
import CreateAdminUserModel from "../../components/Organisms/AdminUsers/models/create";
import Model from "../../components/Atoms/Model/Model";
import DeleteModal from "../../components/Atoms/Model/DeleteModel";
import { toast } from "react-toastify";
import ListSkeleton from "components/Atoms/skeleton/listSkeleton";
import Loader from "components/Atoms/Loader/Loader";

const AdminUsersListPage = ({ setLayoutData }) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const[createOrEdit,setCreateOrEdit]=useState('')
  const [openDeleteModel,setOpenDeleteModel]=useState(false)
  const [selected, setSelected] = useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const anchorRef = React.useRef(null);
  const adminUsers = useSelector((state) => state.adminUsers.adminUsers);
  const count = useSelector((state) => state.adminUsers.count);

  const {isLoading} = useSelector((state) => state.adminUsers);


  const open = Boolean(anchorEl);
  const handleClick = (event, index, data) => {
    anchorEl == index ? setAnchorEl(null) : setAnchorEl(index);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

    //console.log(adminUsers)

  const viewData = (data) => {
    setSelected(data);
  };
  const handleCloseModel=()=>{
    setOpenDeleteModel(false)
  }
  const handleDelete=()=>{
    const requestedData={
        'admin_id': selected?.id?.props?.children
        , action: () => {
          toast.success(`Admin ${selected.name !== null ? selected.name : ""} deleted successfully`);
        setSelected({})
            setOpenDeleteModel(false)
      }
    }
    //console.log(selected)
    dispatch(DeleteAdminUserRequest(requestedData))
  }
  const handlePagination=(page)=>{
       setPage(page)
  }
  //console.log(page)

  useEffect(() => {
    dispatch(GetAllAdminUserssRequest({ filter: { page_number: page, page_size: 10 } }));
  }, [page]);
  const mappedData = adminUsers?.map((data, index) => {
    return {
      ...data,
      id: (
        <Link
          to={`/admin-users/${data?.id}`}
          style={{ textDecoration: "none", color: "#6E7079" }}
        >
          {data?.id}
        </Link>
      ),
      Actions: (
          <div style={{ position: "relative"  }}>
              <Button sx={{ textTransform: 'none', color: 'black', border: 'solid 1px #EDEFF1', mr: 2 }} onClick={() => {
                  //console.log(data)
                  setCreateOrEdit('edit')
                  dispatch(creatAdminUserModel())
              }} ><img src={pen}></img>Edit</Button>
              <Button sx={{
                  textTransform: 'none', color: '#EB001B', border: 'solid 1px #EDEFF1' }} onClick={() => {
                  setOpenDeleteModel(true)
                  }}><img src={trash }></img> Delete</Button>
        </div>
      ),
    };
  });
  useEffect(() => {
    setLayoutData({
      title: "Admin Users List",
      breadcrumb: [
        { title: "Home", path: "/", active: true },

        {
          title: "Admin Users",
          path: "/admin-users",
          active: false,
        },
      ],
    });
  }, []);
  return (
    <>
      <Loader open={isLoading?.loading}></Loader>

   <AdminUsersListTemplate
              setPage={setPage}
              perPage={perPage}
              setPerPage={setPerPage}
              pageNumber={pageNumber}
              handlePageChange={handlePagination}
              total_count={count}
              paginationPage={page}
              setPageNumber={setPageNumber}
              viewData={viewData}
              createOrEdit={createOrEdit}
              setCreateOrEdit={setCreateOrEdit}
              data={mappedData}
              selectedUser={selected}
    />
    
     <DeleteModal
       open={openDeleteModel}
       text={`Are you sure you want to delete "${selected.email}" account`} handelDeleteRequest={handleDelete}  handleClose={handleCloseModel}
     >

     </DeleteModal>
      </>
  );
};

export default AdminUsersListPage;
