import { Stack } from "@mui/material";
import ModelBox from "../../../Atoms/Model/Model";
import { SectionsData } from "../section-details/lv-2";
import CourseSections from "../components/sectionFullData";

const SubSection = ({ open, handleClose, blockIndex, setData, selecetedSessionData, sessions }) => {
  return (
    <ModelBox
      openVar={open}
      closeFunc={handleClose}
      title="Sub Section"
      titleStyle={{ fontSize: "16px", fontWeight: "400", color: "#2F80ED" }}
    >
      <Stack
        alignItems="flex-start"
        // onClick={() => {
        //     if (display?.index === index) setDisplay({ index: '', state: false })
        //     else setDisplay({
        //         index: index,
        //         state: !display?.state
        //     })
        // }}
        // key={index}
        // bgcolor={'white'}
        // p='16px'
        spacing={1}
        // borderRadius='12px'
        mb={3}
      >
        {/* course main data */}
        <CourseSections
          type="modal"
          data={selecetedSessionData}
          // selecetedSessionData={selecetedSessionData}
          // sectionID={section?.section_id}
        />
        {/* course materials */}
        <Stack sx={{ width: "100%" }} spacing={2}>
          <SectionsData sessions={sessions} blockIndex={blockIndex} setData={setData} />
        </Stack>
      </Stack>
    </ModelBox>
  );
};

export default SubSection;
