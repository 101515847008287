import { reduxRequest } from "common/utils/reduxRequest";
import {
  StudentLoading,
  CreateStudentSuccess,
  DeleteStudentSussess,
  ShowStudentDetailsSuccess,
  UpdateStudentSuccess,
  GetAllStudentsSuccess,
  SuspendStudentAccountSuccess,
  ActiveStudentAccountSuccess,
  enrollStudentToMultipleCoursesSuccess,
  getStudentUnrolledCoursesSuccess,
} from "./Actions";
import { makeFilterString } from "../utils/Helper";
import { student } from "../utils/constant";
export const CreateStudentRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: StudentLoading,
    successFunction: CreateStudentSuccess,
    url: `${student}`,
    method: "post",
    loadingType: "create",
    ...variables,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
export const UpdateStudentRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: StudentLoading,
    successFunction: UpdateStudentSuccess,
    url: `${student}/${variables?.student_id}`,
    method: "put",
    loadingType: "update",
    ...variables,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
export const ShowStudentDetailsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: StudentLoading,
    successFunction: ShowStudentDetailsSuccess,
    url: `${student}/${variables?.student_id}`,
    method: "get",
    loadingType: "student-details",
    ...variables,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const GetAllStudentsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: StudentLoading,
    successFunction: GetAllStudentsSuccess,
    url: `${student}/${variables?.filter ? makeFilterString(variables?.filter) : null}`,
    method: "get",
    loadingType: "details",
    ...variables,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const DeleteStudentRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: StudentLoading,
    successFunction: DeleteStudentSussess,
    url: `${student}/${variables?.student_id}`,
    method: "delete",
    loadingType: "delete",
    ...variables,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};


export const SuspendStudentRequest = ({ ...variables }) => {
  console.log(variables);
  const requestData = {
    isLoading: StudentLoading,
    successFunction: SuspendStudentAccountSuccess,
    url: `v2/users/student/${variables?.student_id}`,
    method: "put",
    loadingType: "suspend-student",
    ...variables,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const ActiveStudentRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: StudentLoading,
    successFunction: ActiveStudentAccountSuccess,
    url: `v2/users/student/${variables?.student_id}`,
    method: "put",
    loadingType: "active-student",
    ...variables,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const enrollStudentToMultipleCoursesRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: StudentLoading,
    successFunction: enrollStudentToMultipleCoursesSuccess,
    url: `v2/admin/users/student/${variables.student_id}/courses`,
    method: "post",
    loadingType: "enrollStudentToMultipleCourses",
    ...variables,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const GetStudentUrolledCoursesRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: StudentLoading,
    successFunction: getStudentUnrolledCoursesSuccess,
    url: `v2/users/student/${variables.student_id}/unenrolled_courses${variables?.filter ? makeFilterString(variables?.filter) : null}`,
    method: "get",
    loadingType: "getUnrolledCourses",
    ...variables,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};