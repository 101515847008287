import { Stack } from "@mui/system";

import CourseConfig from "components/Organisms/Courses/components/config";
import CourseMainDetails from "components/Organisms/Courses/components/details";
import CourseInstructors from "components/Organisms/Courses/components/instructors";
import CourseIntro from "components/Organisms/Courses/components/intro";
import CoursePricing from "components/Organisms/Courses/components/pricing";
import { Sections } from "components/Organisms/Courses/section-details/lv-1";
import PrimaryButton from "components/Atoms/Buttons/Primary/PrimaryButton";
import { useDispatch } from "react-redux";
import { deleteAdminCourseRequest } from "services/modules/courses/Requests/courses";
import { useNavigate, useParams } from "react-router-dom";
import JoinedUsers from "../../components/Organisms/Courses/components/joinedUsers";
import PendingUsers from "../../components/Organisms/Courses/components/pendingUsers";
import { useThemePalette } from "common/hooks/theme_palette";
import whiteTrash from "assets/icons/whiteTrash.svg";
import addStudent from "assets/icons/addStudent.svg"
import Subjects from "../../components/Organisms/Courses/components/subjects";
import SideActions from "../../components/Organisms/Courses/components/sideActions";
import Coupons from "components/Organisms/Courses/components/coupons";
import { useState } from "react";
import DeleteModal from "components/Atoms/Model/DeleteModel";
import { useSelector } from "react-redux";
import Loader from "components/Atoms/Loader/Loader";
import { toast } from "react-toastify";
import CourseDetailsSkeleton from "components/Atoms/skeleton/courseDetailsSkeleton";
import { Skeleton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import AddModel from "components/Organisms/Courses/components/addModel";
import { enrollStudentModel } from "services/modules/models/Actions";



function CourseDetailsTemplate({
  errorColor,
  course,
  cards,
  setCards,
  updated,
  setUpdated,
  instructors,
  subjects,
  joinedUsers,
  pendingUsers, coupons }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { course_id } = useParams();
  const [openDeleteModel, setOpenDeleteModel] = useState(false)
  const { secondryColor } = useThemePalette()
  //console.log(joinedUsers)
  const { isLoading } = useSelector((state) => state.courses)
  const { orangeBtn } = useThemePalette();
  const sections = useSelector((state) => state.courses.sections)
  const updatedCoupons = coupons.map(coupon => {
    // Attach belongs_to array with student information to the coupon object
    coupon.belongs_to = ({
      coupon_id: coupon.coupon_id,
      code: coupon.code,
      discount_percentage: coupon.discount_percentage
    });
    return coupon;
  });
  const handleDelete = () => {
    setOpenDeleteModel(false)

    const requestedData = {
      course_id
      , action: () => {
        navigate("/courses");
        toast.success(`Course ${course?.title} deleted successfully`)

      }
    }
    dispatch(deleteAdminCourseRequest(requestedData))
  }
  //console.log(updatedCoupons)
  //console.log(pendingUsers, 'pendingUserspendingUsers');
  return (
    <>
      <Loader open={isLoading?.loading && isLoading?.type === 'delete course'} ></Loader>
      <Stack mb={3} p={2} spacing={2}>
        <Stack flexDirection='row' justifyContent={'end'} gap={2} marginLeft='auto'>
          {/* <PrimaryButton
            width={{
              sm: "50%",
              md: "15%",
            }}
            text="Preview as user"
            variant="outlined"
            style={{
              color: "#F79E1B",
              alignSelf: "flex-end",
              textTransform: 'none',
              borderColor: '#F79E1B',

            }}

          /> */}
          <PrimaryButton
            width={{
              sm: "50%",
              md: "15%",
            }}
            text="Enroll Student"
            variant="contained"
            startIcon={<img src={addStudent} alt='add student'></img>}
            style={{
              background: '#F79E1B',
              color: "white",
              alignSelf: "flex-end",
              textTransform: 'none'

            }}
            click={()=>{
              dispatch(enrollStudentModel())
            }}
          />
          <PrimaryButton
            width={{
              sm: "50%",
              md: "15%",
            }}
            text="Delete course"
            startIcon={<img src={whiteTrash} alt='trash'></img>}
            variant="contained"
            style={{
              background: errorColor,
              color: "white",
              alignSelf: "flex-end",
              textTransform: 'none'
            }}
            click={() => {
              setOpenDeleteModel(true)
            }}
          />

        </Stack>
        <Stack flexDirection='row' height='100%'>
          <Stack
            width={{
              sm: "100%",
              md: "70%",
              lg: "75%",
            }}
            padding={{ md: "20px" }}
            spacing={3}
          >
            <CourseMainDetails data={course} />
            <CourseIntro data={course} />
            <CoursePricing data={course} />
            <CourseConfig data={course} />
            <CourseInstructors data={instructors} />
            <Subjects data={subjects}></Subjects>
            <JoinedUsers students={joinedUsers.students}></JoinedUsers>
            <PendingUsers pendingStudents={pendingUsers.students}></PendingUsers>
            <Coupons coupons={coupons}></Coupons>
            <Stack width="100%" height='auto'>
              {sections.length > 0 && <PrimaryButton
                width={{ sm: "50%", md: "15%" }}
                text="Add Section"
                variant={"contained"}
                style={{
                  background: secondryColor,
                  color: "white",
                  alignSelf: "flex-end",
                  textTransform: 'none',
                  marginBottom: 15,
                }}
                click={() => navigate(`/section/${course_id}`)}
                startIcon={<AddIcon />}
              // click={() => { setOpen(true) }}
              />}
              <Sections sections={cards} updated={updated} setUpdated={setUpdated} setSections={setCards} course={course} />
            </Stack>
          </Stack>
          {/* <SideActions></SideActions> */}
        </Stack>
        <DeleteModal
          open={openDeleteModel}
          handelDeleteRequest={handleDelete}
          handleClose={() => setOpenDeleteModel(false)}
          text={`Are you sure you want to delete "${course?.title}" account`}></DeleteModal>



          {/* enroll student modal */}
          <AddModel 
        title='Add New Student'
        subTitle={'Student '}
        btnTitle={'Enroll Student'}>
      </AddModel>


      </Stack>
    </>
  );
}

export default CourseDetailsTemplate;
