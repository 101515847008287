import { Typography } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { ShowInstructorDetailsRequest } from "services/modules/instructors";
import { ShowStudentDetailsRequest } from "services/modules/students";
import InstructorDetailsTemplate from "Templates/users/instructors/InstructorDetails.Template";
import StudentDetailsTemplate from "Templates/users/students/studentDetails.Template";

const StudentDetailsPage = ({ setLayoutData }) => {
  const dispatch = useDispatch();
  const { student_id } = useParams();
  const student = useSelector((state) => state.students?.student);
  useEffect(() => {
    if (student) dispatch(ShowStudentDetailsRequest({ student_id }));
  }, [student_id]);

  useEffect(() => {
    setLayoutData({
      title: "Users",
      breadcrumb: [
        { title: "Home", path: "/", active: true },

        {
          title: "Student",
          path: "/users/Students",
          active: true,
        },
        {
          title: <Typography textTransform={"capitalize"}> {student?.name} Details</Typography>,
          path: "",
          active: false,
        },
      ],
    });
  }, [student]);
  return <StudentDetailsTemplate student={student} />;
};

export default StudentDetailsPage;
