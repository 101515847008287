import {AuthLoading, AuthSuccess, DeleteAccountSuccess, GetCurrentUserInfoSuccess, getOTPSuccess, logoutSuccess, resetPasswordSuccess, verifyOTPSuccess} from "./Actions";
import { reduxRequest } from "common/utils/reduxRequest";

export const loginRequest = ({ ...variables }) => {
  const requestData = {
    loadingType:'login',
    isLoading: AuthLoading,
      successFunction: AuthSuccess,
      url: `v2/admin_user/login`,
    action:variables?.action,
    method: "post",
    body:variables?.body,
    headers: {
    "X-Organization-Domain":variables?.Organization_name
    },
  };
  //console.log(requestData)

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const OTPRequest = ({ ...variables }) => {
  const requestData = {
    loadingType:'OTP request',
    isLoading: AuthLoading,
    successFunction: getOTPSuccess,
    url: `v2/admin_user/forgot-password-email`,
    action:variables?.action,
    method: "post",
    data: variables?.body,
    ...variables,
    headers:{
      "X-Organization-Domain":variables?.Organization_name
    }
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const OTPVerify = ({ ...variables }) => {
  const requestData = {
    loadingType:'OTP Verification',
    isLoading: AuthLoading,
    successFunction: verifyOTPSuccess,
    url: `v2/admin_user/verify-reset-code`,
    action:variables?.action,
    method: "post",
    data: variables?.body,
    headers:{
      "X-Organization-Domain":variables?.Organization_name
    },
    ...variables
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};


export const resetPasswordRequest = ({ ...variables }) => {
  const requestData = {
    loadingType:'reset',
    isLoading: AuthLoading,
    successFunction: resetPasswordSuccess,
    url: `v2/admin_user/reset-password-email`,
    action:variables?.action,
    method: "post",
    data: variables?.body,
    headers: variables?.headers,
   
    ...variables,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
export const logoutRequest = ({ ...variables }) => {
  const requestData = {
    loadingType: "logout",
    isLoading: AuthLoading,
    successFunction: logoutSuccess,
    url: `v2/user/logout`,
    action: variables?.action,
    method: "DELETE",
    data: variables?.body,
    ...variables
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
export const getCurrentUserInfoRequest = ({ ...variables }) => {
  const requestData = {
    loadingType: "get user",
    isLoading: AuthLoading,
    successFunction: GetCurrentUserInfoSuccess,
    url: `v2/users/profile`,
    action: variables?.action,
    method: "get",
    data: variables?.body,
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const DeleteAccountRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: AuthLoading,
    successFunction: DeleteAccountSuccess,
    url: "v2/users",
    method: "DELETE",
    action: variables?.action,
    failAction: variables?.failAction,
    body: variables?.body,
    ...variables,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
