import React from "react";
import AlertProvider from "./components/Atoms/Alerts/ToastifyAlert";
import "./App.css";
import "./styles/arabicStyle.css";
import AppRoute from "./common/Route";
import { ThemeProvider } from "@mui/material";
import { theme } from "./styles/theme";
import { store } from "./services/store";
import { Provider as ReduxProvider } from "react-redux";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import setAuthToken from "./services/modules/utils/handel_api";

const App = () => {
  const custom_theme = theme;
  setAuthToken(localStorage.getItem("token"));

  /*const token =
  "eyJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoyNywiZGV2aWNlX2lkIjo4OTIsInJvbGUiOiJBZG1pblVzZXIiLCJleHAiOjQ4NjQxMzQzNjV9.FDh2mrVySD_XORA3AYOuer43f9nICrPdGvnYyo1wqak";

  setAuthToken(token);*/

  return (
    <ReduxProvider store={store}>
      <ThemeProvider theme={custom_theme}>
        <AlertProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <AppRoute />
          </LocalizationProvider>
        </AlertProvider>
      </ThemeProvider>
    </ReduxProvider>
  );
};
export default App;
