import { CoursesURL } from "../../utils/constant";
import { adminCoursesLoading } from "../Actions/courses"
import { getCourseInstructorsSuccess } from "../Actions/instrucrors"
import { reduxRequest } from "common/utils/reduxRequest";
import { deleteCourseSectionSuccess, getCourseSectionsSuccess, reorderCourseSectionSuccess } from "../Actions/sections";
import { makeFilterString } from "services/modules/utils/Helper";

export const getCourseSectionsRequest = ({ ...variables }) => {
    const requestData = {
        isLoading: adminCoursesLoading,
        successFunction: getCourseSectionsSuccess,
        url: `${CoursesURL}/sections${variables?.filter ? makeFilterString(variables?.filter) : ''}`,
        method: "get",
        loadingType: "list",
        ...variables,
    };
    return async (dispatch) => {
        reduxRequest({
            dispatch,
            ...requestData,
        });
    };
}
export const reoderCourseSectionRequest = ({ ...variables }) => {
    const requestData = {
        isLoading: adminCoursesLoading,
        successFunction: reorderCourseSectionSuccess,
        url: `${CoursesURL}/${variables?.course_id}/sections/${variables?.section_id}/reorder?new_position=${variables?.new_position}`,
        method: "put",
        loadingType: "order",
        ...variables,
    };
    return async (dispatch) => {
        reduxRequest({
            dispatch,
            ...requestData,
        });
    };
}
export const reoderCourseSectionItemsRequest = ({ ...variables }) => {
    const requestData = {
        isLoading: adminCoursesLoading,
        successFunction: reorderCourseSectionSuccess,
        url: `${CoursesURL}/${variables?.course_id}/sections/${variables?.section_id}/reorder_items?new_position=${variables?.new_position}&item_type=${variables?.item_type}&item_id=${variables?.item_id}`,
        method: "put",
        loadingType: "order items",
        ...variables,
    };
    return async (dispatch) => {
        reduxRequest({
            dispatch,
            ...requestData,
        });
    };
}
export const deleteCourseSectionRequest = ({ ...variables }) => {
    const requestData = {
        isLoading: adminCoursesLoading,
        successFunction: deleteCourseSectionSuccess,
        url: `${CoursesURL}/${variables?.course_id}/sections/${variables?.section_id}`,
        method: "delete",
        loadingType: "delete section",
        ...variables,
    };
    return async (dispatch) => {
        reduxRequest({
            dispatch,
            ...requestData,
        });
    };
}