const InstructorType = {
  INSTRUCTOR_LOADING: "INSTRUCTOR_LOADING",
  GET_ALL_INSTRUCTORS_SUCCESS: "GET_ALL_INSTRUCTORS_SUCCESS",
  DELETE_INSTRUCTOR_SUCCESS: "DELETE_INSTRUCTOR_SUCCESS",
  UPDATE_INSTRUCTOR_SUCCESS: "UPDATE_INSTRUCTOR_SUCCESS",
  SHOW_INSTRUCTOR_DETAILS_SUCCESS: "SHOW_INSTRUCTOR_DETAILS_SUCCESS",
  CREATE_INSTRUCTOR_SUCCESS: "CREATE_INSTRUCTOR_SUCCESS",
};

export default InstructorType;
