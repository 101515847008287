import { Box, Paper, Stack, Typography } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import PrimaryButton from "components/Atoms/Buttons/Primary/PrimaryButton";
import FormDetails from "components/Organisms/assignment/components/details";
import FormTitle from "components/Organisms/assignment/components/title";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { DeleteAssignmentRequest } from "services/modules/courses/Requests/assignment";
import { icons } from "assets/AssetHelper";
import TabPanel from "components/Atoms/Tabs/TabPanel";
import NormalTabs from "components/Atoms/Tabs/NormalTabs";
import AssignmentListPage from "pages/courses/AssignmentList.Page";
const AssignmentTemplate = ({ formik }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { section_id, assignment_id, course_id, tab } = useParams();
  const { primaryColor, secondryColor, redColor } = useThemePalette();
  const tabs = ["about", "pending", "graded", "unsubmitted"];

  const [value, setValue] = useState(tabs.indexOf(tab));

  const handleTabChange = (value) => {
    setValue(value);
    // navigate(`/assignment/${course_id}/${section_id}/${assignment_id}/${tabs[value]}`)

    // navigate(`/courses/${course_id}/${/assignment/${section_id}/168/pending`)
    navigate(`/courses/${course_id}/assignment/${section_id}/${assignment_id}/${tabs[value]}`);
  };

  const TabsOptions = [
    {
      id: 0,
      label: "About",
    },
    {
      id: 1,
      label: (
        <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"center"} gap={1}>
          <Typography>Pending</Typography>
          <Box
            sx={{
              borderRadius: 1,
              border: 0,
              backgroundColor: value == 1 ? "#63A0F2" : "#2F80ED",
              padding: "5px",
              color: "white",
            }}
          >
            1
          </Box>
        </Stack>
      ),
    },
    {
      id: 2,
      label: (
        <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"center"} gap={1}>
          <Typography>Graded</Typography>
          <Box
            sx={{
              borderRadius: 1,
              border: 0,
              backgroundColor: value == 2 ? "#63A0F2" : "#2F80ED",
              padding: "5px",
              color: "white",
            }}
          >
            100
          </Box>
        </Stack>
      ),
    },

    {
      id: 3,
      label: (
        <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"center"} gap={1}>
          <Typography>Unsubmited</Typography>
          <Box
            sx={{
              borderRadius: 1,
              border: 0,
              backgroundColor: value == 3 ? "#63A0F2" : "#2F80ED",
              padding: "5px",
              color: "white",
            }}
          >
            33
          </Box>
        </Stack>
      ),
    },
  ];

  return (
    <Stack p="20px" my={4} flexDirection={"column"}>
      <Box sx={{ px: 3 }}>
        <NormalTabs
          TabsOptions={TabsOptions}
          value={value}
          ContainerStyle="switch-container"
          TabStyle="switch-btn"
          onClick={(value) => handleTabChange(value)}
        />
      </Box>

      <TabPanel value={tab} indicies={["about"]}>
        <Stack gap={2}>
          <Stack
            spacing={2}
            direction="row"
            alignItems="center"
            justifyContent={{ md: "flex-end" }}
          >
            <PrimaryButton
              startIcon={<Box component="img" src={icons?.deleteIcon} />}
              text="Delete Assignment"
              variant={"contained"}
              style={{
                color: "white",
                background: redColor,
              }}
              click={() => {
                const requestData = {
                  course_id,
                  assignment_id,
                  action: () => navigate(`/courses/${course_id}`),
                };
                dispatch(DeleteAssignmentRequest({ ...requestData }));
              }}
            />
            <PrimaryButton
              text="save Assignment"
              variant={"contained"}
              startIcon={<Box component="img" src={icons?.newAssginmentIcon} />}
              style={{
                background: secondryColor,
                color: "white",
              }}
              click={formik.handleSubmit}
            />
          </Stack>

          <Stack component={Paper} p="30px 20px" gap={2}>
            <Typography color={primaryColor} fontSize="16px">
              Assignment
            </Typography>
            <FormTitle formik={formik} />
          </Stack>
          <Stack component={Paper} p="30px 20px">
            <form onSubmit={formik.handleSubmit}>
              <FormDetails formik={formik} />
            </form>
          </Stack>
        </Stack>
      </TabPanel>

      <TabPanel value={tab} indicies={["pending", "graded", "unsubmitted"]}>
        <AssignmentListPage tab={tab} />
      </TabPanel>
    </Stack>
  );
};

export default AssignmentTemplate;
