import {
    Paper,
    TableContainer,
    Typography,
    Stack,
    Divider,
    Table,
    TableBody,
    TableRow,
    TableCell,
    styled,
    tableCellClasses,
    Box,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import whiteTrash from "assets/icons/whiteTrash.svg";
  import { Navigate, useNavigate, useParams } from "react-router-dom";
  import { useDispatch } from "react-redux";
  import { toast } from "react-toastify";
import { DeleteAdminUserRequest } from "services/modules/AdminUsers";
import DeleteModal from "components/Atoms/Model/DeleteModel";
import { useThemePalette } from "common/hooks/theme_palette";
import PrimaryButton from "components/Atoms/Buttons/Primary/PrimaryButton";
import Vimeo from "@u-wave/react-vimeo";
import FormDetails from "components/Organisms/session/components/details";
import { useFormik } from "formik";
import { deleteCourseSessionRequest } from "services/modules/courses/Requests/sessions";

  
  const SessionDetailsTemplate = ({ session }) => {
      const { errorColor } = useThemePalette();
      const [openDeleteModel, setOpenDeleteModel] = useState(false)
      const {course_id,section_id}=useParams();
      const navigate = useNavigate()
      const dispatch = useDispatch();
      const [isProcessingFinished,setIsProcessingFinished]=useState(false)
      useEffect(() => {
        //console.log(session?.video_url)
        const fetchVideoInfo = async () => {
          try {
            const response = await fetch(`https://vimeo.com/api/oembed.json?url=${session?.video_url}`);
            if (response.ok) {
              setIsProcessingFinished(true)
              
            } else {
              setIsProcessingFinished(false)

              console.error('Failed to fetch video information:', response.statusText);
            }
          } catch (error) {
            setIsProcessingFinished(false)
            console.error('Error fetching video information:', error);
          }
        };
    
        fetchVideoInfo();
      }, [session]);
      const handleDeleteSession = () => {
          const requestData = {
            course_id,
            section_id,
            course_session_id: session?.course_session_id,
            action:()=>{
              navigate(`/courses/${course_id}`)
            }
          }
          dispatch(deleteCourseSessionRequest({ ...requestData }));
        }
      const formik = useFormik({
        initialValues: {
            is_blocked: session?.is_blocked||false,
            is_discussion_form_enabled: session?.is_discussion_form_enabled||false, 
            show_website: session?.show_website||false, 
          },
       
       
    
        enableReinitialize: true,
      
      });
  
    
      const handleCloseModel = () => {
          setOpenDeleteModel(false)
      }
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
      },
    }));
  
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
      "&:nth-of-type(even)": {
        backgroundColor: "#2f80ed17",
      },
      // hide last border
      "&:last-child td, &:last-child th": {
        border: 0,
      },
    }));
    const data = [
      { title: "Title", value: session?.title },
      { title: "Description", value: session?.text },
      { title: "Type of sesssion ", value:session?.content_type },
      (session.content_type_display==='Video'||session.content_type==='default')&&  { title: "Video", value: 
    isProcessingFinished ?
      <Box width={'100%'}>
        <Vimeo
          style={{width:'40%', height: '10%' }}
          className="vimeo"
        
          video={session?.video_url}
          controls
          responsive
        />
        </Box>:<Typography>Processing in Vimeo...</Typography>},
              { title: "Notes ", value:session?.notes },


    ];
      return (
          <Stack mb={3} p={2} spacing={2}>
         <PrimaryButton
          width={{
            sm: "50%",
            md: "15%",
          }}
          text=" Delete Session"
          startIcon={<img src={whiteTrash} alt='trash'></img>}
          variant="contained"
          style={{
            background: errorColor,
            color: "white",
            alignSelf: "flex-end",
            textTransform: 'none'
          }}
          click={() => {
              setOpenDeleteModel(true)
  
          }}
        />
      <Stack
        width={{
          sm: "100%",
          md: "90%",
          lg: "85%",
        }}
        padding={{ md: "20px" }}
        spacing={3}
      >
        <TableContainer component={Paper} sx={{ background: "white", borderRadius: "12px" }}>
          <Typography m={2} fontWeight="600" fontSize="16px">
            Session Details
          </Typography>
          <Divider style={{ width: "100%" }} />
  
          <Table aria-label="customized table">
            <TableBody>
              {data?.map((data, index) => {
                return (
                  <StyledTableRow key={index}>
                    <StyledTableCell sx={{ width: "10%", color: "#2C2D33" }}>
                      {data?.title}
                    </StyledTableCell>
                    <StyledTableCell width="25%" sx={{ color: "#6E7079" }}>
                      {data?.value}
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Stack component={Paper}
                      padding={{ md: "25px" }}
                      sx={{ borderRadius: "12px"}}
                      >
        <form >
          <FormDetails formik={formik}  />
        </form>
        </Stack>
              </Stack>
        
      <DeleteModal
                  open={openDeleteModel}
                  text={`Are you sure you want to delete "${session?.title}" Session`} handelDeleteRequest={handleDeleteSession} handleClose={handleCloseModel}
              >
              </DeleteModal>
          </Stack>
  
    );
  };
  
  export default SessionDetailsTemplate;
  