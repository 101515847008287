import Types from './Types';

const LOOKUPS_INIT_STATE = {
    lookups: {},
    schools: [],
    curriculums: [],
    classes: [],
    paginatedClasses: [],
    classSubjects: [],
    count: 0,
    classesCount: 0,
    isLoading: false
};

export function lookupsReducer(state = LOOKUPS_INIT_STATE, action) {
    const { type, payload } = action;
    switch (type) {
        case Types.GET_ADMIN_LOOKUPS_SUCCESS: {
            return {
                ...state,
                lookups: payload,
            };
        }
        case Types.ADMIN_LOOKUPS_LOADING: {
            return {
                ...state,
                isLoading: payload
            };
        }
        case Types.GET_CURRICULUMS_LOOKUPS_SUCCESS: {
            return {
                ...state,
                curriculums: payload?.data?.curriculums
            };
        }
        case Types.GET_CLASS_LOOKUPS_SUCCESS: {
            return {
                ...state,
                classes: payload?.data?.school_classes
            };
        }
        case Types.GET_SCROLL_CLASS_LOOKUPS_SUCCESS: {
            return {
                paginatedClasses: payload?.extra?.page_number == 1
                    ? payload?.data?.school_classes
                    : [...state.paginatedClasses, ...payload?.data?.school_classes],
                classesCount: payload?.extra?.total_count,
            };
        }
        case Types.GET_CLASS_SUBJECT_LOOKUPS_SUCCESS: {
            return {
                ...state,
                classSubjects: payload?.data?.subjects
            };
        }
        default: {
            return state;
        }
    }
}