import { combineReducers } from "redux";
import { coursesReducer } from "./modules/courses/Reducer";
import modelReducer from "./modules/models/Reducer";
import questionBanksReducer from "./modules/question-bank/Reducer";
import { lookupsReducer } from "./modules/lookups/Reducer";
import { quizReducer } from "./modules/quiz/Reducer";
import { studentReducer } from "./modules/students/Reducer";
import { instructorReducer } from "./modules/instructors/Reducer";
import { filesUploadReducer } from "./modules/uploadFiles/Reducer";
import { adminUsersReducer } from "./modules/AdminUsers/Reducer";
import authReducer from "./modules/authentication/Reducer";
import { sectionReducer } from "./modules/section/Reducer";

const rootReducer = combineReducers({
  model: modelReducer,
  courses: coursesReducer,
  questionBanks: questionBanksReducer,
  lookups: lookupsReducer,
  quiz: quizReducer,
  students: studentReducer,
  instructors: instructorReducer,
  sections:sectionReducer,
  files: filesUploadReducer,
  adminUsers:adminUsersReducer,
  auth:authReducer,

});

export default rootReducer;
