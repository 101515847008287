import React from "react";
import { IconButton, Menu, MenuItem, MenuList } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreHorizRounded";

const CActions = ({ id, index, data, actionsList }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const isActions = actionsList({ data, index }).length !== 0;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      {isActions ? (
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
      ) : (
        "-"
      )}

      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0, // Change the elevation value to adjust the box shadow
          style: {
            boxShadow: "rgba(0, 0, 0, 0.08) 0px 4px 32px !important",
            width: "130px",
          },
        }}
        sx={{boxShadow:'rgba(0, 0, 0, 0.08) 0px 4px 32px !important'}}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        classes={{ paper: "customMenu" }}
      >
        <MenuList autoFocusItem={open} id="composition-menu" aria-labelledby="composition-button">
          {actionsList({ id, index, data })?.map((action, index) => {
            return (
              action.condition(index) === true && (
                <MenuItem
                  key={index}
                  onClick={() => {
                    action.click();
                    handleClose();
                  }}
                >
                  {action.text}
                </MenuItem>
              )
            );
          })}
        </MenuList>
      </Menu>
    </div>
  );
};

export default CActions;
