import React, { useEffect, useMemo, useRef, useState } from "react";
import { TextField, Checkbox, Autocomplete } from "@mui/material";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import InfiniteScroll from "react-infinite-scroll-component";

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

function CustomListboxComponent(props) {
  const { children, ...other } = props;
  console.log(children.length);
  const listRef = useRef(null);

  const [isInitialLoad, setIsInitialLoad] = useState(true);

  useEffect(() => {
    if (listRef.current && isInitialLoad) {
      const itemHeight = listRef.current.scrollHeight / children.length;

      const newScrollPosition =
        props.pageNumber === 1 ? 0 : listRef.current.scrollHeight - 11 * itemHeight;

      listRef.current.scrollTop = newScrollPosition;

      setIsInitialLoad(false);
    }
  }, [children.length, isInitialLoad]);
  return (
    <InfiniteScroll
      dataLength={children.length}
      next={props.fetchMoreOptions}
      hasMore={props.hasMore}
      scrollableTarget={props.id}
      scrollThreshold={1}
    >
      <ul
        key={props.id}
        ref={(ulRef) => {
          listRef.current = ulRef;
        }}
        {...other}
        style={{ maxHeight: "200px" }}
      >
        {children}
      </ul>
    </InfiniteScroll>
  );
}

function MultiSelect({
  items,
  inputLabel,
  title,
  value,
  onchange,
  id,
  name,
  style,
  className,
  disabled,
  fetchNextPage,
  hasMore,
  count,
  onOpen,
  isLoading,
}) {
  const [pageNumber, setPageNumber] = useState(1);
  const [key, setKey] = useState("");
  console.log(items, "items");
  const fetchMoreOptions = useMemo(() => {
    return () => {
      const nextPage = pageNumber + 1;
      fetchNextPage(nextPage, 10);
      setPageNumber(nextPage);
    };
  }, [fetchNextPage, pageNumber]);
  const handleInputChange = (event, value, reason) => {
    if (reason === "input") {
      setKey(value);
      //setPageNumber(1); // Reset page number when a new search query is entered
    }
  };

  useEffect(() => {
    const timeId = setTimeout(() => {
      fetchNextPage(1, 10, key);
    }, 500);
    return () => clearTimeout(timeId);
  }, [key]);

  return (
    <Autocomplete
      disabled={disabled}
      multiple
      id={id}
      style={style}
      name={name}
      options={items}
      disableCloseOnSelect
      className={className}
      fullWidth
      value={value}
      onChange={onchange}
      onInputChange={handleInputChange}
      onOpen={onOpen && onOpen}
      loading={isLoading}
      getOptionLabel={(option) => option.name || option.title}
      renderOption={(props, option, { selected }) => (
        <li {...props} key={option.id}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.name || option.title}
        </li>
      )}
      renderInput={(params) => <TextField {...params} label={inputLabel} placeholder={title} />}
      ListboxComponent={(props) => (
        <CustomListboxComponent
          {...props}
          fetchMoreOptions={fetchMoreOptions}
          hasMore={pageNumber < Math.ceil(count / 10)}
          pageNumber={pageNumber}
        />
      )}
    />
  );
}

export default MultiSelect;
