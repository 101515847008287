import { Button, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { useThemePalette } from "common/hooks/theme_palette";
import ModelBox from "components/Atoms/Model/Model";
import MultiSelect from "components/Atoms/Select/MultiSelect";
import ScrollableAutocomplete from "components/Atoms/Select/scrollSelect";
import { useSelector } from "react-redux";
import { enrollStudentModel } from "services/modules/models/Actions";
import { useDispatch } from "react-redux";
import { GetAllStudentsRequest } from "services/modules/students";
import { useParams } from "react-router-dom";
import { useState ,useEffect} from "react";
import { getCourseJoinedUsersRequest ,AddCourseJoinedUsersRequest} from "services/modules/courses/Requests/joined-pendingUsers";
const AddModel=({
  title,
  btnTitle,
  subTitle})=>{
    const [pageNumber, setPageNumber] = useState(1)
    const [selectedOptions, setSelectedOptions] = useState([]);
    const { count, page_number } = useSelector((state) => state.students)

    const { course_id } = useParams();
    const joinedUsers = useSelector((state) => state.students.students)
    const [allStudents, setAllStudents] = useState([]);


    useEffect(() => {

        if (Array.isArray(joinedUsers) && joinedUsers.length > 0) {
          setAllStudents((prevAllStudents) => {
            const filteredJoinedUsers = joinedUsers.filter(joinedUser => 
              !prevAllStudents.some(student => student.student_id === joinedUser.student_id)
            );
        
            return [...prevAllStudents, ...filteredJoinedUsers];
          });
        }
    }, [joinedUsers]);
  
    const dispatch=useDispatch();
    const { orangeBtn } = useThemePalette();
    const openModel=useSelector((state) => state.model.isOpenEnrollStudent)
    const hasMore=allStudents.length < count
    const handleChange = (event, newValue) => {
      setSelectedOptions(newValue.map(student => student.student_id));
    };
  
    const handleClose = () => {
      dispatch(enrollStudentModel())
      setAllStudents([])
    }
    const fetchMoreOptions = (pageNumber, pageSize, key) => {
      //console.log('key is ',key)
      if (key != undefined) {
        dispatch(GetAllStudentsRequest({ filter: { page_number: pageNumber , page_size: pageSize, keyword: key } }))
  
      }
      else
      {
        dispatch(GetAllStudentsRequest({ filter: { page_number: pageNumber , page_size: pageSize } }))

      }
  
    }
  

    const handleAction = () => {
      const requestedData = {
        body: {
          student_ids: selectedOptions
        },
        course_id: course_id,
        action: () => {
          dispatch(getCourseJoinedUsersRequest({ filter: { course_id, status: 'joined', page_number: 1, page_size: 4 } }));
          handleClose()
  
        }
      }
      dispatch(AddCourseJoinedUsersRequest(requestedData))
    }
  

return(
    <ModelBox openVar={openModel} title={title} closeFunc={handleClose} titleStyle={{fontWeight:700}}>
        <Box p={1}>
        <Typography>{subTitle}</Typography>
      
      <MultiSelect items={allStudents} 
      hasMore={hasMore}
      pageNumber={pageNumber} 
      fetchNextPage={fetchMoreOptions} 
      count={count}
      onchange={handleChange} ></MultiSelect>
      <Stack flexDirection={'row'} justifyContent={'center'} alignItems={'center'} gap={4} pt={4}>
        <Button 
        onClick={handleClose}
        variant="outlined"
         sx={{textTransform:'none',color:orangeBtn,borderColor:orangeBtn}}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleAction} sx={{textTransform:'none',backgroundColor:orangeBtn,':hover':{backgroundColor:orangeBtn}}}>
          {btnTitle}
        </Button>

      </Stack>
      </Box>
      </ModelBox>
)
}
export default AddModel;