import { BaseURL, CoursesURL } from "../../utils/constant";
import { adminCoursesLoading } from "../Actions/courses"
import { reduxRequest } from "common/utils/reduxRequest";
import { addCourseJoinedUsersSuccess, getCourseJoinedUsersSuccess, getCoursePendingUsersSuccess, moveToJoinedUsersSuccess } from "../Actions/joined-pendingUsers";
import { makeFilterString } from "services/modules/utils/Helper";

export const getCourseJoinedUsersRequest = ({ ...variables }) => {
    const requestData = {
        isLoading: adminCoursesLoading,
        successFunction: getCourseJoinedUsersSuccess,
        url: `${BaseURL}v2/courses/joined_students${variables?.filter ? makeFilterString(variables?.filter) : null}`,
        method: "get",
        loadingType: "joined user list",
        ...variables,
    };
    return async (dispatch) => {
        reduxRequest({
            dispatch,
            ...requestData,
        });
    };
}
export const AddCourseJoinedUsersRequest = ({ ...variables }) => {
    const requestData = {
        isLoading: adminCoursesLoading,
        successFunction: addCourseJoinedUsersSuccess,
        url: `${BaseURL}v2/admin/courses/students?course_id=${variables?.course_id}`,
        method: "post",
        loadingType: "list",
        ...variables,
    };
    return async (dispatch) => {
        reduxRequest({
            dispatch,
            ...requestData,
        });
    };
}
export const getCoursePendingUsersRequest = ({ ...variables }) => {
    const requestData = {
        isLoading: adminCoursesLoading,
        successFunction: getCoursePendingUsersSuccess,
        url: `${BaseURL}v2/courses/joined_students${variables?.filter ? makeFilterString(variables?.filter) : null}`,
        method: "get",
        loadingType: "list",
        ...variables,
    };
    return async (dispatch) => {
        reduxRequest({
            dispatch,
            ...requestData,
        });
    };
}
export const moveToJoinedUsersRequest = ({ ...variables }) => {
    const requestData = {
        isLoading: adminCoursesLoading,
        successFunction: moveToJoinedUsersSuccess,
        url: `${BaseURL}v2/admin/carts/pay`,
        method: "post",
        loadingType: "list",
        ...variables,
    };
    return async (dispatch) => {
        reduxRequest({
            dispatch,
            ...requestData,
        });
    };
}