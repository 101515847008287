import { Checkbox, Divider, FormControlLabel, FormGroup, Stack, Typography } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers";
import { useThemePalette } from "common/hooks/theme_palette";
import BasicDatePicker from "components/Atoms/Input/DatePicker/DatePicker";
import UploadInput from "components/Atoms/Input/FileInput/UploadInput";
import TextFieldInput from "components/Atoms/Input/TextField/TextFieldInput";
import dayjs from "dayjs";
import React, { useState } from "react";
import { getPresignedLinkAPI, uploadFileAPI } from "services/modules/utils/Upload";

const FormDetails = ({ formik }) => {
  const { inputBg } = useThemePalette();
  const [videoFileName, setVideoFileName] = useState("");

  const handleCheckboxChange = (name, checked) => {
    formik.setFieldValue(name, checked);
  };
  //console.log(formik?.values)

  return (
    <Stack gap={4}>
      <Typography fontWeight={500} fontSize={16} color='#45464E'>Configurations</Typography>
      <Divider/>
      <FormGroup>
        <FormControlLabel
          control={<Checkbox checked={formik?.values.is_blocked} onChange={(e) => handleCheckboxChange('is_blocked', e.target.checked)} />}
          label="Block Session"
        />
        <FormControlLabel
          control={<Checkbox checked={formik?.values.is_discussion_form_enabled} onChange={(e) => handleCheckboxChange('is_discussion_form_enabled', e.target.checked)} />}
          label="Is Discussion form enabled"
        />
        <FormControlLabel
          control={<Checkbox checked={formik?.values.show_website} onChange={(e) => handleCheckboxChange('show_website', e.target.checked)} />}
          label="Show on website"
        />
      </FormGroup>
    </Stack>
  );
};

export default FormDetails;
