import { Box } from '@mui/material';
import { Formik } from 'formik';

export default function Form({ children, initialValues, onSubmit, validationSchema, sx }) {
  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      <Box sx={sx}>{children}</Box>
    </Formik>
  );
}
