import { Divider, Stack, Typography } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreHorizRounded";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { icons } from "../../../../assets/AssetHelper";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useSelector } from "react-redux";
import { useState } from "react";
import { deleteCourseSectionRequest, getCourseSectionsRequest } from "services/modules/courses/Requests/sections";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteModal from "components/Atoms/Model/DeleteModel";
import { useNavigate } from "react-router-dom";


const SectionMainData = ({ data,setDisplay,index }) => {
  const course = useSelector((state) => state.courses.course);
  const{course_id}=useParams();
  const dispatch=useDispatch();
  const navigate=useNavigate()
  const [openDeleteModel,setOpenDeleteModel]=useState(false)

  // //console.log(data?.sessions?.length, 'SectionMainData')
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    event.stopPropagation();
    if (anchorEl === event.currentTarget) setAnchorEl(null);
    else setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };
  const opendeleteSectionModel=(event)=>{
    event.stopPropagation();
    setOpenDeleteModel(true)
    setAnchorEl(null);

  }
  const handleCloseModel=(event)=>{
    event.stopPropagation();
    setOpenDeleteModel(false)



  }
  const handleDelete=(event)=>{
    event.stopPropagation();
    const requestData={
      course_id,
      section_id:data?.section_id,
      action:()=>{
        dispatch(getCourseSectionsRequest({course_id}))
      }
    }
    setOpenDeleteModel(false)
    dispatch(deleteCourseSectionRequest(requestData))

  }
  const handleEditClick=(event)=>{
    event.stopPropagation();
    //console.log('yes')

  navigate(`/section/${course_id}/${data?.section_id}`)
  }
  // //console.log(data);
  return (
    <Stack sx={{ width: "98%",cursor:'pointer' }} alignItems="center" bgcolor="white" borderRadius="12px">
      <Stack 
       direction="row"
        alignItems="flex-start"
        width={"100% !important"}
       justifyContent="space-between">
        <Stack alignItems="center" direction="row">
          <DragIndicatorIcon sx={{ cursor: "move", color: "#BDBDBD" }} />
          <Typography mx="20px" fontWeight="600" fontSize="20px">
            {data?.title}
          </Typography>
        </Stack>

        <Stack alignItems="center" direction="row">
          <Typography fontSize="14px" mx={1}>
            {" "}
            {data?.items?.length}
          </Typography>
          <img src={icons.SourceIcon} alt="" />

          <div>
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? "long-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
              style={{ marginLeft: "20px" }}
            >
              <MoreVertIcon />
            </IconButton>

            <Menu
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  padding: "10px",
                  width: "20ch",
                },
              }}
            >
              <MenuItem onClick={opendeleteSectionModel}>
                <Typography color={"error"}>Delete</Typography>
              </MenuItem>
              <MenuItem onClick={handleEditClick}>
                <Typography >Edit</Typography>
                </MenuItem>
             {/* <Divider sx={{ my: 0.5 }} />
             <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "17px",
                  color: "#828282",
                  paddingInline: "15px",
                }}
              >
                Move to
              </Typography>
              <Typography
                sx={{
                  fontWeight: "500",
                  fontSize: "16px",
                  lineHeight: "19px",
                  color: "#4F4F4F",
                  paddingInline: "15px",
                  paddingBlock: "5px",
                }}
              >
                Bottom
              </Typography>
              <MenuItem onClick={handleClose} sx={{ paddingBlock: "15px" }}>
                <Stack>
                  {course?.sections?.map((section, idx) => {
                    if (data?.title !== section?.title)
                      return (
                        <Typography
                          key={idx}
                          sx={{
                            fontWeight: "500",
                            fontSize: "16px",
                            lineHeight: "19px",
                            color: "#4F4F4F",
                          }}
                        >
                          {section?.title}
                        </Typography>
                      );
                  })}
                </Stack>
                </MenuItem>*/}
            </Menu>
          </div>
          <ExpandMoreIcon></ExpandMoreIcon>
          <DeleteModal
                  open={openDeleteModel}
                  text={`Are you sure you want to delete "${data?.title}" section`} handelDeleteRequest={handleDelete} handleClose={handleCloseModel}
              >
              </DeleteModal>

        </Stack>
      </Stack>
    </Stack>
  );
};

export default SectionMainData;
