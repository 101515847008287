import { useState } from "react";
import StudentsListTemplate from "Templates/users/students/StudentsList.Template";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  ActiveStudentRequest,
  DeleteStudentRequest,
  GetAllStudentsRequest,
  SuspendStudentRequest,
} from "services/modules/students";
import { Button, Box, Typography, Stack, Avatar } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import DeleteModal from "components/Atoms/Model/DeleteModel";
import { toast } from "react-toastify";
import { editStudentUserModel } from "services/modules/models/Actions";
import { Cancel } from "@mui/icons-material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useThemePalette } from "common/hooks/theme_palette";
import CActions from "components/Molecules/Menus/ActionList";
import pen from "assets/icons/pen.svg";
import trash from "assets/icons/trash.svg";
const StudentsListPage = ({ setLayoutData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [perPage, setPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [selected, setSelected] = useState({
    id: "",
  });
  const [selectedRow, setSelectedRow] = useState(null);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [openSuspendModel, setOpenSuspendModel] = useState(false);
  const [openReactiveModel, setOpenReactiveModel] = useState(false);

  const [checked, setChecked] = useState([false]);
  const students = useSelector((state) => state.students.students);
  const count = useSelector((state) => state.students.count);
  const currentPage = useSelector((state) => state.students.page_number);
  const loading = useSelector((state) => state.students.isLoading);
  const [page, setPage] = useState(currentPage);
  const isOpenEditStudentUser = useSelector((state) => state.model.isOpenEditStudentUser);
  const { easy, errorColor } = useThemePalette();
  const onRowClick = (data) => {
    navigate(`/users/student/${data?.row?.values?.student_id}`);
  };
  useEffect(() => {
    if (!isOpenEditStudentUser) {
      setSelectedRow(null);
    }
  }, [isOpenEditStudentUser]);

  const viewData = (data) => {
    setSelected(data);
  };

  useEffect(() => {
    dispatch(GetAllStudentsRequest({ filter: { page_number: page, page_size: 10 } }));
  }, [page]);

  const handleDelete = () => {
    const requestedData = {
      student_id: selectedRow?.student_id,

      action: () => {
        toast.success(`Student ${selectedRow?.name} deleted successfully`);
        setSelectedRow(null);
        setOpenDeleteModel(false);
        dispatch(GetAllStudentsRequest({ filter: { page_number: page, page_size: 10 } }));
      },
    };

    dispatch(DeleteStudentRequest(requestedData));
  };
  const handleSuspened = () => {
    const requestedData = {
      body: {
        user: {
          status: "suspended",
        },
      },
      student_id: selectedRow?.student_id,
      action: () => {
        toast.success(`Student ${selectedRow?.name} suspended successfully`);
        setSelectedRow(null);
        // openSuspendModel(false)
        handleCloseModel(setOpenSuspendModel);
        dispatch(GetAllStudentsRequest({ filter: { page_number: page, page_size: 10 } }));
      },
    };
    dispatch(SuspendStudentRequest(requestedData));
  };
  const handleReactive = () => {
    const requestedData = {
      body: {
        user: {
          status: "active",
        },
      },
      student_id: selectedRow?.student_id,
      action: () => {
        toast.success(`Student ${selectedRow?.name} activeted successfully`);
        handleCloseModel(setOpenReactiveModel);
        dispatch(GetAllStudentsRequest({ filter: { page_number: page, page_size: 10 } }));
      },
    };
    // console.log(requestedData,'requestedData');
    dispatch(ActiveStudentRequest(requestedData));
  };
  const handleCloseModel = (setState) => {
    setState(false);
  };

  const handleEditButton = (data) => {
    dispatch(editStudentUserModel());
    setSelectedRow(data);
  };
  //modal three dots
  const contextMenuActions = ({ data, index }) => {
    const isActive = data?.status == "active";
    return [
      {
        condition: () => {
          return true;
        },
        click: () => {
          // setOpenDeleteModel(true)
          setSelectedRow(data);
          handleEditButton(data);
        },
        text: "Edit",
      },
      {
        condition: () => {
          return true;
        },
        click: () => {
          setSelectedRow(data);
          setOpenDeleteModel(true);
        },
        text: "Delete",
      },
      {
        condition: () => isActive, // Use isActive directly for condition

        click: () => {
          setSelectedRow(data);
          setOpenSuspendModel(true);
        },
        text: <Typography color={errorColor}>Suspend</Typography>,
      },
      {
        condition: () => !isActive, // Use negation of isActive for condition

        click: () => {
          setSelectedRow(data);
          setOpenReactiveModel(true);
        },
        text: <Typography color={easy}>Activate</Typography>,
      },
    ];
  };
  // const Actions = (id, elmIndex, data) => {
  //   return <CActions id={id} index={elmIndex} data={data} actionsList={actionsList} />;
  // // };

  //
  const mappedData = students?.map((data, index) => {
    const isActive = data?.status == "active";
    return {
      ...data,
      student_name: (
        <>
          <Link
            to={`/users/student/${data?.student_id}`}
            style={{ textDecoration: "none", color: "#6E7079" }}
          >
            <Stack direction="row" alignItems="center" justifyconten="center">
              <Avatar
                alt={data?.name}
                src={data?.profile_picture_url}
                sx={{ width: 30, height: 30 }}
              />
              <Typography mx="20px" textTransform="capitalize">
                {data && data.name && data.name.length > 7
                  ? data.name.slice(0, 7) + "..."
                  : data.name}
              </Typography>
            </Stack>
          </Link>
        </>
      ),
      is_phone_verified: (
        <Stack sx={{ display: "flex", alignItems: "center" }}>
          {data?.is_phone_verified ? (
            <CheckCircleIcon style={{ color: "#27AE60" }} />
          ) : (
            <Cancel style={{ color: "#EB5757" }}> </Cancel>
          )}
        </Stack>
      ),
      student_status: (
        <>
          <Typography color={isActive ? easy : errorColor} sx={{ textTransform: "capitalize" }}>
            {data?.status}
          </Typography>{" "}
        </>
      ),
      school: (
        <Typography>
          {" "}
          {data && data.school && data.school.length > 15
            ? data.school.slice(0, 15) + "..."
            : data.school}
        </Typography>
      ),
      school_class: (
        <Typography
          // to={`/courses/${data?.school_class}`}
          style={{ textDecoration: "none", color: "#6E7079" }}
        >
          {data?.school_class ? data?.school_class : "-"}
        </Typography>
      ),
      title: (
        <Link to={`/courses/${data?.id}`} style={{ textDecoration: "none", color: "#6E7079" }}>
          {data?.title}
        </Link>
      ),
      country_code: <Typography textAlign={"center"}>{data?.country_code}</Typography>,
      //  Actions:(Actions(data.student_id,index,data)),
      Actions: (
        <Stack direction="row" style={{ gap: "4px" }}>
          <Button
            sx={{ textTransform: "none", color: "black", border: "solid 1px #EDEFF1"}}
            onClick={(e) => {
              e.stopPropagation();
              handleEditButton(data);
            }}
          >
            <img src={pen}></img>Edit
          </Button>
          <Button
            sx={{
              textTransform: "none",
              color: "#EB001B",
              border: "solid 1px #EDEFF1",
            }}
            onClick={(e) => {
              e.stopPropagation();
              setOpenDeleteModel(true);
              setSelectedRow(data);
            }}
          >
            {" "}
            <img src={trash}></img> Delete
          </Button>
          <Button
            sx={{
              textTransform: "none",
              color: isActive ? errorColor : easy,
              border: "solid 1px #EDEFF1",
            }}
            onClick={(e) => {
              e.stopPropagation();
              setSelectedRow(data);
              isActive ? setOpenSuspendModel(true) : setOpenReactiveModel(true);
            }}
          >
            {isActive ? "Suspend" : "Active"}
          </Button>
        </Stack>
      ),
    };
  });
  const data = [
    {
      id: "0",
      name: "test",
      type: "test",
      email: "test",
      phone_number: "test",
      subscribed_emails: "test",
      social_verified: "test",
    },
    {
      id: "1",
      name: "test",
      type: "test",
      email: "test",
      phone_number: "test",
      social_verified: "test",
    },
  ];

  useEffect(() => {
    setLayoutData({
      title: "Students",
      breadcrumb: [
        { title: "Home", path: "/", active: true },
        { title: "Students", path: "/users/students", active: false },
      ],
    });
  }, []);

  return (
    <Box>
      <StudentsListTemplate
        setPage={setPage}
        perPage={perPage}
        setPerPage={setPerPage}
        pageNumber={pageNumber}
        checked={checked}
        setChecked={setChecked}
        viewData={viewData}
        data={mappedData}
        page={page}
        setPageNumber={setPage}
        count={count}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        onRowClick={onRowClick}
        contextMenuActions={contextMenuActions}
      />
      <DeleteModal
        open={openDeleteModel}
        text={`Are you sure you want to delete "${selectedRow?.name}" student ?`}
        handelDeleteRequest={handleDelete}
        handleClose={(_) => handleCloseModel(setOpenDeleteModel)}
      />
      <DeleteModal
        open={openSuspendModel}
        text={`Are you sure you want to Suspended "${selectedRow?.name}" student ?`}
        handelDeleteRequest={handleSuspened}
        handleClose={(_) => handleCloseModel(setOpenSuspendModel)}
        btnTitle="Suspend"
        isLoading={loading?.loading && loading?.type == "suspend-student"}
      />
      <DeleteModal
        open={openReactiveModel}
        text={`Are you sure you want to Activate "${selectedRow?.name}" student ?`}
        handelDeleteRequest={handleReactive}
        handleClose={(_) => handleCloseModel(setOpenReactiveModel)}
        btnTitle="Activate"
        bgcolor={easy}
        isLoading={loading?.loading && loading?.type == "active-student"}
      />
    </Box>
  );
};

export default StudentsListPage;
