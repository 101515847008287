import * as Yup from "yup";
import { useFormik } from "formik";
import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import AssignmentTemplate from "Templates/Courses/Assignment.Template";
import {
  ShowAssignmentDetailsRequest,
  UpdateAssignmentRequest,
} from "services/modules/courses/Requests/assignment";

const AssignmentPage = ({ setLayoutData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const assignment = useSelector((state) => state.courses.assignment); // what ?? el mafrod fe3lan dah el assignment
  // //console.log(assignment)
  const { section_id, assignment_id, course_id } = useParams();
  useEffect(() => {
    setLayoutData({
      title: "Assignment",
      breadcrumb: [
        { title: "Home", path: "/", active: true },
        { title: "Courses", path: "/courses", active: true },
        {
          title: "Course Detail",
          path: `/courses/${course_id}`,
          active: true,
        },
        { title: "Assignment Details", active: false },
      ],
    });
  }, [course_id]);

  useEffect(() => {
    if (assignment_id) dispatch(ShowAssignmentDetailsRequest({ assignment_id, course_id }));
  }, [assignment_id]);

  const formik = useFormik({
    // initialValues: assignment_id ? {...assignment,due_date:null,due_date_time:null,late_due_date:null,late_due_date_time:null} : {}, //until backend fix that
    initialValues: assignment_id ? {...assignment,attachments:[]} : {}, 
    validationSchema: Yup.object().shape({}),
    enableReinitialize: true,
    onSubmit: (values) => {
      const data = { assignment: { ...values }, attachments: [] };
      const action = () => {
        // navigate(`/courses/${course_id}`);
        //console.log('done')
      };
        const requestData = {
          course_id,
          assignment_id,
          body: data,
          action: action,
        };
        dispatch(UpdateAssignmentRequest({ ...requestData }));
    },
  });
  return <AssignmentTemplate formik={formik} />;
};

export default AssignmentPage;
