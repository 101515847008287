import { CoursesURL } from "../../utils/constant";
import { getCourseCouponsSuccess } from "../Actions/coupons";
import { adminCoursesLoading } from "../Actions/courses"
import { getCourseInstructorsSuccess } from "../Actions/instrucrors"
import { reduxRequest } from "common/utils/reduxRequest";

export const getCourseCouponsRequest = ({ ...variables }) => {
    const requestData = {
        isLoading: adminCoursesLoading,
        successFunction: getCourseCouponsSuccess,
        url: `${CoursesURL}/coupons?course_id=${variables?.course_id}`,
        method: "get",
        loadingType: "list",
        ...variables,
    };
    return async (dispatch) => {
        reduxRequest({
            dispatch,
            ...requestData,
        });
    };
}