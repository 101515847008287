import { Box, Divider, Stack, Typography } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import React from "react";

const QuestionsLevelsStatistics = () => {
  const { hard, medium, easy, lightBlack } = useThemePalette();
  const levels = [
    {
      numOfQuestions: 1,
      title: "Hard Qestions",
      percentage: "10%",
      color: hard,
    },
    {
      numOfQuestions: 15,
      title: "Medium Qestions",
      percentage: "10%",
      color: medium,
    },
    {
      numOfQuestions: 10,
      title: "Easy Qestions",
      percentage: "10%",
      color: easy,
    },
  ];
  return (
    <Box
      sx={{
        width: "100%",
        height: "230px",
        background: "#FFFFFF",
        borderRadius: "9px",
        padding: "17px",
      }}
    >
      <Typography fontWeight="600" fontSize="20px">
        Questions level statistics
      </Typography>
      <Stack flexDirection="row" p={"9px"} justifyContent="space-evenly">
        {levels.map((data, index) => {
          return (
            <>
              <Stack key={index} justifyContent="space-evenly" alignItems="center" gap={1} mt={1}>
                <Typography fontWeight="600" fontSize="40px" color={data.color}>
                  {data?.numOfQuestions}
                </Typography>
                <Typography
                  color={lightBlack}
                  fontWeight="400"
                  fontSize="14px"
                  textAlign="center"
                  width="59px"
                >
                  {data?.title}
                </Typography>
                <Stack textAlign="center">
                  <Typography color={lightBlack} fontWeight="600" fontSize="12px">
                    {" "}
                    {data.percentage}
                  </Typography>
                  <Typography color={lightBlack} fontWeight="400" fontSize="10px">
                    of the quiz
                  </Typography>
                </Stack>
              </Stack>
              {index != levels?.length - 1 && (
                <Divider
                  orientation="vertical"
                  flexItem
                  style={{ height: "150px", marginTop: "20px" }}
                ></Divider>
              )}
            </>
          );
        })}
      </Stack>
    </Box>
  );
};

export default QuestionsLevelsStatistics;
