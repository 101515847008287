import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { withTranslation } from "react-i18next";
import "./NormalTabs.css";

function NormalTabs(props) {

    function a11yProps(index) {
        return {
            id: `vertical-tab-${index}`,
            'aria-controls': `vertical-tabpanel-${index}`,
        };
    }

    return (
        <div>
            <Tabs
                orientation={props.orientation}
                variant="scrollable"
                value={props.value}
                onChange={props.onChange}
                aria-label="Vertical tabs example"
                className={props.ContainerStyle}
                TabIndicatorProps={ {style: props.tabStyles?{background:props.tabStyles[props.value]?.borderBottomColor}:null}}
            >
                {props.TabsOptions.map((option, index) => (
                    <Tab 
                        
                        className={props.TabStyle}
                        icon={option.icon}
                        label={option.label}
                        disabled={option.disabled}
                        {...a11yProps(index)}
                        onClick={() => props.onClick(index)}
                        style={props.tabStyles ? props.tabStyles[index] : {}} // Apply custom styles if provided
                        key={index}
                    />
                ))}
            </Tabs>
        </div>
    );
}

export default withTranslation()(NormalTabs);