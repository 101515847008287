import Types from "../Types";
// assignment
export const getAllCourseAssignmentsSuccess = (payload) => ({
  type: Types.GET_ALL_COURSE_ASSIGNMENTS_SUCCESS,
  payload,
});
export const getSectionCourseAssignmentsSuccess = (payload) => ({
  type: Types.GET_COURSE_SECTION_ASSIGNMENTS_SUCCESS,
  payload,
});

export const createAssignmentSuccess = (payload) => ({
  type: Types.CREATE_ASSIGNMENT_SUCCESS,
  payload,
});

export const ShowAssignmentDetailsSuccess = (payload) => ({
  type: Types.SHOW_ASSIGNMENT_DETAILS_SUCCESS,
  payload,
});

export const updateAssignmentSuccess = (payload) => ({
  type: Types.UPDATE_ASSIGNMENT_SUCCESS,
  payload,
});

export const deleteCourseAssignmentSuccess = (payload) => ({
  type: Types.DELETE_COURSE_ASSIGNMENT_SUCCESS,
  payload,
});


export const getAssignmentSubmissionsSuccess = (payload) => ({
  type: Types.GET_ASSIGNMENT_SUBMISSIONS_SUCCESS,
  payload,
});

export const getStudentAssignmentsSuccess = (payload) => ({
  type: Types.GET_STUDENT_ASSIGNMENTS_SUCCESS,
  payload,
});
