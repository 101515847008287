import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import "../../../components/Organisms/quiz/styles/mainStyles.css"; // to be reviewed
import {
  GetQuestionRequest,
  ShowCurrenQuizRequest,
  UpdataQuizRequest,
} from "services/modules/quiz"; //old apis
import QuizTemplate from "Templates/Courses/quiz/Quiz.Template";
import { Button, ClickAwayListener, MenuItem, MenuList, Paper, Popper } from "@mui/material"; //click away ??
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useRef } from "react";
import { useFormik } from "formik";
import MoreVertIcon from "@mui/icons-material/MoreHorizRounded";

const QuizPage = ({ setLayoutData }) => {
  const dispatch = useDispatch();

  const { quiz_id, course_id } = useParams();
  const navigate = useNavigate();
  const { questions, quizData,submissions,counts } = useSelector((state) => state.quiz);

  const [cards, setCards] = useState([]);
  useEffect(()=>{
    if(questions)
    {
      setCards(questions)

    }
  },[questions])

  const anchorRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event, index, data) => {
    anchorEl == index ? setAnchorEl(null) : setAnchorEl(index);
  };
  const handleClose = () => {
    setAnchorEl(null);
  }; //some modal i'm not aware of

  const formik = useFormik({
    initialValues: quizData ? quizData : {}, 
    validationSchema: Yup.object().shape({
      title: Yup.string().trim().required('Quiz title is required'),
      description: Yup.string().required('Description is required'),  
      start_date: Yup.date().nullable().required('Start date is required'),
      due_date: Yup.date().nullable().required('Due date is required')
        .when(['start_date'], (start_date, schema) => {
          return start_date && schema.min(start_date, 'Due date must be after start date');
        }),
        
    }),
    enableReinitialize: true,
    onSubmit: (values) => {


  },
  });
  //quiz responses list
  const mappedResponses = submissions?.map((data, index) => { 
    return {
      ...data,
      Actions: (
        <div style={{ position: "relative" }}>
          <Button
            id="demo-positioned-button"
            aria-controls={open ? "demo-positioned-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={(e) => handleClick(e, index, data)}
          >
            <MoreVertIcon />
          </Button>
          <Popper
            open={anchorEl == index ? true : false}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            className="popper-custom-style"
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                >
                  <MenuItem onClick={() => navigate(`/courses/${course_id}/quiz/${quiz_id}/responses/${data.quiz_attempt_id}`)}>
                    View
                  </MenuItem>
                  <MenuItem onClick={handleClose}>Delete</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Popper>
        </div>
      ),
    };
  });

  useEffect(() => {
      dispatch(ShowCurrenQuizRequest({ course_id, quiz_id }));
      dispatch(GetQuestionRequest({ course_id, quiz_id }));
  }, [quiz_id]); 
  useEffect(() => {
    setLayoutData({
      title: "Courses",
      breadcrumb: [
        { title: "Home", path: "/", active: true },
        { title: "Courses", path: "/courses", active: true },
        {
          title: "Course Detail",
          path: `/courses/${course_id}`,
          active: true,
        },
        { title: "New Quiz", path: `/quiz/${course_id}`, active: false },
      ],
    });
  }, [course_id]);// leave it for now

  useEffect(() => {
    if(Object.keys(formik.values).length !== 0)
    {

      const timeId = setTimeout(() => {
        const requestData = {
          course_id,
          quiz_id,
          body: {
            quiz: {
              ...formik?.values,
              quiz_configuration:{...formik?.values.quiz_configuration,
                passing_percentage:formik?.values.quiz_configuration.passing_grade,
                final_grade_based_on:formik?.values.quiz_configuration?.final_grade_based_on.value}
            },
          },
        };
          dispatch(UpdataQuizRequest({ ...requestData }));  
    }, 2000);
    return () => clearTimeout(timeId);
  }

  }, [formik?.values?.title ,
    formik?.values?.is_posted,
     formik?.values?.description,
     formik?.values?.start_date,
     formik?.values?.due_date,
     formik?.values?.quiz_configuration?.duration,
     formik?.values?.quiz_configuration?.max_no_of_attempts,
     formik?.values?.quiz_configuration?.passing_grade,
     formik?.values?.quiz_configuration?.num_of_questions_per_page,
     formik?.values?.quiz_configuration?.american_system_penalty,
     formik?.values?.quiz_configuration?.correcting_system,
     formik?.values?.quiz_configuration?.block_following_resources,
     formik?.values.quiz_configuration?.final_grade_based_on.value,
    ]); // if any value in the formik changes , then we send an update request in general since it's the same request



  
  return (
    <QuizTemplate
      mappedData={mappedResponses}
      cards={cards}
      setCards={setCards}
      formik={formik}
    />
  );
};

export default QuizPage;
