import Types from "./Types";
//loading
export const courseSectionLoading = (payload) => ({
    type: Types.COURSE_SECTION_LOADING,
    payload,
  });
// ADD SECTION
export const addSectionToCourseSuccess = (payload) => ({
  type: Types.ADD_SECTION_TO_COURSE_SUCCESS,
  payload,
});
// Delete Secrtion
export const deleteSectionFromCourseSuccess = (payload) => ({
    type: Types.DELETE_SECTION_FROM_COURSE_SUCCESS,
    payload,
  });
  //Edit section
  export const editSectionSuccess = (payload) => ({
    type: Types.EDIT_SECTION_SUCCESS,
    payload,
  });
  export const showSectionSuccess = (payload) => ({
    type: Types.SHOW_SECTION_dETAILS_SUCCESS,
    payload,
  });
  