import { Avatar, Divider, Stack, Typography, Grid,Box} from "@mui/material";
import React, { useState } from "react";
import EditableTable from "components/Molecules/Table/editableTable";
import TextFieldInput from "components/Atoms/Input/TextField/TextFieldInput";
import { useThemePalette } from "common/hooks/theme_palette";

const EvaluationForm = ({ formik }) => {
    const { inputBg } = useThemePalette();

  return (
    <Stack gap={2}>
      <Stack flexDirection={'row'} gap={1} alignItems={'center'}>
        <Avatar alt="Remy Sharp" src="" sx={{ width: 56, height: 56 }}/>
        <Stack flexDirection={'column'}>
          <Typography sx={{fontWeight:600}}>Researches on Geometrical Optics & Waves Assignment</Typography>
          <Typography sx={{color:'#6E7079'}}>Janet Adebayo</Typography>
        </Stack>
      </Stack>
      <Divider/>

      <EditableTable formik={formik}/>

      <Grid container p={2} rowGap={5}>
        <Grid item xs={3}>
        <Typography>Question :</Typography>

        </Grid>

        <Grid item xs={9}>
            <Typography>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, 
                sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, 
                At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren,
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor 
                invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. 
                At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, 
                no sea takimata sanctus est Lorem ipsum dolor sit amet.Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                sed diam nonumy eirmod tempor
                </Typography>
        </Grid>

        <Grid item xs={2.80}>
        <Typography>Answer :</Typography>
        </Grid>


        <Grid item xs={9.20}>
            <Box sx={{borderRadius:'5px',border:1,p:2}}>

            <Typography>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, 
                sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, 
                At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren,
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor 
                invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. 
                At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, 
                no sea takimata sanctus est Lorem ipsum dolor sit amet.Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                sed diam nonumy eirmod tempor
                </Typography>

            </Box>

        </Grid>

        <TextFieldInput
            type="number"
            label="Grade"
            sx={{
              background: inputBg,
              padding: "0",
              borderRadius: "8px",
              "& fieldset": {
                border: "none",
              },
            }}
            placeholder="Enter here"
            name="grade"
            onChange={formik.handleChange}
            value={formik.values.grade}
            error={formik.errors.grade}
            helperText={formik.errors.grade}
      />



      </Grid>
    </Stack>
  );
};

export default EvaluationForm;
