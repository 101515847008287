import { reduxRequest } from "common/utils/reduxRequest";
import { CoursesURL, Organization_name } from "../../utils/constant";
import { deleteCourseQuizSuccess, getAllCourseQuizzesSuccess, getCourseSectionQuizzesSuccess, getStudentQuizzesSuccess } from "../Actions/quizes";
import { adminCoursesLoading } from "../Actions/courses";
import { makeFilterString } from "services/modules/utils/Helper";
export const deleteCourseQuizRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: adminCoursesLoading,
    successFunction: deleteCourseQuizSuccess,
    url: `${CoursesURL}/${variables?.course_id}/quizzes/${variables?.quiz_id}`,
    method: "delete",
    loadingType: "deleteCourseQuiz",
    ...variables,
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const getAllCourseQuizzesRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: adminCoursesLoading,
    successFunction: getAllCourseQuizzesSuccess,
    url: `${CoursesURL}/${variables?.course_id}/quizzes`,
    method: "get",
    loadingType: "getCourseQuizz",
    ...variables,
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
export const getCourseSectionQuizzesRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: adminCoursesLoading,
    successFunction: getCourseSectionQuizzesSuccess,
    url: `${CoursesURL}/sections/${variables?.section_id}/quizzes?course_id=${variables?.course_id}`,
    method: "get",
    loadingType: "getCourseQuizz",
    ...variables,
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const getStudentQuizzesRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: adminCoursesLoading,
    successFunction: getStudentQuizzesSuccess,
    url: `v2/users/${variables?.user_type}/${variables?.student_id}/quizzes${variables?.filter ? makeFilterString(variables?.filter) : null}`,
    method: "get",
    loadingType: "getStudentQuizz",
    ...variables,
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};