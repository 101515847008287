import React from "react";
import { Box, Divider, Stack,Modal,Button ,Typography} from "@mui/material";
import { useEffect, useState } from "react";

import TextFieldInput from "../../../Atoms/Input/TextField/TextFieldInput";
import { useThemePalette } from "../../../../common/hooks/theme_palette";
import { icons } from "../../../../assets/AssetHelper";
import "../styles/mainStyles.css";
import { UpdataChoicesRequest } from "../../../../services/modules/quiz";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import SelectBox from "components/Atoms/Select/Select";
import Answers from "components/Molecules/quiz/answers";
import QuestionAddOptions from "components/Molecules/quiz/questionAddOptions";
import QuestionActions from "components/Molecules/quiz/actions";
import { updateQuestion } from "../utils/question_func";
import SearchableSelect from "components/Atoms/Select/SearchableSelect";
import AnswerReasons from "../components/answerReasons";

const QuestionCard = ({ data, setCards, index, cards, display }) => {
  const dispatch = useDispatch();
  const { isLoading, quizData } = useSelector((state) => state.quiz);
  const { inputBg } = useThemePalette();
  const [optionsCount, setOptionsCount] = useState(data?.quiz_question_choices?.length || 0);
  const [optionsData, setOptionsData] = useState(cards?.data?.quiz_question_choices || []);
  const [optionFocus, setOptionFocus] = useState([false]);
  const [questionDegree, setQuestionDegree] = useState("");
  const [selectedChoice, setSelectedChoice] = useState(null);
// modal for answer hint,correct and wrong answer
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // 
  const { course_id, quiz_id: quiz } = useParams();
  const question_quiz_id = cards[index]?.data?.quiz_question_id;
  const quiz_id = quiz ? quiz : quizData?.quiz_id;
  const diffcultyOptions=[
    {
      id: "0",
      name: "Hard",
    },
    {
      id: "1",
      name: "Medium",
    },
    {
      id: "2",
      name: "Easy",
    },
  ];

  const handelOptions = diffcultyOptions?.map((val) => {
    return {
      label: val?.name,
      value: val?.id,
      color: val?.color,
    };
  });

  // handle question data change 

  const handleFieldChange = (field, e) => {
    const newCards = [...cards];
    newCards[index].data[field] = e.target.value; 
    setCards(newCards);
  };
    useEffect(() => {
    const timeId = setTimeout(() => {
      updateQuestion({
        dispatch,
        value:cards[index]?.data?.text_question,
        cards,
        setCards,
        index,
        display,
        course_id,
        quiz_id,
        question_quiz_id,
      })
    }, 1000);
    return () => clearTimeout(timeId);
  }, [cards[index]?.data?.text_question]);

  // 

  // adding choices into optionsData
  useEffect(() => {
    setOptionsData(cards[index]?.data?.quiz_question_choices);
  }, [cards[index]?.data?.quiz_question_choices]);


  useEffect(() => {
    //console.log(selectedChoice, cards[index]?.data?.quiz_question_choices, "timeout");
    let timeId = setTimeout(() => {
      cards[index]?.data?.quiz_question_choices[selectedChoice?.index]?.quiz_question_choice_id &&
        dispatch(
          UpdataChoicesRequest({
            course_id,
            quiz_id,
            quiz_question_id: cards[index]?.data?.quiz_question_id,
            quiz_question_choice_id:
              cards[index]?.data?.quiz_question_choices[selectedChoice?.index]
                ?.quiz_question_choice_id,
            body: {
              quiz_question_choice: {
                text_choice:
                  cards[index]?.data?.quiz_question_choices[selectedChoice?.index]?.text_choice,
                position:
                  cards[index]?.data?.quiz_question_choices[selectedChoice?.index]?.position,
                  image_choice_url:
                  cards[index]?.data?.quiz_question_choices[selectedChoice?.index]?.image_choice_url,
              },
            },
          })
        );
    }, 600);
    return () => clearTimeout(timeId);
  }, [selectedChoice]);

  // add and update choices
  const handleChoices = (e, choiceIndex,type) => {
    // //console.log(e, choiceIndex, cards[index], "why");
    const newCardData = cards;
    const selectedQuestion = cards[index];
    //to handle cases where we modify image_question_url and text_choice

    if (type ==='text')
    {
      selectedQuestion.data.quiz_question_choices[choiceIndex].text_choice = e.target.value;
      setSelectedChoice({ index: choiceIndex, data: e.target.value  });
    }
    else
    {
      //console.log(choiceIndex)
      selectedQuestion.data.quiz_question_choices[choiceIndex].image_choice_url = e;
      setSelectedChoice({ index: choiceIndex, data: e  });

    }
    setCards(newCardData);
  };

  return (
    // [display?.index === index]  =>this condition to display card details if it's focused
    <Stack width="100%" >
      {/* quetions */}
      <Stack
        justifyContent="space-between"
        direction="row"
        alignItems="start"
        className="custom_input"
        gap={2}
        p={4}
      >
        <Stack width="50%" gap={2}>
          <TextFieldInput
            sx={{
              background: inputBg,
              padding: "0",
              borderRadius: "8px",
              "& fieldset": {
                border: "none",
              },
            }}
            placeholder="Questions Title"
            onChange={(e)=>{handleFieldChange('text_question',e)}}
            value={cards[index]?.data?.text_question}
          />
        </Stack>


        {display?.index === index && (
          <Stack width="50%" >
            <SearchableSelect 
            
            placeholder="Question level"
            options={diffcultyOptions}
            value={handelOptions[handelOptions.findIndex(option => option.label === data?.difficulty)]}
            onChange={(value)=>{
              updateQuestion({
                dispatch,
                value:cards[index]?.data?.text_question,
                difficulty:value?.label?value?.label:null,
                cards,
                setCards,
                index,
                display,
                course_id,
                quiz_id,
                question_quiz_id,
              })
        
            }}
            />


          </Stack>
        )}
      </Stack>
      <Box sx={{width:'50%'}}>
        <Button onClick={handleOpen} style={{textTransform: 'none',fontSize:'16px',fontWeight:700}}>Answer Keys</Button>
      </Box>

      <AnswerReasons open={open} handleClose={handleClose} data={data} setCards={setCards} index={index} cards={cards} />

      
      {/* Answers */}
      {display?.index === index &&(
      <Answers
        display={display}
        index={index}
        optionsCount={optionsCount}
        setOptionsCount={setOptionsCount}
        course_id={course_id}
        quiz_id={quiz_id}
        question_quiz_id={question_quiz_id}
        cards={cards}
        optionFocus={optionFocus}
        handleChoices={handleChoices}
        optionsData={optionsData}
        setOptionsData={setOptionsData}
        setOptionFocus={setOptionFocus}
      />)
}

      {/* add more options */}
      {display?.index === index && optionsCount < 4 && (
        <QuestionAddOptions
          setOptionsCount={setOptionsCount}
          setOptionFocus={setOptionFocus}
          course_id={course_id}
          quiz_id={quiz_id}
          question_quiz_id={question_quiz_id}
          optionsCount={optionsCount}
          optionsData={optionsData}
          setCards={setCards}
          cards={cards}
          index={display?.index}
        />
      )}





      {/* question actions */}
      {display?.index === index && (
        <>
          <Divider />
          <QuestionActions
            isLoading={isLoading}
            questionDegree={questionDegree}
            setQuestionDegree={setQuestionDegree}
            cards={cards}
            setCards={setCards}
            index={index}
            course_id={course_id}
            quiz_id={quiz_id}
            question_quiz_id={question_quiz_id}
            display={display}
          />
        </>
      )}
    </Stack>
  );
};

export default QuestionCard;
