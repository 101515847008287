import React from "react";
import { useThemePalette } from "common/hooks/theme_palette";
import { Grid } from "@mui/material";
import Breadcrumb from "components/Atoms/Breadcrumbs/Breadcrumb";
import Header from "components/Molecules/Header";
import SideNavbar from "components/Molecules/SideNavbar";
import { Outlet, useLocation } from "react-router-dom";
import { DeleteQuizSussess } from "services/modules/quiz/Actions";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
const Layout = ({ layoutData }) => {
  const { bgColor, fontColor } = useThemePalette();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!pathname.includes("quiz")) dispatch(DeleteQuizSussess());
  }, [pathname]);
  return (
    <Grid container sx={{ background: bgColor }} color={fontColor}>
      <Grid
        item
        display={{ xs: "none", lg: "grid" }}
        lg={2}
        sx={{
          background: "white",
          boxSizing: "border-box",
        }}
      >
        <SideNavbar />
      </Grid>
      <Grid item xs={12} lg={10}>
        <Header title={layoutData?.title} />
        <Breadcrumb data={layoutData?.breadcrumb} />
        <Outlet />
      </Grid>
    </Grid>
  );
};

export default Layout;
