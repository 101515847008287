import { Box, Button, Stack, Typography } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import TextFieldInput from "components/Atoms/Input/TextField/TextFieldInput";
import ModelBox from "components/Atoms/Model/Model";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { creatAdminUserModel, creatInstructorModel } from "services/modules/models/Actions";
import * as Yup from "yup";
import SelectBox from "../../../Atoms/Select/Select";
import {
  CreateAdminUserRequest,
  GetAllAdminUserssRequest,
  UpdateAdminUserRequest,
} from "../../../../services/modules/AdminUsers";
import { toast } from "react-toastify";
import { emailRegex } from "services/modules/utils/Helper";
const CreateAdminUserModel = ({ type, submitBtnTitle, initialValue, page }) => {
  //console.log(type)
  const { inputBg, orangeBtn } = useThemePalette();
  const isOpenCreateAdminUser = useSelector((state) => state.model.isOpenCreateAdminUser);
  const adminUser = useSelector((state) => state.adminUsers.adminUser);
  const [formikData, setFormikData] = useState();
  const prepairValidationSchema = () => {
    if (type === "create") {
      return Yup.object().shape({
        email: Yup.string().required("email is required").matches(emailRegex, "Email is not valid"),
        password: Yup.string().required("password").min(6, "password can be at least 6 characters"),
        password_confirmation: Yup.string()
          .required("password confirmation is required")
          .oneOf([Yup.ref("password"), null], `Passwords don't match`),
      });
    }

    return Yup.object().shape({
      email: Yup.string().required("email is required").matches(emailRegex, "Email is not valid"),
      password: Yup.string().required("password is required").min(6, "password can be at least 6 characters"),
      password_confirmation: Yup.string("this field is required")
        .required("password confirmation is required")
        .oneOf([Yup.ref("password"), null], `Passwords don't match`),
    });
  };
  const dispatch = useDispatch();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues:
      type == "edit"
        ? {
            email: initialValue?.email,
            name: initialValue?.name,
            "admin- type": 0,
            password: "",
            password_confirmation: "",
          }
        : type === "create" && {
            email: "",
            name: "",
            password: "",
            password_confirmation: "",
          },
    validationSchema: prepairValidationSchema(),
    onSubmit: (values) => {
      const addEmailRequestData = {
        body: {
          admin: values,
          device: {
            uid: "1",
            fcm_token: "fcm_token",
          },
        },
        action: () => {
          toast.success("Admin created successfully");
          formik.resetForm();

          dispatch(creatAdminUserModel());
        },
      };
      const editEmailRequestedData = {
        body: {
          admin: values,
          device: {
            uid: "1",
            fcm_token: "fcm_token",
          },
        },
        admin_id: initialValue?.id?.props?.children,
        action: () => {
          toast.success("Admin updated successfully");
          dispatch(GetAllAdminUserssRequest({ filter: { page_number: page, page_size: 10 } }));

          dispatch(creatAdminUserModel());
        },
      };
      //console.log(editEmailRequestedData)
      Object.keys(initialValue).length === 0
        ? dispatch(CreateAdminUserRequest(addEmailRequestData))
        : dispatch(UpdateAdminUserRequest(editEmailRequestedData));
      console.log(values);
    },
  });
  
  useEffect(() => {
    if (Object.keys(formik?.errors).length !== 0 && formik?.isSubmitting) {
      const errorMessages = [];
      // Check for password error
      if (formik.errors.password) {
        errorMessages.push(formik.errors.password);
      }
      // Check for password confirmation error
      if (formik.errors.password_confirmation) {
        errorMessages.push(formik.errors.password_confirmation);
      }
      // Check for Email confirmation error
      if (formik.errors.email) {
        errorMessages.push(formik.errors.email);
      }
      if (formik.errors.name) {
        errorMessages.push(formik.errors.name);
      }
      // Display error messages in toast
      if (errorMessages.length > 0) {
        toast.error(errorMessages.join(', '));
      }
    }
  }, [formik?.errors, formik?.isSubmitting]);
  
  return (
    <ModelBox
      openVar={isOpenCreateAdminUser}
      closeFunc={() => {
        formik.resetForm();
        dispatch(creatAdminUserModel());
      }}
      title={type === "create" ? "Add New Admin " : "Edit Admin User"}
      titleStyle={{ fontSize: "20px", fontWeight: "600" }}
    >
      <form onSubmit={formik.handleSubmit}>
        <Stack flexDirection={{ xs: "column", md: "row" }} justifyContent="space-between">
          <Stack spacing={4} width="48%">
            <Box>
              <Typography fontSize="12px" color="#5E6366">
                Name
              </Typography>
              <TextFieldInput
                sx={{
                  background: inputBg,
                  padding: "10px",
                  borderRadius: "8px",
                }}
                placeholder="Name"
                onChange={formik.handleChange}
                value={formik.values.name}
                name="name"
                variant="standard"
                // error={ formik.errors.email}
                // helperText={ formik.errors.email}
              />
            </Box>
            <Box>
              <Typography fontSize="12px" color="#5E6366">
                Password
              </Typography>
              <TextFieldInput
                sx={{
                  background: inputBg,
                  padding: "10px",
                  borderRadius: "8px",
                }}
                type="password"
                placeholder="Password"
                onChange={formik.handleChange}
                value={formik.values.password}
                name="password"
                variant="standard"
                // error={formik.errors.password}
                // helperText={formik.errors.password}
                InputProps={{
                  autoComplete: "new-password",
                  form: {
                    autoComplete: "off",
                  },
                }}
              />
            </Box>  
            {/* <Box>
                          <Typography fontSize="12px" color="#5E6366">
                              {" "}
                              {/* Title */}
            {/*   Admin User Type
                          </Typography>
                          <SelectBox
                              sx={{
                                  background: inputBg,
                                  padding: "10px",
                                  borderRadius: "8px",
                              }}

                              placeholder="Title"
                              onChange={formik.handleChange}
                              value={formik.values['admin-type']}
                              name="admin-type"
                              variant="standard"
                              error={formik.errors['admin-type']}
                              helperText={formik.errors['admin-type']}
                              items={[{ id: 0, name: 'Admin User' }, { id: 1, name: 'Super Admin' }]}
                          />
                      </Box>*/}
            
          </Stack>
          <Stack spacing={4} width="48%">
          <Box>
              <Typography fontSize="12px" color="#5E6366">
                Email
              </Typography>
              <TextFieldInput
                sx={{
                  background: inputBg,
                  padding: "10px",
                  borderRadius: "8px",
                }}
                placeholder="Email"
                onChange={formik.handleChange}
                value={formik.values.email}
                name="email"
                variant="standard"
                // error={ formik.errors.email}
                // helperText={ formik.errors.email}
              />
            </Box>
         
            <Box>
              <Typography fontSize="12px" color="#5E6366">
                Confirm Password
              </Typography>
              <TextFieldInput
                type="password"
                sx={{
                  background: inputBg,
                  padding: "10px",
                  borderRadius: "8px",
                }}
                placeholder="Confirm Password"
                onChange={formik.handleChange}
                value={formik.values.password_confirmation}
                name="password_confirmation"
                variant="standard"
                // error={formik.errors.password_confirmation}
                // helperText={formik.errors.password_confirmation}
                InputProps={{
                  autoComplete: "new-password",
                  form: {
                    autoComplete: "off",
                  },
                }}
              />
            </Box>
          </Stack>
        </Stack>
        <Stack
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"center"}
          gap={2}
          mt={2}
          width={"100%"}
        >
          <Button
            sx={{
              color: orangeBtn,
              border: "1px solid #F79E1B",
              "&:hover": {
                backgroundColor: "transparent",
              },
              textTransform: "none",
              width: "20%",
            }}
            className="r-25px"
            onClick={() => {
              formik.resetForm();
              dispatch(creatAdminUserModel());
            }}
          >
            Cancel
          </Button>

          <Button
            type={"submit"}
            sx={{
              backgroundColor: orangeBtn,
              textTransform: "none",
              width: "20%",
              "&:hover": {
                backgroundColor: orangeBtn,
              },
              color: "white",
            }}
            className="primary r-25px"
          >
            {submitBtnTitle}
          </Button>
        </Stack>
      </form>
    </ModelBox>
  );
};

export default CreateAdminUserModel;
