import AddSectionTemplate from "Templates/Courses/section/AddSection.Template";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

const AddSectionPage=({setLayoutData})=>{
    const dispatch = useDispatch();
    const { course_id } = useParams();
    const course=useSelector((state)=>state.courses.course)

  useEffect(() => {
    setLayoutData({
      title: "Section",
      breadcrumb: [
        { title: "Home", path: "/", active: true },

        {
          title: "Courses",
          path: "/courses",
          active: true,
        },
        {
          title: "Course Detail",
          path: `/courses/${course_id}`,
          active: true,
        },
        {
          title: 'New Section',
          path: "",
          active: false,
        },
      ],
    });
  }, [course_id]);
return(
    <AddSectionTemplate  />
)
}
export default AddSectionPage;