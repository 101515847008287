import { Button, FormControlLabel, Radio, RadioGroup, Stack, Switch,Box } from "@mui/material";
import TextFieldInput from "components/Atoms/Input/TextField/TextFieldInput";
import React from "react";
import ClearIcon from "@mui/icons-material/Clear";
import {
  handelClick,
  handelKeyPress,
  handelRemoveOption,
  handelUpdateModelAnswer,
} from "components/Organisms/quiz/utils/question_func";
import { useDispatch } from "react-redux";
import { icons } from "assets/AssetHelper";
import { useState } from "react";
import { useThemePalette } from "common/hooks/theme_palette";
import { getPresignedUrlRequest } from "services/modules/uploadFiles";

const Answers = ({
  display,
  index,
  optionsCount,
  setOptionsCount,
  course_id,
  quiz_id,
  question_quiz_id,
  cards,
  optionFocus,
  setOptionFocus,
  handleChoices,
  optionsData,
  setOptionsData,
}) => {


  const dispatch = useDispatch();
  const [modelAnswers, setModelAnswers] = useState([
    { label: "Correct answer", state: true, color: "#27AE60" },
    { label: "Wrong answer", state: false, color: "#EB5757" },
    { label: "Wrong answer", state: false, color: "#EB5757" },
    { label: "Wrong answer", state: false, color: "#EB5757" },
  ]);
  const { inputBg } = useThemePalette();
  const handelCorrectAnswer = (e, choiceIndex) => {
    const editedData = modelAnswers;
    editedData?.map((val, index) => {
      if (index == choiceIndex) {
        if (e.target.value) {
          editedData[index].label = "Correct answer";
          editedData[index].color = "#27AE60";
        } else {
          editedData[index].label = "Wrong answer";
          editedData[index].color = "#EB5757";
        }
        editedData[index].state = e.target.value;
      } else {
        editedData[index].state = false;
        editedData[index].label = "Wrong answer";
        editedData[index].color = "#EB5757";
      }
    });
    setModelAnswers(editedData);
    handelUpdateModelAnswer(
      course_id,
      quiz_id,
      question_quiz_id,
      1, //quiz_question_model_answer_id
      cards[index]?.data?.quiz_question_choices[choiceIndex],
      dispatch
    );
  };


  // upload image in option section
const handleChange = (e, choiceIndex,index) => {
  const fileName = e.target.files[0].name; // Extracting the file name  
  dispatch(
    getPresignedUrlRequest({
      body: {
        file: e.target.files[0],
        extension: {
          files: [
            {
              extension: fileName.substring(
                fileName.lastIndexOf(".") + 1,
                fileName.length
              ),
            },
          ],
        },
      },
      updateData: (data) => {
        console.log(data)
        handleChoices(data, choiceIndex,'image',index)

      },
    })
  );
};


// 


  return (
    <RadioGroup
      aria-labelledby="demo-radio-buttons-group-label"
      defaultValue="0"
      name="radio-buttons-group"
      
    >
      {[...Array(optionsCount)]?.map((data, choiceIndex) => {
        return (
          <Stack
            gap={2}
            width="90%"

            direction="row"
            key={choiceIndex}
            alignItems="start"
            my={2}
            justifyContent="space-between"
            className={`custom_input ${display?.index === index && "custom_input_hover"} `}
          >
            <Radio disabled />
            <Stack sx={{ width: "65%" }} gap={2}>



{!cards[index]?.data?.quiz_question_choices[choiceIndex]?.image_choice_url?<TextFieldInput
                // sx={{
                //   background: inputBg,
                //   padding: "0",
                //   borderRadius: "8px",
                //   "& fieldset": {
                //     border: "none",
                //   },
                // }}
                // id="custom-css-outlined-input"
                variant="standard"
                onKeyPress={(event) =>
                  handelKeyPress(
                    event,
                    choiceIndex,
                    setOptionsCount,
                    setOptionFocus,
                    optionsCount,
                    dispatch,
                    course_id,
                    quiz_id,
                    question_quiz_id,
                    optionsCount
                  )
                }
                onChange={(e) => handleChoices(e, choiceIndex,'text',index)}
                focused={display?.index === index ? optionFocus[choiceIndex] : false} //for focuse it self
                onClick={() => handelClick(choiceIndex, setOptionFocus, optionsCount)}
                autoFocus={optionFocus[choiceIndex]} //for cursor focuse
                value={cards[index]?.data?.quiz_question_choices[choiceIndex]?.text_choice}
              />:
              
              <Box
              component={'img'}
              src={cards[index]?.data?.quiz_question_choices[choiceIndex]?.image_choice_url}
              sx={{width:'216px',height:'142px',borderRadius:'5px'}}
              />
}
            </Stack>

            <Stack
              width={{ xs: "fit-content", md: "35%" }}
              direction="row"
              alignItems="center"
              spacing={2}
              //   className={`${optionFocus[choiceIndex] ? "option_displayed" : "option_hidden"} `}
            >
              <FormControlLabel
                // value={modelAnswers[choiceIndex].label}
                sx={{
                  "& .MuiTypography-root": {
                    color: modelAnswers[choiceIndex]?.color,
                    fontSize: "14px",
                    fontWeight: "500",
                    width: "120px",
                  },
                }}
                control={
                  <Switch
                    color="default"
                    checked={modelAnswers[choiceIndex]?.state}
                    onChange={(e) => handelCorrectAnswer(e, choiceIndex,index)}
                  />
                }
                label={modelAnswers[choiceIndex]?.label}
                labelPlacement="start"
              />

              {/* <img src={icons.imageIcon} alt="" /> */}
            <label htmlFor={`upload ${choiceIndex}`}>
                  <input
                    id={`upload ${choiceIndex}`}
                    name={`upload`}
                    type="file"
                    style={{ display: "none" }}
                    onClick={()=>{
                      console.log(index)
                    }}
                    onChange={(e) => handleChange(e, choiceIndex,index)}
                    
                  />
              <Button component="span" >
              <img src={icons.imageIcon} alt="" />
              </Button>
            </label>

              {display?.index === index && (
                <div
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    handelRemoveOption(
                      optionsData,
                      choiceIndex,
                      setOptionsData,
                      setOptionsCount,
                      dispatch,
                      course_id,
                      quiz_id
                    );
                  }}
                >
                  <ClearIcon color="#4F4F4F" />
                </div>
              )}
              {/* )} */}
            </Stack>
          </Stack>
        );
      })}
    </RadioGroup>
  );
};

export default Answers;
