import React, { useState } from "react";
import InstructorsListTemplate from "Templates/users/instructors/InstructorsList.Template";
import { useEffect } from "react";
import {
  DeleteInstructorRequest,
  GetAllInstructorsRequest,
  ShowInstructorDetailsRequest,
} from "services/modules/instructors";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  Avatar,
  Button,
  ClickAwayListener,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
  Box,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreHorizRounded";
import { Link, useNavigate } from "react-router-dom";
import { Stack } from "@mui/system";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { Cancel } from "@mui/icons-material";
import { creatInstructorModel } from "services/modules/models/Actions";
import DeleteModal from "components/Atoms/Model/DeleteModel";

import { toast } from "react-toastify";
const InstructorsListPage = ({ setLayoutData }) => {
  const dispatch = useDispatch();
  const instructors = useSelector((state) => state.instructors.instructors);
  const [selectedRow, setSelectedRow] = useState(null);
  const count = useSelector((state) => state.instructors.count);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const navigate = useNavigate()
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [selected, setSelected] = useState({
    id: "",
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const anchorRef = React.useRef(null);

  const open = Boolean(anchorEl);
  const handleClick = (event, index, data) => {
    anchorEl == index ? setAnchorEl(null) : setAnchorEl(index);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const viewData = (data) => {
    setSelected(data);
  };

  const handleEditButton = (data) => {
    dispatch(creatInstructorModel());
    setSelectedRow(data);
  };
  const handleCloseModel = () => {
    setOpenDeleteModel(false);
  };

  const isOpenCreateInstructor = useSelector((state) => state.model.isOpenCreateInstructor);

  const onRowClick = (data) => {
    navigate(`/users/instructors/${data?.row?.values?.instructor_id}`);
  };
  useEffect(() => {
    if (!isOpenCreateInstructor) {
      setSelectedRow(null);
    }
  }, [isOpenCreateInstructor]);

  const handleDelete = () => {
    const requestedData = {
      instructor_id: selectedRow?.instructor_id,
      action: () => {
        toast.success(`Instructor ${selectedRow?.name} deleted successfully`);
        setSelectedRow(null);
        setOpenDeleteModel(false);
        dispatch(GetAllInstructorsRequest({ filter: { page_number: page, page_size: 10 } }));
      },
    };

    dispatch(DeleteInstructorRequest(requestedData));
  };

  useEffect(() => {
    dispatch(GetAllInstructorsRequest({ filter: { page_number: page, page_size: 10 } }));
  }, [page]);
  const mappedData = instructors?.map((data, index) => {
    return {
      ...data,
      instructor_name: (
        <Link
          to={`/users/instructors/${data?.instructor_id}`}
          style={{ textDecoration: "none", color: "#6E7079" }}
        >
          <Stack direction="row" alignItems="center" justifyconten="center">
            <Avatar
              alt={data?.name}
              src={data?.profile_picture_url}
              sx={{ width: 30, height: 30 }}
            />

            <Typography mx="20px" textTransform="capitalize">
              {data && data.name && data.name.length > 20
                ? data.name.slice(0, 20) + "..."
                : data.name}
            </Typography>
          </Stack>
        </Link>
      ),
      is_phone_verified: (
        <Stack sx={{ display: "flex", alignItems: "center" }}>
          {data?.is_phone_verified ? (
            <CheckCircleIcon style={{ color: "#27AE60" }} />
          ) : (
            <Cancel style={{ color: "#EB5757" }}> </Cancel>
          )}
        </Stack>
      ),
      email: (
        <Typography mx="20px" textTransform="capitalize">
          {data && data.email && data.email.length > 20
            ? data.email.slice(0, 20) + "..."
            : data.email}
        </Typography>
      ),
      country_code: <Typography textAlign={"center"}>{data?.country_code}</Typography>,
      Actions: (
        <div style={{ position: "relative", display:'flex' , gap:'4px' }}>
          <Button
            sx={{ textTransform: "none", color: "black", border: "solid 1px #EDEFF1" }}
            onClick={(e) => {
              e.stopPropagation();
              handleEditButton(data);
            }}
          >
            Edit
          </Button>
          <Button
            sx={{
              textTransform: "none",
              color: "#EB001B",
              border: "solid 1px #EDEFF1",
            }}
            onClick={(e) => {
              e.stopPropagation();
              setOpenDeleteModel(true);
              setSelectedRow(data);
            }}
          >
            {" "}
            Delete
          </Button>
        </div>
      ),
    };
  });
  useEffect(() => {
    setLayoutData({
      title: "Instructors",
      breadcrumb: [
        { title: "Home", path: "/", active: true },

        {
          title: "Instructors",
          path: "/users/instructors",
          active: false,
        },
      ],
    });
  }, []);
  return (
    <Box>
      <InstructorsListTemplate
        perPage={perPage}
        pageNumber={pageNumber}
        viewData={viewData}
        data={mappedData}
        page={page}
        setPageNumber={setPage}
        count={count}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        onRowClick={onRowClick}
      />

      <DeleteModal
        open={openDeleteModel}
        text={`Are you sure you want to delete "${selectedRow?.email}" account ?`}
        handelDeleteRequest={handleDelete}
        handleClose={handleCloseModel}
      />
    </Box>
  );
};

export default InstructorsListPage;
