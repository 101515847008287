import React, { useMemo } from "react";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useTable, useSortBy, useGlobalFilter, usePagination } from "react-table";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import unfoldMoreIcon from "assets/icons/unfoldMoreIcon.svg";
import {
    Button,
    Checkbox,
    Divider,
    FormControlLabel,
    Menu,
    MenuItem,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Fade,
} from "@mui/material";
import styled from "@emotion/styled";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { tableCellClasses } from "@mui/material/TableCell";
import addPerson from "assets/icons/add-person.svg"
import { useThemePalette } from "../../../../common/hooks/theme_palette";
import Header from "./header";
const TableWithBottomBtn = ({
    btnAction,
    Data,
    Columns,
    Title,
    subTitle,
    btnTitle,
    placeHolder,
    noSearchBar,
    noFilter,
    footerBtnTitle,
    selectedData,
    ActionsData,
    currentpage,
    setPage,
    perPage,
    totalPage,
    setPageNumber,
    checked,
    setChecked,
    setPerPage,
    filterList,
    bottomBtnTitle,
    displayFooter=true
  


}) => {
    const { secondryColor } = useThemePalette();
    //console.log(selectedData)

    const [index, setIndex] = useState(null);

    // const [anchorEl, setAnchorEl] = React.useState(null);
    // const open = Boolean(anchorEl);
    // const handleClick = (event) => {
    //     setAnchorEl(event.currentTarget);
    // };
    // const handleClose = () => {
    //     setAnchorEl(null);
    // };
    const dispatch = useDispatch();
    const { isLoading } = useSelector((state) => state.courses);
    const Actions = (id) => {
        ActionsData(id);
    };

   

    // ...

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        "&:nth-of-type(odd)": {
            backgroundColor: "#2f80ed17",
        },
        "&:first-child:last-child": {
            border: 0,
        },
        "&:first-child": {
            border: 0,
        },
        "&:last-child": {
            border: 0,
        },
        "&:last-child td, &:last-child th": {
            backgroundColor: "white",
        },
    }));

// ...

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,

        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));
    const data = useMemo(
        () =>
            Data &&
            Data?.map((value, i) => {
                return {
                    ...value,
                };
            }),
        [Data]
    );

    const columns = useMemo(
        () =>
            Columns?.map((column) => {
                return {
                    Header: column.Header,
                    accessor: column.accessor,
                };
            }),
        [Columns]
    );

    const {
        state,
        setGlobalFilter,
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        pageOptions,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            useControlledState: (state) => {
                return React.useMemo(
                    () => ({
                        ...state,
                        pageIndex: currentpage,
                    }),
                    [state, currentpage]
                );
            },
            initialState: { pageIndex: currentpage },
            manualPagination: true,
            pageCount: totalPage,
        },

        useGlobalFilter,
        useSortBy,
        usePagination,
    );

    return (
        <>
        <TableContainer component={Paper} sx={{ borderRadius: "12px" }}>
            <Header
                    filterList={filterList}
                    noSearchBar={noSearchBar}
                    noFilter={noFilter}
                    Title={Title}
                    globalFilter={state.globalFilter}
                    setGlobalFilter={setGlobalFilter}
                    Data={Data}
                    subTitle={subTitle}
                    btnTitle={btnTitle}
                    placeHolder={placeHolder}
                    empty={Data.length===0 }
                />
                {Data.length > 0 && <Divider />}
            <Table
                {...getTableProps()}
                    bg="white"
                // borderRadius="6px"
                size="small"
            >
                <TableHead bg="#efeeee" color="#645f65">
                    {headerGroups.map((headerGroup, index) => (
                        <TableRow {...headerGroup.getHeaderGroupProps()} key={index}>
                            {headerGroup.headers.map((column, index) => (
                                <TableCell
                                    key={index}
                                    bg="inherit"
                                    {...column.getHeaderProps(
                                        column.Header === "" ? "" : column.getSortByToggleProps()
                                    )}
                                    sx={{ height:  Data.length > 0 && "60px" }}

                                >
                                    <Stack direction="row" alignItems='center' gap={1 }>
                                        {Data.length > 0 ? (
                                            <>
                                                {column.Header === "" ? (
                                                    ""
                                                ) : (
                                                    <span
                                                        style={{
                                                            paddingLeft: "10px",
                                                            margin: "-1px",
                                                        }}
                                                        display="inline-block"
                                                    >
                                                        {column.isSorted ? (
                                                            column.isSortedDesc ? (
                                                                <ArrowDropUpIcon aria-label="sorted descending" fontSize="medium" />
                                                            ) : (
                                                                <ArrowDropDownIcon aria-label="sorted ascending" fontSize="medium" />
                                                            )
                                                        ) : (
                                                            <img src={unfoldMoreIcon} fontSize="20px" />
                                                        )}
                                                    </span>
                                                )}
                                                <Typography alignItems='center' fontWeight="500" fontSize="14px">
                                                    {column.render("Header")}
                                                </Typography>
                                                
                                            </>
                                        ) : (
                                            ""
                                        )}
                                    </Stack>
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableHead>
                {Data.length > 0 && (
                    <TableBody {...getTableBodyProps()} color="black">
                        {page.map((row, index) => {
                            prepareRow(row);
                            return (
                                <StyledTableRow
                                    key={index}
                                    {...row.getRowProps()}
                                    onClick={() => selectedData(row.original)}
                                    sx={{ height: "70px" }}
                                >
                                    {row.cells.map((cell, index) => {
                                        // //console.log(row.cells.find(cel=>cel.column.Header === 'ID').value)

                                        return (
                                            <TableCell
                                                key={index}
                                                sx={{
                                                    color: "#6E7079",
                                                    fontweight: "400",
                                                    fontsize: "14px",
                                                    position: "relative",
                                                }}
                                                {...cell.getCellProps()}
                                            // isNumeric={cell.column.isNumeric}
                                            >
                                                {cell.render("Cell")}
                                            </TableCell>
                                        );
                                    })}

                                </StyledTableRow>
                            );

                        })}
                    </TableBody>
                ) }
                </Table>

                {displayFooter&&<StyledTableRow  >
                    <StyledTableCell component="th" scope="row" width='100%' >
                    <Stack 
                    onClick={btnAction}
                    direction="row" sx={{ cursor: "pointer", alignItems: "center" }}>
                            <img src={addPerson} alt="" />
                        <Typography color={secondryColor} ml={1} fontSize="14px">
                            {bottomBtnTitle}
                        </Typography>
                    </Stack>
                </StyledTableCell>
                <StyledTableCell align="right" width="18%"></StyledTableCell>
            </StyledTableRow>}
            

            
            </TableContainer>
        </>
    );
};

export default TableWithBottomBtn;
