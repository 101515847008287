import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { ShowInstructorDetailsRequest } from "services/modules/instructors";
import InstructorDetailsTemplate from "Templates/users/instructors/InstructorDetails.Template";

const InstructorDetailsPage = ({ setLayoutData }) => {
  const dispatch = useDispatch();
  const { instructor_id } = useParams();
  const instructor = useSelector((state) => state.instructors.instructor);
  useEffect(() => {
    if (instructor_id) dispatch(ShowInstructorDetailsRequest({instructor_id:instructor_id}));
  }, [instructor_id]);
  useEffect(() => {
    setLayoutData({
      title: "Instructors",
      breadcrumb: [
        { title: "Home", path: "/", active: true },

        {
          title: "Instructors",
          path: "/users/instructors",
          active: true,
        },
        {
          title: instructor?.name,
          path: "",
          active: false,
        },
      ],
    });
  }, [instructor?.name]);
  return <InstructorDetailsTemplate instructor={instructor} />;
};

export default InstructorDetailsPage;
