import React, { useEffect, useMemo } from "react";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useTable, useSortBy, useGlobalFilter, usePagination } from "react-table";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import unfoldMoreIcon from "assets/icons/unfoldMoreIcon.svg";
import Footer from "./footer";
import Header from "./header";
import addPerson from "assets/icons/add-person.svg";
import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Fade,
} from "@mui/material";
import styled from "@emotion/styled";
import { useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreHorizRounded";
import { icons } from "../../../assets/AssetHelper";
import { useThemePalette } from "../../../common/hooks/theme_palette";
import { useDispatch } from "react-redux";
import { deleteAdminCourseRequest } from "../../../services/modules/courses/Requests/courses";
import { useSelector } from "react-redux";
import EmptyList from "components/Atoms/SharedComponents/EmptyList";
import CActions from "../Menus/ActionList";
import TableActionList from "../Menus/TableActionList";

const CTable = ({
  Data,
  Columns,
  Title,
  subTitle,
  btnTitle,
  placeHolder,
  noSearchBar,
  noFilter,
  footerBtnTitle,
  handlePageChange,
  paginationPage,
  total_count,
  selectedData,
  ActionsData,
  currentpage,
  setPage,
  perPage,
  totalPage,
  setPageNumber,
  checked,
  setChecked,
  setPerPage,
  filterList,
  handleSearchChange,
  bottomBtnTitle,
  displayAddBtn = false,
  btnAction,
  setAllSelectedData,
  selectBoxData,
  handleCheck,
  handleCheckAll,
  actionsList,
  onRowClick,
  contextMenuActions,
  borderLeft
}) => {
  const { secondryColor } = useThemePalette();

  const [index, setIndex] = useState(null);

  // const [anchorEl, setAnchorEl] = React.useState(null);
  // const open = Boolean(anchorEl);
  // const handleClick = (event) => {
  //     setAnchorEl(event.currentTarget);
  // };
  // const handleClose = () => {
  //     setAnchorEl(null);
  // };
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.courses);
  const [contextMenuPositions, setContextMenuPositions] = useState({});
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);
  // Function to handle right-click on a cell
  const handleContextMenu = (event, rowIndex) => {
    setIsContextMenuOpen(true);
    event.preventDefault(); // Prevent default right-click behavior
    setContextMenuPositions({
      ...contextMenuPositions,
      [rowIndex]: { x: event.clientX, y: event.clientY },
    });
  };

  // Function to close the context menu
  const handleCloseMenu = () => {
    setContextMenuPositions({});
    setIsContextMenuOpen(false);
  };
  const handleContextMenuActionClick = (event, action) => {
    event.stopPropagation(); // Prevent event propagation to the parent onClick handler
    action.click();
    handleCloseMenu();
  };

  const handleAllCheckboxChange = (event) => {
    handleCheckAll && handleCheckAll(event, data);
    let checkedData = [];
    for (let key = 0; key < data?.length; key++) {
      checkedData.push(event.target.checked);
    }
    !handleCheckAll && setChecked((s) => checkedData);
  };

  const handleCheckboxChange = (event, index) => {
    handleCheck && handleCheck(event, index, data);
    !handleCheck &&
      setChecked((s) => {
        s[index] = event?.target?.checked;
        return s;
      });
  };
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
      backgroundColor: "#2f80ed17",
      border: 0,
    },
    "td, th": {
      border: 0,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [openId, setOpenId] = useState(null);
  const handleClick = (event, id) => {
    if (event?.currentTarget) {
      setAnchorEl(event?.currentTarget);
      setOpenId(id);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const Actions = (id, elmIndex, data) => {
    return (
      <TableActionList
        id={id}
        index={elmIndex}
        data={data}
        actionsList={actionsList}
        anchorEl={anchorEl}
        open={open && openId == id}
        handleClick={handleClick}
        handleClose={handleClose}
      />
    );
  };
  const data = useMemo(() => {
    if (!Data) return null;

    return Data.map((value, i) => {
      if (value) {
        const newData = { ...value };
        if (actionsList) {
          newData.Actions = Actions(value?.student_id, i, value);
        }
        return newData;
      }
    });
  }, [Data, actionsList]);

  const columns = useMemo(
    () =>
      Columns?.map((column) => {
        return {
          Header: column.Header,
          accessor: column.accessor,
        };
      }),
    [Columns]
  );

  const {
    state,
    setGlobalFilter,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageOptions,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      useControlledState: (state) => {
        return React.useMemo(
          () => ({
            ...state,
            pageIndex: currentpage,
          }),
          [state, currentpage]
        );
      },
      initialState: { pageIndex: currentpage },
      manualPagination: true,
      pageCount: totalPage,
    },

    useGlobalFilter,
    useSortBy,
    usePagination
  );
  return (
    <TableContainer
      component={Paper}
      sx={{ borderRadius: "12px", paddingY: "15px", borderLeft: borderLeft && borderLeft }}
    >
      <Header
        filterList={filterList}
        noSearchBar={noSearchBar}
        noFilter={noFilter}
        Title={Title}
        globalFilter={state.globalFilter}
        setGlobalFilter={setGlobalFilter}
        Data={Data}
        subTitle={subTitle}
        btnTitle={btnTitle}
        placeHolder={placeHolder}
        handleSearchChange={handleSearchChange}
        selectBoxData={selectBoxData}
        setPage={setPage}
      />
      {Data.length > 0 ? (
        <Divider />
      ) : (
        <>
          <Divider />
          <EmptyList />
        </>
      )}
      <Table
        {...getTableProps()}
        bg="white"
        mb="6"
        // borderRadius="6px"
        aria-label="simple table"
        size="small"
      >
        <TableHead bg="#efeeee" color="#645f65">
          {headerGroups.map((headerGroup, index) => (
            <TableRow {...headerGroup.getHeaderGroupProps()} key={index}>
              {checked && Data.length > 0 && (
                <TableCell>
                  <FormControlLabel
                    ml={4}
                    control={
                      <Checkbox
                        checked={!checked?.includes(false) ? true : false}
                        indeterminate={checked?.includes(false) && checked?.includes(true)}
                        onChange={(e) => handleAllCheckboxChange(e)}
                      />
                    }
                  />
                </TableCell>
              )}
              {headerGroup.headers.map((column, index) => (
                <TableCell
                  key={index}
                  bg="inherit"
                  {...column.getHeaderProps(
                    column.Header === "" ? "" : column.getSortByToggleProps()
                  )}
                >
                  <Stack direction="row" gap={1} alignItems="center">
                    {Data.length > 0 ? (
                      <>
                        {column.Header === "" ? (
                          ""
                        ) : (
                          <span
                            style={{
                              paddingLeft: "10px",
                              margin: "-1px",
                            }}
                            display="inline-block"
                          >
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <ArrowDropUpIcon aria-label="sorted descending" fontSize="medium" />
                              ) : (
                                <ArrowDropDownIcon
                                  aria-label="sorted ascending"
                                  fontSize="medium"
                                />
                              )
                            ) : (
                              <img src={unfoldMoreIcon} fontSize="20px" />
                            )}
                          </span>
                        )}
                        <Typography fontWeight="500" fontSize="14px">
                          {column.render("Header")}
                        </Typography>
                      </>
                    ) : (
                      ""
                    )}
                  </Stack>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        {Data.length > 0 ? (
          <TableBody {...getTableBodyProps()} color="black">
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <StyledTableRow
                  key={index}
                  {...row.getRowProps()}
                  onClick={() => selectedData(row.original)}
                >
                  {checked && (
                    <TableCell sx={{ color: "#6E7079", fontweight: "400", fontsize: "14px" }}>
                      <Checkbox
                        checked={checked[index]}
                        onChange={(e) => handleCheckboxChange(e, index)}
                        style={{
                          marginTop: "10px",
                          marginLeft: "-10px",
                          // borderRadius: '8px'
                        }}
                      />
                    </TableCell>
                  )}
                  {row.cells.map((cell, cellIndex) => {
                    return (
                      <TableCell
                        key={cellIndex}
                        onClick={(_) => onRowClick && !isContextMenuOpen && onRowClick(cell)}
                        onContextMenu={(event) =>
                          contextMenuActions && handleContextMenu(event, index)
                        } // Pass rowIndex to handleContextMenu
                        sx={{
                          color: "#6E7079",
                          fontWeight: "400",
                          fontSize: "14px",
                          position: "relative",
                          alignItems: "center",
                          cursor: onRowClick && "pointer",
                        }}
                        {...cell.getCellProps()}
                      >
                        {/* Render cell content */}
                        {cell.render("Cell")}
                        {contextMenuActions &&
                          contextMenuPositions[index] && ( // Check if context menu should be displayed for this row
                            <Menu
                              open
                              onClose={handleCloseMenu}
                              anchorReference="anchorPosition"
                              anchorPosition={{
                                top: contextMenuPositions[index].y,
                                left: contextMenuPositions[index].x,
                              }}
                              PaperProps={{
                                elevation: 1, // Change the elevation value to adjust the box shadow
                                style: {
                                  boxShadow: "rgba(0, 0, 0, 0.08) 0px 4px 32px !important",
                                  width: "130px",
                                },
                              }}
                            >
                              {contextMenuActions({ data: cell?.row?.original })?.map(
                                (action, index) =>
                                  action.condition() && (
                                    <MenuItem
                                      key={index}
                                      onClick={(event) => {
                                        handleContextMenuActionClick(event, action);
                                        // action.click();
                                        // handleCloseMenu();
                                      }}
                                    >
                                      {typeof action.text === "string" ? (
                                        action.text
                                      ) : (
                                        <Typography color={action.text.props.color}>
                                          {action.text.props.children}
                                        </Typography>
                                      )}
                                    </MenuItem>
                                  )
                              )}
                            </Menu>
                          )}
                      </TableCell>
                    );
                  })}
                </StyledTableRow>
              );
            })}
          </TableBody>
        ) : (
          ""
        )}
      </Table>
      {Data.length > 0 ? (
        <>
          <Footer
            totalPage={totalPage}
            total_count={total_count}
            paginationPage={paginationPage}
            handlePageChange={handlePageChange}
            perPage={perPage}
          />
          {displayAddBtn && <Divider></Divider>}
        </>
      ) : (
        ""
      )}
      {displayAddBtn && (
        <>
          <StyledTableRow>
            <Stack
              padding={2}
              onClick={btnAction}
              direction="row"
              sx={{ cursor: "pointer", alignItems: "center" }}
            >
              <img src={addPerson} alt="" />
              <Typography color={secondryColor} ml={1} fontSize="14px">
                {bottomBtnTitle}
              </Typography>
            </Stack>
          </StyledTableRow>
        </>
      )}
    </TableContainer>
  );
};

export default CTable;
