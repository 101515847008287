import { Box, Button, Menu, MenuItem } from "@mui/material";
import CTable from "components/Molecules/Table/table";
import React from "react";
import { useState } from "react";
import Fade from "@mui/material/Fade";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
const ResponcesTab = ({ mappedData }) => {
  const [pageNumber, setPageNumber] = useState(0);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [selected, setSelected] = useState({
    id: "",
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const viewData = (data) => {
    setSelected(data);
  };
  const [checked, setChecked] = useState([false]);
  const columns = [
    {
      Header: "ID",
      accessor: "quiz_attempt_id",
    },
    {
      Header: "Name",
      accessor: "student_name",
    },
    {
      Header: "Start date",
      accessor: "start_time",
    },
    {
      Header: "No.of Correct Answer",
      accessor: "evaluation.correct_answers_number",
    },
    {
      Header: "Percentage",
      accessor: "total_score",
    },

    {
      Header: "",
      accessor: "Actions",
    },
  ];

  const filterList = (
    <div style={{ height: "40px" }}>
      <Button
        variant="outlined"
        id="fade-button"
        style={{ height: "100%" }}
        aria-controls={open ? "fade-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        startIcon={<FilterAltOutlinedIcon />}
        color="secondary"
      >
        Filter
      </Button>
      {/* <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={handleClose}>Profile</MenuItem>
        <MenuItem onClick={handleClose}>My account</MenuItem>
        <MenuItem onClick={handleClose}>Logout</MenuItem>
      </Menu> */}
    </div>
  );

  return (
    <Box mt={2}>
      <CTable
        selectedData={viewData}
        footerBtnTitle
        filterList={filterList}
        noSearchBar={false}
        noFilter={false}
        Data={mappedData}
        Columns={columns}
        Title="Student List"
        subTitle=""
        btnTitle=""
        placeHolder=""
        setPage={setPage}
        setPerPage={setPerPage}
        currentpage={pageNumber}
        setPageNumber={setPageNumber}
        perPage={perPage}
        totalPage={Math.ceil(1 / 10)}
        checked={checked}
        setChecked={setChecked}
      />
    </Box>
  );
};

export default ResponcesTab;
