import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";

// import {
//   deleteAdminCourseRequest,
//   getAdminCoursesRequest,
// } from "services/modules/courses/Requests/courses";
import { GetAssignmentSubmissionsRequest } from "services/modules/courses/Requests/assignment";

import AssignmentListTemplate from "Templates/Courses/AssignmentList.Template";
import MoreVertIcon from "@mui/icons-material/MoreHorizRounded";
import {
  Button,
  ClickAwayListener,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import React from "react";
import dayjs from "dayjs";

const AssignmentListPage = ({ tab }) => {
  const submissions = useSelector((state) => state.courses.submissions);
  const { section_id, assignment_id, course_id } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [filter, setFilter] = useState(null);
  useEffect(() => {
    setFilter({
      status: tab,
    });
  }, [tab]);

  useEffect(() => {
    // dispatch(getAdminCoursesRequest({ filter }));
    if (filter) {
      dispatch(
        GetAssignmentSubmissionsRequest({
          assignment_id,
          filter,
          unsubmitted: filter?.status === "unsubmitted" ? "unsubmitted" : false,
          course_id,
        })
      );
    }
  }, [filter]);

  const [selected, setSelected] = useState({
    id: "",
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const anchorRef = React.useRef(null);
  const open = Boolean(anchorEl);

  const viewData = (data) => {
    setSelected(data);
  };
  const handleClick = (event, index, data) => {
    anchorEl == index ? setAnchorEl(null) : setAnchorEl(index);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const mappedData = submissions?.map((data, index) => {
    return {
      ...data,
      submitted_date: dayjs(data.submitted_date).format("DD-MM-YYYY hh:mm A"),
      status: (
        <Typography color={data.status === "pass" ? "#27AE60" : "#EB001B"}>
          {data.status}
        </Typography>
      ),
      // school_class: (
      //   <Link
      //     to={`/courses/${data?.school_class}`}
      //     style={{ textDecoration: "none", color: "#6E7079" }}
      //   >
      //     {data?.school_class ? data?.school_class : "-"}
      //   </Link>
      // ),
      Actions: (
        <div style={{ position: "relative" }}>
          <Button
            id="demo-positioned-button"
            aria-controls={open ? "demo-positioned-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={(e) => handleClick(e, index, data)}
          >
            <MoreVertIcon />
          </Button>
          <Popper
            open={anchorEl == index ? true : false}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            className="popper-custom-style"
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                >
                  <MenuItem
                    onClick={() =>
                      navigate(
                        `/assignment/${course_id}/${section_id}/${assignment_id}/${tab}/${data?.delivery_id}`
                      )
                    }
                  >
                    View
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Popper>
        </div>
      ),
    };
  });

  // useEffect(() => {
  //   setLayoutData({
  //     title: "Courses",
  //     breadcrumb: [
  //       { title: "Home", path: "/", active: true },
  //       { title: "Courses", path: "/courses", active: false },
  //     ],
  //   });
  // }, []);

  return (
    <AssignmentListTemplate
      tab={tab}
      dispatch={dispatch}
      setPage={setPage}
      perPage={perPage}
      setPerPage={setPerPage}
      viewData={viewData}
      mappedData={mappedData}
    />
  );
};

export default AssignmentListPage;
