import React, { useEffect, useState } from "react";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { Avatar, Button, Divider, ListItemText, MenuItem, OutlinedInput, Paper, Select, TableContainer, Typography } from "@mui/material";
import { icons } from "../../../../assets/AssetHelper";
import  trash  from "assets/icons/trash.svg";

import { Box, Stack } from "@mui/system";
import { useThemePalette } from "../../../../common/hooks/theme_palette";
import ModelBox from "components/Atoms/Model/Model";
import { CheckBox, Label } from "@mui/icons-material";
import FormControl from '@mui/material/FormControl';
import MultiSelect from "components/Atoms/Select/MultiSelect";
import { GetAllInstructorsRequest } from "services/modules/instructors";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import AddModel from "./addModel";
import { instructor } from "services/modules/utils/constant";
import { AddCourseInstructorsRequest, DeleteCourseInstructorsRequest, getCourseInstructorsRequest } from "services/modules/courses/Requests/instructors";
import { useParams } from "react-router-dom";
import DeleteModal from "components/Atoms/Model/DeleteModel";
import AddInstructor from "./AddInstructor";

const CourseInstructors = ({ data }) => {
  const { secondryColor,orangeBtn } = useThemePalette();
  const dispatch=useDispatch();
  const instructors=useSelector((state)=>state.instructors.instructors)
  const {count,page_number}=useSelector((state)=>state.instructors)
  const { course_id } = useParams();
  //console.log(instructors)
  const isInstructor=localStorage.getItem("userType")==="Instructor"||sessionStorage.getItem("userType")==="Instructor"



const [openAddModel, setOpenAddModel] = useState(false);
const [selectedOptions, setSelectedOptions] = useState([]);
const [allInstructors, setAllInstructors] = useState([]);
const [openDeleteModel,setOpenDelteModel]=useState({state:false, index:null})
const[pageNumber,setPageNumber]=useState(1)
//console.log(allInstructors)
useEffect(() => {
  if(page_number===1&&allInstructors.length!=instructors?.length){
    //console.log('hereeeeeeeee')
    setAllInstructors(instructors)
  }
  else{
  setAllInstructors([...allInstructors, ...instructors]);
  setAllInstructors((prevAllInstructors) =>
    prevAllInstructors.filter(
      (instructor) => !data.find((dataInstructor) => instructor.instructor_id === dataInstructor.instructor_id)
    )
  );
    }
  
}, [instructors]);
//console.log(Math.ceil(count/6))

const fetchMoreOptions=(pageNumber,pageSize,key)=>{
  if(key!=''){
    dispatch(GetAllInstructorsRequest({filter:{ page_number:pageNumber, page_size:pageSize,keyword:key}}))

  }
  else{
  dispatch(GetAllInstructorsRequest({filter:{ page_number:pageNumber, page_size:pageSize}}))
  }
  //console.log("SCROLL");
}


const handleClose=()=>{
  setOpenAddModel(false)
  setAllInstructors([]);
}


  const handleChange =  (event, newValue) => {
    //console.log(newValue)
    setSelectedOptions(newValue.map(instructor => instructor.instructor_id));
  };
  const handleAddInstructor=()=>{
    const requestedData={
      body:{
        teacher_ids:selectedOptions
      },
      course_id:course_id,
      action:()=>{
        dispatch(getCourseInstructorsRequest({ course_id }));
        handleClose()

      }
    }
    dispatch(AddCourseInstructorsRequest(requestedData))
  }
  const deleteInstrucors=(id)=>{
    const requestedData={
      course_id:course_id,
      teacher_id:id,
      action:()=>{
        dispatch(getCourseInstructorsRequest({ course_id }));
        handleClose()

      }
    }
    setOpenDelteModel({state:false, index:null})
    dispatch(DeleteCourseInstructorsRequest(requestedData))
  }
  
  //console.log(selectedOptions)

  useEffect(()=>{
     !isInstructor&& dispatch(GetAllInstructorsRequest({filter:{ page_number:1, page_size:10}}))
  },[])
  
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
      backgroundColor: "#2f80ed17",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
 
 
  

  
  return (
    <TableContainer component={Paper} sx={{ background: "white", borderRadius: "12px" }}>
      <Typography m={2} fontWeight="600" fontSize="16px">
        Instructors
      </Typography>
      <Divider />
      <Table aria-label="customized table">
        <TableBody>
          {data?.map((instructor, index) => {
            return (
              <StyledTableRow key={index}>
                <StyledTableCell component="th" scope="row" width="75%">
                  <Stack direction="row" alignItems="center" justifyconten="center">
                    <Avatar
                      alt={instructor?.name}
                      src={instructor?.profile_picture_url}
                      sx={{ width: 30, height: 30 }}
                    />
                    <Typography mx="20px" textTransform="capitalize">
                      {instructor?.name}
                    </Typography>
                  </Stack>
                </StyledTableCell>
                <StyledTableCell align="right" width="18%">
                  <Button variant="outlined" onClick={()=>setOpenDelteModel({state:true, index:index})} sx={{textTransform:'none',color:'red',borderColor:'#BDBDBD'}}>
                  <img src={trash} alt="" /> Remove
                  </Button>
                  <DeleteModal
                  isInstructor={true}
                  open={openDeleteModel}
                  text={`Are you sure you want to delete "${instructor?.name}" instructor`} 
                  handelDeleteRequest={()=>deleteInstrucors(instructor?.instructor_id)}
                  handleClose={()=>setOpenDelteModel({state:false, index:null})}
                  index={index}
              >
              </DeleteModal>
                  
                </StyledTableCell>
              </StyledTableRow>
            );
          })}
    { !isInstructor&&     <StyledTableRow>
            <StyledTableCell component="th" scope="row" width="75%">
              <Stack direction="row" sx={{ cursor: "pointer" }}>
                <img src={icons.AddPersonIcon} alt="" />
                <Typography color={secondryColor} mx="4px" fontSize="14px" onClick={()=>setOpenAddModel(true)} >
                  Add New Instructor
                </Typography>
              </Stack>
            </StyledTableCell>
            <StyledTableCell align="right" width="18%"></StyledTableCell>
          </StyledTableRow>}
        </TableBody>
      </Table>
      
              
      <AddInstructor 
      openModel={openAddModel}
      handleClose={handleClose}
      handleChange={handleChange}
      fetchMoreOptions={fetchMoreOptions}
      hasMore={pageNumber<Math.ceil(count/10)}
      count={count}
      options={allInstructors}
      handleAction={handleAddInstructor}
      pageNumber={pageNumber}
       title='Add New Instructor'
        subTitle={'instructor Name'} 
        btnTitle={'Add'}></AddInstructor>
    </TableContainer>
  );
};

export default CourseInstructors;
