import React, { useState } from "react";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import MoreVertIcon from "@mui/icons-material/MoreHorizRounded";
import { ReactSortable } from "react-sortablejs";
import { Divider, Stack, Typography, IconButton, Grid } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SectionActions from "../actionsMenu/sectionData";
import { useDispatch } from "react-redux";
import { GetAllAssignmentsRequest } from "services/modules/courses/Requests/assignment";
import { getAllCourseQuizzesRequest } from "services/modules/courses/Requests/quizes";
import { getCourseSectionsRequest, reoderCourseSectionItemsRequest, reoderCourseSectionRequest } from "services/modules/courses/Requests/sections";

export const SectionsData = ({ sessions, sectionID, setOpen, setSelectedSessionData,section }) => {
  const { course_id } = useParams();
  const course = useSelector((state) => state.courses.course);
  const [values, setValues] = useState([]);
  const [display, setDisplay] = useState({ index: 0, state: true });
  const { inputBg } = useThemePalette();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const [session, setSession] = useState();
  const dispatch = useDispatch();

  /*useEffect(() => {
    if (course_id) {
      dispatch(getAllCourseQuizzesRequest({ course_id }));
      dispatch(GetAllAssignmentsRequest({ course_id }));
    }
  }, [course_id]);
  useEffect(() => {
    if (course_id && sectionID) {
      dispatch(getAllCourseSessionsRequest({ course_id, section_id: sectionID }));
    }
  }, [course_id, sectionID]);*/

  const handleClick = (event, data) => {
    setSession(data);
    event.stopPropagation();
    if (anchorEl === event.currentTarget) setAnchorEl(null);
    else setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  useEffect(() => {
    //console.log(values, sessions, "values");

    setValues(sessions);
  }, [sessions]);

  //console.log(values)
  const checkType = (obj) => {
    //console.log(obj, "values");

    if (obj.type==='Assignment') return "Assignment";
    else if (obj.type==='Quiz') return "Quiz";
    else if (obj.type==='CourseSession') return "Session";
  };
//console.log(session)
  const navigateTo = (session, quiz_id) => {
    if (checkType(session) === "Quiz") return navigate(`/courses/${course_id}/quiz/${quiz_id}/questions`);
    else if (checkType(session) === "Assignment")
      return navigate(`/courses/${course_id}/assignment/${sectionID}/${session?.id}/about`);
      else if (checkType(session) === "Session")
      return navigate(`/session/${course_id}/${sectionID}/${session?.id}`);
    else setOpen(true);
    setSelectedSessionData(session);
  };
  const handleSectionItemsChange = (evt) => {
    //console.log(evt)
    const { newIndex,oldIndex, item } = evt;
    const draggedSection = values[oldIndex];
    //console.log(draggedSection)

     const requestData={
      course_id,
      section_id:sectionID,
      item_type:draggedSection?.type,
      item_id:draggedSection?.id,


      new_position:newIndex+1,
      action:()=>{
       dispatch(getCourseSectionsRequest({ course_id }));
      }
    }
      dispatch(reoderCourseSectionItemsRequest(requestData));
    
};
  return (
    <>
      {values?.length > 0 && <Divider sx={{ width: "100%" }} />}
      <ReactSortable list={values} setList={setValues} onEnd={(elm) =>{
            elm.stopPropagation();

        handleSectionItemsChange(elm)}}>
        {values?.map((session, index) => {
          // NOTE 3yzen el section _ id yegy fe el quiz
          // if (sectionID === session?.section_id)
          
          return (
            <React.Fragment key={index}>

            <Stack
              id={session?.id}
              border="1px solid #DDDDDE"
              key={index}
              justifyContent="space-between"
              alignItems="center"
              padding="15px"
              direction="row"
              bgcolor={display?.index === index ? inputBg : "transparent"}
              mb={2}
              borderRadius="6px"
              onClick={(e) => {
                e.stopPropagation()
                if (display?.index === index) setDisplay({ index: "", state: false });
                else
                  setDisplay({
                    index: index,
                    state: !display?.state,
                  });
              }}
            >
              {/* Course Details  */}
              <Grid container alignItems={'center'} justifyContent={{ md: "flex-start", xs: "space-between" }}>
                <Grid item xs={0.7}>
                  <DragIndicatorIcon alignItems={'center'} sx={{
                     cursor: "move", 
                     color: "#BDBDBD",
                     transform: "rotate(90deg)",

                      }} />
                </Grid>
                <Grid
                  item
                  xs={3.3}
                  md={1.6}
                  // onClick={() => setOpen(true)}
                  sx={{ cursor: "pointer" }}
                >
                  <Typography
                    color="#2F80ED"
                    sx={{ fontSize: { md: "14px", xs: "12px" } }}
                    onClick={() => navigateTo(session, session?.id)}
                  >
                    {checkType(session)}
                  </Typography>
                </Grid>
                <Grid item md={8} xs={6}>
                  <Typography
                    color="#45464E"
                    fontSize="12px"
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    maxWidth={{ lg: "80%" }}
                  >
                    {session?.title}
                  </Typography>
                </Grid>
              </Grid>
              {/* Actions Icon */}
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={(e) => handleClick(e, session)}
                style={{ marginLeft: "20px" }}
              >
                <MoreVertIcon />
              </IconButton>
            </Stack>
            </React.Fragment>

          );
          // return <></>
        })}
      </ReactSortable>
      <SectionActions
        course={course}
        anchorEl={anchorEl}
        open={open}
        handleClick={handleClick}
        handleClose={handleClose}
        sectionID={sectionID}
        session={session}
      />
    </>
  );
};