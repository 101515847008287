import CTable from "../../../Molecules/Table/table";
import React, { useEffect, useState } from "react";
import { Avatar, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { GetAllStudentsRequest } from "services/modules/students";
import { useSelector } from "react-redux";
import {  getCourseJoinedUsersRequest } from "services/modules/courses/Requests/joined-pendingUsers";
import { useParams } from "react-router";
import { enrollStudentModel } from "services/modules/models/Actions";

const JoinedUsers = ({ students, viewData, data }) => {
  const joinedUsers = useSelector((state) => state.students.students)
  const {  page_number } = useSelector((state) => state.students)
  const joinedUsersCount = useSelector((state) => state.courses.joinedUsersCount)

  const { course_id } = useParams();


const [allStudents, setAllStudents] = useState([]);
const isInstructor=localStorage.getItem("userType")==="Instructor"||localStorage.getItem("userType")==="Instructor"

const [page,setPage]=useState(page_number)
useEffect(()=>{
    dispatch(getCourseJoinedUsersRequest({filter:{course_id,page_number:page,page_size:4,status:'joined'}}))
},[page])
useEffect(()=>{
    dispatch(getCourseJoinedUsersRequest({filter:{ course_id:course_id,page_number:1,page_size:4,status:'joined'} }));

  }, [])
  const dispatch = useDispatch();

  const handleSearchChange = (key) => {
    if (key) {
      dispatch(getCourseJoinedUsersRequest({ filter: { course_id: course_id, page_number: 1, page_size: 4, status: 'joined', keyword: key } }));
    }
    else {
      dispatch(getCourseJoinedUsersRequest({ filter: { course_id: course_id, page_number: 1, page_size: 4, status: 'joined' } }));

    }
  }

  useEffect(() => {
    if (page_number === 1 && allStudents.length != joinedUsers.length) {
      setAllStudents(joinedUsers)
    }
    else {
      setAllStudents([...allStudents, ...joinedUsers]);
      if (Array.isArray(students) && students.length > 0) {
        // Remove items from allStudents that match items in viewData
        setAllStudents((prevAllStudents) =>
          prevAllStudents.filter(
            (student) => !students.find((viewStudent) => student.student_id === viewStudent.student_id)
          )
        );
      }
    }
  }, [joinedUsers]);


  
useEffect(()=>{
  !isInstructor&& dispatch(GetAllStudentsRequest({ filter:{page_number:1, page_size:10}}))
},[])
   
   

  const mappedData = students?.map((student, index) => {
    return {
      ...student,
      name: <>
        <Stack direction="row" alignItems="center" justifyconten="center">

          <Avatar
            alt={student?.name}
            src={student?.profile_picture_url}
            sx={{ width: 30, height: 30 }}
          />
          <Typography mx="20px" textTransform="capitalize">
            {student?.name}
          </Typography>
        </Stack>
      </>,
      created_at: <>{dayjs(student?.created_at).format("DD-MM-YYYY hh:mm a")}</>,


        };
    });
    const columns = [
        {
            Header: "School",
            accessor: "school",
        },
        {
            Header: "School Class",
            accessor: "school_class",
        },
        {
            Header: "Name",
            accessor: "name",
        },
        {
            Header: "Email",
            accessor: "email",
        },
        {
            Header: "Created At",
            accessor: "created_at",
        },
        {
            Header: "",
            accessor: "Actions",
        },
    ];
    return (
           <>
        < CTable
            selectedData={viewData}
            footerBtnTitle
            noFilter={false}
            noSearchBar={false}
            Data={mappedData || []}
            Columns={columns}
            Title="Joined Users"
            subTitle=""
            btnTitle=""
            placeHolder=""
            hasBottomAddBtn={true}
            bottomBtnTitle={'Add New User'}
            hasPagination={false }
            btnAction={()=>{dispatch(enrollStudentModel())}}
            displayAddBtn={isInstructor?false:true}
            total_count={joinedUsersCount}
            paginationPage={page}
            handlePageChange={setPage}
            perPage={4}
            handleSearchChange={handleSearchChange}
        />
    </>

  )
}
export default JoinedUsers;