/* eslint-disable array-callback-return */
import Types from "./Types";

const INIT_STATE = {
  students: [],
  student: {},
  session: {},
  count: 0,
  page_number: 0,
  unrolledCourses: [],
  unrolledCoursesCount: 0,
  isLoading: {
    type: "",
    loading: false,
  },
};

export function studentReducer(state = INIT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.CREATE_STUDENT_SUCCESS: {
      return {
        ...state,
        students: state.students.concat(payload),
      };
    }
    case Types.STUDENT_LOADING: {
      return {
        ...state,
        isLoading: payload,
      };
    }
    case Types.SESSION_LOADING: {
      return {
        ...state,
        isLoading: payload,
      };
    }
    case Types.GET_ALL_STUDENTS_SUCCESS: {
      return {
        ...state,
        students: payload?.data?.students,
        count: payload?.extra.total_count,
        page_number: payload?.extra.page_number,
      };
    }
    case Types.SHOW_STUDENT_DETAILS_SUCCESS: {
      return {
        ...state,
        student: payload.data,
      };
    }
    case Types.DELETE_STUDENT_SUCCESS: {
      return {
        ...state,
        students: state.students.filter((val) => val?.session_id != payload?.session_id),
      };
    }
    case Types.ENROLL_STUDENT_TO_MULTIPLE_COURSES: {
      return {
        ...state,
      };
    }
    case Types.UPDATE_STUDENT_SUCCESS: {
      let newData = payload.data;
      let sessionArr = state.students;
      let index;
      sessionArr?.map((val, i) => {
        if (val?.quiz_question_id === newData?.quiz_question_id) index = i;
      });
      sessionArr[index] = newData;
      return {
        ...state,
        students: sessionArr,
      };
    }
    case Types.GET_STUDENTS_UNENROLLED_COURSES: {

      return {
        ...state,
        unrolledCourses: payload?.extra?.page_number == 1
          ? payload?.data?.courses
          : [...state.unrolledCourses, ...payload?.data?.courses],
        unrolledCoursesCount: payload?.extra?.total_count,
      };
    }

    default: {
      return state;
    }
  }
}
