import { CoursesURL } from "services/modules/utils/constant";
import {
  createAssignmentSuccess,
  ShowAssignmentDetailsSuccess,
  updateAssignmentSuccess,
  deleteCourseAssignmentSuccess,
  getAllCourseAssignmentsSuccess,
  getSectionCourseAssignmentsSuccess,
  getAssignmentSubmissionsSuccess,
  getStudentAssignmentsSuccess,
} from "../Actions/assignments";
import { reduxRequest } from "common/utils/reduxRequest";
import { adminCoursesLoading } from "../Actions/courses";
import { makeFilterString } from "../../utils/Helper";
import { getStudentQuizzesSuccess } from "../Actions/quizes";

export const CreateAssignmentRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: adminCoursesLoading,
    successFunction: createAssignmentSuccess,
    url: `/v2/admin/courses/${variables?.course_id}/assignments`, // until session part is clear
    method: "post",
    loadingType: "create",
    ...variables,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const UpdateAssignmentRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: adminCoursesLoading,
    successFunction: updateAssignmentSuccess,
    url: `/v2/admin/courses/${variables.course_id}/assignments/${variables?.assignment_id}?course_id=${variables?.course_id}`,
    method: "put",
    loadingType: "update",
    ...variables,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const ShowAssignmentDetailsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: adminCoursesLoading,
    successFunction: ShowAssignmentDetailsSuccess,
    url: `/v2/admin/courses/${variables.course_id}/assignments/${variables?.assignment_id}`,
    method: "get",
    loadingType: "details",
    ...variables,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
export const GetAllAssignmentsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: adminCoursesLoading,
    successFunction: getAllCourseAssignmentsSuccess,
    url: `${CoursesURL}/${variables?.course_id}/assignments`,
    method: "get",
    loadingType: "list",
    ...variables,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
export const GetSectionAssignmentsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: adminCoursesLoading,
    successFunction: getSectionCourseAssignmentsSuccess,
    url: `${CoursesURL}/sections/${variables?.section_id}/assignments?course_id=${variables?.course_id}`,
    method: "get",
    loadingType: "listAssignments" ,
    ...variables,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const DeleteAssignmentRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: adminCoursesLoading,
    successFunction: deleteCourseAssignmentSuccess,
    url: `${CoursesURL}/${variables?.course_id}/assignments/${variables?.assignment_id}`,
    method: "delete",
    loadingType: "delete",
    ...variables,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};


export const GetAssignmentSubmissionsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: adminCoursesLoading,
    successFunction: getAssignmentSubmissionsSuccess,
    url: variables?.unsubmitted===false?`/v2/admin/courses/${variables.course_id}/assignments/${variables?.assignment_id}/submissions${makeFilterString(variables?.filter)}`
    :`/v2/admin/courses/${variables.course_id}/assignments/${variables?.assignment_id}/submissions/${variables?.unsubmitted}`,
    method: "get",
    loadingType: "list",
    ...variables,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const getStudentAssignmentsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: adminCoursesLoading,
    successFunction: getStudentAssignmentsSuccess ,
    url: `v2/users/${variables?.user_type}/${variables?.student_id}/assignments${variables?.filter ? makeFilterString(variables?.filter) : null}`,
    method: "get",
    loadingType: "getStudentAssignments",
    ...variables,
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};