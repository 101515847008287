import { Breadcrumbs, Stack, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import { useThemePalette } from "../../../common/hooks/theme_palette";

const Breadcrumb = ({ data }) => {
  const { activeLinkColor } = useThemePalette();

  return (
    <Stack
      p={1}
      px={2}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      fontWeight="500"
    >
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
        {data?.map((breadcrumbs, index) => {
          return breadcrumbs?.active ? (
            <NavLink to={breadcrumbs?.path} style={{ textDecoration: "none" }} key={index}>
              <Typography color={breadcrumbs?.active ? activeLinkColor : "#919293"} fontSize="14px">
                {" "}
                {breadcrumbs?.title}
              </Typography>
            </NavLink>
          ) : (
            <Typography
              key={index}
              color={breadcrumbs?.active ? activeLinkColor : "#919293"}
              fontSize="14px"
            >
              {" "}
              {breadcrumbs?.title}
            </Typography>
          );
        })}
      </Breadcrumbs>
    </Stack>
  );
};

export default Breadcrumb;
