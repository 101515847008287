
import Types from './Types';

const INIT_STATE = {
    questionBanks: [],
    questionBank: {},
    selectedQuestions: [],
    count: 0,
    load: {
        type: '',
        isLoading: false
    }
};

export default function questionBanksReducer(state = INIT_STATE, action) {
    const { type, payload } = action;

    switch (type) {
        case Types.GET_QUESTION_BANKS_SUCCESS: {
            return {
                ...state,
                questionBanks: payload.data,
            };
        }

        case Types.GET_QUETION_BANK_DETAILS_SUCCESS: {
            return {
                ...state,
                questionBank: payload.data
            };
        }

        case Types.QUETION_BANK_LOADING: {
            return {
                ...state,
                load: payload
            };
        }

        default: {
            return state;
        }
    }
}