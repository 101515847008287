import { Avatar, Button, Menu, MenuItem, Select, Stack, Typography } from '@mui/material'
import React, { useState } from "react";
import NotificationsIcon from '@mui/icons-material/Notifications';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { logoutRequest } from "../../../services/modules/authentication";
import { logoutSuccess } from "../../../services/modules/authentication/Actions";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import ConfirmationModal from "../Models/ConfirmationModal";
import axios from 'axios';
const Header = ({ title }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openLogoutModal, setOpenLogoutModal] = useState(false);
    const navigate=useNavigate();
    const dispatch=useDispatch()
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleLogout = () => {
        handleClose();
        const requestData={
            action:()=>{
                delete axios.defaults.headers.common["Authorization"];

            }
        }
        navigate("/");
        dispatch(logoutRequest(requestData));
        localStorage.clear();
        sessionStorage.clear();
        // window.location.reload();
        dispatch(logoutSuccess());
        localStorage.removeItem("token");
        window.dispatchEvent(new Event("storage"));
    };
    return (
      <>
          <ConfirmationModal
            open={openLogoutModal}
            setOpen={setOpenLogoutModal}
            handleConfirmation={handleLogout}
            handleCancellation={() => setOpenLogoutModal(false)}
            text={["Are you sure you want to logout?"]}
            confirmBtnTitle="Logout"
          />
        <Stack bgcolor='white' p={2} direction='row' justifyContent='space-between' alignItems='center' fontWeight='500' boxShadow='0px 2px 1px rgba(0, 0, 0, 0.1)'>
            <Typography gutterBottom component="div" fontSize='20px' fontWeight='500'>
                {title}
            </Typography>

            <Stack direction='row' px={4} justifyContent='space-between' alignItems='center' width={{ sm: '50%', md: '28%', xl: '20%' }}>
                <NotificationsIcon />
                <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" sx={{ borderRadius: '8px' }} />
                <p>Remy Sharp</p>
                <div>
                    <Button
                        id="demo-positioned-button"
                        aria-controls={open ? 'demo-positioned-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                    >
                        <KeyboardArrowDownIcon />
                    </Button>
                    <Menu
                        id="demo-positioned-menu"
                        aria-labelledby="demo-positioned-button"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        <MenuItem onClick={handleClose}>Profile</MenuItem>
                        <MenuItem onClick={handleClose}>My account</MenuItem>
                        <MenuItem onClick={()=>{
                                setOpenLogoutModal(true);
                        }}>Logout</MenuItem>
                    </Menu>
                </div>
            </Stack>
        </Stack>
      </>

    )
}

export default Header