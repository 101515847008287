import { Paper, Stack, Typography } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import React, { useEffect, useState } from "react";
import TextFieldInput from "components/Atoms/Input/TextField/TextFieldInput";
import UploadInput from "components/Atoms/Input/FileInput/UploadInput";
import dayjs from "dayjs";
import BasicDatePicker from "components/Atoms/Input/DatePicker/DatePicker";
import BasicTimePicker from "components/Atoms/Input/TimePicker/TimePicker";
import SearchableSelect from "components/Atoms/Select/SearchableSelect";
import MultiUpload from "components/Atoms/Input/FileInput/MultiUpload";
import { StringToDateTime } from "common/utils/commonFunctions";

const QuizInfo = ({ formik }) => {
  const { inputBg } = useThemePalette();
  const [startDate, setStartDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [startTime, setStartTime] = useState(dayjs().format("HH:mm:ss"));
  const [Offset, setOffset] = useState(dayjs().format("Z")); //this is specific to time zones , will asscoiate it with the start date for now

  const [endDate, setEndDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [endTime, setEndTime] = useState(dayjs().format("HH:mm:ss"));

  console.log(formik.errors);

  useEffect(() => {
    if (formik.values.start_date && formik.values.due_date) {
      const [start_date_part, start_time_part, off_set] = StringToDateTime(
        formik.values.start_date
      );
      const [end_date_part, end_time_part] = StringToDateTime(formik.values.due_date); //end off set will not be used, it's the same

      setStartDate(start_date_part);
      setStartTime(start_time_part);
      setOffset(off_set);

      setEndDate(end_date_part);
      setEndTime(end_time_part);
    }
  }, [formik.values.start_date, formik.values.due_date]);
  return (
    <Stack spacing={2} direction="column" component={Paper} p={4}>
      <Stack spacing={1} direction="column">
        <TextFieldInput
          label="Quiz Title"
          name="title"
          required
          placeholder="Quiz Title"
          sx={{
            padding: "0",
            borderRadius: "8px",
            "& .MuiInputBase-root": {
              background: inputBg,
            },

            "& fieldset": {
              border: formik.errors.title ? true : "none",
            },
          }}
          onChange={formik.handleChange}
          value={formik?.values?.title}
          error={formik.errors.title}
          helperText={formik.errors.title}
        />

        {/* <SearchableSelect
          options={[{id:0,name:'hossam'},{id:1,name:'test'}]}
          placeholder="course"
          title="Course Title"
          onChange={(value)=>{
            formik.setValues({ ...formik?.values, 'course_title': value })
          }}
          // value={formik.values.course_title}
          // error={formik.errors.course_title}
          // helperText={formik.errors.course_title}
          required
          />

          <SearchableSelect
          options={[{id:0,name:'hossam'}]}
          placeholder="section title"
          title="Section Title"
          onChange={(value)=>{
            formik.setValues({ ...formik?.values, 'section_title': value })
          }}
          // value={formik.values.section_title}
          // error={formik.errors.section_title}
          // helperText={formik.errors.section_title}

          required
          />
 */}
        {/* Temporarily commented until implemented from the backend side */}

        {/* <UploadInput
        title={'File URL'}
        id="file"
        btnText="Choose File"
        placeholder="Select File"
        btnStyle={{
          background: "#2F80ED",
          color: "white",
          width: "100%",
          height: "100%",
          borderRadius: "0 8px 8px 0",
          textTransform: "capitalize",
        }}
        inputStyle={{
          background: inputBg,
          borderRadius: "8px 0 0 8px",
          "& fieldset": {
            border: "none",
          },
        }}
        // change={(e) => uploadFile(e, "image")}
        // textValue={imageFileName}
        formik={formik}
      /> */}

        <TextFieldInput
          label="Quiz description"
          name="description"
          required
          sx={{
            padding: "0",
            borderRadius: "8px",
            "& .MuiInputBase-root": {
              background: inputBg,
            },

            "& fieldset": {
              border: formik.errors.description ? true : "none",
            },
          }}
          placeholder="Description"
          onChange={formik.handleChange}
          value={formik?.values?.description}
          error={formik?.errors?.description}
          helperText={formik?.errors?.description}
          multiline
          rows={4}
        />
        <Stack direction="row" spacing={2} width="100%">
          <Stack width="50%">
            <Typography fontSize="12px" color="#5E6366" mb={1}>
              Start date
            </Typography>
            <BasicDatePicker
              id="start_date"
              label="hossam"
              value={formik.values.start_date}
              onChange={(e) =>
                formik.setValues({
                  ...formik?.values,
                  start_date: dayjs(e).format("YYYY-MM-DD") + " " + startTime + " " + Offset,
                })
              }
              placeholder="Select date"
              sx={{
                "& .MuiInputBase-root": {
                  background: inputBg,
                },
                width: "100%",
                borderRadius: "8px",
                "& fieldset": {
                  border: formik.errors.start_date ? true : "none",
                },
              }}
              disablePast={true}
              maxDate={endDate}
              error={formik.errors.start_date}
              helperText={formik.errors.start_date}
            />
          </Stack>
          <Stack width="50%">
            <Typography fontSize="12px" color="#5E6366" mb={1}>
              Start date Time
            </Typography>
            <BasicTimePicker
              id="start_date_time"
              value={dayjs(formik?.values.start_date)}
              onChange={(e) => {
                formik.setValues({
                  ...formik?.values,
                  start_date: startDate + " " + dayjs(e).format("HH:mm:ss Z"),
                });
              }}
              placeholder="Select time"
              sx={{
                background: inputBg,
                width: "100%",
                borderRadius: "8px",
                "& fieldset": {
                  border: formik.errors.start_date ? true : "none",
                },
              }}
              maxTime={dayjs(formik?.values?.due_date)}
              disableIgnoringDatePartForTimeValidation={true}
            />
          </Stack>
        </Stack>

        <Stack direction="row" spacing={2} width="100%">
          <Stack width="50%">
            <Typography fontSize="12px" color="#5E6366" mb={1}>
              Due date
            </Typography>
            <BasicDatePicker
              id="due_date"
              value={formik.values?.due_date}
              onChange={(e) =>
                formik.setValues({
                  ...formik?.values,
                  due_date: dayjs(e).format("YYYY-MM-DD") + " " + startTime + " " + Offset,
                })
              }
              placeholder="Select date"
              sx={{
                "& .MuiInputBase-root": {
                  background: inputBg,
                },
                borderRadius: "8px",
                width: "100%",
                "& fieldset": {
                  border: formik.errors.due_date ? true : "none",
                },
              }}
              disablePast={true}
              minDate={startDate}
              error={formik.errors.due_date}
              helperText={formik.errors.due_date}
            />
          </Stack>
          <Stack width="50%">
            <Typography fontSize="12px" color="#5E6366" mb={1}>
              Due date time
            </Typography>
            <BasicTimePicker
              id="due_date_time"
              value={dayjs(formik?.values.due_date)}
              onChange={(e) => {
                formik.setValues({
                  ...formik?.values,
                  due_date: endDate + " " + dayjs(e).format("HH:mm:ss Z"),
                });
              }}
              placeholder="Select time"
              sx={{
                background: inputBg,
                width: "100%",
                borderRadius: "8px",
                "& fieldset": {
                  border: formik.errors.due_date ? true : "none",
                },
              }}
              minTime={dayjs(formik?.values?.start_date)}
              disableIgnoringDatePartForTimeValidation={true}
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default QuizInfo;
