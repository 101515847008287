import { useRef, useEffect, useState } from "react";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import {
  Box,
  Divider,
  InputBase,
  Paper,
  Stack,
  TableContainer,
  Typography,
  TableRow,
  TableCell,
  Table,
  TableBody,
} from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { useDispatch } from "react-redux";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import "../styles/styles.css";
import { updateAdminCourseDetailsRequest } from "services/modules/courses/Requests/courses";
import EditableInput from "components/Atoms/Input/EditableInput/EditableInput";
import { resetEditableInputValue } from "services/modules/utils/Helper";

const CourseMainDetails = ({ data }) => {
  const { course_id } = useParams();
  const dispatch = useDispatch();
  const title = useRef("");
  const description = useRef("");

  const [courseData, setCourseData] = useState(data);
  //console.log(courseData['end_date'])
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const handleUpdateDate = (newValue, name) => {
    const requestData = {
      course_id,
      body: {
        course: {
          [name]: dayjs(newValue).format(),
        },
      },
    };
    dispatch(updateAdminCourseDetailsRequest({ ...requestData }));
  };
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
      backgroundColor: "#2f80ed17",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  // Date Component function
  const startDateComp = (name) => {
    return (
      <Stack
        position="relative"
        direction="row"
        alignItems="center"
        className="date-input"
        zIndex="10"
      >
        <CalendarMonthIcon className="calendar" sx={{ marginInline: "10px" }} />
        <MobileDatePicker
          className="calender-input"
          inputFormat="DD/MM/YYYY"
          value={courseData[name]}
          onChange={(newValue) => {
            setCourseData((s) => {
              const updatedData = { ...s, [name]: dayjs(newValue) };
              //console.log(dayjs(newValue))
              handleUpdateDate(newValue, name);
              return { ...s, [name]: dayjs(newValue) };
            });
          }}
          renderInput={(params) => <InputBase {...params} />}
          // disablePast={true}
        />
      </Stack>
    );
  };

  const handleChange = (evt, ref) => {
    ref.current = evt.target.value;
  };

  const handelAccept = () => {
    const titleValue = document.getElementById("title").value;
    const descriptionValue = document.getElementById("description").value;

    const requestData = {
      course_id,
      body: {
        course: {
          title: titleValue,
          description: descriptionValue,
          start_date: dayjs(courseData["start_date"]),
          end_date: dayjs(courseData["end_date"]),
        },
      },
    };
    dispatch(updateAdminCourseDetailsRequest({ ...requestData }));
  };

  useEffect(() => {
    if (data) setCourseData(data);
  }, [data]);
  const sideOptions = () => {
    <TableContainer component={Paper} sx={{ background: "white", borderRadius: "12px" }}>
      <Typography m={2} fontWeight="500" fontSize="14px">
        Upload User Email by CSV File
      </Typography>
    </TableContainer>;
  };

  return (
    <Stack flexDirection="row" gap={4}>
      <TableContainer component={Paper} sx={{ background: "white", borderRadius: "12px" }}>
        <Typography m={2} fontWeight="600" fontSize="16px">
          Course Details
        </Typography>
        <Divider />
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableBody>
            <StyledTableRow>
              <StyledTableCell component="th" scope="row" width="20%">
                Title
              </StyledTableCell>
              <StyledTableCell align="right" width="80%">
                <EditableInput
                  handelAccept={handelAccept}
                  width={75}
                  name="title"
                  id="title"
                  refProp={title}
                  defaultValue={courseData?.title}
                  handelChange={(e) => handleChange(e, title)}
                  // handleBlur={(name) =>  resetEditableInputValue(name, courseData[name])}
                  onClose={(name) => resetEditableInputValue(name, courseData[name])}
                />
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell component="th" scope="row">
                Description
              </StyledTableCell>
              <StyledTableCell align="right">
                <EditableInput
                  multiline={true}
                  handelAccept={handelAccept}
                  rows={4}
                  width={75}
                  name="description"
                  id="description"
                  refProp={description}
                  defaultValue={courseData?.description}
                  handelChange={(e) => handleChange(e, description)}
                  handleBlur={(name) => handleBlur(name)}
                  onClose={(name) => resetEditableInputValue(name, courseData[name])}
                />
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell component="th" scope="row">
                Start Date
              </StyledTableCell>
              <StyledTableCell align="right">
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <Box width="25%">{startDateComp("start_date")}</Box>
                  <Typography width="30%" textAlign="start">
                    End Date (Options)
                  </Typography>
                  <Box width="25%">{startDateComp("end_date")}</Box>
                </Stack>
              </StyledTableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default CourseMainDetails;
