import { Stack, Typography } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import BasicDatePicker from "components/Atoms/Input/DatePicker/DatePicker";
import BasicTimePicker from "components/Atoms/Input/TimePicker/TimePicker";
import TextFieldInput from "components/Atoms/Input/TextField/TextFieldInput";
import UploadInput from "components/Atoms/Input/FileInput/UploadInput";

import dayjs from "dayjs";
import React, { useState } from "react";
import MultiUpload from "components/Atoms/Input/FileInput/MultiUpload";

const FormDetails = ({ formik }) => {
  const { inputBg } = useThemePalette();
  const [imageFileName, setImageFileName] = useState("");

  //console.log(formik.values)
  return (
    <Stack gap={2}>
      <TextFieldInput
        type="number"
        label="Grade"
        sx={{
          background: inputBg,
          padding: "0",
          borderRadius: "8px",
          "& fieldset": {
            border: "none",
          },
        }}
        placeholder="Enter here"
        name="overall_grade"
        onChange={formik.handleChange}
        value={formik.values.overall_grade}
        error={formik.errors.overall_grade}
        helperText={formik.errors.overall_grade}
      />

      <Stack direction="row" spacing={2} width="100%">
        <Stack width="50%">
          <TextFieldInput
            type="number"
            label="Passing Percentage"
            sx={{
              background: inputBg,
              padding: "0",
              borderRadius: "8px",
              "& fieldset": {
                border: "none",
              },
            }}
            placeholder="Enter here"
            name="passing_percentage"
            onChange={formik.handleChange}
            value={formik.values.passing_percentage}
            error={formik.errors.passing_percentage}
            helperText={formik.errors.passing_percentage}
          />
        </Stack>

        <Stack width="50%">
          <TextFieldInput
            type="number"
            label="Position"
            sx={{
              background: inputBg,
              padding: "0",
              borderRadius: "8px",
              "& fieldset": {
                border: "none",
              },
            }}
            placeholder="Enter here"
            name="position"
            onChange={formik.handleChange}
            value={formik.values.position}
            error={formik.errors.position}
            helperText={formik.errors.position}
          />
        </Stack>
      </Stack>

      <Stack direction="row" spacing={2} width="100%">
        <Stack width="50%">
          <Typography fontSize="12px" color="#5E6366" mb={1}>
            Due date
          </Typography>
          <BasicDatePicker
            id="due_date"
            value={formik.values?.due_date}
            onChange={(e) =>
              formik.setValues({ ...formik?.values, due_date: dayjs(e).format("YYYY-MM-DD") })
            }
            placeholder="Select date"
            sx={{
              background: inputBg,
              borderRadius: "8px",
              width: "100%",
              "& fieldset": {
                border: "none",
              },
            }}
          />
        </Stack>
        <Stack width="50%">
          <Typography fontSize="12px" color="#5E6366" mb={1}>
            Due date time
          </Typography>
          <BasicTimePicker
            id="due_date_time"
            value={formik.values.due_date_time}
            onChange={(e) => {
              // //console.log(dayjs(e).format("LT"));
              // //console.log(e);
              formik.setValues({ ...formik?.values, due_date_time: e });
            }}
            placeholder="Select time"
            sx={{
              background: inputBg,
              width: "100%",
              borderRadius: "8px",
              "& fieldset": {
                border: "none",
              },
            }}
          />
        </Stack>
      </Stack>

      <Stack direction="row" spacing={2} width="100%">
        <Stack width="50%">
          <Typography fontSize="12px" color="#5E6366" mb={1}>
            Late Due date
          </Typography>
          <BasicDatePicker
            id="start_date"
            label="hossam"
            value={formik.values.late_due_date}
            onChange={(e) =>
              formik.setValues({ ...formik?.values, late_due_date: dayjs(e).format("YYYY-MM-DD") })
            }
            placeholder="Select date"
            sx={{
              background: inputBg,
              width: "100%",
              borderRadius: "8px",
              "& fieldset": {
                border: "none",
              },
            }}
          />
        </Stack>
        <Stack width="50%">
          <Typography fontSize="12px" color="#5E6366" mb={1}>
            Late Due date Time
          </Typography>
          <BasicTimePicker
            id="late_due_date_time"
            value={formik.values.late_due_date_time}
            onChange={(e) => formik.setValues({ ...formik?.values, late_due_date_time: e })}
            placeholder="Select time"
            sx={{
              background: inputBg,
              width: "100%",
              borderRadius: "8px",
              "& fieldset": {
                border: "none",
              },
            }}
          />
        </Stack>
      </Stack>

      <TextFieldInput
        label="Assignment description"
        required
        sx={{
          background: inputBg,
          padding: "0",
          borderRadius: "8px",
          "& fieldset": {
            border: "none",
          },
        }}
        placeholder="Description"
        name="body"
        onChange={formik.handleChange}
        value={formik.values.body}
        error={formik.errors.body}
        helperText={formik.errors.body}
        multiline
        rows={4}
      />
      {/* Temporarily commented until implemented from the backend side */}

      {/* <MultiUpload
          id="file"
          btnText="Choose File"
          placeholder="Select File"
          btnStyle={{
            background: "#2F80ED",
            color: "white",
            width: "100%",
            height: "100%",
            borderRadius: "0 8px 8px 0",
            textTransform: "capitalize",
          }}
          inputStyle={{
            background: inputBg,
            borderRadius: "8px 0 0 8px",
            "& fieldset": {
              border: "none",
            },
          }}
          // change={(e) => uploadFile(e, "image")}
          textValue={imageFileName}
          formik={formik}
        /> */}
    </Stack>
  );
};

export default FormDetails;
