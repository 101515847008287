import * as Yup from "yup";
import { useFormik } from "formik";
import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  CreateSessionRequest,
  ShowSessionDetailsRequest,
  UpdateSessionRequest,
} from "services/modules/courses/Requests/sessions";
import SessionTemplate from "Templates/Courses/session/Session.Template";
import { GetAllQuizzesRequest } from "services/modules/quiz";

const SessionPage = ({ setLayoutData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const session = useSelector((state) => state.courses.session);
  const { section_id, session_id, course_id } = useParams();
  useEffect(() => {
    setLayoutData({
      title: "Session",
      breadcrumb: [
        { title: "Home", path: "/", active: true },
        { title: "Courses", path: "/courses", active: true },
        {
          title: "Course Detail",
          path: `/courses/${course_id}`,
          active: true,
        },
        { title: "Create Session", active: false },
      ],
    });
  }, [course_id]);

  useEffect(() => {
    if (session_id) dispatch(ShowSessionDetailsRequest(course_id, session_id));
  }, [session_id]);
  useEffect(()=>{
    if(course_id){
      dispatch(GetAllQuizzesRequest({course_id}))  
    }
  },[])

  const formik = useFormik({
    initialValues: session_id ? session : {course_session_type:'video',expected_time_unit:'minutes',expected_time: '0',max_number_of_views:'2'},
    validationSchema: Yup.object().shape({}),
    enableReinitialize: true,
    onSubmit: (values) => {
      const data = { course_session: values };
      const action = (response) => {
        //console.log(response)
        navigate(`/session/${course_id}/${section_id}/${response?.data?.course_session_id}`);
      };
      if (session_id) {
        dispatch(UpdateSessionRequest(course_id, section_id, session_id, data, action));
      } else {
        dispatch(CreateSessionRequest({course_id, section_id, data,action}));
      }
    },
  });
  return <SessionTemplate formik={formik} />;
};

export default SessionPage;
