import { useSelect } from "@mui/base";
import { CircularProgress, Stack, Typography } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import { forgetPasswordSchema, loginSchema } from "common/utils/schemas";
import Form from "components/Molecules/Forms/form";
import FormField from "components/Molecules/Forms/formField";
import SubmitButton from "components/Molecules/Forms/submitButton";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { OTPRequest } from "services/modules/authentication";
import { Organization_name } from "services/modules/utils/constant";

const ForgetPassword=()=>{
    const {fontColor}=useThemePalette();
    const navigate=useNavigate();
    const dispatch=useDispatch();
    const{load}=useSelector((state)=>state.auth)
    const handleFormSubmit = (values,{setSubmitting}) => {
        const requestData={
            body:{
                user:values
            },
            Organization_name,
            action:()=>{
                navigate('/verify_otp')
            }
        }
        dispatch(OTPRequest(requestData))
        setSubmitting(false);
        localStorage.setItem('forgetPasswordEmail',values?.email)
      };
return(
    <>
    <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'center'} width={'100%'} height={'100vh'}  sx={{backgroundColor:'#F1F4F6'}}>
    <Stack p={5} postion={'relative'}   width={'40%'} height={'auto'} sx={{backgroundColor:'white'}}>
      <Stack sx={{ gap: 4 }}>
      <Stack sx={{ gap: 1 }}>

        <Typography  textAlign={'center'} fontFamily="Inter" fontSize="16px" fontWeight='600'>
          Forget Password
        </Typography>
        <Typography color='#6E7079' fontSize={'14px'}>Please enter your email address associated
             with your account to send you OTP to reset your password</Typography>
             </Stack>
        <Form
          initialValues={{
            email: "",
          }}
          validationSchema={forgetPasswordSchema}
          onSubmit={(values, { setSubmitting }) => {
            handleFormSubmit(values,{setSubmitting});
          }}
        >
          <Stack sx={{ gap: 4 }}>
            <FormField
              label="Email*"
              name="email"
              type="text"
              labelStyle={{ fontSize: "12px" ,fontWeight:'400',mb:1,color:fontColor}}
              placeholder="Enter your email"
            />
            <Stack direction="row"
                   alignItems="center">
              <SubmitButton sx={{height:'52px',fontSize: "12px",fontWeight:'400',mb:1}} >
              {load?.loading ? <CircularProgress size={20} color="inherit" /> : "Reset Password"}
                </SubmitButton>
            </Stack>
          </Stack>
        </Form>
      </Stack>
    </Stack>
    </Stack>
    </>
)
}
export default ForgetPassword