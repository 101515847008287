import {
  Paper,
  TableContainer,
  Typography,
  Stack,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
  styled,
  tableCellClasses,
  IconButton,
  Badge,
  Avatar,
} from "@mui/material";
import { icons } from "assets/AssetHelper";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getPresignedUrlRequest } from "services/modules/uploadFile";

const InstructorDetailsTemplate = ({ instructor }) => {
  const dispatch = useDispatch();
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
      backgroundColor: "#2f80ed17",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  const data = [
    { title: "Email", value: instructor?.email ||'-' },
    { title: "User", value: instructor?.name ||'-'},
    { title: "User type", value: instructor?.job_title||'-' },
    { title: "Country Code", value: instructor?.country_code },
    { title: "Phone Number", value: instructor?.phone_number ||'-' },
    { title: "Created At", value: "" },
    { title: "Updated At", value: "" },
  ];

  return (
    <Stack
      width={{
        sm: "100%",
        md: "90%",
        lg: "85%",
      }}
      padding={{ md: "20px" }}
      spacing={3}
    >
      <TableContainer component={Paper} sx={{ background: "white", borderRadius: "12px" }}>
        <Typography m={2} fontWeight="600" fontSize="16px">
          Instructor Details
        </Typography>
        <Divider style={{ width: "100%" }} />
        <Badge
          sx={{ margin: "20px" }}
          overlap="circular"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Avatar
            alt="Travis Howard"
            sx={{ width: "90px", height: "90px" }}
            src={instructor?.profile_picture_url}
          />
        </Badge>
        <Table aria-label="customized table">
          <TableBody>
            {data?.map((data, index) => {
              return (
                <StyledTableRow key={index}>
                  <StyledTableCell sx={{ width: "10%", color: "#2C2D33" }}>
                    {data?.title}
                  </StyledTableCell>
                  <StyledTableCell width="25%" sx={{ color: "#6E7079" }}>
                    {data?.value}
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default InstructorDetailsTemplate;
