import Types from "../Types";
export const getCourseSectionsSuccess = (payload) => ({
    type: Types.GET_COURSE_SECTIONS_SUCCESS,
    payload,
});
export const reorderCourseSectionSuccess = (payload) => ({
    type: Types.REORDER_COURSE_SECTION_SUCCESS,
    payload,
});
export const deleteCourseSectionSuccess = (payload) => ({
    type: Types.DELETE_COURSE_SECTION_SUCCESS,
    payload,
});