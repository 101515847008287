import { CoursesURL } from "../../utils/constant";
import { makeFilterString } from "../../utils/Helper";
import {
  adminCoursesLoading,
  getAdminCourseDetailsSuccess,
  getAdminCoursesSuccess,
  updateAdminCourseDetailsSuccess,
  deleteAdminCourseSuccess,
  createCourseSuccess,
  getAdminCoursesSearchSuccess,
} from "../Actions/courses";
import { reduxRequest } from "common/utils/reduxRequest";

export const getAdminCoursesRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: adminCoursesLoading,
    successFunction: getAdminCoursesSuccess,
    url: `${CoursesURL}${variables?.filter ? makeFilterString(variables?.filter) : null}`,
    method: "get",
    loadingType: "list",
    ...variables,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const getAdminCourseDetailsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: adminCoursesLoading,
    successFunction: getAdminCourseDetailsSuccess,
    url: `${CoursesURL}/detail?course_id=${variables?.course_id}`,
    method: "get",
    loadingType: "details",
    ...variables,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
export const getAdminSearchCourseRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: adminCoursesLoading,
    successFunction: getAdminCoursesSearchSuccess,
    url: `${CoursesURL}/search${variables?.filter ? makeFilterString(variables?.filter) : null}`,
    method: "get",
    loadingType: "search course",
    ...variables,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const updateAdminCourseDetailsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: adminCoursesLoading,
    successFunction: updateAdminCourseDetailsSuccess,
      url: `${CoursesURL}?course_id=${variables?.course_id}`,
    method: "put",
    loadingType: "update",
    ...variables,
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const deleteAdminCourseRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: adminCoursesLoading,
    successFunction: deleteAdminCourseSuccess,
    url: `${CoursesURL}/?course_id=${variables?.course_id}`,
    method: "delete",
    loadingType: "delete course",
    ...variables,
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const createAdminCourseRequest = ({ ...variables }) => {
  console.log(variables);
  const requestData = {
    isLoading: adminCoursesLoading,
    successFunction: createCourseSuccess,
    url: `${CoursesURL}`,
    method: "post",
    loadingType: "create",
    action:variables?.action,
    ...variables,
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
