import { Avatar, Divider, Stack, Typography, Table, TableBody, TableCell, TableContainer, TableRow, Paper, Box } from "@mui/material";
import TextFieldInput from "components/Atoms/Input/TextField/TextFieldInput";
import React from "react";
import { styled } from '@mui/material/styles';

const StyledTableCell = styled(TableCell)(() => ({
  '&.MuiTableCell-head': {
    backgroundColor: 'black',
    color: 'red',
    boxShadow: 'none', 
    textAlign: 'left', 
  },
  '&.MuiTableCell-body': {
    fontSize: 14,
    boxShadow: 'none', 
    textAlign: 'left', 
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(even)': {
    backgroundColor: 'rgba(47, 128, 237, 0.08)',
    boxShadow: 'none'
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const EditableTable = ({ formik }) => {
    const handleInputChange = (event, index) => {
      const { value } = event.target;
      // Update the value in Formik
      const updatedTable = [...formik.values.table];
      updatedTable[index].value = value;
      formik.setFieldValue('table', updatedTable);
    };
  
    return (
      <TableContainer component={Box}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableBody>
            {formik.values.table.map((row, index) => (
              <StyledTableRow key={row.name}>
                <StyledTableCell component="th" scope="row">
                  {row.name}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {row.editable ? (
                    <TextFieldInput
                      value={row.value}
                      onChange={(event) => handleInputChange(event, index)}
                    />
                  ) : (
                    row.value
                  )}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };
export default EditableTable;
