import { Box, Button, CircularProgress, Divider, Stack, Typography } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import Form from "components/Molecules/Forms/form";
import SubmitButton from "components/Molecules/Forms/submitButton";
import OTPInput, { ResendOTP } from "otp-input-react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { OTPRequest, OTPVerify } from "services/modules/authentication";
import { Organization_name } from "services/modules/utils/constant";


const VerifyOTP=()=>{ 
    const [OTP, setOTP] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { gray, orange } = useThemePalette();
  const{load}=useSelector((state)=>state.auth)

  const renderButton = (buttonProps) => {
    return (
      <Typography
        sx={{
          color: buttonProps.remainingTime !== 0 ? "#727272" : orange,
          cursor: buttonProps.remainingTime !== 0 ? " not-allowed" : "pointer",
          fontWeight: "600",
          position: "absolute",
          top: 0,
          right: "70px",
          fontFamily: "Jost",
        }}
        {...buttonProps}
      >
        Resend
      </Typography>
    );
  };

  const renderTime = (remainingTime) => {
    return <Typography>{remainingTime}s</Typography>;
  };

  const email = localStorage.getItem("forgetPasswordEmail");
  const handleSubmit = () => {
    const action = (data) => {
      localStorage.setItem("verification-token", data?.data?.token);
      navigate('/reset_password')
    };
    const requestData = {
      body: {
        user: {
         verification_code: OTP,
          email,
        },
        "device": {
            "uid": "1",
            "fcm_token": "il"
        }
      },
      Organization_name,
      action,
    };
    dispatch(OTPVerify(requestData));
  };
  const handleResendEmail = () => {
    setOTP(null)
    const requestBody = {
      body: {
        user: {
          email,
        },
      },
      Organization_name
    };
    dispatch(OTPRequest(requestBody));
  };
    return(
 <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'center'} width={'100%'} height={'100vh'}  sx={{backgroundColor:'#F1F4F6'}}>
    <Stack p={5} postion={'relative'}   width={'40%'} height={'auto'} sx={{backgroundColor:'white'}}>
          
          <Stack sx={{ gap: 1 }} alignItems={'center'} justifyContent={'center'}>
            <Typography fontFamily="jost" fontSize="32px" fontWeight="400">
              Check your email
            </Typography>
            <Typography fontFamily="jost" fontSize="18px" color={gray}>
              We have sent OTP to {email}
            </Typography>
            <Divider sx={{ border: "1px solid #F5F5F5" }} />
          </Stack>
          <Form
          initialValues={{
            verification_code: "",
            email:localStorage.getItem('forgetPasswordEmail')
          }}
          onSubmit={(values, { setSubmitting }) => {
            handleSubmit(values,{setSubmitting});
          }}
        >
          <Stack sx={{ mt: 4, gap: 4 }} alignItems={'center'} justifyContent={'center'}>
            <OTPInput
              value={OTP}
              onChange={setOTP}
              autoFocus
              OTPLength={6}
              otpType="number"
              disabled={false}
              className="opt-input-container"
              inputClassName="otp-input"
            />
         <Button variant='contained' fullWidth sx={{textTransform:'none'}} onClick={handleSubmit} >
         {load?.loading? <
                CircularProgress size={20} color="inherit" /> : "Done"}
            </Button> 
            <Stack
              sx={{
                color: gray,
                flexDirection:'row',
                gap:13,
                textAlign: "center",
                position: "relative",
              }}
            >
            <Typography >
              Didn&apos;t receive the email?
              </Typography>
              <Stack flexDirection={'column'}>
              <ResendOTP
                maxTime={30}
                renderButton={renderButton}
                renderTime={renderTime}
                onResendClick={handleResendEmail}
                className="reset-otp-container "
              />
              </Stack>
            </Stack>
          </Stack>
          </Form>
      </Stack>
      
      </Stack>

    )

}
export default VerifyOTP;