import { useState } from "react";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { InputBase, Stack } from "@mui/material";

import "./EditableInput.css";

const EditableInput = ({
  id,
  withControles = true,
  handelAccept,
  rows,
  defaultValue,
  customSection,
  width = 75,
  placeholder,
  styles,
  isDisabled = false,
  name,
  type,
  handelChange,
  handleBlur,
  multiline,
  refProp,
  onClose,
  ...props
}) => {
  const [display, setDisplay] = useState(false);
  // //console.log(display)

  return (
    <button
      className="custom_editable_container"
      onFocus={() => {
        setDisplay(true);
      }}
      onBlur={() => {
        setDisplay(false);
      }}
    >
      <Stack spacing={2} direction="row" alignItems="center" position="relative" width="100%">
        <Stack spacing={2} direction="row" alignItems="center" width={`${width}%`}>
          {customSection}
          <InputBase
            id={id}
            multiline={multiline}
            rows={rows}
            ref={refProp}
            name={name}
            type={type}
            onChange={(e) => {
              handelChange(e);
            }}
            defaultValue={defaultValue}
            disabled={isDisabled}
            className="custom_editable_input"
            placeholder={placeholder}
            onBlur={_=>handleBlur(name)}
            autoFocus={display ? true : false}
            sx={{
              width: "100%",
              background: "transparent",
              borderRadius: "8px",
              padding: "6px",
              border: display ? "0.5px solid #BDBDBD" : "0",
              ...styles,
            }}
            {...props}
          />
        </Stack>
        {/* controles */}
        {withControles && (
          <Stack
            spacing={2}
            direction="row"
            sx={{ visibility: display ? "visible" : "hidden" }}
            className="controles"
            width={`${100 - width}%`}
          >
            <Stack
              alignItems="center"
              justifyContent="center"
              className="accept"
              onClick={handelAccept}
            >
              <CheckIcon />
            </Stack>
            <Stack
              alignItems="center"
              justifyContent="center"
              className="cancel"
              onClick={() => {
                setDisplay(false);
                onClose && onClose(name);
              }}
            >
              <CloseIcon />
            </Stack>
          </Stack>
        )}
      </Stack>
    </button>
  );
};

export default EditableInput;
