import CTable from "../../../Molecules/Table/table";
import React from "react";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { Avatar, Divider, Paper, TableContainer, Typography } from "@mui/material";
import { icons } from "../../../../assets/AssetHelper";
import { Stack } from "@mui/system";
import { useThemePalette } from "../../../../common/hooks/theme_palette";
import dayjs from "dayjs";
import TableWithBottomBtn from "components/Molecules/Table/TableWithBottomBtn/tableWithBottomBtn";

const Coupons = ({ coupons, viewData, data }) => {
    //console.log(coupons)
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        "&:nth-of-type(even)": {
            backgroundColor: "#2f80ed17",
        },
        // hide last border
        "&:last-child td, &:last-child th": {
            border: 0,
        },
    }));
    const mappedData = coupons?.map((Coupon, index) => {
        return {
            ...Coupon,
            discount_percentage:<>
            {Coupon?.discount_percentage}%
            </>,
            name: <>
                <Stack direction="row" alignItems="center" justifyconten="center">

                    <Avatar
                        alt={Coupon?.name}
                        src={Coupon?.profile_picture_url}
                        sx={{ width: 30, height: 30 }}
                    />
                    <Typography mx="20px" textTransform="capitalize">
                        {Coupon?.name}
                    </Typography>
                </Stack>
            </>,
            created_at: <>{dayjs(Coupon?.created_at).format("DD-MM-YYYY hh:mm a")}</>,


        };
    });
    const columns = [

        {
            Header: "Discount Percentage",
            accessor: "discount_percentage",
        },
        {
            Header: "Code",
            accessor: "code",
        },
        {
            Header: "Country Code",
            accessor: "country_code",
        },
        {
            Header: "Phone Number",
            accessor: "phone_number",
        },
        {
            Header: "",
            accessor: "Actions",
        },
    ];
    return (

        < TableWithBottomBtn
            selectedData={viewData}
            footerBtnTitle
            noFilter={false}
            noSearchBar={true}
            Data={mappedData || []}
            Columns={columns}
            Title="Coupons"
            subTitle=""
            btnTitle=""
            placeHolder=""
            hasBottomAddBtn={true}
            bottomBtnTitle={'Add New Coupon'}
            hasPagination={false}
        />
    )
}
export default Coupons;