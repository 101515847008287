import { MenuItem, Typography, Stack } from "@mui/material";

import { icons } from "assets/AssetHelper";

function MenuPopup({ anchorEl, handleClose, indx, handleDelete}) {
  return (
    <Stack
      id="basic-menu"
      // anchorEl={anchorEl}
      bgcolor="white"
      padding={1}
      sx={{
        display: anchorEl?.state && anchorEl?.index === indx ? "flex" : "none",
        position: "absolute",
        right: "45px",
        top: "-25px",
        borderRadius: "12px",
        boxShadow: "0px 4px 32px rgba(0, 0, 0, 0.08)",
        alignItems: "center",
        justifyContent: "flex-start",
        width: "130px",
      }}
      onClose={handleClose}
    >
      <MenuItem
        onClick={handleClose}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          width: "100%",
        }}
      >
        <img src={icons.editIcon} alt="edit" />
        <Typography mx="7px">Edit </Typography>
      </MenuItem>
      <MenuItem
        onClick={handleDelete}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          width: "100%",
        }}
      >
        <img src={icons.deleteIcon} alt="delete" />
        <Typography mx="6px">Delete </Typography>
      </MenuItem>
    </Stack>
  );
}

export default MenuPopup;
