import { TextField } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
// import "./DatePicker.css";

export default function BasicDatePicker({ id, value, setValue, sx, placeholder, onChange,disablePast,disableFuture,minDate,maxDate,error ,helperText}) {
  return (
    <DesktopDatePicker
      id={id}
      inputFormat="DD/MM/YYYY"
      toolbarPlaceholder={placeholder}
      value={value}
      onChange={setValue ? (newValue) => setValue(newValue) : onChange}
      renderInput={(params) => <TextField {...params} sx={sx} error={error} helperText={helperText} 
      FormHelperTextProps={{ style: { backgroundColor: 'transparent' }}}

      />}
      disablePast={disablePast} //this is set to true if we are using a due date
      disableFuture={disableFuture}
      minDate={minDate}
      maxDate={maxDate}
    />
  );
}
