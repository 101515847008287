import { Stack,Box, Typography } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";

import PrimaryButton from "components/Atoms/Buttons/Primary/PrimaryButton";
import NormalTabs from "components/Atoms/Tabs/NormalTabs";
import TabPanel from "components/Atoms/Tabs/TabPanel";

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { DeleteQuizRequest, GetQuizSubmissionsRequest } from "services/modules/quiz";
import { useSelector } from "react-redux";
import { DeleteQuizSussess } from "services/modules/quiz/Actions";
import { useNavigate,useParams } from "react-router-dom";
import QuizTab from "components/Organisms/quiz/tabs/quizTab";
import ResponcesTab from "components/Organisms/quiz/tabs/responcesTab";
import { toast } from "react-toastify";

function QuizTemplate({
  setCards,
  cards,
  mappedData,
  formik
}) {
  const { secondryColor } = useThemePalette();
  const { course_id,tab,quiz_id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const tabs=['questions','responses'];
  const [value, setValue] = useState(tabs.indexOf(tab));
  const TabsOptions = [
    {
      id: 0,
      label: "Questions",
    },
    {
      id: 1,
      label: "Responses",
    },
  ];
  const handleTabChange = (value)=>{
    setValue(value)
    
    navigate(`/courses/${course_id}/quiz/${quiz_id}/${tabs[value]}`)
  }

  useEffect(()=>{
    if(tab === 'responses')
    {
      dispatch(GetQuizSubmissionsRequest({ course_id, quiz_id }))
    }
  },[tab])
  return (
    <Stack p="20px" my={4} flexDirection={'column'}>
      <Stack direction={{ md: "row", sm: "column" }} gap={2} display="flex">
        <Stack width="50%">
        <Box sx={{px:3}}>
          <NormalTabs
            TabsOptions={TabsOptions}
            value={value}
            change={() => {}}
            ContainerStyle="switch-container"
            TabStyle="switch-btn"
            onClick={(value) => handleTabChange(value)}
          />
          </Box>
        </Stack>
        <Stack width={{ lg: "50%", sm: "100%" }} justifyContent="center">
          <Stack
            spacing={2}
            direction="row"
            alignItems="center"
            justifyContent={{ md: "flex-end" }}
          >
            <PrimaryButton
              text="Delete"
              variant={"outlined"}
              style={{
                color: secondryColor,
                borderColor: secondryColor,
              }}
              click={() => {
                //  dealete quiz and reset quiz reducer
                const requestData={
                  course_id,
                  quiz_id,
                  action:()=>{
                    toast.success('Deleted quiz successfully')
                    navigate(`/courses/${course_id}`);
                  }
                }
                dispatch(DeleteQuizRequest(requestData));
              }}
            />
          </Stack>
        </Stack>
      </Stack>

      <TabPanel value={tab} indicies={['questions']}>
        <QuizTab
          cards={cards}
          setCards={setCards}
          formik={formik}
        />
    </TabPanel>
    <TabPanel value={tab} indicies={['responses']}>
      {mappedData.length ===0 ? 
      <Stack alignItems="center" justifyContent={'center'} width={"100%"}>
        <Typography fontSize={'50px'} color={'gray'}>No responses</Typography>

      </Stack>
      
      :
      <ResponcesTab mappedData={mappedData} />}

      </TabPanel>

    </Stack>
  );
}

export default QuizTemplate;
