import CTable from "../../../Molecules/Table/table";
import React, { useEffect, useState } from "react";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { Avatar, Divider, Paper, TableContainer, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import dayjs from "dayjs";
import TableWithBottomBtn from "../../../Molecules/Table/TableWithBottomBtn/tableWithBottomBtn";
import AddSubjectModel from "../models/AddSubjectModel";
import AddModel from "./addModel";
import { useSelector } from "react-redux";
import { getClassSubjectsLookupsRequest, getClassesLookupsRequest } from "services/modules/lookups";
import { useDispatch } from "react-redux";

const Subjects = ({ students, viewData, data }) => {
    const [isOpen,setIsOpen]=useState(false);
    const joinedUsers=useSelector((state)=>state.students.students)
    const curriculum=useSelector((state)=>state.lookups.curriculums)
    const classes=useSelector((state)=>state.lookups.classes)
    const classSubjects=useSelector((state)=>state.lookups.classSubjects)


    const [selectedCurriculum,setSelectedCurriculum]=useState()
    const [selectedClass,setSelectedClass]=useState()
    const [selectedClassSubject,setSelectedClassSubject]=useState()


    const dispatch=useDispatch();
    //console.log(selectedCurriculum)
    useEffect(()=>{
        if(selectedCurriculum){
           dispatch( getClassesLookupsRequest({curriculum_id:selectedCurriculum}))
        }

    },[selectedCurriculum])

    useEffect(()=>{
        if(selectedClass){
           dispatch( getClassSubjectsLookupsRequest({class_id:selectedClass}))
        }

    },[selectedClass])


    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));
    const openModel=()=>{

        setIsOpen(true)
      }
      const handleClose=()=>{
        setIsOpen(false)

      }
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        "&:nth-of-type(even)": {
            backgroundColor: "#2f80ed17",
        },
        // hide last border
        "&:last-child td, &:last-child th": {
            border: 0,
        },
    }));
   
    const columns = [
        {
            Header: "Curriculum",
            accessor: "curriculum",
        },
        {
            Header: "School Class",
            accessor: "school_class",
        },
        {
            Header: "Subject",
            accessor: "subject",
        },
       
    ];
    return (
<>
        < TableWithBottomBtn
            selectedData={viewData}
            footerBtnTitle
            noFilter={false}
            noSearchBar={true}
            Data={data?.subjects || []}
            Columns={columns}
            Title="Grades /Subjects"
            subTitle=""
            btnTitle=""
            placeHolder=""
            hasBottomAddBtn={true}
            bottomBtnTitle={'Add New Subject'}
            hasPagination={false}
            btnAction={openModel}

        />
        <AddSubjectModel
        openModel={isOpen}
        handleClose={handleClose}
        title='Add New Subject'
        subTitle1={'Curriculum '} 
        subTitle2={'School class '} 
        subTitle3={'School class subject '} 
        selectedCurriculum={selectedCurriculum}
        selectedClass={selectedClass}
        selectedClassSubject={selectedClassSubject}
        setSelectedCurriculum={setSelectedCurriculum}
        setSelectedClass={setSelectedClass}
        setSelectedClassSubject={setSelectedClassSubject}
        btnTitle={'Add'}
        options1={curriculum}
        options2={classes}
        options3={classSubjects}
        >

        </AddSubjectModel>
        </>
    )
}
export default Subjects;