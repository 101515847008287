import { Box } from '@mui/material'

const TitleCard = () => {
    return (
        <Box my={2}>
            TitleCard
        </Box>
    )
}

export default TitleCard