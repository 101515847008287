import { useDispatch, useSelector } from "react-redux";
import { TextField, Typography, Stack,Dialog,Box,CircularProgress,Button } from "@mui/material";
import { useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import { uploadCSVModel } from "../../../../services/modules/models/Actions";
import { useThemePalette } from "../../../../common/hooks/theme_palette";
import { icons } from "../../../../assets/AssetHelper";
import { getPresignedUrlRequest } from "services/modules/uploadFiles";
import PrimaryButton from "components/Atoms/Buttons/Primary/PrimaryButton";
import { GetQuestionRequest, ImportCSVQuestionsRequest } from "services/modules/quiz";

import { useParams } from "react-router-dom";

const ImportCSV = ({ }) => {
  const { inputBg, secondryColor } = useThemePalette();
  const isOpenUploadCSV = useSelector((state) => state.model.isOpenUploadCSV); 
  const loading = useSelector((state) => state.files.load.loading); 

  const { course_id,tab,quiz_id } = useParams();


  const dispatch = useDispatch();
  const [file,setFile]=useState(null);
  const [name,setName]=useState(null)
  const handleDrop = (e) => {
    e.preventDefault();
    // setDragging(false);
    const files = e.dataTransfer.files;
    handleChange(files[0])
  };

    const handleClose = () => {
        setFile(null)
        setName(null)
    };

    const handleDragEnter = (e) => {
        e.preventDefault();
        // setDragging(true);
    };

    const handleDragLeave = () => {
        // setDragging(false);
    };

    const handleChange = (fileName) => {
        
        dispatch(
          getPresignedUrlRequest({
            body: {
              file: fileName,
              extension: {
                files: [
                  {
                    extension: fileName.name.substring(
                      fileName.name.lastIndexOf(".") + 1,
                      fileName.name.length
                    ),
                  },
                ],
              },
            },
            updateData: (data) => {
                setFile(data)
                setName(fileName.name)
            },
          })
        );
      };
      
        const handleDeleteFile = (file) => {
        //removing from the form directly.
            //maybe store it in a usestate
      };
    
      const handleUpload =()=>{
        const requestData={
            course_id,
            quiz_id,
            file_url:file,
            action:()=>{
                dispatch(uploadCSVModel())
                setFile(null)
                setName(null)
                window.location.reload();
            }
        }
        dispatch(ImportCSVQuestionsRequest(requestData))
      }


  return (
    <Dialog
      open={isOpenUploadCSV}
      onClose={() => dispatch(uploadCSVModel())}
      PaperProps={{
        sx: {
          borderRadius: "12px",
          height: "auto",
          minWidth: "500px",
          padding: "20px",
        },
      }}
    >
        <Stack
            onClick={() => dispatch(uploadCSVModel())}
            sx={{
              cursor: "pointer",
              background: "#FFF2E2",
              borderRadius: "8px",
              alignItems: "center",
              justifyContent: "center",
              width:'10px',
              marginLeft:'auto',
              padding: "4px",
            }}
          >
            <CloseIcon fontSize="14px" />
          </Stack>

        <Stack flexDirection={'column'} spacing={2}>
            <Typography fontSize={'20px'}>Upload CSV</Typography>
            {file ? (
                    <Box
                    sx={{
                        border: "1px solid rgba(0, 0, 0, 0.25)",
                        borderRadius: "10px",
                        cursor: "pointer",
                    }}
                    padding={5}
                    >
                        <Stack flexDirection={'column'} spacing={1} alignItems={'center'} 
                        
                        onClick={() => window.open(file, '_blank')}
                        >
                            <img src={icons?.csvIcon} width={'90px'} height={'90px'} />
                            <Typography>{name}</Typography>
                        </Stack>
            <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              width: "48px",
              height: "48px",
              bgcolor: "white",
              borderRadius: "50%",
              filter: "drop-shadow(0px 0px 4px rgba(185, 185, 185, 0.25))",
              transform: "translate(-20%, 160%)",
              cursor: "pointer",
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </Stack>
                    </Box>
            ) : (
          <Box
          sx={{
            border: " 1px dashed rgba(0, 0, 0, 0.25)",
            borderRadius: "10px",
          }}
          maxHeight="260px"
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDragOver={(e) => e.preventDefault()}
          onDrop={handleDrop}
        >
          <label htmlFor={`upload-csv`}>
            <input
              id={`upload-csv`}
              name={`upload-csv`}
              type="file"
              style={{ display: "none" }}
              onChange={(e)=>{
                handleChange(e.target.files[0])
              }}
            />
            <Stack alignItems="center" rowGap={"24px"} py="3.5rem">
              {loading ? (
                <CircularProgress sx={{ color: secondryColor }} />
              ) : 
              
              (
                <>
                  <img src={icons?.uploadIcon} alt="upload icon" />
                  <Typography fontSize="13px">
                    Select a file or drag and drop here
                  </Typography>
                  <Button
                    component="span"
                    sx={{
                      color: 'orange',
                      border: `1px solid orange`,
                      fontSize: "15px",
                      px: "16px",
                    }}
                  >
                    Select File
                  </Button>
                </>
              )}
            </Stack>
          </label>
        </Box>)}



            <PrimaryButton
            width={{width:'100px'}}
            text="Upload"
            variant="contained"
            style={{
            background: file?secondryColor:'gray',
            color: "white",
            }}
            disabled={file?false:true}
            click={handleUpload}
        />
            
        </Stack>
    </Dialog>
  );
};

export default ImportCSV;
