import { Box } from "@mui/material";
import TextFieldInput from "components/Atoms/Input/TextField/TextFieldInput";
import {  useFormikContext } from "formik";
import ErrorMessage from "./ErrorMessage";

export default function FormField({
                                       label,
                                       type,
                                       name,
                                       labelStyle,
                                       placeholder,
                                       sx,
                                       ...rest
                                     }) {
  const { handleChange, errors, setFieldTouched, touched, setFieldValue } =
    useFormikContext();
  return (
    <Box>
      <TextFieldInput
        onBlur={() => setFieldTouched(name)}
        onChange={handleChange(name)}
        sx={sx}
        label={label}
        labelStyle={labelStyle}
        type={type}
        placeholder={placeholder}
        {...rest}
      />
      <ErrorMessage error={errors[name]} visible={touched[name]} />

    </Box>
  );
}
