import { Box,Modal, Typography } from "@mui/material";
import NormalTabs from "components/Atoms/Tabs/NormalTabs";
import TabPanel from "components/Atoms/Tabs/TabPanel";
import { useState ,useEffect} from "react";
import TextFieldInput from "components/Atoms/Input/TextField/TextFieldInput";
import { useThemePalette } from "common/hooks/theme_palette";
import { useParams } from "react-router-dom";
import { UpdateQuestionRequest } from "services/modules/quiz";
import { useDispatch } from "react-redux";
const AnswerReasons = ({open,handleClose,data, setCards, index, cards}) => {

    const { inputBg } = useThemePalette();
    const { course_id, quiz_id } = useParams();
    const dispatch =useDispatch();
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        borderRadius:'8px',
        p: 4,
      };
  const tabs=['correct answer','wrong answer','hint'];
  const [tab,setTab]=useState('correct answer')
  const [value, setValue] = useState(tabs.indexOf(tab));
  const TabsOptions = [
    {
      id: 0,
      label: "correct answer",
    },
    {
      id: 1,
      label: "wrong answer",
    },
    {
        id: 2,
        label: "hint",
      },
  
  ];


  const tabStyles = [
    { color: '#27AE60', borderBottomColor: '#27AE60' }, // Style for correct answer tab
    { color: '#EB5757', borderBottomColor: '#EB5757' },     // Style for wrong answer tab
    { color: '#F79E1B', borderBottomColor: '#F79E1B' },   // Style for hint tab (you can adjust as needed)
  ];

  const handleTabChange = (value)=>{
    setValue(value)
    setTab(tabs[value])
  }

useEffect(() => {
  const timeId = setTimeout(() => {
    const requestData = {
      course_id,
      quiz_id,
      quiz_question_id: cards[index]?.data?.quiz_question_id,
      body: {
        quiz_question: {
          correct_answer_explanation:cards[index]?.data?.correct_answer_explanation,
          wrong_answer_explanation:cards[index]?.data?.wrong_answer_explanation,
          hint:cards[index]?.data?.hint,
        },
      },
    };
    dispatch(UpdateQuestionRequest({ ...requestData }));
  }, 1000);
  return () => clearTimeout(timeId);
}, [cards[index]?.data?.correct_answer_explanation,cards[index]?.data?.wrong_answer_explanation,cards[index]?.data?.hint]);

  const handleTextChange =(fieldName,value)=>{
    const newCards = [...cards];
    newCards[index].data[fieldName] = value.target.value; 
    setCards(newCards);

  }

    return (
        <Modal
        open={open}
        onClose={handleClose}
        // aria-labelledby="modal-modal-title"
        // aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
            <NormalTabs
                TabsOptions={TabsOptions}
                value={value}
                change={() => {}}
                ContainerStyle="switch-container-reasons"
                TabStyle="switch-btn-reasons"
                tabStyles={tabStyles} // Pass the array of styles to NormalTabs

                onClick={(value) => handleTabChange(value)}
            />
                <TabPanel value={tab} indicies={['correct answer']}>
                    <TextFieldInput
                          label="Explain why is the correct answer"
                          name="correct_answer_explanation"
                        //   required
                          sx={{
                            background: inputBg,
                            padding: "0",
                            borderRadius: "8px",
                            "& fieldset": {
                              border: "none",
                            },
                          }}
                          placeholder="Description"
                          onChange={(value)=>{
                            handleTextChange('correct_answer_explanation',value)
                          }}
                          value={cards[index]?.data?.correct_answer_explanation}
                          multiline
                          rows={4}
                    
                    />
                </TabPanel>

                <TabPanel value={tab} indicies={['wrong answer']}>
                  <TextFieldInput
                          label="Explain why it's a wrong answer"
                          name="wrong_answer_explanation"
                        //   required
                          sx={{
                            background: inputBg,
                            padding: "0",
                            borderRadius: "8px",
                            "& fieldset": {
                              border: "none",
                            },
                          }}
                          placeholder="Description"
                          onChange={(value)=>{
                            handleTextChange('wrong_answer_explanation',value)
                            console.log('test to see',cards)
                          }}
                          value={cards[index]?.data?.wrong_answer_explanation}
                          multiline
                          rows={4}
                    
                    />
                </TabPanel>

                <TabPanel value={tab} indicies={['hint']}>
                  <TextFieldInput
                          label="Write down a hint"
                          name="hint"
                        //   required
                          sx={{
                            background: inputBg,
                            padding: "0",
                            borderRadius: "8px",
                            "& fieldset": {
                              border: "none",
                            },
                          }}
                          placeholder="Description"
                          onChange={(value)=>{
                            handleTextChange('hint',value)
                          }}
                          value={cards[index]?.data?.hint}
                          multiline
                          rows={4}
                    
                    />
                </TabPanel>


            
        </Box>
      </Modal>

    );
};

export default AnswerReasons