import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { withTranslation } from "react-i18next";

function TabPanel(props) {
  const { children, value, indicies, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={!indicies.includes(value)}
      id={`vertical-tabpanel-${value}`}
      aria-labelledby={`vertical-tab-${value}`}
      {...other}
    >
      {indicies.includes(value) && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  indicies: PropTypes.array.isRequired,
  value: PropTypes.number.isRequired,
};

export default withTranslation()(TabPanel);