import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import {
  Box,
  Button,
  Divider,
  MenuItem,
  Paper,
  Stack,
  TableContainer,
  Typography,
  TableRow,
  TableCell,
  Table,
  TableBody,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreHorizRounded";
import Vimeo from "@u-wave/react-vimeo";
import { useRef, useState } from "react";
import videoPlaceholder from "assets/images/video placeholder.jpg";
import imagePlaceholder from "assets/images/image placeholder.jpg";

import MenuPopup from "../../../Molecules/Menus/MenuPopup";
import UploadModel from "components/Atoms/Model/uploadModel";
import {
  getAdminCourseDetailsRequest,
  updateAdminCourseDetailsRequest,
} from "services/modules/courses/Requests/courses";
import { useFormik } from "formik";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import { openActionsMenu } from "services/modules/courses/Actions/courses";
import { useSelector } from "react-redux";
import ActionsDots from "components/Atoms/SharedComponents/ActionsDots";
import CActions from "components/Molecules/Menus/ActionList";

const CourseIntro = ({ data }) => {
  const [anchorEl, setAnchorEl] = useState({ index: "", state: false });
  const [openUploadModel, setOpenUploadModel] = useState(false);
  const { course_id } = useParams();
  const [fileName, setVideoFileName] = useState();
  const actionMenuDetails = useSelector((state) => state.courses.actionMenuDetails);
  const dispatch = useDispatch();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      video_url: data?.video_url || "",
      image_url: data?.image_url || "",
    },
    onSubmit: (values) => {
      const requestData = {
        body: {
          course: values,
        },
        course_id,
        action: () => {
          formik.resetForm();
          setOpenUploadModel(false);
        },
      };
      dispatch(updateAdminCourseDetailsRequest(requestData));
    },
  });

  // const handleClick = (index, state) => {
  //   if (actionMenuDetails.index === index && actionMenuDetails.state === state) {
  //     // If the menu is already open for this index and state, close it
  //     dispatch(openActionsMenu({ index: '', state: false }));
  //   } else {
  //     // Otherwise, open the menu for the specified index and state
  //     dispatch(openActionsMenu({ index, state }));
  //   }
  // };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenUploadModel(true);
    setUploadType(null)
    // dispatch(openActionsMenu({index,state:false}))
  };
  const [uploadType, setUploadType] = useState("");
  const handleDeleteImage = () => {
    setAnchorEl(null);

    const requestData = {
      body: {
        image_url: "",
      },
      course_id,
    };
    dispatch(updateAdminCourseDetailsRequest(requestData));
  };
  const handleDeleteVideo = () => {
    setAnchorEl(null);
    const requestData = {
      body: {
        video_url: "",
      },
      course_id,
    };
    dispatch(updateAdminCourseDetailsRequest(requestData));
  };
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
      backgroundColor: "#2f80ed17",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));


  const actionsList = ({data, index}) => {
    return [
      {
        condition: () => {
          return true;
        },
        click: () => {
          setOpenUploadModel(true);
          setUploadType(data.uploadType);
        },
        text: "Edit",
      },
      {
        condition: () => {
          return true;
        },
        click: () => {
          // Navigate or perform actions based on the uploadType
          data.uploadType === 'video' ? handleDeleteVideo() : handleDeleteImage();
        },
        text: "Delete",
      },
    ];
  };  
  const Actions = (id, elmIndex, data) => {
    return <CActions id={id} index={elmIndex} data={data} actionsList={actionsList} />;
  };
  const list = [
    {
      id: 1,
      uploadType: "video",
    },
    {
      id: 2,
      uploadType: "image",
    },
  ];
  const mappedData = list?.map((data, index) => {
    return { Actions: Actions(data?.id, index, data) };
  });
  return (
    <TableContainer component={Paper} sx={{ background: "white", borderRadius: "12px" }}>
      <Typography m={2} fontWeight="600" fontSize="16px">
        Intro
      </Typography>
      <Divider />
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableBody>
          <StyledTableRow>
            <StyledTableCell component="th" scope="row" width="20%">
              URL / Upload video (Optional)
            </StyledTableCell>
            <StyledTableCell align="right" width="70%">
              <Box width="216px" height="auto">
                {data?.video_url ? (
                  data?.video_url_type === "vimeo" ? (
                    <Vimeo
                      style={{ height: "100%" }}
                      className="vimeo"
                      width="100%"
                      video={data?.video_url}
                      controls
                      responsive
                    />
                  ) : (
                    <iframe
                      width="100%"
                      height="100"
                      src={data?.video_url}
                      title="Video Player"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  )
                ) : (
                  <img src={videoPlaceholder}></img>
                )}
              </Box>
            </StyledTableCell>
            <StyledTableCell align="right" width="10%">
              {mappedData[0]?.Actions}
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell component="th" scope="row" width="20%">
              Upload image
            </StyledTableCell>
            <StyledTableCell align="right" width="70%">
              <Box width="216px" height="142px">
                {data?.image_url ? (
                  <img
                    src={data?.image_url}
                    alt="course intro"
                    loading="lazy"
                    width="100%"
                    height="100%"
                  />
                ) : (
                  <img style={{ width: "209px", height: "142px" }} src={imagePlaceholder}></img>
                )}
              </Box>
            </StyledTableCell>
            <StyledTableCell align="right" width="10%">
              {mappedData[1]?.Actions}
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow height="20" />
          <UploadModel
            openVar={openUploadModel}
            setOpenUploadModel={setOpenUploadModel}
            title={"Upload File"}
            instantly_dispatch={true}
            formik={formik}
            type={uploadType}
            setVideoFileName={setVideoFileName}
            closeFunc={() => setOpenUploadModel(false)}
          ></UploadModel>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CourseIntro;
