import { Button } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import { useFormikContext } from "formik";

export default function SubmitButton({
                                       children = "SUBMIT",
                                       sx,
                                       onSubmit,
                                       ...otherProps
                                     }) {
  const { handleSubmit, isSubmitting } = useFormikContext();
  const { oliveGreen } = useThemePalette();

  return (
    <Button
      type="submit"
      fullWidth
      onClick={onSubmit ? onSubmit : handleSubmit}
      variant="contained"
      sx={{
        textTransform: "none",
        fontSize: "20px",
        p: 2,
        ...sx,
      }}
      disabled={isSubmitting}
      {...otherProps}
    >
      {children}
    </Button>
  );
}
