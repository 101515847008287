/* eslint-disable array-callback-return */
import Types from "./Types";

const INIT_STATE = {
  instructors: [],
  instructor: {},
  count:0,
  page_number:0,
  isLoading: {
    type: "",
    loading: false,
  },
};

export function instructorReducer(state = INIT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.CREATE_INSTRUCTOR_SUCCESS: {
      return {
        ...state,
        instructors: state.instructors.concat(payload),
      };
    }
    case Types.INSTRUCTOR_LOADING: {
      return {
        ...state,
        isLoading: payload,
      };
    }
    case Types.GET_ALL_INSTRUCTORS_SUCCESS: {
      return {
        ...state,
        instructors: payload?.data?.instructors,
        count:payload?.extra.total_count,
        page_number:payload?.extra.page_number
      };
    }
    case Types.SHOW_INSTRUCTOR_DETAILS_SUCCESS: {
      return {
        ...state,
        instructor: payload.data,
      };
    }
    case Types.DELETE_INSTRUCTOR_SUCCESS: {
      return {
        ...state,
        instructors: state.instructors.filter(
          (val) => val?.instructor_id != payload?.instructor_id
        ),
      };
    }
    case Types.UPDATE_INSTRUCTOR_SUCCESS: {
      let newData = payload.data;
      let instructorArr = state.instructors;
      let index;
      instructorArr?.map((val, i) => {
        if (val?.instructor_id === newData?.instructor_id) index = i;
      });
      instructorArr[index] = newData;
      return {
        ...state,
        instructors: instructorArr,
      };
    }

    default: {
      return state;
    }
  }
}
