import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import AdminUserDetailsTemplate from "../../Templates/AdminUsers/AdminUserDetails.Template";
import { ShowAdminUserDetailsRequest } from "../../services/modules/AdminUsers";
import Loader from "components/Atoms/Loader/Loader";

const AdminUserDetailsPage = ({ setLayoutData }) => {
  const dispatch = useDispatch();
    const { admin_id } = useParams();
    //console.log(admin_id)
    const {isLoading} = useSelector((state) => state.adminUsers);

    const adminUser = useSelector((state) => state.adminUsers.adminUser);
  useEffect(() => {
      if (admin_id) dispatch(ShowAdminUserDetailsRequest({ admin_id }));
  }, [admin_id]);
  useEffect(() => {
    setLayoutData({
      title: "Admin Users",
      breadcrumb: [
        { title: "Home", path: "/", active: true },

        {
          title: "Admin uses",
          path: "/admin-users",
          active: true,
        },
        {
          title: 'Admin User Details',
          path: "",
          active: false,
        },
      ],
    });
  }, []);
  return (<>
        <Loader open={isLoading?.loading}></Loader>
  <AdminUserDetailsTemplate adminUser={adminUser} />
  </>

  );
};

export default AdminUserDetailsPage;
