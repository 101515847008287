import { Stack } from "@mui/system";
import { createTheme, FormControl, IconButton, MenuItem, Pagination, PaginationItem, Select, Typography } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useThemePalette } from "common/hooks/theme_palette";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import arrowLeft from "assets/icons/ArrowLeft.svg"
import { ThemeProvider } from "@emotion/react";

const Footer = ({
  total_count,
  handlePageChange,
  paginationPage,
  perPage=10,
}) => {
  const { inputBg } = useThemePalette();
    const arrowLeft = <ArrowBackIcon />;
    const arrowRight = <ArrowForwardIcon />;
  return (
    <Stack
      direction={{ xs: "column", md: "row" }}
      bgcolor="transparent"
      alignItems="center"
      justifyContent="space-between"
      p={2}
    >
      <Stack
        direction="row"
        alignItems="center"
        width={{ xs: "100%", sm: "100%", md: "35%"}}
      >
              <Stack spacing={2}>
                
                      <Pagination
                          renderItem={(item) => (
                          <PaginationItem
                              slots=
                                  {{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                                  {...item}
                              />
                      )}
                      count={Math.ceil(total_count / perPage)}
                      shape='rounded'
                      color="primary"
                      page={paginationPage}
                      onChange={(e, data) => handlePageChange(data)}
                     
                      />

              </Stack>
      </Stack>
    </Stack>
  );
};

export default Footer;
