import Types from './Types';

export const getAdminLookupsSuccess = (payload) => ({
    type: Types.GET_ADMIN_LOOKUPS_SUCCESS,
    payload
});

export const adminLookupsLoading = (payload) => ({
    type: Types.ADMIN_LOOKUPS_LOADING,
    payload
});
export const getCurriculumsLookupsSuccess = (payload) => ({
    type: Types.GET_CURRICULUMS_LOOKUPS_SUCCESS,
    payload
});
export const getClassesLookupsSuccess = (payload) => ({
    type: Types.GET_CLASS_LOOKUPS_SUCCESS,
    payload
});
export const getScrollClassesLookupsSuccess = (payload) => ({
    type: Types.GET_SCROLL_CLASS_LOOKUPS_SUCCESS,
    payload
});
export const getClassSubjectsLookupsSuccess = (payload) => ({
    type: Types.GET_CLASS_SUBJECT_LOOKUPS_SUCCESS,
    payload
});
