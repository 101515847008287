const ModelType = {
  CREATE_COURSE_MODEL: "CREATE_COURSE_MODEL",
  ADD_MORE_QUETIONS_MODEL: "ADD_MORE_QUETIONS_MODEL",
  CREATE_INSTRUCTOR_MODEL: "CREATE_INSTRUCTOR_MODEL",
  CREATE_ADMIN_USER_MODEL:"CREATE_ADMIN_USER_MODEL",
  EDIT_ADMIN_USER_MODEL:"Edit_ADMIN_USER_MODEL",
  EDIT_STUDENT_USER_MODEL:"EDIT_STUDENT_USER_MODEL",
  ENROLL_STUDENT_MODEL:"ENROLL_STUDENT_MODEL",
  TOGGLE_ALERT_MODAL: "TOGGLE_ALERT_MODAL",
  UPLOAD_CSV_MODEL:"UPLOAD_CSV_MODEL",

};
export default ModelType;
