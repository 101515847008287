import Types from "./Types";
const SECTION_INIT_STATE={
    section:{},
    isLoading: {
        type: "",
        loading: false,
      },
}
export function sectionReducer(state=SECTION_INIT_STATE,action){
    const {type,payload}=action;
    switch(type){
        //loading
    case Types.COURSE_SECTION_LOADING: {
        return {
          ...state,
          isLoading: payload,
        };
      }
        case Types.ADD_SECTION_TO_COURSE_SUCCESS:{
            //console.log(payload)
            return{
                ...state,

                section:payload?.data,
            }
        }
        case Types.DELETE_SECTION_FROM_COURSE_SUCCESS:{
            return{
                ...state
            }
        }
        case Types.EDIT_SECTION_SUCCESS:{
            return{
                ...state,
        
            }
        }
        case Types.SHOW_SECTION_dETAILS_SUCCESS:{
            return{
                ...state,
                section:payload?.data,

            }
        }
        default: {
            return state;
          }
    }
}