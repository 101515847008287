import update from 'immutability-helper'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Card } from './card.jsx'
import { Stack } from '@mui/material';
import { useDragDropManager } from 'react-dnd';
import { useScroll } from '../../../../common/hooks/scroll.js';
import { useParams } from 'react-router-dom';

const Container = ({ cards, setCards }) => {

  const listRef = useRef(null);
  const { updatePosition } = useScroll(listRef);

  const dragDropManager = useDragDropManager();
  const monitor = dragDropManager.getMonitor();
  const [display, setDisplay] = useState({
    id: 1,
    state: 'block',
    index: 0
  })

  const moveCard = useCallback((dragIndex, hoverIndex) => {
    //console.log(dragIndex , hoverIndex)
    setCards((prevCards) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]],
        ],
      }),
    )
  }, [])
  const renderCard = useCallback((card, index, display, cards, setDisplay) => {
    return (
      <Card
        key={card?.data?.quiz_question_id}
        index={index}
        id={card?.data?.quiz_question_id}
        type={card?.type}
        data={card?.data}
        moveCard={moveCard}
        cards={cards}
        setCards={setCards}
        display={display}
        setDisplay={setDisplay}
      />
    )
  }, [])

  useEffect(() => {
    const unsubscribe = monitor.subscribeToOffsetChange(() => {
      const offset = monitor.getSourceClientOffset()?.y;
      updatePosition({ position: offset, isScrollAllowed: true });
    });
    return unsubscribe;
  }, [monitor, updatePosition]);

  // set first question data into the first question before clicking 

  return (
    <Stack spacing={2} ref={listRef}  >
      {cards.map((card, i) =>
        <div
          key={i}
          onClick={(event) => {
            if (event.target.tagName.toLowerCase() !== 'input') { // Check if the clicked element is not an input
              setDisplay((s) => ({
                state: 'block',
                id: card?.data?.quiz_question_id,
                index: i,
                data: card,
              }));
            }
          }}
            >
          {
            renderCard(card, i, display, cards, setDisplay)
          }
        </div>
      )}
    </Stack>
  )
}

export default Container