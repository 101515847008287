import CTable from "components/Molecules/Table/table";
import { Stack } from "@mui/material";
import EditStudentModel from "components/Organisms/students/models/create";

function StudentsListTemplate({
  viewData,
  data,
  setPerPage,
  pageNumber,
  perPage,
  checked,
  setChecked,
  page,
  setPage,
  count,
  selectedRow,
  setSelectedRow,
  actionsList,
  onRowClick,
  contextMenuActions,
}) {
  const columns = [
    {
      Header: "ID",
      accessor: "student_id",
    },
    {
      Header: "Name",
      accessor: "student_name",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Country Code",
      accessor: "country_code",
    },
    {
      Header: "Phone number",
      accessor: "phone_number",
    },
    {
      Header: "Phone Verified",
      accessor: "is_phone_verified",
      sort: false
    },
    {
      Header: "School Class",
      accessor: "school_class",
    },
    {
      Header: "School",
      accessor: "school",
    },
    {
      Header: "Status",
      accessor: "student_status",
    },
    {
      Header: "",
      accessor: "Actions",
    },
  ];

  return (
    <Stack my={6} p={2}>
      <CTable
        selectedData={viewData}
        footerBtnTitle
        noFilter={false}
        Data={data}
        Columns={columns}
        Title="Students List"
        subTitle=""
        btnTitle=""
        placeHolder=""
        setPage={setPage}
        setPerPage={setPerPage}
        currentpage={setPage}
        setPageNumber={setPage}
        handlePageChange={setPage}
        paginationPage={page}
        perPage={perPage}
        totalPage={Math.ceil(1 / 10)}
        total_count={count}
        checked={checked}
        setChecked={setChecked}
        contextMenuActions={contextMenuActions}
        onRowClick={onRowClick}
      />
      <EditStudentModel selectedRow={selectedRow} setSelectedRow={setSelectedRow} page={page} page_size={perPage}/>
    </Stack>
  );
}
export default StudentsListTemplate;
