const QuizType = {
  CREATE_QUIZ: "CREATE_QUIZ",
  CREATE_QUESTION: "CREATE_QUESTION",
  UPDATE_QUESTION: "UPDATE_QUESTION",
  UPDATE_QUIZ: "UPDATE_QUIZ",
  CREATE_CHOICES: "CREATE_CHOICES",
  UPDATE_CHOICES: "UPDATE_CHOICES",
  DELETE_CHOICES: "DELETE_CHOICES",
  DELETE_QUESTION: "DELETE_QUESTION",
  SHOW_CURRENT_QUIZ: "SHOW_CURRENT_QUIZ",
  DELETE_QUIZ_SUCCESS: "DELETE_QUIZ_SUCCESS",
  GET_QUIZ_QUESTIONS: "GET_QUIZ_QUESTIONS",
  GET_ALL_QUIZZES_SUCCESS: "GET_ALL_QUIZZES_SUCCESS",
  GET_QUESTIONS_BANK_SUCCESS: "GET_QUESTIONS_BANK_SUCCESS",
  SET_SELECTED_QUESTIONS_SUCCESS: "SET_SELECTED_QUESTIONS_SUCCESS",
  CREATE_QUESTION_MODEL_ANSWER_SUCCESS: "CREATE_QUESTION_MODEL_ANSWER_SUCCESS",
  UPDATE_QUESTION_MODEL_ANSWER_SUCCESS: "UPDATE_QUESTION_MODEL_ANSWER_SUCCESS",
  CLEAR_QUIZ_DATA: "CLEAR_QUIZ_DATA",
  QUIZ_LOADING: "QUIZ_LOADING",

  //the above part is old need refactoring
  GET_QUIZ_SUBMISSIONS_SUCCESS:"GET_QUIZ_SUBMISSIONS_SUCCESS",
  GET_QUIZ_SUBMISSION_SUCCESS:"GET_QUIZ_SUBMISSION_SUCCESS",
  // GET_QUESTIONS_BANK: 'GET_QUESTIONS_BANK',

  IMPORT_CSV_QUESTIONS_SUCCESS:"IMPORT_CSV_QUESTIONS_SUCCESS",
};

export default QuizType;
