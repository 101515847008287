const StudentType = {
  ADMIN_USERS_LOADING: "ADMIN_USERS_LOADING",
  GET_ALL_ADMIN_USERS_SUCCESS: "GET_ALL_ADMIN_USERS_SUCCESS",
  DELETE_ADMIN_USERS_SUCCESS: "DELETE_ADMIN_USERS_SUCCESS",
  UPDATE_ADMIN_USERS_SUCCESS: "UPDATE_ADMIN_USERS_SUCCESS",
  SHOW_ADMIN_USERS_DETAILS_SUCCESS: "SHOW_ADMIN_USERS_DETAILS_SUCCESS",
  CREATE_ADMIN_USERS_SUCCESS: "CREATE_ADMIN_USERS_SUCCESS",
};

export default StudentType;
