import axios from "axios";
import { BaseURL, managedCourse } from "../utils/constant";
import handleErrors from "../utils/handel_error";
import {
  CreateChoicesSuccess,
  CreateQuestionSuccess,
  CreateQuizSuccess,
  DeleteQuestionSuccess,
  ShowCurrentQuizSuccess,
  UpdateChoicesSuccess,
  UpdateQuestionSuccess,
  GetQuizQuestionsSusses,
  GetAllQuizzezSuccess,
  setSelectedQuestionsSuccess,
  DeleteChoicesSuccess,
  UpdateQuestionModelAnswerSuccess,
  CreateQuestionModelAnswerSuccess,
  DeleteQuizSussess,
  QuizLoading,
  GetQuizSubmissionsSuccess,
  GetQuizSubmissionSuccess,
  ImportCSVQuestions,
} from "./Actions";
import { reduxRequest } from "common/utils/reduxRequest";

// ----------------------------------------------------------------------------  Create and Updata Quiz ---------------------------------------------

export const CreateQuizRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: QuizLoading,
    successFunction: CreateQuizSuccess,
    url: `/v2/managed-courses/${variables?.course_id}/quizzes`,
    method: "post",
    loadingType: "createQuiz",
    ...variables,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const UpdataQuizRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: QuizLoading,
    successFunction: CreateQuizSuccess,
    url: `/v2/managed-courses/${variables?.course_id}/quizzes/${variables?.quiz_id}`,
    method: "put",
    loadingType: "updateQuiz",
    ...variables,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const ShowCurrenQuizRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: QuizLoading,
    successFunction: ShowCurrentQuizSuccess,
    url: `/v2/managed-courses/${variables?.course_id}/quizzes/${variables?.quiz_id}`,
    method: "get",
    loadingType: "quizDetails",
    ...variables,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

// not used
export const GetAllQuizzesRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: QuizLoading,
    successFunction: GetAllQuizzezSuccess,
    url: `${managedCourse}/${variables?.course_id}/quizzes`,
    method: "get",
    loadingType: "quizzesList",
    ...variables,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
// ------------------------------------------------------------  Create and Updata and Delete Question ---------------------------------------------
export const CreateQuestionRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: QuizLoading,
    successFunction: CreateQuestionSuccess,
    url: `v2/managed-courses/${variables?.course_id}/quizzes/${variables?.quiz_id}/questions`,
    method: "post",
    loadingType: "createQuestion",
    ...variables,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const UpdateQuestionRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: QuizLoading,
    successFunction: UpdateQuestionSuccess,
    
    url: `/v2/managed-courses/${variables?.course_id}/quizzes/${variables?.quiz_id}/questions/${variables?.quiz_question_id}`,
    method: "put",
    loadingType: "updateQuestion",
    ...variables,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const DeleteQuestionRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: QuizLoading,
    successFunction: DeleteQuestionSuccess,
    url: `v2/managed-courses/${variables?.course_id}/quizzes/${variables?.quiz_id}/questions/${variables?.quiz_question_id}`,
    method: "delete",
    loadingType: "deleteQuestion",
    ...variables,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const GetQuestionRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: QuizLoading,
    successFunction: GetQuizQuestionsSusses,
    url: `/v2/managed-courses/${variables?.course_id}/quizzes/${variables?.quiz_id}/questions`,
    method: "get",
    loadingType: "getQuestion",
    ...variables,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

// NOTE  Wrong Api Response
export const setSelectedQuestionsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: QuizLoading,
    successFunction: setSelectedQuestionsSuccess,
    url: `/v2/question_banks/${variables?.quiz_id}/questions/set_batch`,
    method: "post",
    loadingType: "setBankQuestions",
    ...variables,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
export const CreateChoicesRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: QuizLoading,
    successFunction: CreateChoicesSuccess,
    url: `/v2/managed-courses/${variables?.course_id}/quizzes/${variables?.quiz_id}/questions/${variables?.quiz_question_id}/choices`,
    method: "post",
    loadingType: "createChoice",
    ...variables,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const UpdataChoicesRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: QuizLoading,
    successFunction: UpdateChoicesSuccess,
    url: `/v2/managed-courses/${variables?.course_id}/quizzes/${variables?.quiz_id}/questions/${variables?.quiz_question_id}/choices/${variables?.quiz_question_choice_id}`,
    method: "put",
    loadingType: "updateChoice",
    ...variables,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const DeleteChoicesRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: QuizLoading,
    successFunction: DeleteChoicesSuccess,
    url: `/v2/managed-courses/${variables?.course_id}/quizzes/${variables?.quiz_id}/questions/${variables?.quiz_question_id}/choices/${variables?.quiz_question_choice_id}`,
    method: "delete",
    loadingType: "deleteChoice",
    ...variables,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const UpdataQuestionModelAnswerRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: QuizLoading,
    successFunction: UpdateQuestionModelAnswerSuccess,
    url: `/v2/managed-courses/${variables?.course_id}/quizzes/${variables?.quiz_id}/questions/${variables?.quiz_question_id}/model-answers/${variables?.quiz_question_model_answer_id}`,
    method: "put",
    loadingType: "updateQuestionModelAnswer",
    ...variables,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const CreateQuestionModelAnswerRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: QuizLoading,
    successFunction: CreateQuestionModelAnswerSuccess,
    url: `/v2/managed-courses/${variables?.course_id}/quizzes/${variables?.quiz_id}/questions/${variables?.quiz_question_id}/model_answers`,
    method: "post",
    loadingType: "createQuestionModelAnswer",
    ...variables,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const DeleteQuizRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: QuizLoading,
    successFunction: DeleteQuizSussess,
    url: `/v2/managed-courses/${variables?.course_id}/quizzes/${variables?.quiz_id}`,
    method: "delete",
    loadingType: "deleteQuiz",
    ...variables,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};


export const GetQuizSubmissionsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: QuizLoading,//supossed to be one loader and diff types .
    successFunction: GetQuizSubmissionsSuccess,
    url: `/v2/managed-courses/${variables?.course_id}/quizzes/${variables?.quiz_id}/attempts`,
    method: "get",
    loadingType: "getQuizSubmissions",
    ...variables,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const GetQuizSubmissionRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: QuizLoading,//supossed to be one loader and diff types .
    successFunction: GetQuizSubmissionSuccess,
    url: `/v2/managed-courses/${variables?.course_id}/quizzes/${variables?.quiz_id}/attempts/${variables?.attempt_id}`,
    method: "get",
    loadingType: "getQuizSubmission",
    ...variables,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};



export const ImportCSVQuestionsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: QuizLoading,//supossed to be one loader and diff types .
    successFunction: ImportCSVQuestions,
    url: `/v2/managed-courses/${variables?.course_id}/quizzes/${variables?.quiz_id}/import_csv?quiz_url=${variables?.file_url}`,
    method: "post",
    loadingType: "ImportCSV",
    ...variables,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
