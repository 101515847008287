import Types from "./Types";

export const CreateAdminUserSuccess = (payload) => ({
  type: Types.CREATE_ADMIN_USERS_SUCCESS,
  payload,
});

export const ShowAdminUserDetailsSuccess = (payload) => ({
  type: Types.SHOW_ADMIN_USERS_DETAILS_SUCCESS,
  payload,
});

export const UpdateAdminUserSuccess = (payload) => ({
  type: Types.UPDATE_ADMIN_USERS_SUCCESS,
  payload,
});

export const DeleteAdminUserSussess = (payload) => ({
    type: Types.DELETE_ADMIN_USERS_SUCCESS,
    payload,
});

export const GetAllAdminUsersSuccess = (payload) => ({
  type: Types.GET_ALL_ADMIN_USERS_SUCCESS,
  payload,
});

export const AdminUsersLoading = (payload) => ({
  type: Types.ADMIN_USERS_LOADING,
  payload,
});
