import {
  Avatar,
  Badge,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useThemePalette } from "common/hooks/theme_palette";
import TextFieldInput from "components/Atoms/Input/TextField/TextFieldInput";
import ModelBox from "components/Atoms/Model/Model";
import SearchableSelect from "components/Atoms/Select/SearchableSelect";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getScrolledClassesLookupsRequest } from "services/modules/lookups";
import { editStudentUserModel } from "services/modules/models/Actions";
import { GetAllStudentsRequest, UpdateStudentRequest } from "services/modules/students";
import { getPresignedUrlRequest } from "services/modules/uploadFile";
import { icons } from "../../../../assets/AssetHelper";
import PhoneInput from "components/Atoms/Input/PhoneInput/PhoneInput";
import en from "react-phone-number-input/locale/en.json";
import { useState } from "react";

const EditStudentModel = ({ selectedRow, setSelectedRow, page, page_size, action }) => {
  // country codes options :
  const countryOptions = [
    {
      id: "0",
      name: "+20",
    },
  ];

  const handelCountryOptions = countryOptions?.map((val) => {
    return {
      label: val?.name,
      value: val?.id,
      color: val?.color,
    };
  });

  //

  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 22,
    height: 22,
    border: `2px solid ${theme.palette.background.paper}`,
  }));

  const dispatch = useDispatch();
  const { inputBg, orangeBtn, fontColor } = useThemePalette();
  const isOpenEditStudentUser = useSelector((state) => state.model.isOpenEditStudentUser);
  const { paginatedClasses, classesCount } = useSelector((state) => state.lookups);;
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: selectedRow ? selectedRow : {},
    // validationSchema: Yup.object().shape({}),
    onSubmit: (values) => {
      const requestdata = {
        student_id: values?.student_id,
        body: {
          user: {
            ...values,
          },
          device: {
            uid: "262222",
            fcm_token: "i",
          },
        },
        action: () => {
          dispatch(editStudentUserModel());
          formik.resetForm();
          page && dispatch(GetAllStudentsRequest({ filter: { page_number: page, page_size: 10 } }));
          // action && action();
          toast.success("Student was" + selectedRow ? "Edited" : "added" + "sucessfully");
        },
      };
      if (selectedRow) {
        dispatch(UpdateStudentRequest(requestdata));
      } else {
        // dispatch(addstudent(requestdata)) not implemented but in place if wanted
      }
    },
  });
  const getClasses = () => {
    dispatch(getScrolledClassesLookupsRequest({ curriculum_id: selectedRow?.cirriculum?.id }));
  };
  const filterList = {
    type: "filter",
    placeholder: "School class",
    onChange: (value) => {
      formik.setFieldValue("school_class_id", value?.value);
    },
    onMenuOpen: () => {
      paginatedClasses?.length == 0 && getClasses({});
    },
    options: paginatedClasses && paginatedClasses,
    getList: getClasses,
    count: classesCount,
    applySearchFromRequest: true,
  };

  const handleAvatarChange = (fileName) => {
    dispatch(
      getPresignedUrlRequest({
        body: {
          file: fileName,
          extension: {
            files: [
              {
                extension: fileName.name.substring(
                  fileName.name.lastIndexOf(".") + 1,
                  fileName.name.length
                ),
              },
            ],
          },
        },
        updateData: (data) => {
          //console.log(data)
          formik.setFieldValue("profile_picture_url", data);
        },
      })
    );
  };

  const handleDeleteAvatar = (e) => {
    e.stopPropagation();
    formik.setFieldValue("profile_picture_url", null);
  };

  return (
    <ModelBox
      openVar={isOpenEditStudentUser}
      closeFunc={() => {
        dispatch(editStudentUserModel());
        formik.resetForm();
      }}
      title={selectedRow ? "Edit Student" : "Add New Student"}
      titleStyle={{ fontSize: "20px", fontWeight: "600" }}
    >
      <Box>
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          badgeContent={
            <>
              {formik.values?.profile_picture_url && (
                <IconButton
                  onClick={handleDeleteAvatar}
                  style={{ position: "absolute", top: "-70px", right: 0 }}
                >
                  <img src={icons.wrongIcon} />
                </IconButton>
              )}
              <label htmlFor="avatar-input" style={{ cursor: "pointer" }}>
                <SmallAvatar alt="Remy Sharp" src={icons?.imageIconBlue} />
              </label>
            </>
          }
        >
          <Avatar
            alt="Travis Howard"
            sx={{ width: "90px", height: "90px" }}
            src={formik.values?.profile_picture_url}
          />
        </Badge>
        <input
          id="avatar-input"
          type="file"
          style={{ display: "none" }}
          onChange={(e) => handleAvatarChange(e.target.files[0])}
        />

        <form onSubmit={formik.handleSubmit}>
          <Stack flexDirection={{ xs: "column", md: "row" }} justifyContent="space-between">
            <Stack spacing={4} width="48%">
              <Box>
                <Stack flexDirection={"row"} alignItems={"center"} gap={"3px"}>
                  <Typography
                    as="span"
                    sx={{
                      color: fontColor,
                      fontSize: "12px",
                      fontWeight: "400",
                      lineHeight: "15px",
                      my: 1,
                    }}
                  >
                    School class
                  </Typography>{" "}
                </Stack>
                <SearchableSelect
                  options={filterList.options}
                  onChange={filterList.onChange}
                  placeholder={filterList.placeholder}
                  count={filterList.count && filterList.count}
                  getList={filterList.getList && filterList.getList}
                  onMenuOpen={filterList?.onMenuOpen && filterList?.onMenuOpen}
                  data={paginatedClasses}
                  applySearchFromRequest={filterList.applySearchFromRequest}
                  isLoading={filterList.isLoading && filterList.isLoading}
                  value={{ label: selectedRow?.school_class }}
                  isClearable={false}
                />
              </Box>
              <Box>
                <TextFieldInput
                  type="text"
                  label="Name"
                  sx={{
                    background: inputBg,
                    padding: "0",
                    borderRadius: "8px",
                    "& fieldset": {
                      border: "none",
                    },
                  }}
                  placeholder="Enter here"
                  name="name"
                  onChange={formik.handleChange}
                  value={formik.values?.name}
                  // error={formik.errors.grade}
                  // helperText={formik.errors.grade}
                />
              </Box>

              <Box>
                <TextFieldInput
                  type="password"
                  label="Password"
                  sx={{
                    background: inputBg,
                    padding: "0",
                    borderRadius: "8px",
                    "& fieldset": {
                      border: "none",
                    },
                  }}
                  placeholder="Enter here"
                  name="password"
                  onChange={formik.handleChange}
                  value={formik.values?.password}
                  // error={formik.errors.grade}
                  // helperText={formik.errors.grade}
                />
              </Box>

              <Box>
                <Stack flexDirection={"row"} alignItems={"center"} gap={"3px"}>
                  <Typography
                    as="span"
                    sx={{
                      color: fontColor,
                      fontSize: "12px",
                      fontWeight: "400",
                      lineHeight: "15px",
                      my: 1,
                    }}
                  >
                    Country Code
                  </Typography>{" "}
                  <Typography color={"red"}>*</Typography>
                </Stack>
                <PhoneInput
                  id={"country"}
                  FormControlWidth="100%"
                  name={"country"}
                  selectId={"search-select"}
                  // inputBg={lightGray2}
                  displayCountry={true}
                  labels={en}
                  value={formik.values?.country_code || selectedRow?.country_code}
                  onChange={(value) => {
                    console.log(value);
                    formik.setFieldValue("country_code", `+${value}`);
                    // formik?.setValues({
                    //   ...formik?.values,
                    //   country_code: `+${value}`,
                    // })
                  }}
                  displayPhoneInput={false}
                  error={formik?.touched?.country_code && Boolean(formik?.errors?.country_code)}
                  helperText={formik?.touched?.country_code && formik?.errors?.country_code}
                  countryCodePlaceholder={"Select"}
                />
              </Box>
            </Stack>
            <Stack spacing={4} width="48%">
              <Box>
                <TextFieldInput
                  type="text"
                  label="Email"
                  sx={{
                    background: inputBg,
                    padding: "0",
                    borderRadius: "8px",
                    "& fieldset": {
                      border: "none",
                    },
                  }}
                  placeholder="Enter here"
                  name="email"
                  onChange={formik.handleChange}
                  value={formik.values?.email}
                  // error={formik.errors.grade}
                  // helperText={formik.errors.grade}
                />
              </Box>

              <Box>
                <TextFieldInput
                  type="text"
                  label="Job Title"
                  sx={{
                    background: inputBg,
                    padding: "0",
                    borderRadius: "8px",
                    "& fieldset": {
                      border: "none",
                    },
                  }}
                  placeholder="Enter here"
                  name="job_title"
                  onChange={formik.handleChange}
                  value={formik.values?.job_title}
                  // error={formik.errors.grade}
                  // helperText={formik.errors.grade}
                />
              </Box>

              <Box>
                <TextFieldInput
                  type="text"
                  label="Phone Number"
                  sx={{
                    background: inputBg,
                    padding: "0",
                    borderRadius: "8px",
                    "& fieldset": {
                      border: "none",
                    },
                  }}
                  placeholder="Enter here"
                  name="phone_number"
                  onChange={formik.handleChange}
                  value={formik.values?.phone_number}
                  // error={formik.errors.grade}
                  // helperText={formik.errors.grade}
                />
              </Box>
            </Stack>
          </Stack>

          <TextFieldInput
            label="Bio"
            name="bio"
            sx={{
              background: inputBg,
              padding: "0",
              borderRadius: "8px",
              "& fieldset": {
                border: "none",
              },
            }}
            placeholder="Description"
            onChange={formik.handleChange}
            value={formik?.values?.bio}
            // error={formik.errors.description}
            // helperText={formik.errors.description}
            multiline
            rows={4}
          />
          {/* is_phone_verified:false,is_security_enabled:false,has_many_devices */}
          <Grid container width={"80%"} justifyContent={"space-between"}>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    // checked={formik.values?.is_phone_verified}
                    // onChange={formik.handleChange}
                    checked={formik.values?.is_phone_verified || ""}
                    onChange={(event) => {
                      formik.setValues({
                        ...formik?.values,
                        is_phone_verified: event.target.checked,
                      });
                    }}
                    onBlur={formik.handleBlur}
                    value="is_phone_verified"
                    name="is_phone_verified"
                    size="small"
                    style={{ color: "#F79E1B" }}
                  />
                }
                label={<Typography fontSize={"12px"}>Is Phone Verified</Typography>}
              />
            </Grid>

            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values?.is_security_enabled || ""}
                    onChange={(event) => {
                      formik.setValues({
                        ...formik?.values,
                        is_security_enabled: event.target.checked,
                      });
                    }}
                    onBlur={formik.handleBlur}
                    value="is_security_enabled"
                    name="is_security_enabled"
                    style={{ color: "#F79E1B" }}
                    size="small"
                  />
                }
                label={<Typography fontSize={"12px"}>Is Security Enabled</Typography>}
              />
            </Grid>

            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    // checked={formik.values?.has_many_devices}
                    // onChange={formik.handleChange}
                    checked={formik.values?.has_many_devices || ""}
                    onChange={(event) => {
                      formik.setValues({
                        ...formik?.values,
                        has_many_devices: event.target.checked,
                      });
                    }}
                    onBlur={formik.handleBlur}
                    value="has_many_devices"
                    name="has_many_devices"
                    size="small"
                    style={{ color: "#F79E1B" }}
                  />
                }
                label={<Typography fontSize={"12px"}>Has Many Devices</Typography>}
              />
            </Grid>
          </Grid>

          <Stack
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"center"}
            gap={2}
            mt={2}
            width={"100%"}
          >
            <Button
              sx={{
                color: orangeBtn,
                border: "1px solid #F79E1B",
                "&:hover": {
                  backgroundColor: "transparent",
                },
                textTransform: "none",
                width: "20%",
              }}
              className="r-25px"
              onClick={() => {
                formik.resetForm();
                dispatch(editStudentUserModel());
              }}
            >
              Cancel
            </Button>

            <Button
              type={"submit"}
              sx={{
                backgroundColor: orangeBtn,
                textTransform: "none",
                width: "20%",
                "&:hover": {
                  backgroundColor: orangeBtn,
                },
                color: "white",
              }}
              className="primary r-25px"
            >
              {selectedRow ? "Edit" : "Add"}
            </Button>
          </Stack>
        </form>
      </Box>
    </ModelBox>
  );
};

export default EditStudentModel;
