import { Check, CheckBox } from "@mui/icons-material";
import { Checkbox, CircularProgress, FormControlLabel, Paper, Stack, Typography } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import PrimaryButton from "components/Atoms/Buttons/Primary/PrimaryButton";
import TextFieldInput from "components/Atoms/Input/TextField/TextFieldInput";
import SelectBox from "components/Atoms/Select/Select";
import { useFormik } from "formik";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { addSectionToCourseRequest } from "services/modules/section";
import AddIcon from "@mui/icons-material/Add";
import * as Yup from "yup";


const AddSectionTemplate=()=>{
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {isLoading} = useSelector((state) => state.sections);

    const {sections}=useSelector((state)=>state.courses)
    const newData = 
       [

            { name: 'At Top', position: 1 ,id:1},
          ...(sections?.map(section => ({ name: section.title, position: section.position+1, id: section.position + 1 })) || []),

          // ...(sections.length > 0 ? [{ name: 'At Bottom', position: sections.length + 2, id: sections.length + 2 }] : [])
      ]
  ;
    const {  course_id } = useParams();
    const { primaryColor, secondryColor,inputBg } = useThemePalette();
    const positions = Array.from({ length: sections.length+1}, (_, index) => ({ id: index + 1, name: index + 1 }));

    const formik = useFormik({
        initialValues: {
            title: "",
            position:sections && sections.length > 0 ? sections[sections.length - 1].position + 1 : 1 , //by default is always the last section
            is_posted: true, //by default it's always visible
          },
        validationSchema: Yup.object().shape({
            title: Yup.string().required("Title is required"),
        }),
       

        enableReinitialize: true,
        onSubmit: (values) => {
            const requestData={
                body:{
                    section:{
                        title:values?.title,
                        position:formik?.values?.position,
                        is_posted:values?.is_posted
                    }
                },
                course_id,
                action:()=>{
                    navigate(`/courses/${course_id}`)
                }
            }
            dispatch(addSectionToCourseRequest(requestData))
        },
      });
      console.log(newData)
      console.log(positions);
      console.log(formik?.values?.position);
  
return(
  <form onSubmit={formik.handleSubmit}>

    <Stack my={4} gap={2} px="20px">
      <Stack spacing={2} direction="row" alignItems="center" justifyContent={{ md: "flex-end" }}>

        <PrimaryButton
          text={isLoading?.loading?<CircularProgress size={20} 
          color="inherit" style={{textTransform:'none'}}>
          </CircularProgress>:'Save Section'}
          disabled={isLoading?.loading}
          variant={"contained"}

          style={{
            minWidth:'200px',
            background: secondryColor,
            color: "white",
            textTransform:'none'
          }}
          
          type="submit" 
          
        />
      </Stack>
      <Stack component={Paper} p="30px 20px" gap={2}>
        <Typography color={primaryColor} fontSize="16px">
          Section
        </Typography>
        <TextFieldInput 
        type="string" 
        label={'Section Title*'} 
        placeholder="Title" bgColor={inputBg}
        onChange={formik?.handleChange}
        value={formik?.values?.title}
        name="title"
        error={formik?.errors?.title}
        helperText={formik?.errors?.title}>
        </TextFieldInput>
        <SelectBox
      label={'Position (Move After)'}
        items={newData}
        name={'position'}
        onchange={(e)=>formik?.setFieldValue('position',e.target.value)}
        value={formik?.values?.position}
        error={formik?.errors?.position}
        helperText={formik?.errors?.position}

        sx={{
          padding: "10px",
          borderRadius: "8px",
          "&:before": {
            border: "none",

          },
        }}
      />
              </Stack>
              <Stack p="10px 10px" gap={2}>

              <FormControlLabel
      control={<Checkbox checked={formik.values.is_posted}
      onChange={(e) => {formik.setFieldValue("is_posted", e.target.checked)
  }
}  />}
      label="Visible to user"
    />
    </Stack>
     
    </Stack>
    </form>

)
}
export default AddSectionTemplate;