import { Box, Divider, FormLabel, InputBase, Stack, Switch } from "@mui/material";
import {
  handelCopyQuestion,
  handelDeleteQuestion,
} from "components/Organisms/quiz/utils/question_func";
import React from "react";
import { useDispatch } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useThemePalette } from "common/hooks/theme_palette";
import { icons } from "assets/AssetHelper";
const QuestionActions = ({
  setQuestionDegree,
  questionDegree,
  isLoading,
  cards,
  setCards,
  index,
  course_id,
  quiz_id,
  question_quiz_id,
  display,
}) => {
  const dispatch = useDispatch();
  const { secondryColor } = useThemePalette();

  return (
    <Stack
      width="100%"
      direction={{ md: "row", xs: "column" }}
      alignItems="center"
      mt={1}
      justifyContent="space-between"
    >
      {/* degrees */}
      <Stack
        direction="row"
        alignItems="center"
        bgcolor={"rgba(239, 241, 249, 0.6)"}
        width={{ md: "200px", xs: "90%" }}
        justifyContent="space-between"
        borderRadius="8px"
        p="10px"
      >
        <InputBase
          className={"degree-input"}
          type="number"
          value={questionDegree}
          onChange={(e) => setQuestionDegree(e.target.value)}
          startAdornment={
            <RemoveIcon
              style={{ color: secondryColor, cursor: "pointer" }}
              onClick={() => setQuestionDegree((s) => s > 0 && Number(s) - 1)}
            />
          }
          endAdornment={
            <AddIcon
              style={{ color: secondryColor, cursor: "pointer" }}
              onClick={() => setQuestionDegree((s) => Number(s) + 1)}
            />
          }
          placeholder="No."
        />
      </Stack>
      {/* copy & delete & required */}
      <Stack
        spacing={2}
        width="50%"
        direction="row"
        alignItems="center"
        my={3}
        justifyContent="flex-end"
      >
        <Box
          onClick={() =>
            handelCopyQuestion({
              setCards,
              cards,
              index,
              dispatch,
              quiz_id,
              course_id,
              display,
            })
          }
          sx={{ cursor: "pointer", color: "#828282" }}
        >
          <ContentCopyIcon />
        </Box>
{/* {cards.length > 0 ? */}
<Box
          onClick={() => {
            handelDeleteQuestion({
              dispatch,
              cards,
              setCards,
              course_id,
              quiz_id,
              question_quiz_id,
              index,
            });
          }}
          sx={{ cursor: "pointer" }}
        >
        <img src={icons.deleteRedIcon} alt="" />        
    </Box>
    {/* :null} */}
            <Divider orientation="vertical" variant="middle" flexItem />
        <FormLabel component="div" sx={{ fontSize: "14px" }}>
          Required
        </FormLabel>
        <Switch color="primary" />
      </Stack>
    </Stack>
  );
};

export default QuestionActions;
