import { CircularProgress, Stack, Box, Typography, Checkbox, FormControlLabel } from '@mui/material';
import React, { useState } from 'react';

const QuestionsList = ({
    questions,
    checked,
    setChecked,
    setSelectedQuestions,
    selectedquestions }) => {
    const [loading, setLoading] = useState(false)

    const handleChangeAll = (event) => {
        setLoading(true)
        let checkedData = []
        for (let key = 0; key < questions?.length; key++) {
            checkedData[key] = event.target.checked
        }
        setChecked(s => checkedData)
        setTimeout(() => {
            setLoading(false)
        }, 10)

        if (event.target.checked === true) setSelectedQuestions(s => {
            return questions?.map((data) => {
                return {
                    id: data?.quiz_question_id, type: 'question', data: data
                }
            })

        })
        else setSelectedQuestions([])
    }

    const handleChange = (event, index, questionData) => {
        setLoading(true)
        let checkedData = checked
        checkedData[index] = event.target.checked
        setChecked(s => checkedData)
        let newData = [...selectedquestions]
        if (event.target.checked) {
            newData.push({ id: questionData?.quiz_question_id, type: 'question', data: questionData })
        } else {
            newData?.splice(index, 1)
        }

        setSelectedQuestions(newData)
        setTimeout(() => {
            setLoading(false)
        }, 10)
    };
    const choicesArrange = {
        0: 'A',
        1: 'B',
        2: 'C',
        3: 'D'
    }

    return (
        <div>
            <FormControlLabel
                label="Select All"
                control={
                    <Checkbox
                        checked={!checked?.includes(false) ? true : false}
                        indeterminate={checked?.includes(false) && checked?.includes(true)}
                        onChange={(e) => handleChangeAll(e)}
                    />
                }
            />
            <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                {
                    questions?.map((question, index) => {
                        return <React.Fragment key={index}>
                            <Stack spacing={1} >
                                <Stack direction='row' alignItems='center' >
                                    {!loading ?
                                        <Checkbox
                                            checked={checked[index]}
                                            onChange={(e) => handleChange(e, index, question)} /> :
                                        <CircularProgress />
                                    }
                                    <Typography
                                        sx={{
                                            fontWeight: '500',
                                            fontSize: '16px',
                                            lineHeight: '22px',
                                            textTransform: 'capitalize'
                                        }}
                                    >
                                        {`${index + 1}. ${question?.text_question}`}
                                    </Typography>
                                </Stack>
                                {question?.quiz_question_choices?.map((choice, idx) => {
                                    return (
                                        <Typography
                                            key={idx}
                                            pl='30px'
                                            sx={{
                                                fontSize: '16px',
                                                lineHeight: '24px',
                                                color: '#828282',
                                                textTransform: 'capitalize'
                                            }}
                                        >
                                            {`${choicesArrange[idx]}. ${choice?.text_choice}`}
                                        </Typography>
                                    )
                                })}
                            </Stack>
                        </React.Fragment>
                    })}
            </Box>
        </div>
    )
}

export default QuestionsList