import { useState } from "react";
import {
    IconButton,
    InputAdornment,
    OutlinedInput,
    Stack,
    TextField,
    Typography,
} from "@mui/material";

import { icons } from "assets/AssetHelper";

const Header = ({
    globalFilter,
    setGlobalFilter,
    Data,
    Title,
    subTitle,
    noFilter,
    noSearchBar,
    filterList,
}) => {
    const [value, setValue] = useState(globalFilter);
    const onChange = (value) => {
        setGlobalFilter(value || undefined);
    };
    return (
        <Stack direction="row" alignItems="center" justifyContent="space-between" p={2}>
            <Stack width="50%">
                <Typography fontSize="16px" fontWeight="600" >
                    {Title}
                </Typography>
                {Data.length === 0 ? (
                    ""
                ) : (
                    <Typography variant="subtitle1" >
                        {subTitle}
                    </Typography>
                )}
            </Stack>

            <Stack direction="row" alignItems="center" width={{ sm: "50%", md: "30%" }}>
                {Data.length === 0 ? (
                    ""
                ) : (
                    <>
                        {noSearchBar ? (
                            ""
                        ) : (
                            <OutlinedInput
                                id="outlined-adornment-password"
                                type={"text"}
                                value={value}
                                size="small"
                                placeholder="Search"
                                sx={{ marginX: "5px" }}
                                onChange={(e) => {
                                    setValue(e.target.value);
                                    onChange(e.target.value);
                                }}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <IconButton aria-label="toggle password visibility" edge="start">
                                            <img src={icons.searchIcon} alt="" />
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        )}

                        {noFilter ? "" : <>{filterList}</>}
                    </>
                )}
            </Stack>
        </Stack>
    );
};

export default Header;
