import { Divider, Checkbox , Paper, Stack, Typography,FormControlLabel,IconButton ,Box } from "@mui/material";
import TextFieldInput from "components/Atoms/Input/TextField/TextFieldInput";
import React from "react";
import DragabelCards from "../dragabelCards";
import AddMoreQuestions from "../models/select-from-questions-banks";
import QuestionsLevelsStatistics from "../components/questionsLevelsStatistics";
import { useThemePalette } from "common/hooks/theme_palette";
import SearchableSelect from "components/Atoms/Select/SearchableSelect";
import UploadInput from "components/Atoms/Input/FileInput/UploadInput";
import dayjs from "dayjs";
import BasicDatePicker from "components/Atoms/Input/DatePicker/DatePicker";
import BasicTimePicker from "components/Atoms/Input/TimePicker/TimePicker";
import { icons } from "assets/AssetHelper";
import QuizOptions from "../components/quizOptions";
import QuizInfo from "../components/quizInfo";
import QuizConfigurations from "../components/quizConfigurations";
import ImportCSV from "../models/import-from-csv";

const QuizTab = ({
  cards,
  setCards,
  formik
}) => {
  const { inputBg, labelColor } = useThemePalette();
  return (
    <Stack direction={{ xs: "column", md: "row" }} gap={4} my={2} width="100%">
      <Stack spacing={2} direction="column" width={{ xs: "100%", md: "70%" }}>
        {/* quiz info */}
        <QuizInfo formik={formik}/>
        {/* quiz configurations */}
        <QuizConfigurations formik={formik}/>
        {/* exam cards */}
        <DragabelCards cards={cards} setCards={setCards} />
        {/* Import More Questions Modal */}
        <AddMoreQuestions />
        {/* upload csv modal */}
        <ImportCSV/>
      </Stack>

      <Stack width={{ xs: "auto", md: "25%" }} flexDirection={'column'} gap={2}>
        <QuestionsLevelsStatistics />
        <QuizOptions cards={cards}/>
      </Stack>
    </Stack>
  );
};

export default QuizTab;
