import { Typography } from "@mui/material";
import { Stack } from "@mui/system";
import TextFieldInput from "../../../Atoms/Input/TextField/TextFieldInput";
import SelectBox from "../../../Atoms/Select/Select";

function CreateCourseStep2(props) {
  return (
    <Stack spacing={2} direction="row" className="custom_input" width="100%">
      {/* right side */}
      <Stack spacing={2} width="50%">
        <Typography fontSize="12px" color="#5E6366">
          {" "}
          {/* price */}
          Full original price *
        </Typography>
        <TextFieldInput
          sx={{
            background: props.inputBg,
            padding: "10px",
            borderRadius: "8px",
          }}
          placeholder="00.0"
          onChange={props.formik.handleChange}
          value={props.formik.values.original_price}
          name="original_price"
          variant="standard"
          error={props.formik.errors.original_price}
          helperText={props.formik.errors.original_price}
        />
        <Typography fontSize="12px" color="#5E6366">
          {" "}
          {/* currency */}
          Price currency
        </Typography>
        <SelectBox
          items={[
            {
              id: "EGP",
              name: "EGP",
            },
            {
              id: "USD",
              name: "USD",
            },
          ]}
          className="custom-select"
          value={props.currency}
          onchange={(e) => props.setCurrency(e.target.value)}
          variant="standard"
          sx={{
            padding: "10px",
            borderRadius: "8px",
          }}
        />
        <Typography fontSize="12px" color="#5E6366">
          {" "}
          {/* discount */}
          Discount
        </Typography>
        <TextFieldInput
          variant="standard"
          sx={{
            background: props.inputBg,
            padding: "10px",
            borderRadius: "8px",
          }}
          placeholder="0.0"
          onChange={props.formik.handleChange}
          value={props.formik.values.discount_percentage}
          name="discount_percentage"
          error={props.formik.errors.discount_percentage}
          helperText={props.formik.errors.discount_percentage}
        />
        <Typography fontSize="12px" color="#5E6366">
          {" "}
          {/* Price per section */}
          Price per section
        </Typography>
        <TextFieldInput
          variant="standard"
          sx={{
            background: props.inputBg,
            padding: "10px",
            borderRadius: "8px",
          }}
          placeholder="0"
          onChange={props.formik.handleChange}
          value={props.formik.values.price_section}
          name="price_section"
          error={props.formik.errors.price_section}
          helperText={props.formik.errors.price_section}
        />
        <Typography fontSize="12px" color="#5E6366">
          {" "}
          {/* Price per session */}
          Price per session
        </Typography>
        <TextFieldInput
          variant="standard"
          sx={{
            background: props.inputBg,
            padding: "10px",
            borderRadius: "8px",
          }}
          placeholder="0"
          onChange={props.formik.handleChange}
          value={props.formik.values.price_session}
          name="price_session"
          error={props.formik.errors.price_session}
          helperText={props.formik.errors.price_session}
        />
      </Stack>
      {/* left side */}
      <Stack spacing={2} width="50%">
        <Typography fontSize="12px" color="#5E6366">
          {" "}
          {/* Max Seats */}
          Max seats
        </Typography>
        <TextFieldInput
          variant="standard"
          sx={{
            background: props.inputBg,
            padding: "10px",
            borderRadius: "8px",
          }}
          placeholder="30"
          onChange={props.formik.handleChange}
          value={props.formik.values.max_quantity}
          name="max_quantity"
          error={props.formik.errors.max_quantity}
          helperText={props.formik.errors.max_quantity}
        />
        <Typography fontSize="12px" color="#5E6366">
          {" "}
          {/* Payment type */}
          Payment Type
        </Typography>
        <SelectBox
          items={props.paymentMethods}
          className="custom-select"
          value={props.paymentType}
          onchange={(e) => props.setPaymentType(e.target.value)}
          variant="standard"
          sx={{
            padding: "10px",
            borderRadius: "8px",
          }}
        />
        <Typography fontSize="12px" color="#5E6366">
          {" "}
          {/* Rate of comments per day */}
          Rate of comments per day
        </Typography>
        <TextFieldInput
          variant="standard"
          sx={{
            background: props.inputBg,
            padding: "10px",
            borderRadius: "8px",
          }}
          placeholder="0"
          onChange={props.formik.handleChange}
          value={props.formik.values.rate_of_comments_per_day}
          name="rate_of_comments_per_day"
          error={props.formik.errors.rate_of_comments_per_day}
          helperText={props.formik.errors.rate_of_comments_per_day}
        />
        <Typography fontSize="12px" color="#5E6366">
          {" "}
          {/* Rate of comments per hour */}
          Rate of comments per hour
        </Typography>
        <TextFieldInput
          variant="standard"
          sx={{
            background: props.inputBg,
            padding: "10px",
            borderRadius: "8px",
          }}
          placeholder="0"
          onChange={props.formik.handleChange}
          value={props.formik.values.rate_of_comments_per_hour}
          name="rate_of_comments_per_hour"
          error={props.formik.errors.rate_of_comments_per_hour}
          helperText={props.formik.errors.rate_of_comments_per_hour}
        />
      </Stack>
    </Stack>
  );
}

export default CreateCourseStep2;
