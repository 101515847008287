import { reduxRequest } from "common/utils/reduxRequest";

import { makeFilterString } from "../utils/Helper";
import {
  AdminUsersLoading,
  CreateAdminUserSuccess, DeleteAdminUserSussess,
  GetAllAdminUsersSuccess,
  ShowAdminUserDetailsSuccess, UpdateAdminUserSuccess
} from "./Actions";
import { ShowStudentDetailsSuccess, UpdateStudentSuccess } from "../students/Actions";
export const CreateAdminUserRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: AdminUsersLoading,
    successFunction: CreateAdminUserSuccess,
    url: `v2/admins`,
    method: "post",
    loadingType: "create",
    ...variables,
   
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
export const UpdateAdminUserRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: AdminUsersLoading,
    successFunction: UpdateAdminUserSuccess,
    url: `v2/admins/${variables?.admin_id}`,
    method: "put",
    loadingType: "update",
    ...variables,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
export const ShowAdminUserDetailsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: AdminUsersLoading,
    successFunction: ShowAdminUserDetailsSuccess,
    url: `v2/admins/${variables?.admin_id}`,
    method: "get",
    loadingType: "details",
    ...variables,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const GetAllAdminUserssRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: AdminUsersLoading,
    successFunction: GetAllAdminUsersSuccess,
    url: `v2/admins${variables?.filter ? makeFilterString(variables?.filter) : null}`,
    method: "get",
    loadingType: "details",
    ...variables,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const DeleteAdminUserRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: AdminUsersLoading,
      successFunction: DeleteAdminUserSussess,
      url: `v2/admins/${variables.admin_id}`,
    method: "delete",
    loadingType: "delete",
    body:variables?.body,
    ...variables,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
