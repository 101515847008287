import { Accordion, AccordionDetails, AccordionSummary, Typography, Stack } from "@mui/material";
import { NavLink, useLocation } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { images, icons } from "assets/AssetHelper";
import { useState } from "react";
import { Organization_name } from "services/modules/utils/constant";

const SideNavbar = () => {
  const location = useLocation();
  const path = location.pathname;
  const [expanded, setExpanded] = useState(false);
  const isInstructor=localStorage.getItem("userType")==="Instructor"||localStorage.getItem("userType")==="Instructor"
  //console.log(localStorage.getItem("userType"))
  

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  let sidebarNavs = [
    { title: "Dashboard", path: "/", icon: icons.Home, type: "normal" },
   { title: "Admin Users", path: "/admin-users", icon: icons.Usersetting, type: "normal" },
    {
      title: "Users",
      path: "/users/instructors",
      icon: icons.User,
      type: "accordion",
      list: [
        { title: "Instructors", path: "/users/instructors" },
        { title: "Students", path: "/users/students" },
      ],
    },
    { title: "Courses", path: "/courses", icon: icons.Courses, type: "normal" },
    { title: "Assistant", path: "", icon: icons.Assistant, type: "normal" },
    {
      title: "Educational Systems",
      path: ``,
      icon: icons.Assistant,
      type: "accordion",
      list: [
        { title: "AnyThing", path: "" },
        { title: "Students", path: "/users/students" },
      ],
    },
    { title: "Settings", path: "", icon: icons.Setting, type: "accordion" },
  ];
  if (isInstructor) {
    sidebarNavs = sidebarNavs.filter(item => item.title !== "Educational Systems" &&
     item.title !== "Settings" && item.title !== "Admin Users"&&
      item.title !== "Assistant"&&item.title!=="Users");
}

  return (
    <Stack width="100%" minHeight="100vh" direction="column" spacing={4}>
      {/* logo */}
      <Stack direction="row" alignItems="center" p={2}>
        <img src={images.Logo} loading="lazy" width="60px" alt="" />
        <Typography
          gutterBottom
          sx={{
            fontSize: "20px",
            fontWeight: "700",
            marginInlineStart: "10px",
          }}
        >
          {Organization_name||'Circles'}
        </Typography>
      </Stack>
      {/* navs */}
      <Stack width="100%" minHeight="100vh" direction="column" spacing={2} pt={4}>
        {sidebarNavs?.map((data, index) => {
          return data?.type === "normal" ? (
            <Stack
              direction="row"
              alignItems="center"
              key={index}
              p="10px"
              px="15px"
              sx={{
                bgcolor: path === data.path ? "#2F80ED" : "",
              }}
              onClick={() => setExpanded(false)}
            >
              <img src={data?.icon} alt="" />
              <NavLink
                to={data?.path}
                style={{
                  fontSize: "14px",
                  textDecoration: "none",
                  color: path === data.path ? "white" : "black",
                  marginInlineStart: "15px",
                }}
              >
                {data?.title}
              </NavLink>
            </Stack>
          ) : (
            <Accordion
              key={index}
              sx={{
                width: "100%",
                border: "0",
                boxShadow: "none",
                background: "transparent",
                padding: "0",
              }}
              className="nav-accordion"
              expanded={expanded === `panel${index}`}
              onChange={handleChange(`panel${index}`)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}a-content`}
                id={`panel${index}a-header`}
                sx={{ padding: "0", paddingX: "15px" }}
              >
                <Stack direction="row" alignItems="center">
                  <img src={data?.icon} alt="" />
                  <NavLink
                    to={data?.path}
                    style={{
                      fontSize: "14px",
                      textDecoration: "none",
                      color: "black",
                      marginInlineStart: "15px",
                    }}
                  >
                    {data?.title}
                  </NavLink>
                </Stack>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  background: "#F0F3FF",
                  height: "100%",
                  padding: "0",
                }}
              >
                {data?.list?.map((val, idx) => {
                  return (
                    <Typography
                      padding="8px"
                      paddingX="35px"
                      key={idx}
                      sx={{ bgcolor: path === val.path ? "#2F80ED" : "" }}
                    >
                      <NavLink
                        to={val?.path}
                        style={{
                          fontSize: "14px",
                          fontWeight: "400",
                          textDecoration: "none",
                          color: path === val.path ? "white" : "#53545C",
                        }}
                      >
                        {val?.title}
                      </NavLink>
                    </Typography>
                  );
                })}
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Stack>
    </Stack>
  );
};

export default SideNavbar;
