import {
  Paper,
  TableContainer,
  Typography,
  Stack,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
  styled,
  tableCellClasses,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useThemePalette } from "../../common/hooks/theme_palette";
import PrimaryButton from "../../components/Atoms/Buttons/Primary/PrimaryButton";
import whiteTrash from "assets/icons/whiteTrash.svg";
import DeleteModal from "../../components/Atoms/Model/DeleteModel";
import { DeleteAdminUserRequest } from "../../services/modules/AdminUsers";
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Dayjs } from "dayjs";
import utc from 'dayjs/plugin/utc'; // Import the utc plugin
import timezone from 'dayjs/plugin/timezone'; // Import the timezone plugin
import customParseFormat from 'dayjs/plugin/customParseFormat'; // Import the customParseFormat plugin

// Extend dayjs with plugins
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);
export const formatDate = (date = new Date(), format = "DD-MM-YYYY hh:mm A") => {
  dayjs.extend(utc);
  return dayjs.utc(date).format(format);
};
const AdminUserDetailsTemplate = ({ adminUser }) => {
    const { errorColor } = useThemePalette();
    const [openDeleteModel, setOpenDeleteModel] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch();

    const handleDelete = () => {
        const requestedData = {
            'admin_id': adminUser?.id
            , action: () => {
                
                toast.success(`Admin ${adminUser.name} deleted successfully`)
                setOpenDeleteModel(false)
                navigate('/admin-users')
            }
        }
        //console.log(adminUser)
        dispatch(DeleteAdminUserRequest(requestedData))
    }
    const handleCloseModel = () => {
        setOpenDeleteModel(false)
    }
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
      backgroundColor: "#2f80ed17",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  const data = [
    { title: "Email", value: adminUser?.email ||'-'},
    { title: "User", value: adminUser?.name ||'-' },
    { title: "Created At", value:dayjs(adminUser?.created_at).format('YYYY-MM-DD HH:mm [(GMT)]Z')},
    { title: "Updated At", value: dayjs(adminUser?.updated_at).format('YYYY-MM-DD HH:mm [(GMT)]Z') },
  ];
    return (
        <Stack mb={3} p={2} spacing={2}>
       <PrimaryButton
        width={{
          sm: "50%",
          md: "15%",
        }}
        text=" Delete Admin User"
                variant="contained"
                icon=<img src={whiteTrash}  />
        style={{
          background: errorColor,
          color: "white",
            alignSelf: "flex-end",
            textTransform: 'none'
        }}
        click={() => {
            setOpenDeleteModel(true)

        }}
      />
    <Stack
      width={{
        sm: "100%",
        md: "90%",
        lg: "85%",
      }}
      padding={{ md: "20px" }}
      spacing={3}
    >
      <TableContainer component={Paper} sx={{ background: "white", borderRadius: "12px" }}>
        <Typography m={2} fontWeight="600" fontSize="16px">
          Admin user Details
        </Typography>
        <Divider style={{ width: "100%" }} />

        <Table aria-label="customized table">
          <TableBody>
            {data?.map((data, index) => {
              return (
                <StyledTableRow key={index}>
                  <StyledTableCell sx={{ width: "10%", color: "#2C2D33" }}>
                    {data?.title}
                  </StyledTableCell>
                  <StyledTableCell width="25%" sx={{ color: "#6E7079" }}>
                    {data?.value}
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
            </Stack>
            <DeleteModal
                open={openDeleteModel}
                text={`Are you sure you want to delete "${adminUser.name}" account`} handelDeleteRequest={handleDelete} handleClose={handleCloseModel}
            >
            </DeleteModal>
        </Stack>

  );
};

export default AdminUserDetailsTemplate;
