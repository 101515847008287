const StudentType = {
  STUDENT_LOADING: "STUDENT_LOADING",
  GET_ALL_STUDENTS_SUCCESS: "GET_ALL_STUDENTS_SUCCESS",
  DELETE_STUDENT_SUCCESS: "DELETE_STUDENT_SUCCESS",
  UPDATE_STUDENT_SUCCESS: "UPDATE_STUDENT_SUCCESS",
  SHOW_STUDENT_DETAILS_SUCCESS: "SHOW_STUDENT_DETAILS_SUCCESS",
  CREATE_STUDENT_SUCCESS: "CREATE_STUDENT_SUCCESS",
  SUSPEND_STUDENT_ACCOUNT_SUCCESS: "SUSPEND_STUDENT_ACCOUNT_SUCCESS",
  ACTIVE_STUDENT_ACCOUNT_SUCCESS: "ACTIVE_STUDENT_ACCOUNT_SUCCESS",
  ENROLL_STUDENT_TO_MULTIPLE_COURSES :"ENROLL_STUDENT_TO_MULTIPLE_COURSES",
  GET_STUDENTS_UNENROLLED_COURSES:"GET_STUDENTS_UNENROLLED_COURSES"
};

export default StudentType;
