import { Button, CircularProgress, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { useThemePalette } from "common/hooks/theme_palette";
import ModelBox from "components/Atoms/Model/Model";
import MultiSelect from "components/Atoms/Select/MultiSelect";
import SearchableSelect from "components/Atoms/Select/SearchableSelect";
import SelectBox from "components/Atoms/Select/Select";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { addCourseSubjectsRequest, getCourseSubjectsRequest } from "services/modules/courses/Requests/subjects";
import { useSelector } from "react-redux";
const AddSubjectModel=({
  openModel,
  handleClose,
  handleAction,
  options1,
  options2,
  options3,
  fetchMoreOptions,
    hasMore,
    count,
  title,
  handleChange,
  btnTitle,
  pageNumber,
  subTitle1,
  subTitle2,
  subTitle3,
  setSelectedCurriculum,
  setSelectedClass,
  setSelectedClassSubject,
  selectedCurriculum,
  selectedClass,
  selectedClassSubject,
  })=>{
    const { orangeBtn } = useThemePalette();
    const dispatch=useDispatch();
    const {course_id}=useParams();
    const Loading=useSelector((state)=>state.courses.isLoading)

    const handleAddSubject=()=>{
      const requestedData={
        course_id:course_id,
        curriculum_id:selectedCurriculum,
        subject_id:selectedClassSubject,
        school_class_id:selectedClass,
        action:()=>{
             handleClose();
             dispatch(getCourseSubjectsRequest({course_id:course_id}))
        }
      }
      dispatch(addCourseSubjectsRequest(requestedData))
    }

return(
    <ModelBox openVar={openModel} title={title} closeFunc={handleClose} titleStyle={{fontWeight:700}}>
        <Box p={1}>
          <SearchableSelect options={options1} title={subTitle1}  onChange={(e)=>{
            setSelectedCurriculum(e?.value)
          }
          }></SearchableSelect>
          <SearchableSelect options={options2} title={subTitle2}  onChange={(e)=>setSelectedClass(e?.value)}></SearchableSelect>
          <SearchableSelect options={options3} title={subTitle3} onChange={(e)=>setSelectedClassSubject(e.value)}></SearchableSelect>

      
      <Stack flexDirection={'row'} justifyContent={'center'} alignItems={'center'} gap={4} pt={4}>
        <Button 
        onClick={handleClose}
        variant="outlined"
         sx={{textTransform:'none',color:orangeBtn,borderColor:orangeBtn}}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleAddSubject} sx={{textTransform:'none',backgroundColor:orangeBtn,':hover':{backgroundColor:orangeBtn}}}>
          {Loading?.loading && Loading.type==='addCourseSubject' ? <CircularProgress size={20} color="inherit" /> : btnTitle}
        </Button>

      </Stack>
      </Box>
      </ModelBox>
)
}
export default AddSubjectModel;