import { ButtonBase, Menu, MenuItem } from "@mui/material";
import React from "react";
import { useState } from "react";
import { icons } from "../../../../assets/AssetHelper";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { CreateQuizRequest } from "services/modules/quiz";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { CreateAssignmentRequest } from "services/modules/courses/Requests/assignment";

const SectionSelecteSource = ({ course, section }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { quizData } = useSelector((state) => state.quiz);
  let x = Math.floor(Math.random() * 10 + 1);
  //console.log(section);
  const { course_id } = useParams();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e, type) => {
    e.stopPropagation();
    setAnchorEl(null);
    if (type == "quiz") {
      const requestedData = {
        course_id,
        body: {
          quiz: {
            title: "Untitled Quiz",
            start_date: dayjs().format("DD-MM-YYYY"),
            due_date: dayjs().add(1, "day").format("DD-MM-YYYY"),
            is_posted: false,
            description: null,
            section_id: section?.section_id,
            quiz_configuration: {
              num_of_questions_per_page: 1,
              passing_percentage: 50,
              duration: 2,
              max_no_of_attempts: 1,
              correcting_system: "normal",
              final_grade_based_on: "highest",
              american_system_penalty: 0.0,
              block_following_resources: false,
            },
          },
        },
        action: (response) => {
          navigate(`/courses/${course_id}/quiz/${response?.data?.quiz_id}/questions`);
        },
      };
      dispatch(CreateQuizRequest(requestedData));
    }
    if (type == "assignment") {
      const requestedData = {
        course_id,
        body: {
          assignment: {
            title: "Untitled Assignment",
            body: "Descritption",
            attachment_url: null,
            due_date: dayjs().add(1, "day").format("DD-MM-YYYY"),
            late_due_date: dayjs().add(7, "day").format("DD-MM-YYYY"),
            late_penalty_percentage: 0,
            passing_percentage: 60,
            overall_grade: 100,
            section_id: section?.section_id,
          },
        },
        action: (response) => {
          navigate(
            `/courses/${course_id}/assignment/${section?.section_id}/${response?.data?.assignment_id}/about`
          );
        },
      };
      dispatch(CreateAssignmentRequest(requestedData));
    }
    if (type == "session") navigate(`/session/${course_id}/${section?.section_id}`);
  };

  return (
    <div>
      <ButtonBase
        variant="contained"
        sx={{
          background: "#E0E0E0",
          color: "#4F4F4F",
          padding: "11px",
          borderRadius: "4px",
          marginInline: "10px",
        }}
        id="demo-positioned-button"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <img src={icons.SourceIcon} style={{ marginInline: "5px" }} alt="" />
        Add a Source
      </ButtonBase>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem onClick={(e) => handleClose(e, "session")}>Create Session</MenuItem>
        <MenuItem onClick={(e) => handleClose(e, "quiz")}>Create Quiz</MenuItem>
        <MenuItem onClick={(e) => handleClose(e, "assignment")}>Create Assignment</MenuItem>
      </Menu>
    </div>
  );
};

export default SectionSelecteSource;
