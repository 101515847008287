import { Cancel } from "@mui/icons-material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import dayjs from "dayjs";

import {
  Avatar,
  Badge,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  styled,
  tableCellClasses,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import PrimaryButton from "components/Atoms/Buttons/Primary/PrimaryButton";
import addStudent from "assets/icons/addStudent.svg";
import whiteTrash from "assets/icons/whiteTrash.svg";
import deactivate from "assets/icons/deactivate.svg";
import { useThemePalette } from "common/hooks/theme_palette";
import DeleteModal from "components/Atoms/Model/DeleteModel";
import { useSelector } from "react-redux";
import { editStudentUserModel } from "services/modules/models/Actions";
import EditStudentModel from "components/Organisms/students/models/create";
import {
  ActiveStudentRequest,
  DeleteStudentRequest,
  GetAllStudentsRequest,
  ShowStudentDetailsRequest,
  SuspendStudentRequest,
} from "services/modules/students";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { icons } from "assets/AssetHelper";
import StudentQuizes from "components/Organisms/students/studentQuizes";
import StudentAssignments from "components/Organisms/students/studentAssignments";
import PaidCourses from "components/Organisms/students/paidCourses";

const StudentDetailsTemplate = ({ student }) => {
  const { errorColor, orangeBtn, easy, borderColor, gray } = useThemePalette();
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [openSuspendModel, setOpenSuspendModel] = useState(false);
  const [openReactiveModel, setOpenReactiveModel] = useState(false);
  const loading = useSelector((state) => state.students.isLoading);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isActive = student?.status == "active";
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
      backgroundColor: "#2f80ed17",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  const editAction = () => {
    dispatch(ShowStudentDetailsRequest({ student_id: student?.student_id }));
  };
  const handleDelete = () => {
    const requestedData = {
      student_id: student?.student_id,

      action: () => {
        toast.success(`Student ${student?.name} deleted successfully`);
        setOpenDeleteModel(false);
        navigate("/users/Students");
      },
    };

    dispatch(DeleteStudentRequest(requestedData));
  };
  const handleSuspened = () => {
    const requestedData = {
      body: {
        user: {
          status: "suspended",
        },
      },
      student_id: student?.student_id,
      action: () => {
        toast.success(`Student ${student?.name} suspended successfully`);
        // openSuspendModel(false)
        handleCloseModel(setOpenSuspendModel);
        editAction();
      },
    };
    dispatch(SuspendStudentRequest(requestedData));
  };
  const handleReactive = () => {
    const requestedData = {
      body: {
        user: {
          status: "active",
        },
      },
      student_id: student?.student_id,
      action: () => {
        toast.success(`Student ${student?.name} activeted successfully`);
        handleCloseModel(setOpenReactiveModel);
        editAction();
      },
    };
    // console.log(requestedData,'requestedData');
    dispatch(ActiveStudentRequest(requestedData));
  };
  const handleCloseModel = (setState) => {
    setState(false);
  };

  const handleEditButton = (data) => {
    dispatch(editStudentUserModel());
  };
  const data = [
    { title: "School", value: student?.school || "-" },
    { title: "School Class", value: student?.school_class || "-" },
    { title: "Name", value: student?.name || "-" },
    { title: "Email", value: student?.email || "-" },
    // { title: "User", value: student?.name || "-" },
    // { title: "User type", value: student?.job_title || "-" },
    { title: "Country Code", value: student?.country_code },
    { title: "Phone Number", value: student?.phone_number || "-" },
    { title: "Social Account", value: student?.social_account || "-" },
    {
      title: "Is Phone Verified",
      value: student?.is_phone_verified ? (
        <CheckCircleIcon style={{ color: "#27AE60" }} />
      ) : (
        <Cancel style={{ color: "#EB5757" }}> </Cancel>
      ),
    },
    {
      title: "Status",
      value: <Typography textTransform={'capitalize'} color={isActive ? easy : errorColor}>{student?.status}</Typography> || "-",
    },
    { title: "Created At", value: dayjs(student?.updated_at).format("YYYY-MM-DD HH:mm [(GMT)]Z") },
  ];
  const sideActions = [
    {
      icon: icons.task,
      title: "Orders",
      click: (_) => console.log("clicked"),
      alt: "task icon",
    },
    {
      icon: icons.device,
      title: "Device",
      click: (_) => console.log("clicked"),
      alt: "device icon",
    },
    {
      icon: icons.bookmark,
      title: "Bookmarked Product",
      click: (_) => console.log("clicked"),
      alt: "bookmark icon",
    },
    {
      icon: icons.purchased,
      title: "Purchased product",
      click: (_) => console.log("clicked"),
      alt: "purchased icon",
    },
  ];
  return (
    <>
      <Stack mb={3} p={2} spacing={2}>
        <Stack flexDirection="row" justifyContent={"end"} gap={2} marginLeft="auto">
          <PrimaryButton
            width={{
              sm: "50%",
              md: "15%",
            }}
            text="Edit Student"
            variant="contained"
            startIcon={<img src={addStudent} alt="add student" />}
            style={{
              background: "#F79E1B",
              color: "white",
              alignSelf: "flex-end",
              textTransform: "none",
            }}
            click={() => {
              handleEditButton();
            }}
          />
          <PrimaryButton
            width={{
              sm: "50%",
              md: "15%",
            }}
            text="Delete Student"
            startIcon={<img src={whiteTrash} alt="trash" />}
            variant="contained"
            style={{
              background: errorColor,
              color: "white",
              alignSelf: "flex-end",
              textTransform: "none",
            }}
            click={() => {
              setOpenDeleteModel(true);
              setSelectedRow(data);
            }}
          />
          <PrimaryButton
            width={{
              sm: "50%",
              md: "15%",
            }}
            text={isActive ? "Suspend" : "Activate"}
            startIcon={<img src={deactivate} alt="trash" />}
            variant="outlined"
            style={{
              // background: errorColor,
              color: isActive ? orangeBtn : easy,
              alignSelf: "flex-end",
              textTransform: "none",
              border: `1px solid ${isActive ? orangeBtn : easy}`,
            }}
            click={() => {
              isActive ? setOpenSuspendModel(true) : setOpenReactiveModel(true);
            }}
          />
        </Stack>
        <Stack flexDirection="row" height="100%">
          <Stack
            width={{
              sm: "100%",
              md: "70%",
              lg: "75%",
            }}
            padding={{ md: "20px" }}
            spacing={3}
          >
            <Stack width="100%" height="auto" spacing={3}>
              <TableContainer component={Paper} sx={{ background: "white", borderRadius: "12px" }}>
                <Typography m={2} fontWeight="600" fontSize="16px">
                  Student Details
                </Typography>
                <Divider style={{ width: "100%" }} />
                <Badge
                  sx={{ margin: "20px" }}
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                >
                  <Avatar
                    alt="Travis Howard"
                    sx={{ width: "90px", height: "90px" }}
                    src={student?.profile_picture_url}
                  />
                </Badge>
                <Table aria-label="customized table">
                  <TableBody>
                    {data?.map((data, index) => {
                      return (
                        <StyledTableRow key={index}>
                          <StyledTableCell sx={{ width: "10%", color: "#2C2D33" }}>
                            {data?.title}
                          </StyledTableCell>
                          <StyledTableCell width="25%" sx={{ color: "#6E7079" }}>
                            {data?.value}
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TableContainer component={Paper} sx={{ background: "white", borderRadius: "12px" }}>
                <Typography m={2} fontWeight="600" fontSize="16px">
                  Configurations
                </Typography>
                <Grid
                  m={2}
                  container
                  width={"80%"}
                  direction={"column"}
                  justifyContent={"space-between"}
                  sx={{ borderTop: `1px solid ${borderColor} !important` }}
                >
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={student?.is_phone_verified || ""}
                          disabled
                          value="is_phone_verified"
                          name="is_phone_verified"
                          size="small"
                          style={{ color: "#F79E1B" }}
                        />
                      }
                      label={
                        <Typography color={gray} fontWeight={500} fontSize={"12px"}>
                          Is Phone Verified
                        </Typography>
                      }
                    />
                  </Grid>

                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={student?.is_security_enabled || ""}
                          value="is_security_enabled"
                          name="is_security_enabled"
                          style={{ color: "#F79E1B" }}
                          size="small"
                          disabled
                        />
                      }
                      label={
                        <Typography color={gray} fontWeight={500} fontSize={"12px"}>
                          Is Security Enabled
                        </Typography>
                      }
                    />
                  </Grid>

                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={student?.has_many_devices || ""}
                          value="has_many_devices"
                          name="has_many_devices"
                          size="small"
                          style={{ color: "#F79E1B" }}
                          disabled
                        />
                      }
                      label={
                        <Typography color={gray} fontWeight={500} fontSize={"12px"}>
                          Has Many Devices
                        </Typography>
                      }
                    />
                  </Grid>
                </Grid>
                <Divider />
              </TableContainer>
            </Stack>
          </Stack>
          {/* <Stack mb={3} pt={2} sx={{ width: "20%" }}>
            <Typography fontWeight="500" fontSize="16px">
              Product
            </Typography>
            <Stack
              mt={1}
              rowGap={2}
              p={2}
              sx={{ background: "white", borderRadius: "12px" }}
              width={"100%"}
            >
              {sideActions?.map((action, i) => (
                <Stack
                  sx={{ cursor: "pointer" }}
                  key={i}
                  direction={"row"}
                  gap="5px"
                  alignItems={"center"}
                >
                  <img width={"14px"} height={"14px"} src={action.icon} alt={action?.alt} />
                  <Typography fontWeight="500" fontSize="14px">
                    {action?.title}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          </Stack> */}
        </Stack>
        <PaidCourses />
        <StudentQuizes />
        <StudentAssignments />
        <DeleteModal
          open={openDeleteModel}
          text={`Are you sure you want to delete "${student?.name}" student ?`}
          handelDeleteRequest={handleDelete}
          handleClose={(_) => handleCloseModel(setOpenDeleteModel)}
        />
        <DeleteModal
          open={openSuspendModel}
          text={`Are you sure you want to Suspended "${student?.name}" student ?`}
          handelDeleteRequest={handleSuspened}
          handleClose={(_) => handleCloseModel(setOpenSuspendModel)}
          btnTitle="Suspend"
          isLoading={loading?.loading && loading?.type == "suspend-student"}
        />
        <DeleteModal
          open={openReactiveModel}
          text={`Are you sure you want to Activate "${student?.name}" student ?`}
          handelDeleteRequest={handleReactive}
          handleClose={(_) => handleCloseModel(setOpenReactiveModel)}
          btnTitle="Activate"
          bgcolor={easy}
          isLoading={loading?.loading && loading?.type == "active-student"}
        />
        <EditStudentModel selectedRow={student} action={editAction} />
      </Stack>
    </>
  );
};

export default StudentDetailsTemplate;
