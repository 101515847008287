import { Divider, Stack, Typography,Box,Button,Grid} from "@mui/material"
import React from "react"
import QuestionCard from "./questionCard"
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
const PaginatedQuestions = ({quizData,data,page,count})=>{
const question={
    quiz_title:'Lorem ipsum dolor sit amet',
    grade:'4',
    grade_out_of:'4',
    grade_percentage:'85%',
    passing_percentage:'60%',
    passed:true,
    question_text:'Lorem ipsum dolor sit amet, consetetur sadimpor Lorem ipsum dolor sit amet, consetetur sadimpor Lorem ipsum dolor sit amet, consetetur sadimpor ',
    question_img:null,
    chosen_choice_id:1,
    choices:[
        {
            id:1,
            text:'cieties, it can be deferred, subsumed, subjected',
            img:null,
            isCorrect:true
        },
        {
            id:2,
            text:'cieties, it can be deferred, subsumed, subjected',
            img:null,
            isCorrect:false
        },
        {
            id:3,
            text:'cieties, it can be deferred, subsumed, subjected',
            img:null,
            isCorrect:false
        }
    ]
}
return(
    <Stack flexDirection={'column'} gap={1}>
            {data?.questions?.map((question,index)=>{
        return(
            <QuestionCard key={question?.quiz_question_id} question={question} attempt_info={data?.attempt_info} quizData={quizData}/>
        )
            })}

        <Grid container alignItems={'center'} justifyContent={'space-between'}>
            <Grid item>
                <Button startIcon={<ArrowBackIosNewIcon />} style={{textTransform: 'none',fontSize:'17px',fontWeight:700,color:"#118ACB66"}}>
                    Previous
                </Button>
            </Grid>
            <Grid item>
                <Typography fontSize={'20px'} fontWeight={700}>
                    {page}/{count}
                </Typography>
            </Grid>
            <Grid item>
                <Button endIcon={<ArrowForwardIosIcon />} style={{textTransform: 'none',fontSize:'17px',fontWeight:700,color:"#118ACB66"}}>
                    Next
                </Button>
            </Grid>


        </Grid>
    </Stack>
)

}
export default PaginatedQuestions