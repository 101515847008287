import Types from "../Types";

// quizzes
export const deleteCourseQuizSuccess = (payload) => ({
  type: Types.DELETE_COURSE_QUIZ_SUCCESS,
  payload,
});

export const getAllCourseQuizzesSuccess = (payload) => ({
  type: Types.GET_ALL_COURSE_QUIZZES_SUCCESS,
  payload,
});
export const getCourseSectionQuizzesSuccess = (payload) => ({
  type: Types.GET_COURSE_SECTION_QUIZ_SUCCESS,
  payload,
});

export const getStudentQuizzesSuccess = (payload) => ({
  type: Types.GET_STUDENT_QUIZES_SUCCESS,
  payload,
});
