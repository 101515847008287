/* eslint-disable array-callback-return */
import Types from "./Types";

const QUIZ_INIT_STATE = {
  questions: [],
  quizData: {},
  quizzes: [],
  quizQuestionBank: [],
  submissions:[],
  submission:{},
  questions_count:0,
  counts:0,
  isLoading: false,
  isLoadingDelete: false,
};

export function quizReducer(state = QUIZ_INIT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.CLEAR_QUIZ_DATA: {
      return {
        ...state,
        quizData: {},
        questions: [],
      };
    }
    case Types.CREATE_QUIZ: {
      return {
        ...state,
        quizData: payload.data,
      };
    }
    case Types.CREATE_QUESTION: {
      return {
        ...state,
        questions: state.questions.concat({
          data: payload.data,
          type: "question",
          index: state.questions.length,
        }),
      };
    }
    case Types.SHOW_CURRENT_QUIZ: {
      return {
        ...state,
        quizData: payload.data,
      };
    }
    case Types.GET_ALL_QUIZZES_SUCCESS: {
      return {
        ...state,
        quizzes: payload.data,
      };
    }
    case Types.DELETE_QUIZ_SUCCESS: {
      return {
        ...state,
        quizData: {},
        questions: [],
      };
    }
    case Types.GET_QUIZ_QUESTIONS: {
      // map hna 3la el question w a3ml array gded kol so2l feh el type w el index w data = question = payload
      let newArr = payload?.data?.map((data, i) => {
        return { data, type: "question", index: i };
      });
      return {
        ...state,
        questions: newArr,
      };
    }
    case Types.GET_QUESTIONS_BANK_SUCCESS: {
      let newArr = payload?.data?.map((data, i) => {
        return { data, type: "question", index: i };
      });
      return {
        ...state,
        quizQuestionBank: newArr,
      };
    }
    // case Types.GET_QUESTIONS_BANK: {
    //     let newArr = payload?.data?.questions?.map((data, i) => { return { data, type: 'question', index: i } })
    //     return {
    //         ...state,
    //         questions: newArr
    //     }
    // }
    case Types.SET_SELECTED_QUESTIONS_SUCCESS: {
      let newArr = payload?.data?.questions?.map((data, i) => {
        return { data, type: "question", index: i };
      });

      return {
        ...state,
        questions: newArr,
      };
    }
    case Types.UPDATE_QUESTION: {
      let newData = payload.data;
      let questionArr = state.questions;
      let index;

      questionArr?.map((val, i) => {
        if (val?.data?.quiz_question_id === newData?.quiz_question_id) index = i;
      });

      questionArr[index] = {
        type: questionArr[index]?.type,
        index: index,
        data: newData,
      };

      return {
        ...state,
        questions: questionArr,
      };
    }
    case Types.UPDATE_QUIZ: {
      return {
        ...state,
        quizData: payload.data,
      };
    }
    case Types.CREATE_CHOICES: {
      let newData = payload.data;
      let questionArr = state.questions;
      let index;
      questionArr?.map((val, i) => {
        if (val?.data?.quiz_question_id === newData?.quiz_question_id) index = i;
      });
      questionArr[index] = {
        type: questionArr[index]?.type,
        index,
        data: newData,
      };
      return {
        ...state,
        questions: questionArr,
      };
    }

    case Types.UPDATE_CHOICES: {
      let newData = payload.data;
      let questionArr = state.questions;
      let index;

      questionArr?.map((val, i) => {
        if (val?.data?.quiz_question_id === newData?.quiz_question_id) index = i;
      });
      questionArr[index] = {
        type: questionArr[index]?.type,
        index,
        data: newData,
      };
      return {
        ...state,
        questions: questionArr,
      };
    }
    case Types.DELETE_CHOICES: {
      let newData = payload.data;
      let questionArr = state.questions;
      let index;
      //console.log(newData);
      questionArr?.map((val, i) => {
        if (val?.data?.quiz_question_id === newData?.quiz_question_id) index = i;
      });
      questionArr[index] = {
        type: questionArr[index]?.type,
        index,
        data: newData,
      };
      return {
        ...state,
        questions: questionArr,
      };
    }
    case Types.DELETE_QUESTION: {
      let newData = payload.data;
      let questionArr = state.questions;
      const filteredData = questionArr?.filter(
        (val) => val?.data?.quiz_question_id !== newData?.quiz_question_id
      );
      return {
        ...state,
        questions: filteredData,
      };
    }
    case Types.DELETE_QUESTION_LOADING: {
      return {
        ...state,
        isLoadingDelete: payload,
      };
    }

    case Types.GET_QUIZ_SUBMISSIONS_SUCCESS:{
      return {
        ...state,
        submissions:payload?.data?.attempts,
        counts:payload?.extra?.total_count,
      }
    }

    case Types.GET_QUIZ_SUBMISSION_SUCCESS:{
      return {
        ...state,
        submission:payload?.data,
        questions_count:payload?.extra?.total_count
      }
    }

    default: {
      return state;
    }
  }
}
