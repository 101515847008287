import { FormControl, FormHelperText, InputLabel, MenuItem, Typography } from "@mui/material";
import React, { useMemo } from "react";
import Select from "@mui/material/Select";
import "./Select.css";
import { useThemePalette } from "../../../common/hooks/theme_palette";
import { theme } from "styles/theme";
import InfiniteScroll from "react-infinite-scroll-component";
function CustomListboxComponent(props) {
  const { children, ...other } = props;
  //console.log(children.length);
  return (
    <InfiniteScroll
      dataLength={children.length}
      next={props.fetchMoreOptions}
      hasMore={props.hasMore}
      scrollableTarget={props.id}
    >
      <ul {...other} style={{ maxHeight: "200px" }}>
        {children}
      </ul>
    </InfiniteScroll>
  );
}

function SelectBox({
  items,
  title,
  value,
  onchange,
  id,
  name,
  error,
  helperText,
  labelStyle,
  label,
  style,
  disabled,
  className,
  defaultValue,
  placeholder,
  sx,
  variant = "standard",
  onclick = () => {},
  inputLabel,
  onClick,
}) {
  // const [value, setValue] = React.useState("");
  // const handleChange = (event) => {
  //   setValue(event.target.value);
  // };

  const { inputBg, labelColor, primaryColor ,gray} = useThemePalette();

  const defaultStyle = {
    color: labelColor,
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "15px",
    my: 1,
  };
  const mainLabelStyle = useMemo(() => {
    return labelStyle ? labelStyle : defaultStyle;
  }, [labelStyle]);
  return (
    <FormControl
      disabled={disabled}
      fullWidth
      style={style}
      error={error ? true : false}
      className={className}
    >
      <InputLabel // Use InputLabel
        htmlFor="demo-simple-select"
        sx={{ color: primaryColor, fontSize: "14px" }}
      >
        {placeholder}
      </InputLabel>
      {/* {label && (
        <span className={labelStyle}>{label}</span>
      )} */}
      {label && (
        <Typography as="span" sx={mainLabelStyle}>
          {label}
        </Typography>
      )}{" "}
      <Select
        displayEmpty
        id={id}
        name={name}
        defaultValue={defaultValue}
        inputProps={{ "aria-label": "Without label" }}
        value={value}
        onChange={onchange && onchange}
        label={label}
        placeholder={placeholder}
        variant={variant}
        MenuComponent={(props) => (
          <InfiniteScroll
            dataLength={items.length}
            next={fetchMoreOptions}
            hasMore={hasMore}
            scrollableTarget={id}
          >
            <ul {...props} style={{ maxHeight: "200px", overflowY: "auto" }}>
              {props.children}
            </ul>
          </InfiniteScroll>
        )}
        sx={{
          width: "100%",
          border: "none",
          background: inputBg,
          textAlign: "left",
          ...sx,
        }}
      >
        <MenuItem
          value=""
          disabled
          style={{
            direction: localStorage.getItem("lang") === "en" ? "rtl" : "ltr",
            color: "GrayText",
          }}
        >
          {title}
        </MenuItem>
        {items?.map((item) => (
          <MenuItem
            value={item.id}
            key={item.id}
            onClick={(e) => {
              onclick(item.id);
            }}
            style={{
              direction: localStorage.getItem("lang") === "en" ? "rtl" : "ltr",
              // in some cases, we want the inputLabel to remain present as a place holder
              // to achieve that we have to include the placeholder in the options [{id:"', name:"<placeholder', disabled:true},...options]
              // and the disable prop. to hide it from the menu
              // the value has to be 0
              display: item?.disable && "none",
              ...item?.style,
            }}
            sx={{
              "&.Mui-selected": {
                bgcolor: gray,
              },
            }}
          >
            {item.icon ? <img style={{ margin: "0 10px" }} src={item.icon} alt="item icon" /> : ""}{" "}
            {item.name}
          </MenuItem>
        ))}
      </Select>
      {error ? <FormHelperText>{helperText}</FormHelperText> : ""}
    </FormControl>
  );
}

export default SelectBox;
