import Logo from "./images/logo.png"
import Usersetting from "./icons/usersetting.svg"
import Home from "./icons/home.svg"
import User from "./icons/user.svg"
import Assistant from "./icons/assistant.svg"
import Courses from "./icons/courses.svg"
import Setting from "./icons/setting.svg"
import imageIcon from './icons/image.svg';
import deleteIcon from './icons/deleteIcon.svg';
import editIcon from './icons/edit.svg';
import videoIcon from './icons/video.svg';
import exportIcon from './icons/export.svg';
import plusCircleIcon from './icons/plus-circle.svg';
import searchIcon from './icons/search.svg';
import filterIcon from './icons/filter.svg';
import AddPersonIcon from './icons/add-person.svg'
import SourceIcon from './icons/source.svg'
import AttachedIcon from './icons/attached.svg'
import EmptyQuestionsIcons from './icons/empty-questions.svg'
import QuestionBankIcon from './icons/question-bank.svg'
import downloadIcon from './icons/downloadIcon.svg'
import Mask from './dummy/Mask.png'
import deleteRedIcon from './icons/deleteRedIcon.svg'
import newAssginmentIcon from './icons/newAssignmentIcon.svg'
import correct from './icons/correct.svg'
import addCircleIcon from './icons/addCircle.svg'
import importIcon from './icons/importIcon.svg'
import youtubeIcon from './icons/youtubeIcon.svg'
import uploadIcon from './icons/uploadIcon.svg'
import correctIcon from './icons/correctIcon.svg'
import wrongIcon from './icons/wrongIcon.svg'
import correctGreenIcon from './icons/correctGreen.svg'
import wrongRedIcon from './icons/wrongRed.svg'
import imageIconBlue from './icons/imageIcon.svg'
import DeleteAccount from "./icons/DeleteAccount.png";
import Exclamationmark from "./icons/Exclamationmark.svg";
import Success from "./icons/Success.svg";
import NotFound from "./icons/NotFound.svg";
import bookmark from "./icons/bookmark.svg";
import device from "./icons/device.svg";
import task from "./icons/task.svg";
import purchased from "./icons/purchased.svg";
import greenCircle from "./icons/greenCircle.svg";

import csvIcon from './icons/csvFile.svg'
export const dummy = {
    Mask
}

export const images = {
    Logo
}

export const icons = {
    greenCircle,
    purchased,
    bookmark,
    task,
    device,
    DeleteAccount,
    Exclamationmark,
    Success,
    NotFound,
    Usersetting,
    Home,
    User,
    Assistant,
    Courses,
    Setting,
    imageIcon,
    deleteIcon,
    editIcon,
    videoIcon,
    exportIcon,
    plusCircleIcon,
    searchIcon,
    filterIcon,
    AddPersonIcon,
    SourceIcon,
    AttachedIcon,
    EmptyQuestionsIcons,
    QuestionBankIcon,
    newAssginmentIcon,
    deleteRedIcon,
    downloadIcon,
    correct,
    addCircleIcon,
    importIcon,
    uploadIcon,
    youtubeIcon,
    correctIcon,
    wrongIcon,
    correctGreenIcon,
    wrongRedIcon,
    imageIconBlue,
    csvIcon
}