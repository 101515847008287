import Types from "./Types";

export const CreateQuizSuccess = (payload) => ({
  type: Types.CREATE_QUIZ,
  payload,
});

export const QuizLoading = (payload) => ({
  type: Types.QUIZ_LOADING,
  payload,
});

export const ShowCurrentQuizSuccess = (payload) => ({
  type: Types.SHOW_CURRENT_QUIZ,
  payload,
});

export const CreateQuestionSuccess = (payload) => ({
  type: Types.CREATE_QUESTION,
  payload,
});



export const UpdateQuestionSuccess = (payload) => ({
  type: Types.UPDATE_QUESTION,
  payload,
});



export const UpdateQuizSuccess = (payload) => ({
  type: Types.UPDATE_QUIZ,
  payload,
});


export const CreateChoicesSuccess = (payload) => ({
  type: Types.CREATE_CHOICES,
  payload,
});


export const UpdateChoicesSuccess = (payload) => ({
  type: Types.UPDATE_CHOICES,
  payload,
});


export const DeleteChoicesSuccess = (payload) => ({
  type: Types.DELETE_CHOICES,
  payload,
});

export const DeleteQuestionSuccess = (payload) => ({
  type: Types.DELETE_QUESTION,
  payload,
});



export const DeleteQuizSussess = () => ({
  type: Types.DELETE_QUIZ_SUCCESS,
});

export const GetQuizQuestionsSusses = (payload) => ({
  type: Types.GET_QUIZ_QUESTIONS,
  payload,
});

export const GetAllQuizzezSuccess = (payload) => ({
  type: Types.GET_ALL_QUIZZES_SUCCESS,
  payload,
});

export const GetQuestionsBankSuccess = (payload) => ({
  type: Types.GET_QUESTIONS_BANK_SUCCESS,
  payload,
});

export const setSelectedQuestionsSuccess = (payload) => ({
  type: Types.SET_SELECTED_QUESTIONS_SUCCESS,
  payload,
});

// export const GetQuestionBankSuccess = (payload) =>({
//     type: Types.GET_QUESTIONS_BANK,
//     payload
// })

export const CreateQuestionModelAnswerSuccess = (payload) => ({
  type: Types.CREATE_QUESTION_MODEL_ANSWER_SUCCESS,
  payload,
});

export const UpdateQuestionModelAnswerSuccess = (payload) => ({
  type: Types.UPDATE_QUESTION_MODEL_ANSWER_SUCCESS,
  payload,
});

export const ClearQuizData = (payload) => ({
  type: Types.CLEAR_QUIZ_DATA,
  payload,
});
//above code need refactoring , it's old

export const GetQuizSubmissionsSuccess =(payload)=>({
  type:Types.GET_QUIZ_SUBMISSIONS_SUCCESS,
  payload,
});

export const GetQuizSubmissionSuccess =(payload)=>({
  type:Types.GET_QUIZ_SUBMISSION_SUCCESS,
  payload,
});


export const ImportCSVQuestions =(payload)=>({
  type:Types.IMPORT_CSV_QUESTIONS_SUCCESS,
  payload,
});


