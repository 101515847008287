import { useEffect, useState } from "react";
import { Button, Fade, Menu, MenuItem, Stack } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import CTable from "components/Molecules/Table/table";
import PrimaryButton from "components/Atoms/Buttons/Primary/PrimaryButton";
import { createCourseModel } from "services/modules/models/Actions";
import CreateCourse from "components/Organisms/Courses/models/create";
import { useThemePalette } from "common/hooks/theme_palette";
import { useDispatch } from "react-redux";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import dayjs from "dayjs";
import {
  createAdminCourseRequest,
  getAdminCourseDetailsRequest,
} from "services/modules/courses/Requests/courses";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ListSkeleton from "components/Atoms/skeleton/listSkeleton";

function CoursesListTemplate({
  mappedData,
  viewData,
  setPageNumber,
  setPage,
  setPerPage,
  perPage,
  count,
  page,
  handleSearchChange,
  onRowClick
}) {
  const { secondryColor } = useThemePalette();
  //const [pageNumber, setPageNumber] = useState(0);
  const [checked, setChecked] = useState([false]);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const course = useSelector((state) => state.courses.course);
  const currentPage = useSelector((state) => state.courses.currentPage);
  const { isLoading } = useSelector((state) => state.courses);
  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  const currentDate = new Date();
  const endDate = new Date(currentDate);
  endDate.setMonth(endDate.getMonth() + 1);
  const formattedStartDate = formatDate(currentDate);
  const formattedEndDate = formatDate(endDate);

  const [createdCourse, setCourse] = useState();
  // useEffect(() => {
  // dispatch(getAdminCourseDetailsRequest({ course_id: course?.id }));
  // navigate(`/courses/${course?.id}`)
  // }, [course?.id, dispatch]);

  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    setCourse(course);
  }, [course]);
  const createCourseRequest = () => {
    const requestedData = {
      body: {
        course: {
          title: "Untitled Course",
          description: "Description",
          image_url: "",
          video_url: "",
          transcoded_video_url: "",
          level: "advanced",
          place: "",
          start_date: formattedStartDate,
          end_date: formattedEndDate,
          tools: [],
          technologies: [],
          classification: "",
          original_price_cents: 0,
          original_price_currency: "EGP",
          discount_percentage: "",
          max_quantity: 1000,
          is_price_hidden: false,
          rate_of_comments_per_day: "1",
          rate_of_comments_per_hour: "1",
        },
      },
      action: (data) => {
        console.log(data, "data");
        navigate(`/courses/${data?.data?.id}`);
      },
    };
    dispatch(createAdminCourseRequest(requestedData));
  };
  const filterList = (
    <div style={{ height: "40px" }}>
      <Button
        variant="outlined"
        id="fade-button"
        style={{ height: "100%" }}
        aria-controls={open ? "fade-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        startIcon={<FilterAltOutlinedIcon />}
        color="secondary"
      >
        Filter
      </Button>
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={handleClose}>Profile</MenuItem>
        <MenuItem onClick={handleClose}>My account</MenuItem>
        <MenuItem onClick={handleClose}>Logout</MenuItem>
      </Menu>
    </div>
  );
  const Data = mappedData?.map((data, index) => {
    return {
      ...data,

      created_at: <>{dayjs(data?.created_at).format("DD-MM-YYYY hh:mm a")}</>,
    };
  });
  const columns = [
    {
      Header: "ID",
      accessor: "id",
    },
    {
      Header: "Title",
      accessor: "title",
    },

    {
      Header: "Price",
      accessor: "original_price",
    },
    {
      Header: "Start date",
      accessor: "start_date",
    },
    {
      Header: "",
      accessor: "Actions",
    },
  ];
  return (
    <>
      <Stack mb={3} p={2} spacing={2}>
        <PrimaryButton
          width={{
            sm: "50%",
            md: "15%",
          }}
          text="Add Course"
          variant={"contained"}
          style={{
            background: secondryColor,
            color: "white",
            alignSelf: "flex-end",
            textTransform: "none",
          }}
          startIcon={<AddIcon />}
          click={() => createCourseRequest()}
        />
        <CTable
          selectedData={viewData}
          footerBtnTitle
          filterList={filterList}
          noFilter={false}
          noSearchBar={false}
          Data={Data || []}
          Columns={columns}
          Title="Course List"
          subTitle=""
          btnTitle=""
          placeHolder=""
          setPage={setPage}
          setPerPage={setPerPage}
          paginationPage={page}
          handlePageChange={setPageNumber}
          perPage={perPage}
          total_count={count}
          checked={checked}
          setChecked={setChecked}
          handleSearchChange={handleSearchChange}
          onRowClick={onRowClick}
        />
      </Stack>

      {/* create course model */}
      <CreateCourse />
    </>
  );
}

export default CoursesListTemplate;
