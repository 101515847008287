import { useState } from "react";
import { Button, Fade, Menu, MenuItem, Stack } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import CTable from "components/Molecules/Table/table";
import PrimaryButton from "components/Atoms/Buttons/Primary/PrimaryButton";
// import { createCourseModel } from "services/modules/models/Actions";
// import CreateCourse from "components/Organisms/Courses/models/create";
import { useThemePalette } from "common/hooks/theme_palette";
import { useDispatch } from "react-redux";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";

function AssignmentListTemplate({ mappedData, viewData, setPage, setPerPage, perPage,tab }) {
  const { secondryColor } = useThemePalette();
  const [pageNumber, setPageNumber] = useState(0);
  const [checked, setChecked] = useState([false]);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const filterList = (
    <div style={{ height: "40px" }}>
      <Button
        variant="outlined"
        id="fade-button"
        style={{ height: "100%" }}
        aria-controls={open ? "fade-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        startIcon={<FilterAltOutlinedIcon />}
        color="secondary"
      >
        Filter
      </Button>
      {/* <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={handleClose}>Profile</MenuItem>
        <MenuItem onClick={handleClose}>My account</MenuItem>
        <MenuItem onClick={handleClose}>Logout</MenuItem>
      </Menu> */}
    </div>
  );

  const columns = [
    {
      Header: "ID",
      accessor: "delivery_id",
    },
    // {
    //   Header: "Due Date",
    //   accessor: "submitted_date",
    // },
    {
      Header: "Name",
      accessor: "student.name",
    },
    {
      Header: "Email",
      accessor: "student.email",
    },
    {
      Header: "Submited Date",
      accessor: "submitted_date",
    },
    {
      Header: "Grade",
      accessor: "grade",
    },
    {
      Header: "Status",
      accessor: "status",
    },
    {
      Header: "",
      accessor: "Actions",
    },
  ];

  return (
    <>
        <CTable
          selectedData={viewData}
          footerBtnTitle
          filterList={filterList}
          noFilter={false}
          noSearchBar={false}
          Data={mappedData || []}
          Columns={columns}
          Title={tab+" asssignment"}
          subTitle=""
          btnTitle=""
          placeHolder=""
          setPage={setPage}
          setPerPage={setPerPage}
          currentpage={pageNumber}
          setPageNumber={setPageNumber}
          perPage={perPage}
          totalPage={Math.ceil(1 / 10)}
          checked={checked}
          setChecked={setChecked}
        />
    </>
  );
}

export default AssignmentListTemplate;
