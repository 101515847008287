import { CircularProgress, Stack, Typography } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import { forgetPasswordSchema, resetPasswordSchema } from "common/utils/schemas";
import Form from "components/Molecules/Forms/form";
import FormField from "components/Molecules/Forms/formField";
import SubmitButton from "components/Molecules/Forms/submitButton";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { resetPasswordRequest } from "services/modules/authentication";
import { Organization_name } from "services/modules/utils/constant";

const ResetPassword=()=>{
    const {fontColor}=useThemePalette();
    const email = localStorage.getItem("forgetPasswordEmail");
    const navigate=useNavigate();
    const dispatch=useDispatch();
    const{load}=useSelector((state)=>state.auth)

    const token = localStorage.getItem("verification-token");

    const handleResetPassword=(values,{setSubmitting})=>{
        const requestData={
        body:{
        user:{
            ...values,
            email

        },
        "device": {
            "uid": "1",
            "fcm_token": "il"
        }
    },
    headers: {
        "verification-token": token,
        "X-Organization-Domain":Organization_name

      },
    action:()=>{
        navigate('/')
        toast.success('Password has been reset successfully ')
    }
   }
   dispatch(resetPasswordRequest(requestData))
   setSubmitting(false);

   
    }
    return(
        <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'center'} width={'100%'} height={'100vh'}  sx={{backgroundColor:'#F1F4F6'}}>
    <Stack p={5} postion={'relative'}   width={'50%'} height={'auto'} sx={{backgroundColor:'white'}}>
      <Stack sx={{ gap: 4 }}>
        <Typography  textAlign={'center'} fontFamily="Inter" fontSize="16px" fontWeight='600'>
          Reset Password
        </Typography>
        <Form
          initialValues={{
            password: "",
            password_confirmation: "",
          }}
          validationSchema={resetPasswordSchema}

          onSubmit={(values, { setSubmitting }) => {
            handleResetPassword(values,{setSubmitting});
          }}
        >
          <Stack gap={1}>
          <Stack sx={{ gap: 4 }}>
           
            <FormField
              label="New Password*"
              name="password"
              labelStyle={{ fontSize: "12px",fontWeight:'400',mb:1,color:fontColor }}
              type="password"
              placeholder="***********"
            />
            <FormField
              label="Confirm New Password*"
              name="password_confirmation"
              labelStyle={{ fontSize: "12px",fontWeight:'400',mb:1,color:fontColor }}
              type="password"
              placeholder="***********"
            />
          </Stack>
            
            <Stack direction="row"
                   alignItems="center">
              <SubmitButton isSubmitting={false} sx={{height:'52px',fontSize: "12px",fontWeight:'400',mb:1}} >
              {load?.loading ? <
                CircularProgress size={20} color="inherit" /> : "Done"}

                </SubmitButton>
            </Stack>
          </Stack>
        </Form>
      </Stack>
    </Stack>
    </Stack>
    )

}
export default ResetPassword