import Types from "./Types";
const INIT_STATE = {
  currentUser: {},
  Otp:'',
  load: null,
};
export default function authReducer(state = INIT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.AUTH_SUCCESS: {
      return {
        ...state,
        load: null,
      };
    }
    case Types.AUTH_LOADING: {
      return {
        ...state,
        load: payload,
      };
    }
    case Types.DELETE_ACCOUNT_SUCCESS: {
      return {
        ...state,
      };
    }
    case Types.GET_CURRENT_USER_INFO_SUCCESS: {
      return {
        currentUser: payload?.data?.user,
        load: null,
      };
    }
    case Types.GET_OTP_SUCCESS: {
      return {
        ...state,
        Otp: payload?.data,
      };
    }
    case Types.VERIFY_OTP_SUCCESS: {
      return {
        ...state,
      };
    }
    case Types.RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
      };
    }
    case Types.LOGOUT_SUCCESS: {
      return {
        ...INIT_STATE,
      };
    }
    default: {
      return state;
    }
  }
}