import { Dialog, Typography,Stack, Box, Grid, Button,CircularProgress, LinearProgress } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getPresignedLinkAPI, uploadFileAPI } from "services/modules/utils/Upload";
import { useThemePalette } from "common/hooks/theme_palette";
import LinearWithValueLabel from "../Loader/uploadFileLoader";
import { getPresignedUrlRequest } from "services/modules/uploadFile";

const UploadModel=({
  openVar,
  setVideoFileName,
  formik,
  closeFunc,
  instantly_dispatch=false,
  setOpenUploadModel,titleStyle,title,type,id,text})=>{
    const [fileUrl, setFileUrl] = useState(null);
  const [file, setFile] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [error, setError] = useState(null);
  const dispatch=useDispatch();
  const {orangeBtn}=useThemePalette();
  const [progress, setProgress] = useState(0);
  const [isUploadFinished,setIsUploadFinished]=useState(false)

  const onUploadProgress = (progressEvent) => {
    //console.log("Upload Progress Event:", progressEvent);

    const totalLength = progressEvent.lengthComputable ? progressEvent.total :
     progressEvent.target.getResponseHeader('content-length') || 
     progressEvent.target.getResponseHeader('x-decompressed-content-length');
    const progress = Math.round((progressEvent.loaded * 100) / totalLength);
    setProgress(progress);
  };
  const handleClose = () => {
    setFileUrl(null);
    changeAction("");
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);
    const files = e.dataTransfer.files;

    if (files.length > 0) {
      const file = files[0];
       uploadFile(file, type,true);
    }
  };
  const handleStopUpload=()=>{
    setFile(null)
    setVideoFileName('')
  }
  useEffect(() => {
    return () => {
      // Clean up state or perform any necessary actions
      setFile(null);
      setProgress(0);
      setIsUploadFinished(false);
    };
  }, []);
  const uploadFile = async (event, type,drag) => {
    const file = drag?event:event.target.files[0];
    const extension = file?.type.slice(file?.type.indexOf("/") + 1);
    const res = await getPresignedLinkAPI([{ extension}]);
    const presignedUrl = res?.res?.data[0].presigned_url;
    //console.log(presignedUrl)
    setFile(file)
    //console.log(file)
    setIsUploadFinished(false)

    const upload = await uploadFileAPI(presignedUrl, file,onUploadProgress);
    //console.log(upload)

    if (upload) {
      
      setIsUploadFinished(true)
      setVideoFileName(file?.name);
      //console.log(res?.res?.data[0].file_url)
      if(type==='video'){
        formik.setFieldValue("video_url", res?.res?.data[0].file_url)
        // b7dd mn el intro wla mn el edit course 3shan el intro by request 3la tol 
        if(instantly_dispatch){
          formik.setFieldValue("url_link_type", 'upload')
        }
        else{
          formik.setFieldValue("url_link_type", 'general')

        }

      }
      else{
        formik.setFieldValue("image_url", res?.res?.data[0].file_url);

      }

    setOpenLoader(false);
      //console.log("2 seconds have passed!");
      
  };
}

 
    return(
        <Dialog
            open={openVar}
            onClose={closeFunc}
            PaperProps={{
                sx: {
                    borderRadius: "12px",
                    height: 'auto',
                    minWidth: {lg:'525px' , md:'800px'  , sm:'500px'},
                    padding: '20px',
                }
            }}
        >
            <Stack direction='row' alignItems='center' justifyContent='space-between' mb={2} mx={1}>
                <Typography
                    sx={titleStyle}
                    component="h2"
                >
                    {title}
                </Typography>
                <Stack
                    onClick={closeFunc}
                    sx={{
                        cursor: 'pointer',
                        borderRadius: '8px',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '4px'
                    }}
                >
                                    <CloseIcon fontSize='14px' />

                </Stack>

                
            </Stack>
            <Grid
            item
            xs={12}
            sx={{
              border: " 1px dashed rgba(0, 0, 0, 0.25)",
              borderRadius: "10px",
            }}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDragOver={(e) => e.preventDefault()}
            onDrop={(e)=>handleDrop(e,'video')}
          >
            <label htmlFor={`upload-${id}`}>
              <input
                id={`upload-${id}`}
                name={`upload-${id}`}
                type="file"
                style={{ display: "none" }}
                onChange={(event)=>{uploadFile(event,type,false)}}
              />
              <Stack alignItems="center" rowGap={"24px"} py="3.5rem">
               
                  <>
                    <Typography fontSize="13px">Select a file or drag and drop here</Typography>
                   
                    <Button
                      component="span"
                      variant="contained"
                      sx={{
                        fontSize: "15px",
                        px: "16px",
                        textTransform:'none',
                      }}
                    >
                      Select File
                    </Button>
                  </>
              </Stack>
            </label>
          </Grid>
          {file&&
          <>
          <LinearWithValueLabel 
          progress={progress} 
          setProgress={setProgress} 
          file={file}
          stopUpload={handleStopUpload}>
          </LinearWithValueLabel>    
          
          <Stack flexDirection={'row'} gap={4} justifyContent={'center'} alignItems={'center'} mt={2}>
          <Button 
          variant="outlined"
           sx={{textTransform:'none',color:orangeBtn,borderColor:orangeBtn,
           '&:hover':{color:orangeBtn,borderColor:orangeBtn,background:'white'}}} 
           onClick={()=>{
            setOpenUploadModel(false)
            setVideoFileName('')
          }
            }>
            Cancel
          </Button>
          <Button 
          variant="contained" 
          sx={{textTransform:'none',background:orangeBtn,'&:hover':{background:orangeBtn}}}
          onClick={()=>{
            setOpenUploadModel(false)
            setFile(null);
            instantly_dispatch&&formik?.handleSubmit()
            
          }}
          disabled={!isUploadFinished}
          >
            Uplaod
          </Button>
          </Stack>
          </>
}
            
        </Dialog>
    )

}
export default UploadModel