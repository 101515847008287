import Types from "./Types";
export const AuthSuccess = (payload) => ({
  type: Types.AUTH_SUCCESS,
  payload,
});
export const AuthLoading = (payload) => ({
  type: Types.AUTH_LOADING,
  payload,
});
export const GetCurrentUserInfoSuccess = (payload) => ({
  type: Types.GET_CURRENT_USER_INFO_SUCCESS,
  payload,
});
export const logoutSuccess = (payload) => ({
  type: Types.LOGOUT_SUCCESS,
});
export const getOTPSuccess = (payload) => ({
  type: Types.GET_OTP_SUCCESS,
});
export const verifyOTPSuccess = (payload) => ({
  type: Types.VERIFY_OTP_SUCCESS,
});
export const resetPasswordSuccess = (payload) => ({
  type: Types.RESET_PASSWORD_SUCCESS,
});
export const DeleteAccountSuccess = (payload) => ({
  type: Types.DELETE_ACCOUNT_SUCCESS,
  payload,
});