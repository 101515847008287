import Types from "../Types";
export const getCourseInstructorsSuccess = (payload) => ({
    type: Types.GET_COURSE_INSTRUCTORS_SUCCESS,
    payload,
});
export const addCourseInstructorsSuccess = (payload) => ({
    type: Types.ADD_COURSE_INSTRUCTORS_SUCCESS,
    payload,
});
export const deleteCourseInstructorsSuccess = (payload) => ({
    type: Types.DELETE_COURSE_INSTRUCTORS_SUCCESS,
    payload,
});