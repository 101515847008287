import { Button, Grid,CircularProgress,Stack,Box,Typography } from "@mui/material";
import TextFieldInput from "../TextField/TextFieldInput";
import { useState } from "react";
import UploadModel from "components/Atoms/Model/uploadModel";

function UploadInput({ placeholder, btnStyle, inputStyle, btnText, change, id, textValue,
  openModel=false ,setOpenUploadModel}) {
 
  return (
    <Stack flexDirection={'column'}>
      


    <Grid container>
      <Grid item xs={8}>
        <TextFieldInput sx={inputStyle} placeholder={placeholder} value={textValue} />
      </Grid>
      <Grid item xs={4}>
        <label htmlFor={`upload-${id}`}>
          {!openModel&&
          <input
            id={`upload-${id}`}
            name={`upload-${id}`}
            type="file"
            style={{ display: "none" }}
            onChange={change}
          />}
          <Button component="span" onClick={()=>setOpenUploadModel(true)} style={btnStyle}>
            {btnText}
          </Button>
        </label>
      </Grid>
     
    </Grid>
    </Stack>

  );
}

export default UploadInput;
