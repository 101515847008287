const authTypes = {
  AUTH_SUCCESS: "AUTH_SUCCESS",
  AUTH_LOADING: "AUTH_LOADING",
  GET_OTP_SUCCESS:"GET_OTP_SUCCESS",
  VERIFY_OTP_SUCCESS:"VERIFY_OTP_SUCCESS",
  RESET_PASSWORD_SUCCESS:"RESET_PASSWORD_SUCCESS",
  GET_CURRENT_USER_INFO_SUCCESS: "GET_CURRENT_USER_INFO_SUCCESS",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  DELETE_ACCOUNT_SUCCESS: "DELETE_ACCOUNT_SUCCESS",
};
export default authTypes;