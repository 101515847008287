import Types from "./Types";

export const CreateInstructorSuccess = (payload) => ({
  type: Types.CREATE_INSTRUCTOR_SUCCESS,
  payload,
});

export const ShowInstructorDetailsSuccess = (payload) => ({
  type: Types.SHOW_INSTRUCTOR_DETAILS_SUCCESS,
  payload,
});

export const UpdateInstructorSuccess = (payload) => ({
  type: Types.UPDATE_INSTRUCTOR_SUCCESS,
  payload,
});

export const DeleteInstructorSussess = () => ({
  type: Types.DELETE_INSTRUCTOR_SUCCESS,
});

export const GetAllInstructorsSuccess = (payload) => ({
  type: Types.GET_ALL_INSTRUCTORS_SUCCESS,
  payload,
});

export const InstructorLoading = (payload) => ({
  type: Types.INSTRUCTOR_LOADING,
  payload,
});
