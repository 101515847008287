import { CoursesURL } from "../../utils/constant";
import { adminCoursesLoading } from "../Actions/courses"
import { getCourseInstructorsSuccess } from "../Actions/instrucrors"
import { reduxRequest } from "common/utils/reduxRequest";
import { addCourseSubjectsSuccess, getCourseSubjectsSuccess } from "../Actions/subjects";

export const getCourseSubjectsRequest = ({ ...variables }) => {
    const requestData = {
        isLoading: adminCoursesLoading,
        successFunction: getCourseSubjectsSuccess,
        url: `${CoursesURL}/subjects?course_id=${variables?.course_id}`,
        method: "get",
        loadingType: "list",
        ...variables,
    };
    return async (dispatch) => {
        reduxRequest({
            dispatch,
            ...requestData,
        });
    };
}
export const addCourseSubjectsRequest = ({ ...variables }) => {
    const requestData = {
        isLoading: adminCoursesLoading,
        successFunction: addCourseSubjectsSuccess,
        url: `${CoursesURL}/subjects?course_id=${variables?.course_id}&curriculum_id=${variables?.curriculum_id}&school_class_id=${variables?.school_class_id}&subject_id=${variables?.subject_id}`,
        method: "post",
        loadingType: "addCourseSubject",
        ...variables,
    };
    return async (dispatch) => {
        reduxRequest({
            dispatch,
            ...requestData,
        });
    };
}