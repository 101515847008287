import Types from "./Types";

const INIT_STATE = {
  files: [],
  activeIndex: undefined,
  load: {
    type: "",
    loading: false,
  },
  loadFile: {
    type: "",
    loading: false,
  },
  error: null,
};

export function filesUploadReducer(state = INIT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    //uploaded successfully
    case Types.FILE_UPLOAD_SUCCESS: {
      //console.log(payload);
      return {
        ...state,
        files: [...state.files, payload],
        // files: payload,
        error: null,
      };
    }

    case Types.FILE_UPLOAD_LOADING: {
      return {
        ...state,
        load: payload,
        error: null,
      };
    }
    case Types.FILE_UPLOAD_ON_SERVER_LOADING: {
      return {
        ...state,
        loadFile: payload,
      };
    }
    case Types.FILE_EMPTY: {
      return {
        ...state,
        files: [],
      };
    }
    case Types.GET_ACTIVE_INDEX: {
      return {
        ...state,
        files: state.files.filter((file) => file !== payload),
      };
    }
    case Types.FILE_UPLOAD_FAIL: {
      return {
        ...state,
        load: payload,
        error: true,
      };
    }
    default: {
      return state;
    }
  }
}
